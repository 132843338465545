/**
 * HOMES周辺施設画像最低出稿枚数
 */
export const Enum = {
  '1枚': '1',
  '2枚': '2',
  '3枚': '3',
  '4枚': '4',
  '5枚': '5',
  '6枚': '6',
  '7枚': '7',
  '8枚': '8',
  '9枚': '9',
  '10枚': '10',
} as const
