import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const YusenInitPriceCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['yusen_initprice_umu_kbn']}
      display={(yusen_initprice_umu_kbn) =>
        yusen_initprice_umu_kbn === miraieFields.yusen_initprice_umu_kbn.Enum.有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
