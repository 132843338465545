import { Heading, HeadingProps } from '@chakra-ui/react'

export const FormSectionHeading = (props: HeadingProps) => (
  <Heading
    fontSize="32px"
    lineHeight="36px"
    fontWeight="700"
    mt={4}
    mb={2}
    {...props}
  />
)
