import { Ooaza } from '@terass/common/src/firestore/Address'
import { doc, onSnapshot, query } from 'firebase/firestore'

import { useWatchAddressForm } from './useAddressForm'
import { useWatchAddressCodes } from './useWatchAddressCodes'

import { useObserver } from '@/hooks/useObserver'
import { DocumentNotFoundError } from '@/utils/errors'
import { getCollection } from '@/utils/firestore'

export const useOoazaList = (): {
  id: string
  data: Ooaza
}[] => {
  const prefectureCode = useWatchAddressForm('prefectureCode')
  const shikuchosonCode = useWatchAddressForm('shikuchosonCode')
  return useObserver({
    observerKey: ['ooazaList', prefectureCode, shikuchosonCode],
    observerFn: (
      onChange,
      onError,
      { observerKey: [, prefectureCode, shikuchosonCode] },
    ) =>
      onSnapshot(
        query(
          getCollection(
            'address',
            prefectureCode,
            'shikuchoson',
            shikuchosonCode,
            'ooaza',
          ),
        ),
        (snapshot) =>
          onChange(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })),
          ),
        onError,
      ),
  })
}

export const useOoaza = (): Ooaza => {
  const { prefectureCode, shikuchosonCode, ooazaCode } = useWatchAddressCodes()
  return useObserver({
    observerKey: ['ooaza', prefectureCode, shikuchosonCode, ooazaCode],
    observerFn: (
      onChange,
      onError,
      { observerKey: [, prefectureCode, shikuchosonCode, ooazaCode] },
    ) =>
      onSnapshot(
        doc(
          getCollection(
            'address',
            prefectureCode,
            'shikuchoson',
            shikuchosonCode,
            'ooaza',
          ),
          ooazaCode,
        ),
        (snapshot) => {
          const data = snapshot.data()
          if (data) onChange(data)
          else onError(new DocumentNotFoundError())
        },
      ),
  })
}
