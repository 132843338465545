import { useSuspenseQuery } from '@tanstack/react-query'

import { useOperator } from '@/hooks/useOperator'
import { fetchMediaPublishedRequestFutureForRent } from '@/utils/functions'

/**
 * 先物媒体掲載依頼の一覧を取得する
 */
export const useSakimonoBaitaiKeisaiIraiList = () => {
  const { email } = useOperator()
  const { data } = useSuspenseQuery({
    queryKey: ['fetchMediaPublishedRequestFutureForRent', email],
    queryFn: fetchMediaPublishedRequestFutureForRent,
  })

  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    data: data!,
  }
}
