import { Radio, RadioProps } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { ValueOf } from '@terass/common/src/types'
import { swapKeyValue } from '@terass/common/src/utils'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const ChidaiKubunRadios = (props: RadioProps) => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })
  const options = optionMap[bk_sbt_kbn] || []
  return (
    <>
      {options.map((value) => (
        <Radio key={value} value={value} {...props}>
          {swapKeyValue(miraieFields.chidai_kbn.Enum)[value]}
        </Radio>
      ))}
    </>
  )
}
const optionMap: {
  [K in miraieFields.bk_sbt_kbn.Enum]?: ValueOf<
    typeof miraieFields.chidai_kbn.Enum
  >[]
} = {
  [miraieFields.bk_sbt_kbn.Enum.売地]: [
    miraieFields.chidai_kbn.Enum.無,
    miraieFields.chidai_kbn.Enum.有,
  ],
  get [miraieFields.bk_sbt_kbn.Enum.売戸建住宅]() {
    return this[miraieFields.bk_sbt_kbn.Enum.売地]
  },
  [miraieFields.bk_sbt_kbn.Enum.売マンション]: [
    miraieFields.chidai_kbn.Enum.無,
    miraieFields.chidai_kbn.Enum.有,
    miraieFields.chidai_kbn.Enum.管理費含,
  ],
}
