import { ValueOf } from '../../../types'

/**
 * 物件種目区分
 */
export const Enum = {
  所有権譲渡: '1101',
  借地権譲渡: '1102',
  売戸建: '1201',
  売マンション: '1301',
  ['売戸建て(2戸以上)']: '1701',
  貸マンション: '3201',
  貸アパート: '3202',
  貸戸建住宅: '3203',
  貸テラスハウス: '3204',
} as const

export type Enum = ValueOf<typeof Enum>

export const values = Object.values(Enum)
