import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const ChintaiAkiKosuContents = ({ children }: PropsWithChildren) => {
  const isChintai = useIsChintai()
  const [bk_item_kbn] = useWatchMiraieFields({ name: ['bk_item_kbn'] })
  const isKodate =
    bk_item_kbn === miraieFields.bk_item_kbn.Enum.貸戸建住宅 ||
    bk_item_kbn === miraieFields.bk_item_kbn.Enum.貸テラスハウス

  const display = isChintai && !isKodate
  return display && children
}
