import { identity } from '../../../utils'

import { code, SetsubiTokki } from './code'
import { group } from './group'

export const getDetail = (targetCode: string) =>
  identity<{ [K in string]?: SetsubiTokki }>(code)[targetCode]

const _code = identity<{ [K in keyof typeof code]: SetsubiTokki }>(code)

export { type SetsubiTokki, group, _code as code }
