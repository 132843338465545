export function priceToJpString(price: number): string {
  if (price < 10000) return `${price.toString()}円`

  const units: { [key: string]: number } = {
    兆: 1000000000000,
    億: 100000000,
    万: 10000,
  }

  let currentPrice = price

  const result = Object.entries(units).reduce((acc, [unit, value]) => {
    const quotient = Math.floor(currentPrice / value)
    if (quotient === 0) return acc
    currentPrice %= value
    return `${acc}${quotient}${unit}`
  }, '')

  return `${result}円`
}

export function truncateDecimal(number: number, decimalPlaces: number): number {
  const factor = Math.pow(10, decimalPlaces)
  return Math.floor(number * factor) / factor
}

export function calculateTsubo(menseki: number): number {
  return menseki * 0.3025
}
