import { HTMLChakraProps, chakra } from '@chakra-ui/react'
import { FieldValues } from 'react-hook-form'

import { ImageInputField } from './ImageInputField'

import {
  ImageInputContext,
  Provider,
} from '@/components/form/controls/ImageInput/ImageInputContext'

export type FileInputProps<T extends FieldValues> = ImageInputContext<T> &
  HTMLChakraProps<'label'>

export const ImageInput = <T extends FieldValues>({
  fieldName,
  children,
  ...props
}: FileInputProps<T>) => {
  return (
    <Provider value={{ fieldName }}>
      <chakra.label
        cursor="pointer"
        display="inline-block"
        tabIndex={0}
        {...props}
      >
        <ImageInputField fieldName={fieldName} />
        {children}
      </chakra.label>
    </Provider>
  )
}
