import { Grid, GridProps } from '@chakra-ui/react'

export const PanelImagesLayoutGrid = (props: GridProps) => (
  <Grid
    templateColumns="repeat(4, 128px)"
    autoRows="128px"
    gap="2"
    sx={{ '& > *:first-of-type': { gridColumn: 'span 2', gridRow: 'span 2' } }}
    {...props}
  />
)
