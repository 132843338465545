import { Shisetsu, ShisetsuWithFile } from '../useShisetsuForm'
import { MapService } from '../utils'

import { createActiveShisetsuAndUploadImage } from './createActiveShisetsuAndUploadImage'
import { createDeleteShisetsu } from './createDeleteShisetsu'

export const createNewShisetsu = async ({
  initialShisetsu,
  shisetsu,
  lat,
  lng,
  mapService,
  id,
}: {
  initialShisetsu: Shisetsu[]
  shisetsu: ShisetsuWithFile[]
  lat: number | undefined | null
  lng: number | undefined | null
  mapService: MapService
  id: string
}) => {
  // shisetsu_noを採番し直しながら施設リストを作成。必要なら画像をアップロードする。
  const activeShisetsu = await createActiveShisetsuAndUploadImage(
    shisetsu,
    lat,
    lng,
    mapService,
    id,
  )

  // 削除用のパラメータを作成（明示的に削除リクエストを送らないと消えない）
  const deletedShisetsu = createDeleteShisetsu(initialShisetsu, activeShisetsu)

  return [...activeShisetsu, ...deletedShisetsu]
}
