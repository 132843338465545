import { Portal } from '@terass/common/src/portal'
import { exhaustiveCheck } from '@terass/common/src/utils'

import { AthomeLogo } from '@/components/logos/AthomeLogo'
import { HomesLogo } from '@/components/logos/HomesLogo'
import { RakumachiLogo } from '@/components/logos/RakumachiLogo'
import { SuumoLogo } from '@/components/logos/SuumoLogo'

export type PortalLogoProps = {
  portal: Portal
} & React.SVGProps<SVGSVGElement>

export const PortalLogo = ({ portal, ...props }: PortalLogoProps) => {
  switch (portal) {
    case 'suumo':
      return <SuumoLogo {...props} />
    case 'athome':
      return <AthomeLogo {...props} />
    case 'homes':
      return <HomesLogo {...props} />
    case 'rakumachi':
      return <RakumachiLogo {...props} />
    default:
      throw exhaustiveCheck(portal)
  }
}
