import { useLocation } from '@tanstack/react-router'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useEffect } from 'react'

const analytics = getAnalytics()

/** page_viewイベントを送信するコンポーネント */
export const PageView = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: pathname,
      page_title: document.title,
    })
  }, [pathname])

  return null
}
