import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'

import { createContext } from '@/hooks/useContext'

export const [BukkenImagesContextProvider, useBukkenImagesContext] =
  createContext<
    {
      updateData: (
        data: Pick<
          PostMiraieV1ImportForm,
          'images' | 'present_caption' | 'pamphlet_info_caption_kbn'
        >,
      ) => Promise<void>
    } & Pick<
      PostMiraieV1ImportForm,
      | 'images'
      | 'bk_sbt_kbn'
      | 'suumo_c_open_kbn'
      | 'pamphlet_info_caption_kbn'
      | 'present_caption'
    >
  >()
