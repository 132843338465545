import { Flex } from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'

import { DuplicateButton } from '@/components/detail/DuplicateButton'
import { EditButton } from '@/components/detail/EditButton'
import { BaitaiForm } from '@/components/form/BaitaiForm'
import { useUserRole } from '@/hooks/useUser'

export const Route = createFileRoute('/bukkens/$importId/')({
  component: ImportDetail,
})

export function ImportDetail() {
  const role = useUserRole()

  return (
    <BaitaiForm>
      <Flex position="sticky" bottom={4} gap={4}>
        <EditButton>編集する</EditButton>
        {role !== 'agent' && (
          <DuplicateButton>複製して編集する</DuplicateButton>
        )}
      </Flex>
    </BaitaiForm>
  )
}
