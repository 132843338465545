import {
  Button,
  ButtonProps,
  Modal,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { Suspense } from 'react'

import { LatLngModalContent } from '@/components/form/General/LatLngModalContent'
import { useIsEdit } from '@/hooks/useIsEdit'

export const LatLngButton = (props: ButtonProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const isDisabled = !useIsEdit()

  return (
    <>
      <Button
        isDisabled={isDisabled}
        variant="outline"
        onClick={onOpen}
        {...props}
      />
      <Modal {...{ isOpen, onClose }} size="5xl">
        <ModalOverlay />
        <Suspense fallback={<Spinner />}>
          <LatLngModalContent />
        </Suspense>
      </Modal>
    </>
  )
}
