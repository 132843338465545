import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ShakuchiKeiyakuDateCollapse = ({
  children,
}: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['teishaku_keiyaku_flg']}
      display={(teishaku_keiyaku_flg) =>
        teishaku_keiyaku_flg ===
        miraieFields.teishaku_keiyaku_flg.Enum.定期借家契約
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
