import { swapKeyValue } from '../../../../utils'
import * as bk_sbt_kbn from '../../bk_sbt_kbn'

import * as img_sbt_kbn from './enum'

type Values = {
  img_sbt_kbn: img_sbt_kbn.Enum
  bk_sbt_kbn: bk_sbt_kbn.Enum
}

export const getLabel = (values: Values) => {
  if (
    values.img_sbt_kbn === img_sbt_kbn.Enum['間取図(平面図)'] &&
    values.bk_sbt_kbn === bk_sbt_kbn.Enum.売地
  )
    return '区画図'
  if (
    values.img_sbt_kbn === img_sbt_kbn.Enum.室内 &&
    values.bk_sbt_kbn === bk_sbt_kbn.Enum.居住用
  )
    return 'その他室内・内装'
  if (
    values.img_sbt_kbn === img_sbt_kbn.Enum.前面道路含む外観 &&
    values.bk_sbt_kbn === bk_sbt_kbn.Enum.居住用
  )
    return 'ロビー'
  if (
    values.img_sbt_kbn === img_sbt_kbn.Enum['建物プラン例(外観写真)'] &&
    values.bk_sbt_kbn === bk_sbt_kbn.Enum.居住用
  )
    return 'セキュリティ'
  if (
    values.img_sbt_kbn === img_sbt_kbn.Enum.地図 &&
    values.bk_sbt_kbn === bk_sbt_kbn.Enum.居住用
  )
    return 'アクセス図'
  if (
    values.img_sbt_kbn === img_sbt_kbn.Enum.周辺写真 &&
    values.bk_sbt_kbn === bk_sbt_kbn.Enum.居住用
  )
    return 'その他画像'

  return swapKeyValue(img_sbt_kbn.Enum)[values.img_sbt_kbn]
}
