import { InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
  RadioGroup,
  UnselectedRadio,
  EnumRadios,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { YusenPriceCollapse } from '@/components/form/PriceAndCost/YusenPriceCollapse'

export const YusenMonthly: React.FC = () => {
  return (
    <>
      <FormControl fieldName="yusen_umu_kbn" fieldset>
        <FormLabel>有線放送（定額料金）</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.yusen_umu_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <YusenPriceCollapse>
        <FormGroup nested>
          <FormControl fieldName="yusen_price">
            <InputGroup>
              <NumberInput />
              <InputRightAddon>円 / 月</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </YusenPriceCollapse>
    </>
  )
}
