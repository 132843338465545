import React from 'react'

export const PlaceholderOption = (
  props: React.DetailedHTMLProps<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    HTMLOptionElement
  >,
) => (
  <option value="" {...props}>
    未選択
  </option>
)
