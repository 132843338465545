import { miraieFields } from '@terass/common/src'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const useWatchAddressCodes = () => {
  const [gyosei_cd, aza_cd] = useWatchMiraieFields({
    name: ['gyosei_cd', 'aza_cd'],
  })

  // 値がない場合は、未選択を表すコードを設定する
  const [prefectureCode, shikuchosonCode] = gyosei_cd
    ? miraieFields.gyosei_cd.splitGyoseiCode(gyosei_cd)
    : [
        miraieFields.gyosei_cd.prefecture.Code.未選択,
        miraieFields.gyosei_cd.shikuchoson.Code.未選択,
      ]
  const [ooazaCode, azaCode] = aza_cd
    ? miraieFields.aza_cd.splitAzaCode(aza_cd)
    : [
        miraieFields.aza_cd.ooaza.Code.未選択,
        miraieFields.aza_cd.aza.Code.未選択,
      ]

  return {
    prefectureCode,
    shikuchosonCode,
    ooazaCode,
    azaCode,
  }
}
