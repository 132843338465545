export type EnumOptionsProps = {
  enum: Record<string, string>
}

export const EnumOptions = ({ enum: enumProp }: EnumOptionsProps) => (
  <>
    {Object.entries(enumProp).map(([label, value]) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))}
  </>
)
