/**
 * 建築工法区分
 */
export const Enum = {
  '2x4工法': '1',
  '2x6工法': '2',
  軸組工法: '3',
  プレハブ工法: '4',
  パネル工法: '5',
  ユニット工法: '6',
  SE工法: '7',
  SW工法: '8',
  FP工法: '9',
  GL工法: '10',
  SPR工法: '11',
  シールド工法: '12',
  その他: '99',
} as const
