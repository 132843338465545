import { Box } from '@chakra-ui/react'

import { CSpinner } from '@/components/Atoms'
import { searchableSelectStyle } from '@/components/form/controls'

export const LoadingSelect = () => (
  <Box {...searchableSelectStyle}>
    <CSpinner />
  </Box>
)
