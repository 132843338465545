import { Select as ChakraSelect, SelectProps } from '@chakra-ui/react'

import { useCreateBaitaiKeisaiFormControlContext } from '@/components/CreateBaitaiKeisaiForm/FormControlContext'
import { useCreateBaitaiKeisaiFormContext } from '@/hooks/useCreateBaitaiKeisaiForm'

export const Select = (props: SelectProps) => {
  const { fieldName } = useCreateBaitaiKeisaiFormControlContext()
  const { register } = useCreateBaitaiKeisaiFormContext()

  return <ChakraSelect {...register(fieldName)} {...props} />
}
