import React from 'react'

const defaultValueSymbol = Symbol()

/**
 * 初期値の不要な `React.Context` を作成する
 */
export const createContext = <T>(
  displayName?: string,
): [
  Provider: React.NamedExoticComponent<React.ProviderProps<T>>,
  useContext: () => T,
] => {
  const context = React.createContext<T | typeof defaultValueSymbol>(
    defaultValueSymbol,
  )
  context.displayName = displayName

  const useContext = () => {
    const value = React.useContext(context)
    if (value === defaultValueSymbol) throw new Error('value is not provided.')
    return value
  }

  return [context.Provider, useContext]
}
