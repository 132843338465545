import {
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  useModalContext,
  HStack,
  Box,
  Text,
} from '@chakra-ui/react'
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api'
import { Suspense, useState } from 'react'

import { EkiMarkers } from './EkiMarkers'
import { EkiOptions } from './EkiOptions'
import { useEnsenEkiContext } from './useEnsenEkiContext'

import { CSpinner } from '@/components/Atoms'
import { useLoadScript } from '@/hooks/useLoadScript'

export const EnsenEkiModalContent = () => {
  const { onClose } = useModalContext()
  const {
    position: { lat, lng },
  } = useEnsenEkiContext()

  const { isLoaded, loadError } = useLoadScript()
  const [map, setMap] = useState<google.maps.Map>()
  const [hoveredEkiId, setHoveredEkiId] = useState<string | undefined>()

  if (loadError) return 'Error'
  if (!isLoaded) return 'Loading...'

  return (
    <ModalContent>
      <ModalHeader>沿線駅</ModalHeader>
      <ModalBody height="60vh">
        <HStack height="inherit">
          <Box width="70%">
            <GoogleMap
              mapContainerStyle={{
                height: '60vh',
                width: '100%',
              }}
              center={{
                lat,
                lng,
              }}
              zoom={13}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
              }}
              onLoad={setMap}
            >
              <MarkerF
                position={{ lat, lng }}
                icon={{
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 9,
                }}
              />
              <InfoWindowF position={{ lat, lng }}>
                <Text>物件所在地</Text>
              </InfoWindowF>
              {map && (
                <Suspense>
                  <EkiMarkers
                    position={{ lat, lng }}
                    hoveredEkiId={hoveredEkiId}
                    map={map}
                  />
                </Suspense>
              )}
            </GoogleMap>
          </Box>

          <Box height="inherit" overflowY="scroll" width="30%">
            {map && (
              <Suspense fallback={<CSpinner />}>
                <EkiOptions
                  position={{ lat, lng }}
                  setHoveredEkiId={setHoveredEkiId}
                  map={map}
                />
              </Suspense>
            )}
          </Box>
        </HStack>
      </ModalBody>
      <ModalFooter gap={4}>
        <Button colorScheme="gray" onClick={onClose}>
          キャンセル
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
