import {
  CheckboxGroupProps,
  CheckboxGroup as ChakraCheckboxGroup,
  Flex,
} from '@chakra-ui/react'

export const CheckboxGroup = (props: CheckboxGroupProps) => (
  <Flex direction="column" gap={2} display="inline-flex">
    <ChakraCheckboxGroup {...props} />
  </Flex>
)
