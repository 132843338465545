import { miraieFields } from '@terass/common/src'

import { SetsubiTokkiForm } from '@/components/form/Setsubi/useSetsubiTokkiForm'

export const isCodeVisible = ({
  code,
  bk_sbt_kbn,
  portalsFilter,
}: {
  code: string
  bk_sbt_kbn: miraieFields.bk_sbt_kbn.Enum
  portalsFilter: SetsubiTokkiForm['portalsFilter']
}) => {
  const setsubiTokki = miraieFields.setsubi_kbn_renketsu.getDetail(code)
  if (!setsubiTokki) return false

  const portalState = setsubiTokki[bk_sbt_kbn]
  if (!portalState) return false

  if (!portalsFilter.length) return true

  return portalsFilter.some((portal) => portalState[portal])
}
