import { ArrowBackIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'
import { miraieFields } from '@terass/common/src'

import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export function BackNavButton() {
  const status = useV1ImportDocument(({ status }) => status)
  const sakimono_flg = useV1ImportDocument(
    ({ formData: { sakimono_flg } }) => sakimono_flg,
  )
  const type =
    sakimono_flg === miraieFields.sakimono_flg.Enum.先物
      ? 'futures'
      : 'contracts'
  const { path, label } = (
    {
      published: {
        path: 'publishes',
        label: '公開中',
      },
      draft: {
        path: 'drafts',
        label: '下書き',
      },
      closed: {
        path: 'closes',
        label: '掲載停止中',
      },
    } as const
  )[status]

  return (
    <Link to={`/${type}/${path}` as const}>
      <Button variant="link" leftIcon={<ArrowBackIcon />} my={4}>
        {label}の一覧に戻る
      </Button>
    </Link>
  )
}
