import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const SonotaBikoCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['reform_mizumawari_flg']}
      display={(reform_mizumawari_flg) =>
        reform_mizumawari_flg === miraieFields.reform_mizumawari_flg.Enum.済
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
