import { miraieFields } from '@terass/common/src'

import {
  useBukkenImagesContext,
  useBukkenImagesFieldArray,
} from '@/components/form/BukkenImages/hooks'
import { toast } from '@/utils/standaloneToast'

export type UseAppendableImagesProps = {
  category: miraieFields.images.category.Enum
}

export const useAppendableImages = ({ category }: UseAppendableImagesProps) => {
  const { bk_sbt_kbn } = useBukkenImagesContext()
  const { fields, append } = useBukkenImagesFieldArray()
  const imageNumbers = miraieFields.images.img_no.values.filter(
    (img_no) =>
      miraieFields.images.img_no.getCategory({ img_no, bk_sbt_kbn }) ===
      category,
  )
  const maxSize = imageNumbers.length
  const appendableLength = maxSize - fields.length
  const isAppendable = appendableLength > 0
  const appendImageFiles = (files: File[]) => {
    if (files.length > appendableLength) {
      toast({
        status: 'warning',
        description: `${appendableLength}枚まで追加できます。`,
      })
      return
    }

    const img_sbt_kbn = miraieFields.images.img_sbt_kbn.getFirstValue({
      category,
      bk_sbt_kbn,
    })
    append(
      files.map((_imageFile) => ({
        _imageFile,
        img_sbt_kbn,
      })),
    )
  }

  return {
    appendImageFiles,
    maxSize,
    isAppendable,
    fields,
  }
}
