import { InputProps } from '@chakra-ui/react'

import { TextInput } from '@/components/form/controls'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const OpenHouseFromDateInput = (props: InputProps) => {
  const [toDate] = useWatchMiraieFields({ name: ['openhouse_to_date'] })

  return <TextInput type="date" max={toDate} {...props} />
}
