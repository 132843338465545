import { Flex, Text, InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
} from '../controls'

import { HaniEnumCheckbox } from './HaniEnumCheckbox'

import { FormGroup } from '@/components/form/FormGroup'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'

export const CarParkingBunjoRyokin = () => {
  return (
    <FormControl fieldset>
      <FormLabel>分譲価格</FormLabel>
      <FormGroup nested>
        <FormControl fieldName="parking_bunjo_price_hani_kbn">
          <HaniEnumCheckbox>料金を範囲で指定する</HaniEnumCheckbox>
          <PortalErrorMessages />
        </FormControl>

        <FormControl fieldset>
          <Flex gap={2} align="center">
            <FormControl fieldName="parking_from_price">
              <InputGroup>
                <NumberInput />
                <InputRightAddon>円</InputRightAddon>
              </InputGroup>
            </FormControl>

            <MiraieFieldContents
              name={['parking_bunjo_price_hani_kbn']}
              display={(parking_bunjo_price_hani_kbn) =>
                parking_bunjo_price_hani_kbn ===
                miraieFields.parking_bunjo_price_hani_kbn.Enum['～']
              }
            >
              <Text>～</Text>

              <FormControl fieldName="parking_to_price">
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>円</InputRightAddon>
                </InputGroup>
              </FormControl>
            </MiraieFieldContents>
          </Flex>

          <PortalErrorMessages fieldName="parking_from_price" />
          <PortalErrorMessages fieldName="parking_to_price" />
        </FormControl>
      </FormGroup>
    </FormControl>
  )
}
