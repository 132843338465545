import { Button, Flex, Text } from '@chakra-ui/react'
import { useErrorBoundary } from 'react-error-boundary'

export const SakimonoBaitaiKeisaiIraiListFallback = () => {
  const { resetBoundary } = useErrorBoundary()

  return (
    <Flex alignItems="center">
      <Text>先物媒体掲載依頼を取得できませんでした。</Text>
      <Button onClick={resetBoundary} size="sm">
        再試行
      </Button>
    </Flex>
  )
}
