import {
  Card,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Button,
  Text,
  VisuallyHidden,
  FormLabel,
  Box,
  CardBody,
  Flex,
} from '@chakra-ui/react'
import { useNavigate } from '@tanstack/react-router'
import { miraieFields } from '@terass/common/src'
import { useRef } from 'react'
import { FormProvider } from 'react-hook-form'

import { Contents } from '@/components/Contents'
import {
  FormControl,
  FormErrorMessage,
  Select,
} from '@/components/CreateBaitaiKeisaiForm'
import { BkItemKbnOptions } from '@/components/chintai/BkItemKbnOptions'
import { useSakimonoBaitaiKeisaiIraiContext } from '@/components/chintai/SakimonoBaitaiKeisaiIraiContext'
import { Shops } from '@/constants'
import { useCreateBaitaiKeisaiForm } from '@/hooks/useCreateBaitaiKeisaiForm'
import { useOperator } from '@/hooks/useOperator'
import { createImport } from '@/utils/import'

export const SakimonoBaitaiKeisaiIraiListItemCard = () => {
  const { email } = useOperator()
  const navigate = useNavigate()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const initialFocusRef = useRef<HTMLButtonElement>(null)
  const finalFocusRef = useRef<HTMLButtonElement>(null)
  const {
    name,
    price, // ここはT3の価格なので常に正規化されている
    bk_item_kbn,
    bk_sbt_kbn,
    id,
    propertyId,
    openDate,
    closeDate,
  } = useSakimonoBaitaiKeisaiIraiContext()
  const methods = useCreateBaitaiKeisaiForm({
    defaultValues: { bk_item_kbn, bk_sbt_kbn },
  })
  const onSubmit = methods.handleSubmit(async ({ bk_sbt_kbn, bk_item_kbn }) => {
    const { id: importId } = await createImport({
      __readers: [email],
      __writers: [email],
      __tera_three: {
        shinseiId: id,
        bukkenId: propertyId,
      },
      formData: {
        bk_item_kbn,
        bk_sbt_kbn,
        tatemono_name: name ?? undefined,
        price: miraieFields.price.convertToMiraiePrice(
          price,
          miraieFields.bk_sbt_kbn.isChintai(bk_sbt_kbn),
        ),
      },
      teraSeven: {},
      // TODO: 賃貸に対応するなら、shop選択の実装が必要
      shopId: Shops.名古屋,
    })

    navigate({
      to: '/bukkens/$importId/edit',
      params: { importId },
    })
  })

  return (
    <>
      <Card
        as="button"
        variant="outline"
        outline="none"
        _hover={{
          bgColor: 'gray.100',
        }}
        _focusVisible={{
          boxShadow: 'outline',
        }}
        transitionProperty="common"
        transitionDuration="normal"
        textAlign="start"
        type="button"
        onClick={onOpen}
        ref={finalFocusRef}
      >
        <CardBody>
          <Heading size="md">{name}</Heading>
          <Flex gap={2}>
            <span>掲載期間:</span>
            <span>{openDate}</span>
            <span>~</span>
            <span>{closeDate}</span>
          </Flex>
        </CardBody>
      </Card>
      <Modal {...{ isOpen, onClose, initialFocusRef, finalFocusRef }}>
        <ModalOverlay />
        <FormProvider {...methods}>
          <Contents as="form" onSubmit={onSubmit}>
            <ModalContent>
              <ModalHeader>媒体掲載作成</ModalHeader>
              <ModalCloseButton />
              <ModalBody display="flex" flexDirection="column" gap={4}>
                <Text>以下の先物媒体掲載依頼から媒体掲載を作成します。</Text>

                <Box>
                  <FormLabel fontWeight="semibold">物件名</FormLabel>
                  <Text>{name}</Text>
                </Box>

                <Box>
                  <FormLabel fontWeight="semibold">賃料</FormLabel>
                  <Text>{price}円</Text>
                </Box>

                <VisuallyHidden>
                  <FormControl fieldName="bk_sbt_kbn">
                    <Select>
                      <option value={bk_sbt_kbn}></option>
                    </Select>
                  </FormControl>
                </VisuallyHidden>
                <FormControl fieldName="bk_item_kbn">
                  <FormLabel fontWeight="semibold">物件種目</FormLabel>
                  <Select>
                    <BkItemKbnOptions />
                  </Select>
                  <FormErrorMessage />
                </FormControl>
              </ModalBody>
              <ModalFooter gap={4}>
                <Button onClick={onClose} colorScheme="gray">
                  キャンセル
                </Button>
                <Button ref={initialFocusRef} type="submit">
                  OK
                </Button>
              </ModalFooter>
            </ModalContent>
          </Contents>
        </FormProvider>
      </Modal>
    </>
  )
}
