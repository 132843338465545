import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ShikibikiCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['shikibiki_flg']}
      display={(shikibiki_flg) =>
        shikibiki_flg === miraieFields.shikibiki_flg.Enum.有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
