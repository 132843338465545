import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const NaisoBikoCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['reform_naiso_flg']}
      display={(reform_naiso_flg) =>
        reform_naiso_flg === miraieFields.reform_naiso_flg.Enum.済
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
