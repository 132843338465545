import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ChurinjoDetailCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['athome_c_open_kbn', 'churinjo_umu_kbn', 'bk_sbt_kbn']}
      display={(athome_c_open_kbn, churinjo_umu_kbn, bk_sbt_kbn) =>
        (churinjo_umu_kbn === miraieFields.churinjo_umu_kbn.Enum.空有 ||
          churinjo_umu_kbn === miraieFields.churinjo_umu_kbn.Enum.空無 ||
          churinjo_umu_kbn === miraieFields.churinjo_umu_kbn.Enum.近有) &&
        (miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'rent' ||
          athome_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載 ||
          athome_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載期間外)
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
