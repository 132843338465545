/** 未使用の画像URL `img_data_url` の一覧を取得する */
export function getUnusedImageUrls(
  before: { img_data_url?: string }[],
  after: { img_data_url?: string }[],
) {
  const unused: string[] = []

  before.forEach(
    (b) =>
      typeof b.img_data_url === 'string' &&
      after.every((a) => a.img_data_url !== b.img_data_url) &&
      unused.push(b.img_data_url),
  )

  return unused
}
