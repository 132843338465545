import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const HokenCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['hoken_flg']}
      display={(hoken_flg) => hoken_flg === miraieFields.hoken_flg.Enum.要}
    >
      {children}
    </MiraieFieldCollapse>
  )
}
