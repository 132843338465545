import {
  Modal,
  ModalOverlay,
  ModalProps,
  useDisclosure,
} from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'

import { ModalContent } from '@/components/form/BukkenImages/ModalContent'
import { useBukkenImagesForm } from '@/components/form/BukkenImages/hooks'
import { ConfirmAlertDialog } from '@/components/form/ConfirmAlertDialog'

export function EditBukkenImagesModal({
  onCloseComplete,
}: Pick<ModalProps, 'onCloseComplete'>) {
  const { isOpen: isModalOpen, onClose: onModalClose } = useDisclosure({
    defaultIsOpen: true,
  })
  const {
    isOpen: isAlertOpen,
    onClose: onAlertClose,
    onOpen: onAlertOpen,
  } = useDisclosure()
  const methods = useBukkenImagesForm()
  const {
    reset,
    formState: { isDirty },
  } = methods
  const confirmCloseModal = () => {
    isDirty ? onAlertOpen() : onModalClose()
  }
  const abortEdit = () => {
    onAlertClose()
    onModalClose()
    reset()
  }

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isModalOpen}
        onClose={confirmCloseModal}
        onCloseComplete={onCloseComplete}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent onModalClose={onModalClose} />
      </Modal>
      <ConfirmAlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        onAbort={abortEdit}
      />
    </FormProvider>
  )
}
