export const values = [
  '9001',
  '9002',
  '9003',
  '9004',
  '9005',
  '9006',
  '9007',
  '9008',
  '9009',
  '9010',
] as const
