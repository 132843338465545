import { miraieFields } from '@terass/common/src'
import { ValueOf } from '@terass/common/src/types'
import { exhaustiveCheck } from '@terass/common/src/utils'

import { ShisetsuWithFile } from '@/components/form/Shisetsu/useShisetsuForm'
import { getDirectDistance } from '@/utils/googleMap'

export type MapService = ReturnType<typeof createMapService>

export const createMapService = (
  googleMap: React.MutableRefObject<google.maps.Map | null>,
  lat: number | undefined | null,
  lng: number | undefined | null,
) => {
  const getLatLng = () => {
    if (lat == null || lng == null) throw new Error('lat or lng is null')

    return { lat, lng }
  }

  function getPlaceService() {
    if (googleMap.current == null) {
      return undefined
    }

    return new google.maps.places.PlacesService(googleMap.current)
  }

  const search = async (
    service: google.maps.places.PlacesService,
    requests: google.maps.places.PlaceSearchRequest[],
    kubun: ValueOf<typeof miraieFields.shisetsu.shisetsu_sbt_kbn.Enum>,
    callback: (places: Place[]) => void,
  ) => {
    const targetLatLng = getLatLng()
    const maxItemCount = 5

    const searchResults = requests.map((request) => {
      return new Promise<Place[]>((resolve) => {
        service.nearbySearch(request, (result, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            resolve([])
            return
          }

          const places =
            result?.flatMap((x) => {
              if (!x.name) return []

              const location = x.geometry?.location
              if (!location) return []

              const latLng = {
                lat: location.lat(),
                lng: location.lng(),
              }

              return {
                shisetsu_sbt_kbn: kubun,
                name: x.name,
                address: x.formatted_address ?? x.vicinity ?? '',
                directDistance: getDirectDistance(targetLatLng, latLng),
                latLng,
              }
            }) ?? []

          resolve(places)
        })
      })
    })

    const places = (await Promise.all(searchResults))
      .flat()
      .sort((a, b) => a.directDistance - b.directDistance)
      .slice(0, maxItemCount)

    callback(places)
  }

  const keywordSearch = async (
    keywords: string[],
    kubun: ValueOf<typeof miraieFields.shisetsu.shisetsu_sbt_kbn.Enum>,
    callback: (places: Place[]) => void,
  ) => {
    const service = getPlaceService()
    if (service == null) return
    if (keywords.length === 0) return

    const requests: google.maps.places.PlaceSearchRequest[] = keywords.map(
      (keyword) => {
        return {
          location: getLatLng(),
          keyword,
          rankBy: google.maps.places.RankBy.DISTANCE,
        }
      },
    )

    await search(service, requests, kubun, callback)
  }

  const typeSearch = async (
    types: string[],
    kubun: ValueOf<typeof miraieFields.shisetsu.shisetsu_sbt_kbn.Enum>,
    callback: (places: Place[]) => void,
  ) => {
    const service = getPlaceService()
    if (service == null) return
    if (types.length === 0) return

    const requests: google.maps.places.PlaceSearchRequest[] = types.map(
      (type) => {
        return {
          location: getLatLng(),
          type,
          rankBy: google.maps.places.RankBy.DISTANCE,
        }
      },
    )

    await search(service, requests, kubun, callback)
  }

  const nearbySearch = async (
    placeCondition: PlaceCondition,
    callback: (places: Place[]) => void,
  ) => {
    const kind = placeCondition.kind
    switch (kind) {
      case 'type':
        await typeSearch(placeCondition.types, placeCondition.kubun, callback)
        break
      case 'keyword':
        await keywordSearch(
          placeCondition.keywords,
          placeCondition.kubun,
          callback,
        )
        break
      default:
        exhaustiveCheck(kind)
    }
  }

  const getRouteDistance = async (
    from: LatLng,
    to: LatLng,
    travelMode: google.maps.TravelMode = google.maps.TravelMode.WALKING,
  ) => {
    const directionsService = new google.maps.DirectionsService()

    const result = await directionsService.route({
      origin: new google.maps.LatLng(from.lat, from.lng),
      destination: new google.maps.LatLng(to.lat, to.lng),
      travelMode,
    })

    const distance = result.routes[0]?.legs[0]?.distance?.value
    const duration = result.routes[0]?.legs[0]?.duration?.value

    return { distance, duration }
  }

  return { nearbySearch, getRouteDistance }
}

export type LatLng = {
  lat: number
  lng: number
}

export type Place = {
  shisetsu_sbt_kbn: ValueOf<typeof miraieFields.shisetsu.shisetsu_sbt_kbn.Enum>
  name: string
  address: string
  directDistance: number
  latLng: LatLng
}

export type PlaceCondition =
  | {
      kind: 'type'
      kubun: ValueOf<typeof miraieFields.shisetsu.shisetsu_sbt_kbn.Enum>
      types: string[]
    }
  | {
      kind: 'keyword'
      kubun: ValueOf<typeof miraieFields.shisetsu.shisetsu_sbt_kbn.Enum>
      keywords: string[]
    }

export const placeConditions: PlaceCondition[] = [
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.駅,
    types: ['train_station', 'subway_station'], // 'transit_station',
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.スーパー,
    types: ['supermarket'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.コンビニ,
    types: ['convenience_store'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.ショッピング施設,
    types: ['department_store', 'furniture_store'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.飲食店,
    types: ['restaurant'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.販売店,
    types: ['book_store'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.大学,
    types: ['university'],
  },
  {
    kind: 'keyword',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.専門学校,
    keywords: ['専門学校'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.高校,
    types: ['secondary_school'],
  },
  {
    kind: 'keyword',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.中学校,
    keywords: ['中学校'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.小学校,
    types: ['primary_school'],
  },
  {
    kind: 'keyword',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.幼稚園保育園,
    keywords: ['幼稚園', '保育園'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.役所,
    types: ['city_hall'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.銀行,
    types: ['bank'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.郵便局,
    types: ['post_office'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.警察署交番,
    types: ['police'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.図書館,
    types: ['library'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.病院,
    types: ['dentist'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.総合病院,
    types: ['hospital'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.公園,
    types: ['park'],
  },
  // { kind: 'type', kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.レジャー観光, types: [] },
  // { kind: 'type', kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.周辺の街並み, types: [] },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.ドラッグストア,
    types: ['drugstore'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.ホームセンター,
    types: ['home_goods_store'],
  },

  {
    kind: 'keyword',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.商店街,
    keywords: ['商店街'],
  },
  {
    kind: 'type',
    kubun: miraieFields.shisetsu.shisetsu_sbt_kbn.Enum.その他,
    types: ['atm', 'gym'],
  },
]

export const isActiveShisetsu = ({
  img_data_url,
  _imageFile,
  shisetsu_name,
  shisetsu_com,
}: ShisetsuWithFile) => {
  return !!img_data_url || !!_imageFile || !!shisetsu_name || !!shisetsu_com
}
