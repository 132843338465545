import { pick } from './pick'

export function omit<T extends object, Keys extends readonly (keyof T)[]>(
  obj: T,
  keys: Keys,
): { [K in keyof T as Exclude<K, Keys[number]>]: T[K] }

export function omit(obj: Record<PropertyKey, unknown>, keys: PropertyKey[]) {
  const exclude = keys.reduce<Record<PropertyKey, unknown>>(
    (acc, key) => Object.assign(acc, { [key]: true }),
    {},
  )
  return pick(
    obj,
    Reflect.ownKeys(obj).filter((key) => !exclude[key]),
  )
}
