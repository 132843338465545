import {
  Shisetsu,
  ShisetsuWithFile,
} from '@/components/form/Shisetsu/useShisetsuForm'
import { MapService } from '@/components/form/Shisetsu/utils'

export const convertToMiraieShisetsu = async (
  shisetsuWithFile: ShisetsuWithFile,
  lat: number | undefined | null,
  lng: number | undefined | null,
  mapService: MapService,
): Promise<Omit<Shisetsu, 'shisetsu_no'>> => {
  const shisetsu_kyori =
    lat == null ||
    lng == null ||
    shisetsuWithFile.latLng === undefined ||
    shisetsuWithFile.directDistance === undefined
      ? shisetsuWithFile.shisetsu_kyori
      : (
          await mapService.getRouteDistance(
            { lat, lng },
            shisetsuWithFile.latLng,
          )
        ).distance

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _imageFile, directDistance, latLng, ...shisetsu } = shisetsuWithFile

  return {
    ...shisetsu,
    shisetsu_kyori,
  }
}
