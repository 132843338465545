import { Box, Card, CardBody } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { Madori } from './Madori'
import { Room } from './Room'
import { TatoRoom } from './TatoRoom'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { RoomSectionContents } from '@/components/form/Room/RoomSectionContents'
import { sectionIds } from '@/components/form/SectionBar'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const RoomSection = () => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })

  return (
    <RoomSectionContents>
      <Box as="section" id={sectionIds.roomSection}>
        <FormSectionHeading>部屋関連</FormSectionHeading>
        <Card>
          <CardBody as={FormGroup}>
            {bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)'] ? (
              <TatoRoom />
            ) : (
              <Room />
            )}
            <Madori />
          </CardBody>
        </Card>
      </Box>
    </RoomSectionContents>
  )
}
