/**
 * 鍵保管場所区分
 */
export const Enum = {
  管理会社: '1',
  現地: '2',
  要確認: '3',
  当社: '4',
  立合: '5',
} as const
