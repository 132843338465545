/**
 * 部屋タイプ区分
 */
export const Enum = {
  和室: '1',
  洋室: '2',
  DK: '3',
  LK: '4',
  LDK: '5',
  L: '6',
  D: '7',
  K: '8',
  LD: '22',
  S: '23',
  ロフト: '31',
  グルニエ: '32',
  サンルーム: '33',
} as const
