import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'
import { resolver } from '@terass/common/src/miraie/resolver'
import {
  FieldArrayPath,
  FieldError,
  FieldPath,
  FieldPathValue,
  FieldPathValues,
  get,
  useController,
  UseControllerProps,
  useFieldArray,
  useForm,
  useFormContext,
  useFormState,
  UseFormStateProps,
  useWatch,
} from 'react-hook-form'

export const useMiraieForm = ({
  defaultValues,
}: {
  defaultValues: PostMiraieV1ImportForm
}) =>
  useForm<PostMiraieV1ImportForm>({
    defaultValues,
    criteriaMode: 'all',
    resolver,
    reValidateMode: 'onSubmit',
  })

export const useMiraieFormState = (
  props?: UseFormStateProps<PostMiraieV1ImportForm>,
) => useFormState<PostMiraieV1ImportForm>(props)

export const useMiraieFormError = (
  name: MiraieFieldPath,
): undefined | FieldError => get(useMiraieFormState({ name }).errors, name)

export const useMiraieFormContext = () =>
  useFormContext<PostMiraieV1ImportForm>()

export const useWatchMiraieFields: <
  T extends readonly MiraieFieldPath[],
>(props: {
  name: readonly [...T]
}) => MiraieFieldPathValues<T> = useWatch

export const useMiraieFormController = <T extends MiraieFieldPath>(
  props: UseMiraieFormControllerProps<T>,
) => useController<PostMiraieV1ImportForm, T>(props)

export const useMiraieFieldArray = <
  T extends FieldArrayPath<PostMiraieV1ImportForm>,
>(
  name: T,
) => useFieldArray<PostMiraieV1ImportForm, T>({ name })

export type MiraieFieldPath = FieldPath<PostMiraieV1ImportForm>

export type MiraieFieldPathValue<T extends MiraieFieldPath> = FieldPathValue<
  PostMiraieV1ImportForm,
  T
>

export type MiraieFieldPathValues<T extends readonly MiraieFieldPath[]> =
  FieldPathValues<PostMiraieV1ImportForm, T>

export type UseMiraieFormControllerProps<
  T extends MiraieFieldPath = MiraieFieldPath,
> = UseControllerProps<PostMiraieV1ImportForm, T>
