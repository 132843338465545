import { PropsWithChildren, useState } from 'react'

import { createContext } from '@/hooks/useContext'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

const usePanelImagesLayoutState = () => {
  const initialPanelImagesLayout = useV1ImportDocument(
    ({ panelImagesLayout }) => panelImagesLayout,
  )
  const [panelImagesLayout, setPanelImagesLayout] = useState(() =>
    [...Array(20).keys()].map((index) => ({
      id: String(index + 1),
      imageUrl: initialPanelImagesLayout?.[index],
    })),
  )
  const savedPanelImagesLayout = panelImagesLayout
    .map(({ imageUrl }) => imageUrl)
    .filter((imageUrl) => imageUrl !== undefined)

  return {
    panelImagesLayout,
    setPanelImagesLayout,
    savedPanelImagesLayout,
  }
}

const [Provider, usePanelImagesLayoutContext] =
  createContext<ReturnType<typeof usePanelImagesLayoutState>>()

export const PanelImagesLayoutContextProvider = (props: PropsWithChildren) => (
  <Provider value={usePanelImagesLayoutState()} {...props} />
)

export { usePanelImagesLayoutContext }
