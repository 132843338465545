import { Aza } from '@terass/common/src/firestore/Address'
import { doc, onSnapshot, query } from 'firebase/firestore'

import { useWatchAddressForm } from './useAddressForm'
import { useWatchAddressCodes } from './useWatchAddressCodes'

import { useObserver } from '@/hooks/useObserver'
import { DocumentNotFoundError } from '@/utils/errors'
import { getCollection } from '@/utils/firestore'

export const useAzaList = (): { id: string; data: Aza }[] => {
  const prefectureCode = useWatchAddressForm('prefectureCode')
  const shikuchosonCode = useWatchAddressForm('shikuchosonCode')
  const ooazaCode = useWatchAddressForm('ooazaCode')
  return useObserver({
    observerKey: ['azaList', prefectureCode, shikuchosonCode, ooazaCode],
    observerFn: (
      onChange,
      onError,
      { observerKey: [, prefectureCode, shikuchosonCode, ooazaCode] },
    ) =>
      onSnapshot(
        query(
          getCollection(
            'address',
            prefectureCode,
            'shikuchoson',
            shikuchosonCode,
            'ooaza',
            ooazaCode,
            'aza',
          ),
        ),
        (snapshot) =>
          onChange(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })),
          ),
        onError,
      ),
  })
}

export const useAza = (): Aza => {
  const { prefectureCode, shikuchosonCode, ooazaCode, azaCode } =
    useWatchAddressCodes()
  return useObserver({
    observerKey: ['aza', prefectureCode, shikuchosonCode, azaCode],
    observerFn: (
      onChange,
      onError,
      { observerKey: [, prefectureCode, shikuchosonCode, azaCode] },
    ) =>
      onSnapshot(
        doc(
          getCollection(
            'address',
            prefectureCode,
            'shikuchoson',
            shikuchosonCode,
            'ooaza',
            ooazaCode,
            'aza',
          ),
          azaCode,
        ),
        (snapshot) => {
          const data = snapshot.data()
          if (data) onChange(data)
          else onError(new DocumentNotFoundError())
        },
      ),
  })
}
