export const MonthOptions = () => {
  return (
    <>
      {[...new Array(12).keys()].map((value) => {
        const month = ++value
        return (
          <option key={month} value={month}>
            {month}月
          </option>
        )
      })}
    </>
  )
}
