import {
  Button,
  ButtonProps,
  Modal,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'

import { CreateSakimonoModalContent } from '@/components/sakimono/CreateSakimonoModalContent'

export const CreateSakimonoButton = (props: ButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen} {...props} />
      <Modal {...{ isOpen, onClose }}>
        <ModalOverlay />
        <CreateSakimonoModalContent />
      </Modal>
    </>
  )
}
