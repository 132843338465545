import { miraieFields } from '@terass/common/src'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'
import {
  FieldArrayPath,
  FieldPath,
  UseFormStateProps,
  useFieldArray,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form'

import { LatLng } from '@/components/form/Shisetsu/utils'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

const convertToShisetsuWithFile = (
  shisetsu: Shisetsu[],
): ShisetsuWithFile[] => {
  const shisetsuWithFileList: ShisetsuWithFile[] = shisetsu.map(
    ({ shisetsu_no: _, ...x }) => ({ ...x }),
  )

  const max = miraieFields.shisetsu.shisetsu_no.values.length
  if (shisetsu.length > max) throw new Error('shisetsu.length > max')

  return [
    ...shisetsuWithFileList,
    ...[...new Array(max - shisetsu.length)].map(
      () => ({}) satisfies ShisetsuWithFile,
    ),
  ]
}

export type Shisetsu = NonNullable<PostMiraieV1ImportForm['shisetsu']>[number]

export type ShisetsuWithFile = Omit<Shisetsu, 'shisetsu_no'> & {
  _imageFile?: File
  directDistance?: number
  latLng?: LatLng
}

export type ShisetsuForm = {
  initialShisetsu: Shisetsu[]
  shisetsu: ShisetsuWithFile[]
  lat: PostMiraieV1ImportForm['lat']
  lon: PostMiraieV1ImportForm['lon']
}

export const useShisetsuForm = () => {
  const [shisetsu = [], lat, lon] = useWatchMiraieFields({
    name: ['shisetsu', 'lat', 'lon'],
  })

  return useForm<ShisetsuForm>({
    values: {
      initialShisetsu: shisetsu,
      shisetsu: convertToShisetsuWithFile(shisetsu),
      lat,
      lon,
    },
  })
}

export const useShisetsuFormState = (props?: UseFormStateProps<ShisetsuForm>) =>
  useFormState<ShisetsuForm>(props)

export const useShisetsuFormContext = () => useFormContext<ShisetsuForm>()

export type ShisetsuFieldPath = FieldPath<ShisetsuForm>
export const useWatchShisetsuForm = <T extends ShisetsuFieldPath>(name: T) =>
  useWatch<ShisetsuForm, T>({ name })

export const useShisetsuFormFieldArray = <
  T extends FieldArrayPath<ShisetsuForm>,
>(
  name: T,
) => useFieldArray<ShisetsuForm, T>({ name })
