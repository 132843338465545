import { Flex, InputGroup, InputRightAddon } from '@chakra-ui/react'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
  TextInput,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { useIsChintai } from '@/hooks/useIsChintai'

export const Ichijikin: React.FC = () => {
  const isChintai = useIsChintai()

  return (
    <FormGroup>
      <FormGroupHeading>一時金</FormGroupHeading>
      <IchijikinForm index="1" />
      <IchijikinForm index="2" />
      <IchijikinForm index="3" />
      <IchijikinForm index="4" />
      <IchijikinForm index="5" />
      {isChintai && (
        <>
          <FormControl fieldName="room_cleaning_price">
            <FormLabel>室内清掃</FormLabel>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>円</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="zappi">
            <FormLabel>雑費</FormLabel>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>円</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="key_kokan">
            <FormLabel>鍵交換</FormLabel>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>円</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </>
      )}
    </FormGroup>
  )
}

const IchijikinForm: React.FC<{ index: '1' | '2' | '3' | '4' | '5' }> = ({
  index,
}) => {
  return (
    <FormControl fieldset>
      <FormLabel>{`名目${index}`}</FormLabel>
      <Flex gap={4} align="center">
        <FormControl fieldName={`ichijikin_name_${index}`}>
          <TextInput />
        </FormControl>

        <FormControl fieldName={`ichijikin_price_${index}`}>
          <InputGroup>
            <NumberInput />
            <InputRightAddon>円</InputRightAddon>
          </InputGroup>
        </FormControl>
      </Flex>
      <PortalErrorMessages fieldName={`ichijikin_name_${index}`} />
      <PortalErrorMessages fieldName={`ichijikin_price_${index}`} />
    </FormControl>
  )
}
