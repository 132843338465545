import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react'

import {
  ShisetsuFieldPath,
  useShisetsuFormContext,
} from '@/components/form/Shisetsu/useShisetsuForm'

type SelectProps = ChakraSelectProps & {
  fieldName: ShisetsuFieldPath
}

export const Select = ({ fieldName, ...props }: SelectProps) => {
  const { register } = useShisetsuFormContext()
  return <ChakraSelect {...register(fieldName)} {...props} />
}
