import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const OpenHouseBikoHomesContents = ({ children }: PropsWithChildren) => {
  const [homes_c_open_kbn] = useWatchMiraieFields({
    name: ['homes_c_open_kbn'],
  })
  const isHomesOpen = homes_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
  const isChintai = useIsChintai()
  const display = !isChintai && isHomesOpen
  return display && children
}
