import { z } from 'zod'

const sessionClaimsSchema = z.object({
  role: z.enum(['agent', 'assistant']),
} as const)

export type SessionClaims = z.infer<typeof sessionClaimsSchema>

export const parseSessionClaims = (value: unknown) =>
  sessionClaimsSchema.parse(value)
