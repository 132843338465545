import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { GaisoControls } from './GaisoControls'
import { KyoyobuControls } from './KyoyobuControls'
import { MizumawariControls } from './MizumawariControls'
import { NaisoControls } from './NaisoControls'
import { ReformAriCollapse } from './ReformAriCollapse'
import { SonotaControls } from './SonotaControls'

import {
  EnumRadios,
  FormControl,
  FormHelperText,
  PortalErrorMessages,
  RadioGroup,
  UnselectedRadio,
} from '@/components/form/controls'

export const ReformFlagControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>リフォーム</FormGroupHeading>
      <FormControl fieldName="reform_flg" fieldset>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.reform_flg.Enum} />
        </RadioGroup>
        <FormHelperText>
          SUUMO売買では「水回り」のリフォーム実施箇所（実施内容）は「内装」のリフォーム実施箇所（実施内容）として反映されます。「水回り」と「内装」の両方でリフォーム実施年月が入力されている場合、古い日付の方が適用されます。
        </FormHelperText>
        <PortalErrorMessages />
      </FormControl>
      <ReformAriCollapse>
        <FormGroup nested>
          <MizumawariControls />
          <NaisoControls />
          <GaisoControls />
          <KyoyobuControls />
          <SonotaControls />
        </FormGroup>
      </ReformAriCollapse>
    </FormGroup>
  )
}
