import { Box, Card, CardBody } from '@chakra-ui/react'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { ImagesLayoutContents } from '@/components/form/ImagesLayout/ImagesLayoutContents'
import { PanelImagesLayout } from '@/components/form/ImagesLayout/PanelImagesLayout'
import { sectionIds } from '@/components/form/SectionBar'

export const ImagesLayoutSection = () => (
  <ImagesLayoutContents>
    <Box as="section" id={sectionIds.imagesLayoutSection}>
      <FormSectionHeading>レイアウト</FormSectionHeading>
      <Card>
        <CardBody as={FormGroup}>
          <PanelImagesLayout />
        </CardBody>
      </Card>
    </Box>
  </ImagesLayoutContents>
)
