import { Button, ButtonProps } from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'

import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'
import { useV1ImportParam } from '@/hooks/useV1ImportParam'

export const EditButton = (props: ButtonProps) => {
  const importId = useV1ImportParam()
  const status = useV1ImportDocument(({ status }) => status)

  return (
    status !== 'closed' && (
      <Link to="/bukkens/$importId/edit" params={{ importId }}>
        <Button {...props} />
      </Link>
    )
  )
}
