import { Button, ButtonGroup, IconButton } from '@chakra-ui/react'
import {
  MdSkipPrevious,
  MdNavigateBefore,
  MdNavigateNext,
  MdSkipNext,
} from 'react-icons/md'

export type NavigationButtonGroupProps = {
  hasPrevious: boolean
  hasNext: boolean
  previousPage: () => void
  nextPage: () => void
  firstPage: () => void
  lastPage: () => void
}

export function NavigationButtonGroup({
  hasPrevious,
  hasNext,
  previousPage,
  nextPage,
  firstPage,
  lastPage,
}: NavigationButtonGroupProps) {
  return (
    <ButtonGroup isAttached size="sm" variant="outline">
      <IconButton
        isDisabled={!hasPrevious}
        onClick={firstPage}
        icon={<MdSkipPrevious />}
        aria-label="最初に戻る"
      />
      <Button
        isDisabled={!hasPrevious}
        onClick={previousPage}
        leftIcon={<MdNavigateBefore />}
      >
        前へ
      </Button>
      <Button
        isDisabled={!hasNext}
        onClick={nextPage}
        rightIcon={<MdNavigateNext />}
      >
        次へ
      </Button>
      <IconButton
        isDisabled={!hasNext}
        onClick={lastPage}
        icon={<MdSkipNext />}
        aria-label="最後へ"
      />
    </ButtonGroup>
  )
}
