/** 明治元年 */
const MEIJI_GANNEN = 1868

const formatter = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
  era: 'short',
})

export const formatYearToEra = (year: number) => {
  year = Math.trunc(year)

  const endOfYear = new Date(year, 11, 31, 23, 59, 59, 999)
  const endOfYearEra = dateToEra(endOfYear)
  if (year === MEIJI_GANNEN) return formatEra(endOfYearEra)

  const startOfYear = new Date(year, 0)
  const startOfYearEra = dateToEra(startOfYear)
  if (endOfYearEra.era === startOfYearEra.era) return formatEra(endOfYearEra)

  return [endOfYearEra, startOfYearEra].map(formatEra).join('/')
}

function dateToEra(date: Date): JapaneseEra {
  const { era, year } = formatter.formatToParts(date).reduce<{
    [K in Intl.DateTimeFormatPartTypes]?: string
  }>((acc, { type, value }) => ({ ...acc, [type]: value }), {})
  if (!era || !year) throw new Error()

  return { era, year: parseInt(year) }
}

function formatEra({ era, year }: JapaneseEra) {
  return `${era}${year}年`
}

type JapaneseEra = {
  era: string
  year: number
}
