export const DateOptions = () => {
  return (
    <>
      {[...new Array(31).keys()].map((n) => {
        const date = ++n
        return (
          <option key={date} value={date}>
            {date}日
          </option>
        )
      })}
    </>
  )
}
