import { Box, Flex } from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'
import { PropsWithChildren } from 'react'

import { ConverterLogo } from './logos/ConverterLogo'

export const Header = ({ children }: PropsWithChildren) => (
  <Flex
    as="nav"
    alignItems="center"
    gap={4}
    height={16}
    px={5}
    py={4}
    borderColor="gray.200"
    borderStyle="solid"
    borderBottomWidth="thin"
  >
    <Link to="/">
      <ConverterLogo height="32px" />
    </Link>
    <Box flexGrow={1} />
    {children}
  </Flex>
)
