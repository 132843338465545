import { ValueOf } from '../../../../types'

export const Enum = {
  外観: '1',
  間取: '2',
  内観: '3',
  ['内外観・その他']: '4',
  構造: '5',
  設備: '6',
  現地案内図: '7',
  交通アクセス図: '8',
  路線図: '9',
  CM横画像: '10',
  売主コメント: '11',
  外観パース図: '12',
  アクセス図: '13',
  会社間流通14: '14',
  会社間流通15: '15',
  // プレゼント画像とパンフレット画像を同じカテゴリにすると画像番号の採番に不都合が出るので別にする
  // 'コラム・その他': '16',
  プレゼント画像: '16_7002',
  パンフレット画像: '16_7003',
  全体区画図: '18',
  '住戸・区画画像': '77',
  パノラマ用ミニマップ: '79',
  未分類: '99',
} as const

export type Enum = ValueOf<typeof Enum>

export const values = Object.values(Enum)
