import React from 'react'

import { PortalIconContainer } from '@/components/icons'

export type PortalSearchKeyIconContainerProps = {
  searchKey?: boolean
} & React.ComponentProps<typeof PortalIconContainer>

export const PortalSearchKeyIconContainer = ({
  searchKey,
  ...props
}: PortalSearchKeyIconContainerProps) => (
  <PortalIconContainer
    {...(searchKey ? { borderColor: 'red ' } : {})}
    {...props}
  />
)
