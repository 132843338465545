import {
  Box,
  Card,
  CardBody,
  Center,
  Grid,
  Image,
  Text,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { swapKeyValue } from '@terass/common/src/utils'

import { isActiveShisetsu } from './utils'

import { useMiraieFieldArray } from '@/hooks/useMiraieForm'
import { gridTemplate } from '@/utils/gridTemplate'

export const Preview = () => {
  const { fields } = useMiraieFieldArray('shisetsu')

  const images = fields.filter(isActiveShisetsu)

  return (
    <Grid gap={4}>
      {images.map((x) => {
        return (
          <Card
            key={x.id}
            _hover={{ borderColor: 'gray.300', bgColor: 'gray.100' }}
            width="100%"
          >
            <CardBody as={Grid} gridTemplate={gridTemplateStyle} gap={2}>
              <Box gridArea={getImageControlsArea('img_data_url')}>
                <Image
                  src={x.img_data_url}
                  fit="contain"
                  h="full"
                  fallback={<Center h="full">画像なし</Center>}
                />
              </Box>
              <Text gridArea={getImageControlsArea('shisetsu_sbt_kbn')}>
                {x.shisetsu_sbt_kbn
                  ? swapKeyValue(miraieFields.shisetsu.shisetsu_sbt_kbn.Enum)[
                      x.shisetsu_sbt_kbn
                    ]
                  : ''}
              </Text>
              <Text gridArea={getImageControlsArea('shisetsu_kyori')}>
                距離：
                {x.shisetsu_kyori ? `${x.shisetsu_kyori}m` : '記載なし'}
              </Text>
              <Text gridArea={getImageControlsArea('shisetsu_name')}>
                {x.shisetsu_name}
              </Text>
            </CardBody>
          </Card>
        )
      })}
    </Grid>
  )
}

const { style: gridTemplateStyle, getArea: getImageControlsArea } =
  gridTemplate`
    "${'img_data_url'} ${'shisetsu_sbt_kbn'} ${'shisetsu_kyori'}" auto
    "${'img_data_url'} ${'shisetsu_name'}   ${'shisetsu_name'}" 1fr
    / 1fr    3fr              2fr
    `
