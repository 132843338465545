import { Box, Card, CardBody } from '@chakra-ui/react'

import { Athome } from './Athome'
import { Bikou } from './Bikou'
import { Homes } from './Homes'
import { PortalStaff } from './PortalStaff'
import { Suumo } from './Suumo'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { sectionIds } from '@/components/form/SectionBar'

export const CommentSection: React.FC = () => {
  return (
    <Box as="section" id={sectionIds.commentSection}>
      <FormSectionHeading>コメント・備考・スタッフ・URL</FormSectionHeading>
      <Card>
        <CardBody as={FormGroup}>
          <Bikou />
          <Suumo />
          <Homes />
          <Athome />
          <PortalStaff />
        </CardBody>
      </Card>
    </Box>
  )
}
