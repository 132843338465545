import { InputGroup, InputLeftAddon, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  EnumOptions,
  EnumRadios,
  FormControl,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  UnselectedRadio,
} from '@/components/form/controls'

const setsudoIndex = [1, 2, 3, 4] as const
export const Setsudo = () => {
  return (
    <MiraieFieldContents
      name={['bk_sbt_kbn']}
      display={(bk_sbt_kbn) =>
        bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
      }
    >
      <FormGroup>
        <FormGroupHeading>接道</FormGroupHeading>

        <FormControl fieldName="setsudo_kbn" fieldset>
          <FormLabel>接道タイプ</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.setsudo_kbn.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
          }
        >
          <FormGroup>
            {setsudoIndex.map((i) => (
              <FormControl key={i} fieldset>
                <FormLabel>{`接道${i}`}</FormLabel>
                <FormGroup nested>
                  <FormControl fieldName={`setsudo_hogaku_kbn_${i}`}>
                    <FormLabel>方角</FormLabel>
                    <Select>
                      <PlaceholderOption />
                      <EnumOptions
                        enum={miraieFields.setsudo_hogaku_kbn.Enum}
                      />
                    </Select>
                    <PortalErrorMessages />
                  </FormControl>

                  <FormControl fieldName={`setsudo_koshi_kbn_${i}`} fieldset>
                    <FormLabel>公私</FormLabel>
                    <RadioGroup>
                      <UnselectedRadio />
                      <EnumRadios enum={miraieFields.setsudo_koshi_kbn.Enum} />
                    </RadioGroup>
                    <PortalErrorMessages />
                  </FormControl>

                  <FormControl fieldName={`setsudo_haba_${i}`}>
                    <InputGroup>
                      <InputLeftAddon>幅員</InputLeftAddon>
                      <NumberInput step={miraieFields.setsudo_haba.step} />
                      <InputRightAddon>m</InputRightAddon>
                    </InputGroup>
                    <PortalErrorMessages />
                  </FormControl>

                  <FormControl fieldName={`setsudo_setsumen_${i}`}>
                    <InputGroup>
                      <InputLeftAddon>接面</InputLeftAddon>
                      <NumberInput step={miraieFields.setsudo_setsumen.step} />
                      <InputRightAddon>m</InputRightAddon>
                    </InputGroup>
                    <PortalErrorMessages />
                  </FormControl>

                  <FormControl fieldName={`setsudo_ichi_flg_${i}`} fieldset>
                    <FormLabel>位置指定</FormLabel>
                    <RadioGroup>
                      <UnselectedRadio />
                      <EnumRadios enum={miraieFields.setsudo_ichi_flg.Enum} />
                    </RadioGroup>
                    <PortalErrorMessages />
                  </FormControl>
                </FormGroup>
              </FormControl>
            ))}
          </FormGroup>
        </MiraieFieldContents>
      </FormGroup>
    </MiraieFieldContents>
  )
}
