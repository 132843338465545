import { useForm, useFormContext, useWatch } from 'react-hook-form'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export type SetsubiTokkiForm = {
  portalsFilter: ('suumo' | 'athome' | 'homes')[]
  codes: string[]
}

export const useSetsubiTokkiForm = () => {
  const [codes = []] = useWatchMiraieFields({ name: ['setsubi_kbn_renketsu'] })

  return useForm<SetsubiTokkiForm>({
    values: {
      portalsFilter: [],
      codes,
    },
  })
}

export const useSetsubiTokkiFormContext = () =>
  useFormContext<SetsubiTokkiForm>()

export const useWatchSetsubiTokkiForm = <T extends keyof SetsubiTokkiForm>(
  name: T,
) => useWatch<SetsubiTokkiForm, T>({ name })
