import { zodResolver } from '@hookform/resolvers/zod'
import { miraieFields } from '@terass/common/src'
import {
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { z } from 'zod'

export type CreateBaitaiKeisaiForm = z.infer<
  typeof createBaitaiKeisaiFormSchema
>

export const useCreateBaitaiKeisaiForm: typeof useForm<
  CreateBaitaiKeisaiForm
> = (props) =>
  useForm<CreateBaitaiKeisaiForm>({
    resolver: zodResolver(createBaitaiKeisaiFormSchema),
    ...props,
  })

export const useCreateBaitaiKeisaiFormContext =
  useFormContext<CreateBaitaiKeisaiForm>

export const useWatchCreateBaitaiKeisaiForm = <
  T extends keyof CreateBaitaiKeisaiForm,
>(
  name: T,
) => useWatch<CreateBaitaiKeisaiForm, T>({ name })

export const useCreateBaitaiKeisaiFormState =
  useFormState<CreateBaitaiKeisaiForm>

const createBaitaiKeisaiFormSchema = z
  .object({
    bk_sbt_kbn: z.nativeEnum(miraieFields.bk_sbt_kbn.Enum, {
      errorMap: (issue, ctx) =>
        issue.code === z.ZodIssueCode.invalid_enum_value
          ? { message: '物件種別を選択してください。' }
          : { message: ctx.defaultError },
    }),
    bk_item_kbn: z.nativeEnum(miraieFields.bk_item_kbn.Enum, {
      errorMap: (issue, ctx) =>
        issue.code === z.ZodIssueCode.invalid_enum_value
          ? { message: '物件種目を選択してください。' }
          : { message: ctx.defaultError },
    }),
    office: z.string(),
  })
  .refine(miraieFields.bk_item_kbn.isAvailable, (_values) => ({
    path: ['bk_item_kbn' satisfies keyof typeof _values],
    message: '物件種目を選択してください。',
  }))
