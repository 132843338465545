import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { ChidaiKubunRadios } from '@/components/form/Tochi/ChidaiRadios'
import {
  EnumOptions,
  EnumRadios,
  FieldLength,
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const Shakuchiken = () => {
  return (
    <MiraieFieldCollapse name={['tochi_pow_kbn']} display={Boolean}>
      <FormGroup>
        <FormGroupHeading>借地関連</FormGroupHeading>
        <FormControl fieldName="tochi_shakuchiken_kbn" fieldset>
          <FormLabel>土地借地権</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.tochi_shakuchiken_kbn.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldName="suumo_tato_shakuchiken_type_biko">
            <FormLabel>借地権種期間等備考</FormLabel>
            <Textarea />
            <FieldLength
              maxLength={
                miraieFields.suumo_tato_shakuchiken_type_biko.maxLength
              }
            />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="suumo_tato_shakuchiken_chinryo_biko">
            <FormLabel>借地権月賃料備考</FormLabel>
            <Textarea />
            <FieldLength
              maxLength={
                miraieFields.suumo_tato_shakuchiken_chinryo_biko.maxLength
              }
            />
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldContents
            name={['suumo_c_open_kbn']}
            display={(suumo_c_open_kbn) =>
              suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
            }
          >
            <FormControl fieldName="tato_shakuchiken_other_price_biko">
              <FormLabel>借地権他費用備考</FormLabel>
              <Textarea />
              <FieldLength
                maxLength={
                  miraieFields.tato_shakuchiken_other_price_biko.maxLength
                }
              />
              <PortalErrorMessages />
            </FormControl>
          </MiraieFieldContents>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldName="shakuchi_kikan_kbn" fieldset isRequired>
            <FormLabel>借地期間</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.shakuchi_kikan_kbn.Enum} />
            </RadioGroup>
            <FormHelperText>
              「満了日」または「契約期間」のいずれかを入力してください。
            </FormHelperText>
            <PortalErrorMessages />
          </FormControl>

          <FormGroup nested>
            <FormControl fieldName="shakuchi_keiyaku_end_date">
              <FormLabel>契約満了日</FormLabel>
              <TextInput type="date" />
              <PortalErrorMessages />
            </FormControl>

            <FormControl fieldset>
              <FormLabel>契約期間</FormLabel>
              <Flex gap={2} alignItems="center">
                <FormControl fieldName="shakuchi_year">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>年</InputRightAddon>
                  </InputGroup>
                </FormControl>
                <FormControl fieldName="shakuchi_month">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>ヶ月</InputRightAddon>
                  </InputGroup>
                </FormControl>
              </Flex>
              <PortalErrorMessages fieldName="shakuchi_year" />
              <PortalErrorMessages fieldName="shakuchi_month" />
            </FormControl>
          </FormGroup>

          <FormControl fieldName="chidai_kbn" fieldset>
            <FormLabel>地代</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <ChidaiKubunRadios />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldCollapse
            name={['chidai_kbn']}
            display={(chidai_kbn) =>
              chidai_kbn === miraieFields.chidai_kbn.Enum.有
            }
          >
            <FormGroup nested>
              <FormControl fieldName="chidai_month">
                <InputGroup>
                  <InputLeftAddon>月額</InputLeftAddon>
                  <NumberInput />
                  <InputRightAddon>円 / 月</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </MiraieFieldCollapse>

          <FormControl fieldset>
            <FormLabel>借地権費用</FormLabel>
            <FormGroup nested>
              <FormControl fieldset>
                <Flex gap={2} alignItems="center">
                  <FormControl fieldName="shakuchi_shikikin">
                    <InputGroup>
                      <InputLeftAddon>敷金</InputLeftAddon>
                      <NumberInput step={miraieFields.shakuchi_shikikin.step} />
                    </InputGroup>
                  </FormControl>
                  <FormControl fieldName="shakuchi_tani_kbn">
                    <Select>
                      <PlaceholderOption />
                      <EnumOptions enum={miraieFields.shakuchi_tani_kbn.Enum} />
                    </Select>
                  </FormControl>
                </Flex>
                <PortalErrorMessages fieldName="shakuchi_shikikin" />
                <PortalErrorMessages fieldName="shakuchi_tani_kbn" />
              </FormControl>

              <FormControl fieldset>
                <Flex gap={2} alignItems="center">
                  <FormControl fieldName="shakuchi_hoshokin">
                    <InputGroup>
                      <InputLeftAddon>保証金</InputLeftAddon>
                      <NumberInput step={miraieFields.shakuchi_hoshokin.step} />
                    </InputGroup>
                  </FormControl>
                  <FormControl fieldName="shakuchi_hoshokin_tani_kbn">
                    <Select>
                      <PlaceholderOption />
                      <EnumOptions
                        enum={miraieFields.shakuchi_hoshokin_tani_kbn.Enum}
                      />
                    </Select>
                  </FormControl>
                </Flex>
                <PortalErrorMessages fieldName="shakuchi_hoshokin" />
                <PortalErrorMessages fieldName="shakuchi_hoshokin_tani_kbn" />
              </FormControl>

              <MiraieFieldContents
                name={['bk_sbt_kbn']}
                display={(bk_sbt_kbn) =>
                  bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
                  bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
                  bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
                }
              >
                <FormControl fieldset>
                  <Flex gap={2} alignItems="center">
                    <FormControl fieldName="shakuchi_kenrikin">
                      <InputGroup>
                        <InputLeftAddon>権利金</InputLeftAddon>
                        <NumberInput
                          step={miraieFields.shakuchi_kenrikin.step}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl fieldName="shakuchi_kenrikin_tani_kbn">
                      <Select>
                        <PlaceholderOption />
                        <EnumOptions
                          enum={miraieFields.shakuchi_kenrikin_tani_kbn.Enum}
                        />
                      </Select>
                    </FormControl>
                  </Flex>
                  <PortalErrorMessages fieldName="shakuchi_kenrikin" />
                  <PortalErrorMessages fieldName="shakuchi_kenrikin_tani_kbn" />
                </FormControl>
              </MiraieFieldContents>
            </FormGroup>
          </FormControl>

          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
            }
          >
            <FormControl fieldName="shakuchi_keiyakujiko">
              <FormLabel>借地権 その他契約事項</FormLabel>
              <Textarea />
              <FieldLength
                maxLength={miraieFields.shakuchi_keiyakujiko.maxLength}
              />
              <PortalErrorMessages />
            </FormControl>
          </MiraieFieldContents>

          <MiraieFieldCollapse
            name={['bk_sbt_kbn', 'tochi_pow_kbn', 'suumo_c_open_kbn']}
            display={(bk_sbt_kbn, tochi_pow_kbn, suumo_c_open_kbn) =>
              miraieFields.c_open_kbn.isOpen(suumo_c_open_kbn) &&
              tochi_pow_kbn === miraieFields.tochi_pow_kbn.Enum.定期借地権 &&
              (bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション)
            }
          >
            <FormControl fieldset>
              <FormLabel>定期借地</FormLabel>
              <FormGroup nested>
                <FormControl fieldName="teishaku_joto_kbn" fieldset>
                  <FormLabel>譲渡可否</FormLabel>
                  <RadioGroup>
                    <UnselectedRadio />
                    <EnumRadios enum={miraieFields.teishaku_joto_kbn.Enum} />
                  </RadioGroup>
                  <PortalErrorMessages />
                </FormControl>

                <MiraieFieldCollapse
                  name={['teishaku_joto_kbn']}
                  display={(teishaku_joto_kbn) =>
                    teishaku_joto_kbn === miraieFields.teishaku_joto_kbn.Enum.可
                  }
                >
                  <FormGroup>
                    <FormControl fieldName="teishaku_shodakuyo_kbn" fieldset>
                      <FormLabel>譲渡承諾要</FormLabel>
                      <RadioGroup>
                        <UnselectedRadio />
                        <EnumRadios
                          enum={miraieFields.teishaku_shodakuyo_kbn.Enum}
                        />
                      </RadioGroup>
                      <PortalErrorMessages />
                    </FormControl>

                    <FormControl fieldName="teishaku_shodakusha_kbn" fieldset>
                      <FormLabel>譲渡承諾者</FormLabel>
                      <RadioGroup>
                        <UnselectedRadio />
                        <EnumRadios
                          enum={miraieFields.teishaku_shodakusha_kbn.Enum}
                        />
                      </RadioGroup>
                      <PortalErrorMessages />
                    </FormControl>

                    <FormControl fieldName="teishaku_shodakuprice_kbn" fieldset>
                      <FormLabel>譲渡承諾料</FormLabel>
                      <RadioGroup>
                        <UnselectedRadio />
                        <EnumRadios
                          enum={miraieFields.teishaku_shodakuprice_kbn.Enum}
                        />
                      </RadioGroup>
                      <PortalErrorMessages />
                    </FormControl>
                  </FormGroup>
                </MiraieFieldCollapse>

                <FormControl fieldName="teishaku_kbn" fieldset>
                  <FormLabel>設定登記</FormLabel>
                  <RadioGroup>
                    <UnselectedRadio />
                    <EnumRadios enum={miraieFields.teishaku_kbn.Enum} />
                  </RadioGroup>
                  <PortalErrorMessages />
                </FormControl>

                <FormControl fieldName="teishaku_jiki_kbn" fieldset>
                  <FormLabel>賃料改定時期</FormLabel>
                  <RadioGroup>
                    <UnselectedRadio />
                    <EnumRadios enum={miraieFields.teishaku_jiki_kbn.Enum} />
                  </RadioGroup>
                  <PortalErrorMessages />
                </FormControl>

                <MiraieFieldCollapse
                  name={['teishaku_jiki_kbn']}
                  display={(teishaku_jiki_kbn) =>
                    teishaku_jiki_kbn ===
                    miraieFields.teishaku_jiki_kbn.Enum.一定期間毎
                  }
                >
                  <FormControl fieldName="teishaku_kaitei">
                    <InputGroup>
                      <InputLeftAddon>賃料改定</InputLeftAddon>
                      <NumberInput />
                      <InputRightAddon>年毎</InputRightAddon>
                    </InputGroup>
                    <PortalErrorMessages />
                  </FormControl>
                </MiraieFieldCollapse>

                <FormControl fieldName="teishaku_kaitei_kbn" fieldset>
                  <FormLabel>賃料改定額</FormLabel>
                  <RadioGroup>
                    <UnselectedRadio />
                    <EnumRadios enum={miraieFields.teishaku_kaitei_kbn.Enum} />
                  </RadioGroup>
                  <PortalErrorMessages />
                </FormControl>
              </FormGroup>
            </FormControl>
          </MiraieFieldCollapse>
        </MiraieFieldContents>
      </FormGroup>
    </MiraieFieldCollapse>
  )
}
