import z from 'zod'

import { TeraSeven } from '../firestore/TeraSeven'

import { PostMiraieV1Import } from './PostMiraieV1Import'

export type PostMiraieV1ImportForm = z.infer<typeof PostMiraieV1ImportForm>
export const PostMiraieV1ImportForm = z.object({
  ...PostMiraieV1Import.shape,
  teraSeven: TeraSeven,
})
