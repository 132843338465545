import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { MitsumoriNaiyoCollapse } from './MitsumoriNaiyoCollapse'

import {
  EnumRadios,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const MitsumoriControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>見積もり</FormGroupHeading>
      <FormControl fieldName="reform_mitsumori_flg" fieldset>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.reform_mitsumori_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <MitsumoriNaiyoCollapse>
        <FormGroup nested>
          <FormControl fieldName="reform_mitsumori_biko">
            <FormLabel>内容</FormLabel>
            <Textarea />
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </MitsumoriNaiyoCollapse>
    </FormGroup>
  )
}
