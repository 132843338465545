import { Center, Heading, HeadingProps, Spinner } from '@chakra-ui/react'

export const PageHeading: React.FC<{ title: string } & HeadingProps> = ({
  title,
  ...props
}) => (
  <Heading fontSize="30px" {...props}>
    {title}
  </Heading>
)

export const CSpinner: React.FC = () => {
  return (
    <Center mt="40px">
      <Spinner
        size="xl"
        color="pink.500"
        speed="0.7s"
        thickness="4px"
      ></Spinner>
    </Center>
  )
}
