import { Flex, FlexProps } from '@chakra-ui/react'

export type FormGroupProps = {
  nested?: boolean
} & FlexProps

export const FormGroup = ({ nested, ...props }: FormGroupProps) => (
  <Flex
    direction="column"
    gap={4}
    {...(nested
      ? {
          borderColor: 'gray.100',
          borderStyle: 'solid',
          borderLeftWidth: 'thin',
          pl: 4,
        }
      : {})}
    {...props}
  />
)
