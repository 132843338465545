import { useIsChintai } from '@/hooks/useIsChintai'

export const HourOptions = () => {
  const isChintai = useIsChintai()
  const max = 23
  const min = isChintai ? 0 : 8
  return (
    <>
      <option />
      {[...new Array(max - min + 1).keys()].map((n) => {
        const value = `${n + min}`.padStart(2, '0')
        return (
          <option key={value} value={value}>
            {value}
          </option>
        )
      })}
    </>
  )
}
