import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const MitsumoriNaiyoCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['reform_mitsumori_flg']}
      display={(reform_mitsumori_flg) =>
        reform_mitsumori_flg === miraieFields.reform_mitsumori_flg.Enum.有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
