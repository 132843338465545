import {
  FormHelperText as ChakraFormHelperText,
  FormHelperTextProps,
} from '@chakra-ui/react'

export const FormHelperText = (props: FormHelperTextProps) => (
  <ChakraFormHelperText
    fontSize="xs"
    color="gray.500"
    whiteSpace="pre-wrap"
    {...props}
  />
)
