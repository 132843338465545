export type Shops = {
  大阪: string
  名古屋: string
  九州: string
  東京: string
}

export function getShops(env: 'prod' | 'test'): Shops {
  return {
    prod: {
      大阪: '1644',
      名古屋: '1512',
      九州: '1748',
      東京: '1747',
    },
    test: {
      // テスト環境の店舗のshop_id
      大阪: '464',
      名古屋: '464',
      九州: '464',
      東京: '464',
    },
  }[env]
}
