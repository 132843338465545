import {
  Button,
  ButtonProps,
  Modal,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'

import { ConfirmAlertDialog } from '@/components/form/ConfirmAlertDialog'
import { ModalContentForm } from '@/components/form/Setsubi/ModalContentForm'
import { useSetsubiTokkiForm } from '@/components/form/Setsubi/useSetsubiTokkiForm'
import { useIsEdit } from '@/hooks/useIsEdit'
import {
  useMiraieFormContext,
  useWatchMiraieFields,
} from '@/hooks/useMiraieForm'

export const EditSetsubiTokkiButton = (props: ButtonProps) => {
  const { setValue } = useMiraieFormContext()
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })
  const methods = useSetsubiTokkiForm()
  const {
    reset,
    formState: { isDirty },
  } = methods
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure()
  const updateCodes = (codes: string[]) => {
    setValue('setsubi_kbn_renketsu', codes)
    onModalClose()
  }
  const isDisabled = !useIsEdit()
  const confirmModalClose = () => {
    isDirty ? onAlertOpen() : onModalClose()
  }
  const abortEdit = () => {
    onAlertClose()
    onModalClose()
    reset()
  }

  return (
    <>
      <Button isDisabled={isDisabled} onClick={onModalOpen} {...props}>
        編集
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={confirmModalClose}
        size="6xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <FormProvider {...methods}>
          <ModalContentForm updateCodes={updateCodes} bk_sbt_kbn={bk_sbt_kbn} />
        </FormProvider>
      </Modal>
      <ConfirmAlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        onAbort={abortEdit}
      />
    </>
  )
}
