import { Box, Card, CardBody } from '@chakra-ui/react'

import { BunjouHanbai } from './BunjouHanbai'

import { BunjoSectionContents } from '@/components/form/Bunjou/BunjoSectionContents'
import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { sectionIds } from '@/components/form/SectionBar'

export const BunjouSection: React.FC = () => {
  return (
    <BunjoSectionContents>
      <Box as="section" id={sectionIds.bunjouSection}>
        <FormSectionHeading>分譲関連</FormSectionHeading>
        <Card>
          <CardBody as={FormGroup}>
            <BunjouHanbai />
          </CardBody>
        </Card>
      </Box>
    </BunjoSectionContents>
  )
}
