/**
 * 再建築条件区分
 */
export const Enum = {
  '（旧基準）建築基準法第43条但書許可要。事前相談による一次決済取得': '1',
  '（旧基準）建築基準法第43条但書許可要。一括(包括)許可同意基準に適合': '2',
  '（旧基準）建築基準法第43条但書許可要': '3',
  '（旧基準）再建築不可': '4',
  '建築基準法43条2項1号の認定要※建築可能な建物は200㎡以内の専用住宅に限られる':
    '5',
  '建築基準法43条2項2号の許可要（一括許可（包括）同意基準に適合）': '6',
  '建築基準法43条2項2号の許可要（建築審査会の同意が必要）': '7',
  '建築基準法43条2項1号認定済※建築可能な建物は200㎡以内の専用住宅に限られる':
    '8',
  建築基準法43条2項2号許可済: '9',
  再建築不可: '10',
} as const
