import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ReformKanoCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['reform_renovation_kanou_flg']}
      display={(reform_renovation_kanou_flg) =>
        reform_renovation_kanou_flg ===
        miraieFields.reform_renovation_kanou_flg.Enum.可能
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
