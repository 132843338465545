import { ValueOf } from '../../../../types'
import * as bk_sbt_kbn from '../../bk_sbt_kbn'
import * as category from '../category'

import * as img_sbt_kbn from './enum'

export const availableMap: {
  [I in img_sbt_kbn.Enum]: {
    [B in ValueOf<typeof bk_sbt_kbn.Enum>]: {
      [C in category.Enum]?: true
    }
  }
} = {
  [img_sbt_kbn.Enum['間取図(平面図)']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['パノラマ用ミニマップ']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['パノラマ用ミニマップ']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['間取']]: true,
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
      [category.Enum['パノラマ用ミニマップ']]: true,
    },
  },
  [img_sbt_kbn.Enum['地形図']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {},
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['外観']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['外観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['室内']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['バス']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['トイレ']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['洗面']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['キッチン']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['エントランス']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['収納']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['玄関']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['庭']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['駐車場']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['共有部分']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['その他設備']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['設備']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['設備']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['眺望']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['バルコニー']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['周辺環境']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {},
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['公図実測図']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {},
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['リビング']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['その他']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum.プレゼント画像]: true,
      [category.Enum.パンフレット画像]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
      [category.Enum.プレゼント画像]: true,
      [category.Enum.パンフレット画像]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
      [category.Enum.プレゼント画像]: true,
      [category.Enum.パンフレット画像]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['地図']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['現地案内図']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['現地案内図']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['現地案内図']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['会社間流通14']]: true,
    },
  },
  [img_sbt_kbn.Enum['交通アクセス図']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['交通アクセス図']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['交通アクセス図']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['交通アクセス図']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['路線図']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['路線図']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['路線図']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['路線図']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['構造']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['構造']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['構造']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['外観パース図']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['外観パース図']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['外観パース図']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['外観']]: true,
    },
  },
  [img_sbt_kbn.Enum['CM横画像']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {},
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['SUUMOCM画像']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {},
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['周辺写真']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {},
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['会社間流通15']]: true,
    },
  },
  [img_sbt_kbn.Enum['前面道路含む外観']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['建物プラン例(外観写真)']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['建物プラン例(内観写真)']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['建物プラン例(パース・外観)']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['建物プラン例(パース・内観)']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['建物プラン例(間取り図)']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['その他建物プラン例']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['寝室']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['子供部屋']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {
      [category.Enum['内観']]: true,
      [category.Enum['内外観・その他']]: true,
    },
  },
  [img_sbt_kbn.Enum['同仕様画像(外観)']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {},
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['同仕様画像(リビング)']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['同仕様画像(バス)']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['同仕様画像(キッチン)']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['同仕様画像(その他内観)']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['その他現地']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['モデルルーム・モデルハウス写真']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['その他内観']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['ロビー']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['発電・温水設備']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['設備']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['設備']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['冷暖房・空調設備']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['設備']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['設備']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['防犯設備']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['設備']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['設備']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['分譲済街並み写真']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {},
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['航空写真']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['高台写真']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['完成予想図(内観)']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
      [category.Enum['売主コメント']]: true,
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['街並完成予想図']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {},
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['全体区画図']]: {
    [bk_sbt_kbn.Enum.売地]: {
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {
      [category.Enum.全体区画図]: true,
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['間取り']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {
      [category.Enum['住戸・区画画像']]: true,
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['区画図']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {
      [category.Enum['内外観・その他']]: true,
      [category.Enum['CM横画像']]: true,
    },
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {
      [category.Enum['住戸・区画画像']]: true,
    },
    [bk_sbt_kbn.Enum.居住用]: {},
  },
  [img_sbt_kbn.Enum['区画図+建物プラン例']]: {
    [bk_sbt_kbn.Enum.売地]: {},
    [bk_sbt_kbn.Enum.売戸建住宅]: {},
    [bk_sbt_kbn.Enum.売マンション]: {},
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: {},
    [bk_sbt_kbn.Enum.居住用]: {},
  },
}
