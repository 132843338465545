import { Button, ButtonProps, useDisclosure } from '@chakra-ui/react'

import { EditBukkenImagesModal } from '@/components/form/BukkenImages/EditBukkenImagesModal'
import { useIsEdit } from '@/hooks/useIsEdit'

export const EditBukkenImagesButton = (props: ButtonProps) => {
  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure()
  const isDisabled = !useIsEdit()

  return (
    <>
      <Button
        isDisabled={isDisabled}
        variant="outline"
        onClick={onModalOpen}
        {...props}
      >
        編集
      </Button>
      {isModalOpen && <EditBukkenImagesModal onCloseComplete={onModalClose} />}
    </>
  )
}
