import { miraieFields } from '@terass/common/src'

export type CategoryHeadingProps = {
  category: miraieFields.images.category.Enum
  bk_sbt_kbn: miraieFields.bk_sbt_kbn.Enum
}

export function CategoryHeading({
  category,
  bk_sbt_kbn,
}: CategoryHeadingProps) {
  return (
    <>
      {category === miraieFields.images.category.Enum['内外観・その他'] &&
      bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地
        ? '土地写真・その他画像'
        : Object.entries(miraieFields.images.category.Enum).find(
            ([, value]) => value === category,
          )?.[0]}
    </>
  )
}
