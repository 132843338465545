import { Tag } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const HoreiKubunTags = () => {
  const [horei_kbn_renketsu = []] = useWatchMiraieFields({
    name: ['horei_kbn_renketsu'],
  })

  return (
    <>
      {horei_kbn_renketsu.map((value) => (
        <Tag
          key={value}
          colorScheme="gray"
          variant="outline"
          whiteSpace="nowrap"
        >
          {miraieFields.horei_kbn_renketsu.getLabel(value)}
        </Tag>
      ))}
    </>
  )
}
