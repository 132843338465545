export const Enum = {
  ショッピング施設: '1',
  コンビニ: '2',
  飲食店: '3',
  販売店: '4',
  大学: '5',
  専門学校: '6',
  高校: '7',
  中学校: '8',
  小学校: '9',
  幼稚園保育園: '10',
  病院: '11',
  郵便局: '12',
  銀行: '13',
  公園: '14',
  役所: '15',
  警察署交番: '16',
  図書館: '17',
  レジャー観光: '18',
  駅: '19',
  周辺の街並み: '20',
  ドラッグストア: '50',
  ホームセンター: '51',
  スーパー: '52',
  商店街: '53',
  総合病院: '80',
  その他: '99',
} as const
