import { Box, Card, CardBody } from '@chakra-ui/react'

import { BukkenImagesContextProvider } from './BukkenImagesContextProvider'

import { EditBukkenImagesButton } from '@/components/form/BukkenImages/EditBukkenImagesButton'
import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { sectionIds } from '@/components/form/SectionBar'

export const BukkenImagesSection: React.FC = () => {
  return (
    <Box as="section" id={sectionIds.bukkenImagesSection}>
      <FormSectionHeading>画像</FormSectionHeading>
      <Card>
        <CardBody as={FormGroup}>
          <BukkenImagesContextProvider>
            <EditBukkenImagesButton alignSelf="start" />
          </BukkenImagesContextProvider>
        </CardBody>
      </Card>
    </Box>
  )
}
