import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const KanrihiKyoekihiCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['kyoekihi_kanrihi_flg']}
      display={(kyoekihi_kanrihi_flg) =>
        kyoekihi_kanrihi_flg === miraieFields.kyoekihi_kanrihi_flg.Enum.有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
