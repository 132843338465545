import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { NobeyukaMenseki } from './NobeyukaMenseki'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  EnumOptions,
  FormControl,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  Select,
  TextInput,
} from '@/components/form/controls'

export const Menseki = () => {
  return (
    <MiraieFieldContents
      name={['bk_sbt_kbn']}
      display={(bk_sbt_kbn) =>
        miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'buy'
      }
    >
      <FormGroup>
        <FormGroupHeading>面積</FormGroupHeading>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
          }
        >
          <FormControl fieldset>
            <FormLabel>専有面積</FormLabel>
            <Flex gap={2}>
              <FormControl fieldName="tatesen_menseki" isRequired>
                <InputGroup>
                  <NumberInput step={miraieFields.tatesen_menseki.step} />
                  <InputRightAddon>㎡</InputRightAddon>
                </InputGroup>
              </FormControl>

              <FormControl fieldName="tatesen_menseki_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.tatesen_menseki_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>
            <PortalErrorMessages fieldName="tatesen_menseki" />
            <PortalErrorMessages fieldName="tatesen_menseki_kbn" />
          </FormControl>
        </MiraieFieldContents>

        <NobeyukaMenseki />

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
          }
        >
          <FormControl fieldName="kenchiku_menseki_h">
            <FormLabel>建築面積</FormLabel>
            <InputGroup>
              <NumberInput step={miraieFields.kenchiku_menseki_h.step} />
              <InputRightAddon>㎡</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
          }
        >
          <FormControl fieldset>
            <FormLabel>面積詳細</FormLabel>
            <FormGroup nested>
              <FormControl fieldName="kyoju_yoto_igai_kbn">
                <FormLabel>うち居住用途以外</FormLabel>
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.kyoju_yoto_igai_kbn.Enum} />
                </Select>
                <PortalErrorMessages />
              </FormControl>

              <MiraieFieldCollapse
                name={['suumo_c_open_kbn', 'kyoju_yoto_igai_kbn']}
                display={(suumo_c_open_kbn, kyoju_yoto_igai_kbn) =>
                  miraieFields.c_open_kbn.isOpen(suumo_c_open_kbn) &&
                  kyoju_yoto_igai_kbn ===
                    miraieFields.kyoju_yoto_igai_kbn.Enum.その他
                }
              >
                <FormControl fieldName="kyoju_yoto_igai_other_setsumei">
                  <InputGroup>
                    <InputLeftAddon>その他</InputLeftAddon>
                    <TextInput />
                  </InputGroup>
                  <PortalErrorMessages />
                </FormControl>
              </MiraieFieldCollapse>

              <MiraieFieldCollapse
                name={['kyoju_yoto_igai_kbn']}
                display={(kyoju_yoto_igai_kbn) => Boolean(kyoju_yoto_igai_kbn)}
              >
                <FormControl fieldName="kyoju_yoto_igai_menseki">
                  <InputGroup>
                    <InputLeftAddon>うち居住用途以外面積</InputLeftAddon>
                    <NumberInput
                      step={miraieFields.kyoju_yoto_igai_menseki.step}
                    />
                    <InputRightAddon>㎡</InputRightAddon>
                  </InputGroup>
                  <PortalErrorMessages />
                </FormControl>
              </MiraieFieldCollapse>

              <MiraieFieldContents
                name={['suumo_c_open_kbn']}
                display={(suumo_c_open_kbn) =>
                  miraieFields.c_open_kbn.isOpen(suumo_c_open_kbn)
                }
              >
                <FormControl fieldName="chika_menseki">
                  <InputGroup>
                    <InputLeftAddon>うち地下室面積</InputLeftAddon>
                    <NumberInput step={miraieFields.chika_menseki.step} />
                    <InputRightAddon>㎡</InputRightAddon>
                  </InputGroup>
                  <PortalErrorMessages />
                </FormControl>

                <FormControl fieldName="shako_menseki">
                  <InputGroup>
                    <InputLeftAddon>うち1F車庫面積</InputLeftAddon>
                    <NumberInput step={miraieFields.shako_menseki.step} />
                    <InputRightAddon>㎡</InputRightAddon>
                  </InputGroup>
                  <PortalErrorMessages />
                </FormControl>

                <FormControl fieldName="chika_shako_menseki">
                  <InputGroup>
                    <InputLeftAddon>うち地下車庫面積</InputLeftAddon>
                    <NumberInput step={miraieFields.chika_shako_menseki.step} />
                    <InputRightAddon>㎡</InputRightAddon>
                  </InputGroup>
                  <PortalErrorMessages />
                </FormControl>
              </MiraieFieldContents>
            </FormGroup>
          </FormControl>
        </MiraieFieldContents>
      </FormGroup>
    </MiraieFieldContents>
  )
}
