import { createContext } from '@/hooks/useContext'
import { LatLng } from '@/utils/googleMap'

export type EnsenEkiIndex = '1' | '2' | '3' | '4'

type EnsenEkiContext = {
  index: EnsenEkiIndex
  position: LatLng
}

export const [EnsenEkiContextProvider, useEnsenEkiContext] =
  createContext<EnsenEkiContext>()
