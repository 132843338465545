import { miraieFields } from '@terass/common/src'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

type OsusumePickupFieldName = Extract<
  keyof PostMiraieV1ImportForm,
  `osusume_pickup_${number}`
>

export type OsusumePickupOptionsProps = {
  excludeFields: [OsusumePickupFieldName, OsusumePickupFieldName]
}

export const OsusumePickupOptions = ({
  excludeFields,
}: OsusumePickupOptionsProps) => {
  const [setsubi_kbn_renketsu, ...excludeCodes] = useWatchMiraieFields({
    name: ['setsubi_kbn_renketsu', ...excludeFields],
  })
  const codes = setsubi_kbn_renketsu ?? []

  return (
    <>
      {codes.map((code) => {
        const display = !excludeCodes.includes(code)
        if (!display) return null

        const setsubiTokki = miraieFields.setsubi_kbn_renketsu.getDetail(code)
        if (!setsubiTokki) return null

        return (
          <option key={code} value={code}>
            {setsubiTokki.name}
          </option>
        )
      })}
    </>
  )
}
