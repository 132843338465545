import { Box, Card, CardBody } from '@chakra-ui/react'

import { Menseki } from './Menseki'
import { Other } from './Other'
import { Tatemono } from './Tatemono'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionDescription } from '@/components/form/FormSectionDescription'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { sectionIds } from '@/components/form/SectionBar'
import { TatemonoSectionContents } from '@/components/form/Tatemono/TatemonoSectionContents'

export const TatemonoSection: React.FC = () => {
  return (
    <TatemonoSectionContents>
      <Box as="section" id={sectionIds.tatemonoSection}>
        <FormSectionHeading>建物関連</FormSectionHeading>
        <FormSectionDescription>
          建物に関する基本的な情報を入力してください
        </FormSectionDescription>
        <Card>
          <CardBody as={FormGroup}>
            <Tatemono />
            <Menseki />
            <Other />
          </CardBody>
        </Card>
      </Box>
    </TatemonoSectionContents>
  )
}
