import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  useMergeRefs,
} from '@chakra-ui/react'
import { forwardRef } from 'react'

import { useBukkenImagesFormContext } from '@/components/form/BukkenImages/hooks'
import { BukkenImagesPath } from '@/components/form/BukkenImages/types'

export type SelectProps = ChakraTextareaProps & {
  fieldName: BukkenImagesPath
}

export const Textarea = forwardRef<HTMLTextAreaElement, SelectProps>(
  function Textarea({ fieldName, ...props }, forwardedRef) {
    const { register } = useBukkenImagesFormContext()
    const { ref: registeredRef, ...registered } = register(fieldName)
    const ref = useMergeRefs(forwardedRef, registeredRef)
    return <ChakraTextarea ref={ref} {...registered} {...props} />
  },
)
