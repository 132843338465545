import { Radio } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { Portal } from '@terass/common/src/portal'
import { formatDate } from '@terass/common/src/utils/date'
import { ChangeEventHandler } from 'react'

import {
  useMiraieFormContext,
  useMiraieFormController,
} from '@/hooks/useMiraieForm'

export type CloseOpenKubunRadioProps = {
  portal: Portal
}

export function CloseOpenKubunRadio({ portal }: CloseOpenKubunRadioProps) {
  const { setValue } = useMiraieFormContext()
  const {
    field: { value, ...field },
  } = useMiraieFormController({
    name: `${portal}_c_open_kbn`,
  })
  const isChecked = value === miraieFields.c_open_kbn.Enum.掲載期間外
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(`teraSeven.keisaiKikan.${portal}.to`, formatDate(new Date()))
    field.onChange(e)
  }

  return (
    <Radio
      {...field}
      onChange={onChange}
      isChecked={isChecked}
      value={miraieFields.c_open_kbn.Enum.掲載期間外}
    >
      掲載しない
    </Radio>
  )
}
