import { HTMLChakraProps, chakra } from '@chakra-ui/react'
import { serverTimestamp } from 'firebase/firestore'

import { usePanelImagesLayoutContext } from '@/components/form/ImagesLayout/PanelImagesLayoutContext'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'
import { useV1ImportParam } from '@/hooks/useV1ImportParam'
import { updateImport } from '@/utils/import'
import { toast } from '@/utils/standaloneToast'

export const MiraieForm = (props: HTMLChakraProps<'form'>) => {
  const importId = useV1ImportParam()
  const { handleSubmit } = useMiraieFormContext()
  const { savedPanelImagesLayout } = usePanelImagesLayoutContext()
  const onSubmit = handleSubmit(async ({ teraSeven, ...formData }) => {
    await updateImport(importId, {
      formData,
      teraSeven,
      status: 'draft',
      draftedAt: serverTimestamp(),
      panelImagesLayout: savedPanelImagesLayout,
    })

    toast({
      description: '媒体掲載が下書き保存されました。',
      status: 'info',
      position: 'top',
      isClosable: true,
    })
  })

  return <chakra.form onSubmit={onSubmit} {...props} />
}
