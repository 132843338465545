import { FieldPath, useForm, useWatch } from 'react-hook-form'

export type SelectMasqueradeForm = {
  query: string
}

export const useSelectMasqueradeForm = () =>
  useForm<SelectMasqueradeForm>({ defaultValues: { query: '' } })

export const useWatchSelectMasqueradeForm = <
  T extends FieldPath<SelectMasqueradeForm>,
>(
  name: T,
) => useWatch({ name })
