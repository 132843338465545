import { miraieFields } from '@terass/common/src'

import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const usePrice = () => {
  const [price] = useWatchMiraieFields({ name: ['price'] })
  const isChintai = useIsChintai()

  return miraieFields.price.normalizePrice(price, isChintai)
}
