import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { InteriorDetailCollapse } from '@/components/form/Reform/InteriorDetailCollapse'
import {
  EnumRadios,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const NaisoCustomizeControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>内装カスタマイズ</FormGroupHeading>
      <FormControl fieldName="interior_custom_kbn" fieldset>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.interior_custom_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <InteriorDetailCollapse>
        <FormGroup nested>
          <FormControl fieldName="interior_custom_naiyo">
            <FormLabel>内容</FormLabel>
            <Textarea />
            <PortalErrorMessages />
          </FormControl>
          <FormControl fieldName="interior_custom_joken">
            <FormLabel>条件</FormLabel>
            <Textarea />
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </InteriorDetailCollapse>
    </FormGroup>
  )
}
