import { Flex, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import React from 'react'

import { OsusumePickupOptions } from '@/components/form/Comment/OsusumePickupOptions'
import { StaffCommentTextarea } from '@/components/form/Comment/StaffCommentTextarea'
import { StaffPrCheckbox } from '@/components/form/Comment/StaffPrCheckbox'
import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { SuumoOptionsFormControl } from '@/components/form/Portal/SuumoOptionsFormControl'
import {
  EnumOptions,
  EnumRadios,
  FieldLength,
  FormControl,
  FormLabel,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'
import { useIsChintai } from '@/hooks/useIsChintai'

export const Suumo = () => {
  const isChintai = useIsChintai()

  return (
    <FormGroup>
      <FormGroupHeading>SUUMO専用項目</FormGroupHeading>
      {/* i_company_sub_catch_1~5を排他にするためCollapse使わず出しわけ */}
      {isChintai ? (
        <>
          <FormControl fieldName="net_catch">
            <FormLabel>キャッチコピー</FormLabel>
            <Textarea />
            <FieldLength maxLength={miraieFields.net_catch.maxLength} />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="net_free_com">
            <FormLabel>ネット用コメント</FormLabel>
            <Textarea />
            <FieldLength maxLength={miraieFields.net_free_com.maxLength} />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="suumo_biko">
            <FormLabel>備考</FormLabel>
            <Textarea />
            <FieldLength maxLength={miraieFields.suumo_biko.maxLength} />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldset>
            <FormLabel>会社間広告</FormLabel>
            <FormGroup>
              <FormControl fieldName="i_company_main_catch_1">
                <Flex gap={2} alignItems="center">
                  <InputGroup>
                    <InputLeftAddon>メインキャッチ1</InputLeftAddon>
                    <TextInput />
                  </InputGroup>
                  <FieldLength
                    maxLength={miraieFields.i_company_main_catch_1.maxLength}
                  />
                </Flex>
                <PortalErrorMessages />
              </FormControl>

              <FormControl fieldName="i_company_main_catch_2">
                <Flex gap={2} alignItems="center">
                  <InputGroup>
                    <InputLeftAddon>メインキャッチ2</InputLeftAddon>
                    <TextInput />
                  </InputGroup>
                  <FieldLength
                    maxLength={miraieFields.i_company_main_catch_2.maxLength}
                  />
                </Flex>
                <PortalErrorMessages />
              </FormControl>

              {([1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const).map((i) => (
                <FormControl key={i} fieldName={`i_company_sub_catch_${i}`}>
                  <Flex gap={2} alignItems="center">
                    <InputGroup>
                      <InputLeftAddon>サブキャッチ{i}</InputLeftAddon>
                      <TextInput />
                    </InputGroup>
                    <FieldLength
                      maxLength={
                        miraieFields.i_company_sub_catch.maxLengthChintai
                      }
                    />
                  </Flex>
                  <PortalErrorMessages />
                </FormControl>
              ))}

              <FormControl fieldName="i_company_free_com">
                <Flex gap={2} alignItems="center">
                  <InputGroup>
                    <InputLeftAddon>フリーコメント</InputLeftAddon>
                    <TextInput />
                  </InputGroup>
                  <FieldLength
                    maxLength={miraieFields.i_company_free_com.maxLength}
                  />
                </Flex>
                <PortalErrorMessages />
              </FormControl>

              <FormControl fieldName="shisetsu_kankyo">
                <FormLabel>コメント</FormLabel>
                <InputGroup>
                  <InputLeftAddon>１階入居店舗コメント</InputLeftAddon>
                  <TextInput />
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </FormControl>

          <FormControl fieldset>
            <FormLabel>画像指定</FormLabel>
            <FormGroup nested>
              <FormControl fieldName="yusen_img_kbn">
                <FormLabel>賃貸SUUMO内優先画像区分</FormLabel>
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.yusen_img_kbn.Enum} />
                </Select>
                <PortalErrorMessages />
              </FormControl>

              {(
                [
                  [1, 2, 3],
                  [2, 3, 1],
                  [3, 1, 2],
                ] as const
              ).map(([fieldIndex, exclude1, exclude2]) => (
                <FormControl
                  key={fieldIndex}
                  fieldName={`osusume_pickup_${fieldIndex}`}
                >
                  <FormLabel>
                    おすすめピックアップピクト指定{fieldIndex}
                  </FormLabel>
                  <Select>
                    <PlaceholderOption />
                    <OsusumePickupOptions
                      excludeFields={[
                        `osusume_pickup_${exclude1}`,
                        `osusume_pickup_${exclude2}`,
                      ]}
                    />
                  </Select>
                  <PortalErrorMessages />
                </FormControl>
              ))}
            </FormGroup>
          </FormControl>
        </>
      ) : (
        <>
          <FormControl fieldName="bk_catch">
            <FormLabel>メインキャッチ</FormLabel>
            <Textarea />
            <FieldLength maxLength={miraieFields.bk_catch.maxLength} />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldset>
            <FormLabel>スタッフからのコメント</FormLabel>
            <FormGroup>
              <SuumoOptionsFormControl fieldName="staff_keisai_flg">
                <StaffPrCheckbox />
                <PortalErrorMessages />
              </SuumoOptionsFormControl>
              <FormControl fieldName="staff_pr_com">
                <StaffCommentTextarea />
                <FieldLength maxLength={miraieFields.staff_pr_com.maxLength} />
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </FormControl>

          <FormControl fieldName="net_com">
            <FormLabel>サブキャッチ</FormLabel>
            <Textarea />
            <FieldLength maxLength={miraieFields.net_com.maxLength} />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldset>
            <FormLabel>関連ホームページURL</FormLabel>
            <FormGroup nested>
              {([1, 2, 3, 4, 5] as const).map((i) => (
                <React.Fragment key={i}>
                  <FormControl fieldName={`link_name_${i}`}>
                    <InputGroup>
                      <InputLeftAddon>リンク先表示名称{i}</InputLeftAddon>
                      <TextInput />
                    </InputGroup>
                    <PortalErrorMessages />
                  </FormControl>

                  <FormControl key={i} fieldName={`link_url_${i}`}>
                    <InputGroup>
                      <InputLeftAddon>リンク先URL{i}</InputLeftAddon>
                      <TextInput />
                    </InputGroup>
                    <PortalErrorMessages />
                  </FormControl>
                </React.Fragment>
              ))}
            </FormGroup>
          </FormControl>

          <MiraieFieldContents
            name={['suumonet_keisai_kbn', 'bk_sbt_kbn']}
            display={(suumonet_keisai_kbn, bk_sbt_kbn) =>
              suumonet_keisai_kbn ===
                miraieFields.suumonet_keisai_kbn.Enum.SUUMOネットレポート &&
              miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'buy'
            }
          >
            <FormGroup>
              {([1, 2, 3] as const).map((i) => (
                <FormControl fieldset key={i}>
                  <FormLabel>購入サポート{i}</FormLabel>
                  <FormGroup nested>
                    <FormControl fieldName={`konyu_support_gaiyo_kbn_${i}`}>
                      <FormLabel>概要</FormLabel>
                      <Select>
                        <PlaceholderOption />
                        <EnumOptions
                          enum={miraieFields.konyu_support_gaiyo_kbn.Enum}
                        />
                      </Select>
                      <PortalErrorMessages />
                    </FormControl>

                    <FormControl fieldName={`konyu_support_gaiyo_shosai_${i}`}>
                      <FormLabel>詳細</FormLabel>
                      <Textarea />
                      <FieldLength
                        maxLength={
                          miraieFields.konyu_support_gaiyo_shosai.maxLength
                        }
                      />
                      <PortalErrorMessages />
                    </FormControl>

                    <FormControl
                      fieldName={`konyu_support_gaiyo_ryokin_umu_flg_${i}`}
                      fieldset
                    >
                      <FormLabel>金額</FormLabel>
                      <RadioGroup>
                        <UnselectedRadio />
                        <EnumRadios
                          enum={
                            miraieFields.konyu_support_gaiyo_ryokin_umu_flg.Enum
                          }
                        />
                      </RadioGroup>
                      <PortalErrorMessages />
                    </FormControl>

                    <FormControl fieldName={`konyu_support_gaiyo_ryokin_${i}`}>
                      <InputGroup>
                        <InputLeftAddon>金額</InputLeftAddon>
                        <TextInput />
                      </InputGroup>
                      <PortalErrorMessages />
                    </FormControl>

                    <FormControl fieldName={`konyu_support_gaiyo_kikan_${i}`}>
                      <FormLabel>期間</FormLabel>
                      <Textarea />
                      <FieldLength
                        maxLength={
                          miraieFields.konyu_support_gaiyo_kikan.maxLength
                        }
                      />
                      <PortalErrorMessages />
                    </FormControl>

                    <FormControl fieldName={`konyu_support_gaiyo_chuui_${i}`}>
                      <FormLabel>注意点・制約事項</FormLabel>
                      <Textarea />
                      <FieldLength
                        maxLength={
                          miraieFields.konyu_support_gaiyo_chuui.maxLength
                        }
                      />
                      <PortalErrorMessages />
                    </FormControl>
                  </FormGroup>
                </FormControl>
              ))}
            </FormGroup>
          </MiraieFieldContents>

          <FormControl fieldset>
            <FormLabel>会社間広告(自動作成図面登録用)</FormLabel>
            <FormGroup nested>
              <FormControl fieldName="i_company_main_catch">
                <Flex gap={2} alignItems="center">
                  <InputGroup>
                    <InputLeftAddon>メインキャッチ</InputLeftAddon>
                    <TextInput />
                  </InputGroup>
                  <FieldLength
                    maxLength={miraieFields.i_company_main_catch.maxLength}
                  />
                </Flex>
                <PortalErrorMessages />
              </FormControl>

              {([1, 2, 3, 4, 5] as const).map((i) => (
                <FormControl key={i} fieldName={`i_company_sub_catch_${i}`}>
                  <Flex gap={2} alignItems="center">
                    <InputGroup>
                      <InputLeftAddon>サブキャッチ{i}</InputLeftAddon>
                      <TextInput />
                    </InputGroup>
                    <FieldLength
                      maxLength={miraieFields.i_company_sub_catch.maxLength}
                    />
                  </Flex>
                  <PortalErrorMessages />
                </FormControl>
              ))}

              <FormControl fieldName="i_company_free_com">
                <Flex gap={2} alignItems="center">
                  <InputGroup>
                    <InputLeftAddon>フリーコメント</InputLeftAddon>
                    <TextInput />
                  </InputGroup>
                  <FieldLength
                    maxLength={miraieFields.i_company_free_com.maxLength}
                  />
                </Flex>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </FormControl>

          <FormControl fieldset>
            <FormLabel>その他</FormLabel>
            <FormGroup nested>
              <FormControl fieldName="pickup_com">
                <FormLabel>見学できる物件ピックアップ用コメント</FormLabel>
                <Flex gap={2} alignItems="center">
                  <TextInput />
                  <FieldLength maxLength={miraieFields.pickup_com.maxLength} />
                </Flex>
                <PortalErrorMessages />
              </FormControl>

              <FormControl fieldName="carnavi_addr">
                <FormLabel>カーナビ案内住所</FormLabel>
                <Flex gap={2} alignItems="center">
                  <TextInput />
                  <FieldLength
                    maxLength={miraieFields.carnavi_addr.maxLength}
                  />
                </Flex>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </FormControl>
        </>
      )}
    </FormGroup>
  )
}
