export function pick<T extends object, Keys extends readonly (keyof T)[]>(
  obj: T,
  keys: Keys,
): { [K in keyof T as Extract<Keys[number], K>]: T[K] }

export function pick(obj: Record<PropertyKey, unknown>, keys: PropertyKey[]) {
  return keys.reduce<Record<string, unknown>>(
    (acc, key) => Object.assign(acc, { [key]: obj[key] }),
    {},
  )
}
