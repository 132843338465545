import { miraieFields } from '@terass/common/src'
import { ReactNode } from 'react'
import { useFieldArray } from 'react-hook-form'

import { BukkenImagesForm } from '@/components/form/BukkenImages/types'
import { createContext } from '@/hooks/useContext'

const useBukkenImagesFieldArray = useFieldArray<
  BukkenImagesForm,
  miraieFields.images.category.Enum
>

const [Provider, useBukkenImagesFieldArrayContext] =
  createContext<ReturnType<typeof useBukkenImagesFieldArray>>()

export function BukkenImagesFieldArrayContextProvider({
  category,
  children,
}: {
  category: miraieFields.images.category.Enum
  children: ReactNode
}) {
  const value = useBukkenImagesFieldArray({ name: category })
  return <Provider {...{ value, children }} />
}

export { useBukkenImagesFieldArrayContext as useBukkenImagesFieldArray }
