import {
  Box,
  Button,
  Stack,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import { Link } from 'react-scroll'

import { BunjoSectionContents } from '@/components/form/Bunjou/BunjoSectionContents'
import { ColumnSuumoSectionContents } from '@/components/form/ColumnSuumo/ColumnSuumoSectionContents'
import { ImagesLayoutContents } from '@/components/form/ImagesLayout/ImagesLayoutContents'
import { OpenHouseSectionContents } from '@/components/form/OpenHouse/OpenHouseSectionContents'
import { ReformSectionContents } from '@/components/form/Reform/ReformSectionContents'
import { RoomSectionContents } from '@/components/form/Room/RoomSectionContents'
import { TatemonoSectionContents } from '@/components/form/Tatemono/TatemonoSectionContents'
import { TochiSectionContents } from '@/components/form/Tochi/TochiSectionContents'
// import { ToshiSectionContents } from '@/components/form/Toshi/ToshiSectionContents'

export const sectionIds = {
  categoryField: 'categoryField',
  portalSection: 'portalSection',
  generalFormSection: 'generalFormSection',
  costFormSection: 'costFormSection',
  reformSection: 'reformSection',
  tatemonoSection: 'tatemonoSection',
  ensenEkiSection: 'ensenEkiSection',
  roomSection: 'roomSection',
  bunjouSection: 'bunjouSection',
  tochiSection: 'tochiSection',
  toshiField: 'toshiField',
  columnSuumoSection: 'columnSuumoSection',
  torihikiSection: 'torihikiSection',
  setsubiSection: 'setsubiSection',
  commentSection: 'commentSection',
  openHouseSection: 'openHouseSection',
  bukkenImagesSection: 'bukkenImagesSection',
  shisetsuSection: 'shisetsuSection',
  imagesLayoutSection: 'imagesLayoutSection',
} as const

const NavLink = ({
  to,
  children,
}: {
  to: keyof typeof sectionIds
  children: ReactNode
}) => {
  const [isActive, setIsActive] = useState(false)
  const setActive = () => setIsActive(true)
  const setInactive = () => setIsActive(false)

  return (
    <Link to={to} onSetActive={setActive} onSetInactive={setInactive} spy>
      <Box
        backgroundColor={isActive ? 'gray.600' : 'gray.700'}
        color="white"
        py={2}
        px={4}
        _hover={{
          backgroundColor: 'gray.600',
        }}
        cursor="pointer"
        transitionProperty="common"
        transitionDuration="normal"
      >
        {children}
      </Box>
    </Link>
  )
}

const Navigation = () => {
  return (
    <Stack as="nav" color="white.100" spacing="0">
      <NavLink children="基本種別" to={sectionIds.categoryField} />
      <NavLink children="掲載設定" to={sectionIds.portalSection} />
      <NavLink children="基本" to={sectionIds.generalFormSection} />
      <NavLink children="沿線駅" to={sectionIds.ensenEkiSection} />
      <NavLink children="価格費用" to={sectionIds.costFormSection} />
      <ReformSectionContents>
        <NavLink children="リフォーム" to={sectionIds.reformSection} />
      </ReformSectionContents>
      <TatemonoSectionContents>
        <NavLink children="建物" to={sectionIds.tatemonoSection} />
      </TatemonoSectionContents>
      <RoomSectionContents>
        <NavLink children="部屋" to={sectionIds.roomSection} />
      </RoomSectionContents>
      <BunjoSectionContents>
        <NavLink children="分譲" to={sectionIds.bunjouSection} />
      </BunjoSectionContents>
      <TochiSectionContents>
        <NavLink children="土地借地権" to={sectionIds.tochiSection} />
      </TochiSectionContents>
      {/* TODO 投資用に対応するまで非表示 */}
      {/* <ToshiSectionContents> */}
      {/*  <NavLink children="投資用" to={sectionIds.toshiField} />*/}
      {/* </ToshiSectionContents> */}

      <ColumnSuumoSectionContents>
        <NavLink children="コラム" to={sectionIds.columnSuumoSection} />
      </ColumnSuumoSectionContents>
      <NavLink children="取引付帯情報" to={sectionIds.torihikiSection} />
      <NavLink children="設備特記" to={sectionIds.setsubiSection} />
      <NavLink children="コメント備考" to={sectionIds.commentSection} />
      <OpenHouseSectionContents>
        <NavLink children="オープンハウス" to={sectionIds.openHouseSection} />
      </OpenHouseSectionContents>
      <NavLink children="画像" to={sectionIds.bukkenImagesSection} />
      <NavLink children="周辺環境" to={sectionIds.shisetsuSection} />
      <ImagesLayoutContents>
        <NavLink children="レイアウト" to={sectionIds.imagesLayoutSection} />
      </ImagesLayoutContents>
    </Stack>
  )
}

const HamburgerIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 100 100">
    <path d="M 10 25 h 80 z" strokeWidth="5" stroke="#ddd" />
    <path d="M 10 50 h 80 z" strokeWidth="5" stroke="#ddd" />
    <path d="M 10 75 h 80 z" strokeWidth="5" stroke="#ddd" />
  </svg>
)
const DrawerMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        onClick={onOpen}
        pos="fixed"
        left={2}
        top={70}
        width={31}
        height={31}
        p={2}
        zIndex={1}
        colorScheme="blackAlpha"
      >
        <HamburgerIcon />
      </Button>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="left"
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor="">
          <DrawerCloseButton />
          <DrawerHeader color="">Menu</DrawerHeader>
          <DrawerBody width="100%" padding={0}>
            <Navigation />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export const SectionBar: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Stack>
      <Box display={{ base: 'block', xl: 'none' }} zIndex="sticky">
        <DrawerMenu />
      </Box>
      {children}
      <Box
        display={{ base: 'none', xl: 'block' }}
        w={200}
        position="fixed"
        top={16}
        left={0}
        zIndex="sticky"
      >
        <Navigation />
      </Box>
    </Stack>
  )
}
