import { Checkbox } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  useMiraieFormContext,
  useWatchMiraieFields,
} from '@/hooks/useMiraieForm'

export const StaffPrCheckbox = () => {
  const { setValue } = useMiraieFormContext()
  const [staff_keisai_flg] = useWatchMiraieFields({
    name: ['staff_keisai_flg'],
  })
  const isChecked = staff_keisai_flg === miraieFields.staff_keisai_flg.Enum.指示

  return (
    <Checkbox
      isChecked={isChecked}
      onChange={(e) =>
        setValue(
          'staff_keisai_flg',
          e.target.checked
            ? miraieFields.staff_keisai_flg.Enum.指示
            : miraieFields.staff_keisai_flg.Enum.非指示,
        )
      }
    >
      スタッフPRオプション
    </Checkbox>
  )
}
