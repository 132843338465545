import eki from '@terass/common/src/eki.json'
import { identity } from '@terass/common/src/utils'

const ensenNames = Object.keys(eki)

export function findEnsenByEki(ekiName: string): string | null {
  for (const ensenName of ensenNames) {
    const ekiNames =
      identity<{ [K in string]?: string[] }>(eki)[ensenName] ?? []
    if (ekiNames.includes(ekiName)) return ensenName
  }
  return null
}

/**
 * 徒歩分数から徒歩距離(メートル)を計算する
 */
export function walkTimeToDistance(walkTime: number) {
  return walkTime * 80
}
