import {
  Button,
  Flex,
  HStack,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  TextInput,
  NumberInput,
  Select,
  EnumOptions,
  PlaceholderOption,
  FormHelperText,
} from '../controls'

import { EditEnsenEkiButton } from './EditEnsenEkiButton'
import { EnsenEkiIndex } from './useEnsenEkiContext'

import { WalkTimeInput } from '@/components/form/EnsenEki/WalkTimeInput'
import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { useIsEdit } from '@/hooks/useIsEdit'
import {
  useMiraieFormContext,
  useWatchMiraieFields,
} from '@/hooks/useMiraieForm'

export const EnsenEki: React.FC<{ index: EnsenEkiIndex }> = ({ index }) => {
  const [ekiName, ensenName] = useWatchMiraieFields({
    name: [`eki_name_${index}`, `ensen_name_${index}`],
  })
  const { setValue } = useMiraieFormContext()
  const [isOpen, setIsOpen] = useState(index === '1' || !!ekiName)

  const { isOpen: isModalOpen, onClose, onOpen } = useDisclosure()
  const isEdit = useIsEdit()

  useEffect(() => {
    if (ekiName) setIsOpen(true)
  }, [ekiName])

  if (!isOpen)
    return (
      <Button
        isDisabled={!isEdit}
        variant="outline"
        onClick={() => setIsOpen(true)}
      >
        沿線・駅を追加
      </Button>
    )

  return (
    <FormGroup>
      <FormGroupHeading>
        <HStack justifyContent="space-between">
          <Text> {`沿線${index}`}</Text>
          {index !== '1' && isEdit && (
            <Button leftIcon={<BsTrash />} onClick={onOpen} height={8}>
              沿線{index}を削除
            </Button>
          )}
        </HStack>
      </FormGroupHeading>
      <Modal isOpen={isModalOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>沿線駅を削除</ModalHeader>
          <ModalBody>
            <Stack>
              <Text mb={4}> 沿線駅を削除してよろしいですか？</Text>
              <Text>{ensenName}</Text>
              <Text>{ekiName && `${ekiName}駅`}</Text>
            </Stack>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button onClick={onClose} colorScheme="gray">
              キャンセル
            </Button>
            <Button
              onClick={() => {
                setValue(`ensen_name_${index}`, undefined)
                setValue(`eki_name_${index}`, undefined)
                setValue(`yusen_kotsu_kbn_${index}`, undefined)
                setValue(`walk_time_${index}`, undefined)
                setValue(`walk_kyori_${index}`, undefined)
                setValue(`car_time_${index}`, undefined)
                setValue(`car_kyori_${index}`, undefined)
                setValue(`bus_line_name_${index}`, undefined)
                setValue(`bus_stop_name_${index}`, undefined)
                setValue(`bus_time_${index}`, undefined)
                setValue(`bus_stop_walk_time_${index}`, undefined)
                setValue(`bus_stop_walk_kyori_${index}`, undefined)
                setIsOpen(false)
                onClose()
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <FormLabel mb={0}>沿線駅</FormLabel>
      <FormGroup nested>
        <FormControl
          fieldName={`ensen_name_${index}`}
          maxWidth="50%"
          isRequired
        >
          <FormLabel>路線名</FormLabel>
          {/* 「ゆりかもめ」など「線」で終わらない路線名があるので、路線名にはRightAddonを付けない */}
          <TextInput />
          <PortalErrorMessages />
        </FormControl>
        <FormControl fieldName={`eki_name_${index}`} maxWidth="50%" isRequired>
          <FormLabel>駅名</FormLabel>
          <InputGroup>
            <TextInput />
            <InputRightAddon>駅</InputRightAddon>
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>
        <EditEnsenEkiButton index={index} alignSelf="start" />
      </FormGroup>
      <FormControl fieldName={`yusen_kotsu_kbn_${index}`} isRequired>
        <FormLabel>優先手段</FormLabel>
        <Select>
          <PlaceholderOption />
          <EnumOptions enum={miraieFields.yusen_kotsu_kbn.Enum} />
        </Select>
        <PortalErrorMessages />
      </FormControl>

      <FormControl fieldset>
        <FormLabel>駅徒歩</FormLabel>
        <Flex gap={4} align="center">
          <FormControl fieldName={`walk_time_${index}`}>
            <InputGroup>
              <WalkTimeInput index={index} />
              <InputRightAddon>分</InputRightAddon>
            </InputGroup>
          </FormControl>

          <FormControl fieldName={`walk_kyori_${index}`}>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>m</InputRightAddon>
            </InputGroup>
          </FormControl>
        </Flex>
        <FormHelperText>
          徒歩距離は 徒歩分数x80 で自動計算されます。
        </FormHelperText>

        <PortalErrorMessages fieldName={`walk_time_${index}`} />
        <PortalErrorMessages fieldName={`walk_kyori_${index}`} />
      </FormControl>

      <FormControl fieldset>
        <FormLabel>車</FormLabel>
        <Flex gap={4} align="center">
          <FormControl fieldName={`car_time_${index}`}>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>分</InputRightAddon>
            </InputGroup>
          </FormControl>

          <FormControl fieldName={`car_kyori_${index}`}>
            <InputGroup>
              <NumberInput step={miraieFields.car_kyori.step} />
              <InputRightAddon>km</InputRightAddon>
            </InputGroup>
          </FormControl>
        </Flex>
        <PortalErrorMessages fieldName={`car_time_${index}`} />
        <PortalErrorMessages fieldName={`car_kyori_${index}`} />
      </FormControl>

      <FormControl fieldset>
        <FormLabel>バス</FormLabel>
        <Flex gap={4} align="center">
          <FormControl fieldName={`bus_line_name_${index}`}>
            <TextInput placeholder="路線名" />
          </FormControl>

          <FormControl fieldName={`bus_stop_name_${index}`}>
            <TextInput placeholder="停名" />
          </FormControl>
        </Flex>
        <PortalErrorMessages fieldName={`bus_line_name_${index}`} />
        <PortalErrorMessages fieldName={`bus_stop_name_${index}`} />
      </FormControl>

      <FormControl fieldset>
        <Flex gap={4} align="center">
          <FormControl fieldName={`bus_time_${index}`}>
            <InputGroup>
              <InputLeftAddon>乗車</InputLeftAddon>
              <NumberInput />
              <InputRightAddon>分</InputRightAddon>
            </InputGroup>
          </FormControl>

          <FormControl fieldName={`bus_stop_walk_time_${index}`}>
            <InputGroup>
              <InputLeftAddon>停徒歩</InputLeftAddon>
              <NumberInput />
              <InputRightAddon>分</InputRightAddon>
            </InputGroup>
          </FormControl>

          <FormControl fieldName={`bus_stop_walk_kyori_${index}`}>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>m</InputRightAddon>
            </InputGroup>
          </FormControl>
        </Flex>
        <PortalErrorMessages fieldName={`bus_time_${index}`} />
        <PortalErrorMessages fieldName={`bus_stop_walk_time_${index}`} />
        <PortalErrorMessages fieldName={`bus_stop_walk_kyori_${index}`} />
      </FormControl>
    </FormGroup>
  )
}
