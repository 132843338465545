import { useIsChintai } from '@/hooks/useIsChintai'

/** 1時間あたりの分 */
const MINUTES_PER_HOUR = 60

/** 賃貸における分の間隔 */
const CHINTAI_MINUTES_INTERVAL = 5

/** 売買における分の間隔 */
const BAIBAI_MINUES_INTERVAL = 15

export const MinuteOptions = () => {
  const isChintai = useIsChintai()
  const minuteInterval = isChintai
    ? CHINTAI_MINUTES_INTERVAL
    : BAIBAI_MINUES_INTERVAL
  const options = [...new Array(MINUTES_PER_HOUR / minuteInterval).keys()].map(
    (a) => `${a * minuteInterval}`.padStart(2, '0'),
  )

  return (
    <>
      <option />
      {options.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </>
  )
}
