import { Shisetsu } from '@/components/form/Shisetsu/useShisetsuForm'

export function createDeleteShisetsu(
  initialShisetsu: Shisetsu[],
  newShisetsu: Shisetsu[],
) {
  return initialShisetsu
    .filter((x) => {
      return newShisetsu.every((y) => y.shisetsu_no !== x.shisetsu_no)
    })
    .map(({ shisetsu_no }) => ({ shisetsu_no }))
}
