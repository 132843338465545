/**
 * 駐車場備考区分
 */
export const Enum = {
  駐2台可: '1',
  駐3台可: '2',
  駐2台付: '3',
  駐3台付: '4',
  地下駐: '5',
  地下車庫: '6',
  屋根付駐: '7',
  平置屋根付駐: '8',
  機械式屋根付駐: '9',
  屋内駐: '10',
  平置駐: '11',
  シャッタ付車庫: '12',
  シャッタ付地下車庫: '13',
  シャッタ付屋内駐: '14',
  シャッタ付駐: '15',
  リモコンシャッタ付地下駐: '16',
  リモコンシャッタ付駐: '17',
} as const
