import * as bk_sbt_kbn from '../../bk_sbt_kbn'
import * as category from '../category'

import { getGroupedValues } from './getGroupedValues'
import { groupNames } from './groupName'
import { isAvailable } from './isAvailable'

type Values = {
  category: category.Enum
  bk_sbt_kbn: bk_sbt_kbn.Enum
}

export const getFirstValue = ({ category, bk_sbt_kbn }: Values) => {
  for (const groupName of groupNames) {
    const firstValue = getGroupedValues({ groupName, bk_sbt_kbn }).find(
      (img_sbt_kbn) => isAvailable({ img_sbt_kbn, bk_sbt_kbn, category }),
    )
    if (firstValue) return firstValue
  }
  throw new Error()
}
