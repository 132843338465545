import { VStack, Accordion, Button } from '@chakra-ui/react'
import { shisetsu_sbt_kbn } from '@terass/common/src/miraie/fields/shisetsu'
import { ValueOf } from '@terass/common/src/types'
import { useState } from 'react'

import { PlaceAccordionItem } from '@/components/form/Shisetsu/PlaceAccordionItem'
import {
  useShisetsuFormContext,
  useWatchShisetsuForm,
} from '@/components/form/Shisetsu/useShisetsuForm'
import {
  MapService,
  Place,
  isActiveShisetsu,
  placeConditions,
} from '@/components/form/Shisetsu/utils'
import { useLoadScript } from '@/hooks/useLoadScript'

type Props = {
  addPlace: (args: Place) => void
  mapService: MapService
}

export const ShisetsuOption = ({ addPlace, mapService }: Props) => {
  const [lat, lng] = useShisetsuFormContext().getValues(['lat', 'lon'])
  const shisetsuList = useWatchShisetsuForm('shisetsu')
  const { isLoaded, loadError } = useLoadScript()

  const [isLoading, setIsLoading] = useState(false)

  if (!lat || !lng)
    return <>入力補助を利用する場合は、物件の緯度軽度を選択してください</>

  if (loadError) return 'Error'
  if (!isLoaded) return 'Loading...'

  const autoFill = async () => {
    setIsLoading(true)
    const autoPlaces: Place[] = []
    const maxCount = shisetsuList.filter((x) => !isActiveShisetsu(x)).length
    for (const shisetsu_sbt_kbn of autoPlacePriorities) {
      if (autoPlaces.length >= maxCount) break

      const placeCondition = placeConditions.find(
        (x) => x.kubun === shisetsu_sbt_kbn,
      )
      if (!placeCondition) continue

      await mapService.nearbySearch(placeCondition, (places) => {
        autoPlaces.push(...places.slice(0, 2))
      })
    }

    autoPlaces.slice(0, maxCount).forEach(addPlace)

    setIsLoading(false)
  }

  return (
    <VStack width="100%" flexGrow={1} flex={1} direction="column" gap={4}>
      <Button onClick={autoFill} isLoading={isLoading} variant="outline">
        自動入力
      </Button>
      <Accordion allowMultiple width="100%">
        {placeConditions.map((x, i) => (
          <PlaceAccordionItem
            key={i}
            placeCondition={x}
            addPlace={addPlace}
            mapService={mapService}
          />
        ))}
      </Accordion>
    </VStack>
  )
}

const autoPlacePriorities: Array<ValueOf<typeof shisetsu_sbt_kbn.Enum>> = [
  shisetsu_sbt_kbn.Enum.スーパー,
  shisetsu_sbt_kbn.Enum.コンビニ,
  shisetsu_sbt_kbn.Enum.ドラッグストア,
  shisetsu_sbt_kbn.Enum.銀行,
  shisetsu_sbt_kbn.Enum.総合病院,
  shisetsu_sbt_kbn.Enum.郵便局,
  shisetsu_sbt_kbn.Enum.警察署交番,
  shisetsu_sbt_kbn.Enum.中学校,
  shisetsu_sbt_kbn.Enum.公園,
  shisetsu_sbt_kbn.Enum.役所,
  shisetsu_sbt_kbn.Enum.幼稚園保育園,
  shisetsu_sbt_kbn.Enum.小学校,
  shisetsu_sbt_kbn.Enum.駅,
]
