import { Box, Card, CardBody } from '@chakra-ui/react'

import { KahiControls } from './KahiControls'
import { MitsumoriControls } from './MitsumoriControls'
import { ReformFlagControls } from './ReformFlagControls'
import { ReformSectionContents } from './ReformSectionContents'
import { RenovationControls } from './RenovationControls'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { KahiContents } from '@/components/form/Reform/KahiContents'
import { MitsumoriContents } from '@/components/form/Reform/MitsumoriContents'
import { NaisoCustomizeContents } from '@/components/form/Reform/NaisoCustomizeContents'
import { NaisoCustomizeControls } from '@/components/form/Reform/NaisoCustomizeControls'
import { sectionIds } from '@/components/form/SectionBar'

export const ReformSection = () => {
  return (
    <ReformSectionContents>
      <Box as="section" id={sectionIds.reformSection}>
        <FormSectionHeading>リフォーム</FormSectionHeading>
        <Card>
          <CardBody as={FormGroup}>
            <ReformFlagControls />
            <MitsumoriContents>
              <MitsumoriControls />
            </MitsumoriContents>
            <KahiContents>
              <KahiControls />
            </KahiContents>
            <RenovationControls />
            <NaisoCustomizeContents>
              <NaisoCustomizeControls />
            </NaisoCustomizeContents>
          </CardBody>
        </Card>
      </Box>
    </ReformSectionContents>
  )
}
