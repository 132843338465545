import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const RenovationKubunCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['renovation_kbn']}
      display={(renovation_kbn) =>
        renovation_kbn === miraieFields.renovation_kbn.Enum.済 ||
        renovation_kbn === miraieFields.renovation_kbn.Enum.完了予定
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
