import { InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
  RadioGroup,
  EnumRadios,
  UnselectedRadio,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { CatvInitPriceCollapse } from '@/components/form/PriceAndCost/CatvInitPriceCollapse'

export const CATVInit: React.FC = () => {
  return (
    <>
      <FormControl fieldName="catv_initprice_umu_kbn" fieldset>
        <FormLabel>CATV（初期費用）</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.catv_initprice_umu_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <CatvInitPriceCollapse>
        <FormGroup nested>
          <FormControl fieldName="catv_initprice">
            <InputGroup>
              <NumberInput />
              <InputRightAddon>円</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </CatvInitPriceCollapse>
    </>
  )
}
