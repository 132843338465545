import { FormGroup } from '@/components/form/FormGroup'
import { CloseDateFormControl } from '@/components/form/Portal/CloseDateFormControl'
import { CloseDateInput } from '@/components/form/Portal/CloseDateInput'
import { MapSearchFlgCheckbox } from '@/components/form/Portal/MapSearchFlgCheckbox'
import { OpenKubun } from '@/components/form/Portal/OpenKubun'
import { PortalOptionsCollapse } from '@/components/form/Portal/PortalOptionsCollapse'
import { RoomNoFlgCheckbox } from '@/components/form/Portal/RoomNoFlgCheckbox'
import { TatemonoFlgCheckbox } from '@/components/form/Portal/TatemonoFlgCheckbox'
import {
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  PortalErrorMessages,
} from '@/components/form/controls'

export const AthomeRadioGroup: React.FC = () => {
  return (
    <FormGroup>
      <OpenKubun portal="athome" />
      <PortalOptionsCollapse portal="athome">
        <FormGroup nested>
          <FormControl fieldset>
            <CheckboxGroup>
              <TatemonoFlgCheckbox portal="athome" />
              <RoomNoFlgCheckbox portal="athome" />
              <MapSearchFlgCheckbox portal="athome" />
            </CheckboxGroup>
            <FormHelperText>athomeでは番地は表示されません</FormHelperText>
          </FormControl>
          <CloseDateFormControl portal="athome">
            <FormLabel>掲載終了日</FormLabel>
            <CloseDateInput />
            <PortalErrorMessages />
          </CloseDateFormControl>
        </FormGroup>
      </PortalOptionsCollapse>
    </FormGroup>
  )
}
