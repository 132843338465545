import { Flex } from '@chakra-ui/react'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { SonotaBikoCollapse } from './SonotaBikoCollapse'

import {
  BooleanEnumCheckbox,
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  PortalErrorMessages,
  Textarea,
  TextInput,
  MonthOptions,
  PlaceholderOption,
  Select,
  YearOptions,
} from '@/components/form/controls'

export const MizumawariControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>水回り</FormGroupHeading>
      <FormControl fieldset>
        <FormLabel>実施年月</FormLabel>
        <Flex gap={2}>
          <FormControl fieldName="reform_mizumawari_ym_year">
            <Select>
              <PlaceholderOption />
              <YearOptions />
            </Select>
          </FormControl>
          <FormControl fieldName="reform_mizumawari_ym_month">
            <Select>
              <PlaceholderOption />
              <MonthOptions />
            </Select>
          </FormControl>
        </Flex>
        <FormHelperText>
          HOME'Sフォーマット使用ポータルはリフォーム実施年月が未来の年月の場合はリフォーム情報は反映されません。
        </FormHelperText>
        <PortalErrorMessages fieldName="reform_mizumawari_ym_year" />
        <PortalErrorMessages fieldName="reform_mizumawari_ym_month" />
      </FormControl>
      <FormControl fieldset>
        <FormLabel>実施箇所</FormLabel>
        <CheckboxGroup>
          {mizumawariCheckFields.map((fieldName) => (
            <BooleanEnumCheckbox fieldName={fieldName} key={fieldName}>
              {
                {
                  reform_kitchen_flg: 'キッチン',
                  reform_yokusitsu_flg: '浴室',
                  reform_toire_flg: 'トイレ',
                  reform_washroom_flg: '洗面所',
                  reform_water_pipe_flg: '給排水道管',
                  reform_water_heater_flg: '給湯器',
                  reform_mizumawari_flg: 'その他',
                }[fieldName]
              }
            </BooleanEnumCheckbox>
          ))}
        </CheckboxGroup>
        {mizumawariCheckFields.map((fieldName) => (
          <PortalErrorMessages fieldName={fieldName} key={fieldName} />
        ))}
      </FormControl>
      <SonotaBikoCollapse>
        <FormGroup nested>
          <FormControl fieldName="reform_mizumawari_biko">
            <FormLabel>その他</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </SonotaBikoCollapse>
      <FormControl fieldName="reform_mizumawari_enforcement_content">
        <FormLabel>実施内容</FormLabel>
        <Textarea />
        <PortalErrorMessages />
      </FormControl>
    </FormGroup>
  )
}

const mizumawariCheckFields = [
  'reform_kitchen_flg',
  'reform_yokusitsu_flg',
  'reform_toire_flg',
  'reform_washroom_flg',
  'reform_water_pipe_flg',
  'reform_water_heater_flg',
  'reform_mizumawari_flg',
] as const satisfies readonly (keyof PostMiraieV1ImportForm)[]
