import { IsUnion } from '../types/isUnion'

export type SwapKeyValue<T extends Record<string, string>> = {
  [K in keyof T as T[K] extends infer U extends string
    ? IsUnion<U> extends true
      ? never
      : U
    : never]: `${Exclude<K, symbol>}`
} extends infer U
  ? {
      [K in keyof U as IsUnion<U[K]> extends true ? never : K]: U[K]
    }
  : never

export function swapKeyValue<T extends Record<string, string>>(
  obj: T,
): SwapKeyValue<T>
export function swapKeyValue(obj: Record<string, string>) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (Object.prototype.hasOwnProperty.call(acc, value)) throw new Error()
    return Object.assign(acc, { [value]: key })
  }, {})
}
