/**
 * 保証人代行会社区分
 */
export const Enum = {
  オリコフォレントインシュア: '1',
  その他: '2',
  全保連: '3',
  // VESTA: '4', // 廃止された模様
  日本セーフティー: '5',
  日本賃貸保証: '6',
  オリコ: '7',
  フォーシーズ: '8',
  Casa: '9',
  エルズサポート: '10',
  ランドインシュア: '11',
  グローバルトラストネットワーク: '12',
  新日本信用保証: '13',
} as const
