import { Radio, RadioProps } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { swapKeyValue } from '@terass/common/src/utils'

import { useIsChintai } from '@/hooks/useIsChintai'

export const HikiwatashiKubunRadios = (props: RadioProps) => {
  const isChintai = useIsChintai()
  return (
    <>
      {(
        [
          miraieFields.hikiwatashi_kbn.Enum.即時,
          miraieFields.hikiwatashi_kbn.Enum.相談,
          miraieFields.hikiwatashi_kbn.Enum.指定有,
          ...(isChintai ? [] : [miraieFields.hikiwatashi_kbn.Enum.契約後引渡]),
        ] as const
      ).map((value) => (
        <Radio key={value} value={value} {...props}>
          {swapKeyValue(miraieFields.hikiwatashi_kbn.Enum)[value]}
        </Radio>
      ))}
    </>
  )
}
