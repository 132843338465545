import { officeNamesSchema } from '@terass/common/src/tera-three/office'

import { Shops } from '../constants'

export const convertOfficeToShop = (office: string) =>
  officeToShop(office) ?? Shops.名古屋

export const officeToShop = (office: string) => {
  const isOfOfficeToShops = officeNamesSchema.safeParse(office)
  if (isOfOfficeToShops.success) {
    return Shops[isOfOfficeToShops.data]
  }
  return null
}
