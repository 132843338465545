// firebaseを初めにinitializeするために、一番上でimportしている
// eslint-disable-next-line import/order
import { initializeFirebase } from './utils/firebase'

import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import App from './App'
import { config } from './config'
import { router } from './route'

import { ToastContainer } from '@/utils/standaloneToast'

Sentry.init({
  // MODE=developmentの場合、dsnは空文字でSentryは無効になる
  dsn: config.SENTRY_DSN,
  environment: `frontend-${config.MODE}`,
  integrations: [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: config.isProduction ? 0.2 : 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

initializeFirebase()

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!
const root = ReactDOM.createRoot(rootElement)

root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
  </React.StrictMode>,
)
