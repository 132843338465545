import { Center } from '@chakra-ui/react'

import {
  getImageControlsArea,
  useImageControlsContext,
} from '@/components/form/Shisetsu/ImageControlsContext'
import { ShisetsuForm } from '@/components/form/Shisetsu/useShisetsuForm'
import {
  FileInputProps,
  ImageFile,
  ImageInput as _ImageInput,
} from '@/components/form/controls/ImageInput'

type Props = Omit<FileInputProps<ShisetsuForm>, 'fieldName'>

export const ImageInput = (props: Props) => {
  const { index } = useImageControlsContext()

  return (
    <_ImageInput<ShisetsuForm>
      fieldName={`shisetsu.${index}._imageFile`}
      gridArea={getImageControlsArea('_imageFile')}
      aspectRatio="1"
      transition="common"
      borderWidth="thin"
      borderColor="gray.200"
      transitionProperty="common"
      transitionDuration="normal"
      _hover={{ borderColor: 'gray.300', bgColor: 'gray.100' }}
      _focusWithin={{ outline: true }}
      position="relative"
      {...props}
    >
      <ImageFile<ShisetsuForm>
        src={`shisetsu.${index}.img_data_url`}
        fit="contain"
        h="full"
        fallback={<Center h="full">画像を選択</Center>}
      />
    </_ImageInput>
  )
}
