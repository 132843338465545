import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const InteriorDetailCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['interior_custom_kbn']}
      display={(interior_custom_kbn) =>
        interior_custom_kbn === miraieFields.interior_custom_kbn.Enum.可
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
