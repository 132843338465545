import {
  ChakraComponent,
  HTMLChakraProps,
  chakra,
  StyleProps,
} from '@chakra-ui/react'
import { forwardRef } from 'react'

/** ブラウザ検索が使えるselect */
export const SearchableSelect = forwardRef<
  ChakraComponent<'select'>,
  HTMLChakraProps<'select'> & { isError?: boolean }
>(({ isError, ...props }, ref) => {
  const style: StyleProps = isError
    ? {
        ...searchableSelectStyle,
        borderColor: 'red.500',
        borderWidth: 2,
      }
    : searchableSelectStyle

  // size（表示するoptionの数）を指定することで、初めからoptionが展開される
  return <chakra.select size={12} ref={ref} {...style} {...props} />
})

export const searchableSelectStyle: StyleProps = {
  width: '100%',
  height: '95%',
  border: '1px solid',
  borderRadius: 6,
  borderColor: 'inherit',
} as const
