import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'

export const ImagesLayoutContents = ({ children }: PropsWithChildren) => (
  <MiraieFieldContents
    name={['suumo_c_open_kbn']}
    display={miraieFields.c_open_kbn.isOpen}
  >
    {children}
  </MiraieFieldContents>
)
