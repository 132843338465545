import {
  Button,
  ButtonProps,
  useDisclosure,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'
import { ekiSupportedPrefectures } from '@terass/common/src/tera-seven/prefectures'

import { FormGroupHelperText } from '../FormGroupHelperText'
import { useWatchAddressCodes } from '../General/Address/hooks/useWatchAddressCodes'

import { EnsenEkiModalContent } from './EnsenEkiModalContent'
import { EnsenEkiContextProvider, EnsenEkiIndex } from './useEnsenEkiContext'

import { useIsEdit } from '@/hooks/useIsEdit'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

type Props = ButtonProps & { index: EnsenEkiIndex }

export const EditEnsenEkiButton = ({ index, ...buttonProps }: Props) => {
  const [lat, lng] = useWatchMiraieFields({ name: ['lat', 'lon'] })
  const hasLatLng = lat != null && lng != null
  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure()
  const isDisabled = !useIsEdit() || !hasLatLng
  const showButton =
    !!ekiSupportedPrefectures[useWatchAddressCodes().prefectureCode]
  if (!showButton) return null

  return (
    <>
      <Button isDisabled={isDisabled} onClick={onOpen} {...buttonProps}>
        地図から沿線駅を編集
      </Button>
      {!hasLatLng ? (
        <FormGroupHelperText>
          地図から駅を編集するには、緯度経度を入力してください。
        </FormGroupHelperText>
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size="6xl"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <EnsenEkiContextProvider value={{ index, position: { lat, lng } }}>
            <EnsenEkiModalContent />
          </EnsenEkiContextProvider>
        </Modal>
      )}
    </>
  )
}
