import { Button, ButtonProps, useDisclosure } from '@chakra-ui/react'

import { EditShisetsuImagesModal } from '@/components/form/Shisetsu/EditShisetsuImagesModal'
import { useIsEdit } from '@/hooks/useIsEdit'

export const ShisetsuButton = (props: ButtonProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const isDisabled = !useIsEdit()

  return (
    <>
      <Button isDisabled={isDisabled} onClick={onModalOpen} {...props}>
        編集
      </Button>

      {isModalOpen && (
        <EditShisetsuImagesModal onCloseComplete={onModalClose} />
      )}
    </>
  )
}
