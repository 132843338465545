import { Center } from '@chakra-ui/react'

import {
  getImageControlsArea,
  useImageControlsContext,
} from '@/components/form/BukkenImages/Controls/ImageControlsContext'
import { BukkenImagesForm } from '@/components/form/BukkenImages/types'
import {
  FileInputProps,
  ImageFile,
  ImageInput as _ImageInput,
} from '@/components/form/controls/ImageInput'

export const ImageInput = (
  props: Omit<FileInputProps<BukkenImagesForm>, 'fieldName'>,
) => {
  const { category, index } = useImageControlsContext()

  return (
    <_ImageInput<BukkenImagesForm>
      fieldName={`${category}.${index}._imageFile`}
      position="relative"
      gridArea={getImageControlsArea('_imageFile')}
      aspectRatio="1"
      transition="common"
      borderWidth="thin"
      borderColor="gray.200"
      transitionProperty="common"
      transitionDuration="normal"
      _hover={{ borderColor: 'gray.300', bgColor: 'gray.100' }}
      _focusWithin={{ outline: true }}
      {...props}
    >
      <ImageFile<BukkenImagesForm>
        src={`${category}.${index}.img_data_url`}
        fit="contain"
        h="full"
        fallback={<Center h="full">画像を選択</Center>}
      />
    </_ImageInput>
  )
}
