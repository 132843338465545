import { Box, Grid, GridItem, Text } from '@chakra-ui/react'

import { Contents } from '@/components/Contents'
import { useOperator } from '@/hooks/useOperator'

export const MasqueradeInfo = () => {
  const { name, email } = useOperator()

  return (
    <Box>
      <Text>
        以下のエージェントとして媒体掲載を公開します。よろしいですか？
      </Text>
      <Grid templateColumns="auto auto 1fr" gap={2} mt={4}>
        <Contents>
          <GridItem>名前</GridItem>
          <GridItem>:</GridItem>
          <GridItem>{name}</GridItem>
        </Contents>
        <Contents>
          <GridItem>メールアドレス</GridItem>
          <GridItem>:</GridItem>
          <GridItem>{email}</GridItem>
        </Contents>
      </Grid>
    </Box>
  )
}
