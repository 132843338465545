import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const YokokuKokokuCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['suumo_c_open_kbn', 'tato_kokoku_kbn']}
      display={(suumo_c_open_kbn, tato_kokoku_kbn) =>
        suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載 &&
        tato_kokoku_kbn === miraieFields.tato_kokoku_kbn.Enum.販売予定
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
