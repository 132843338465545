import { ValueOf } from '../../../../types'

export const Enum = {
  /**
   * 土地では `区画図`
   */
  '間取図(平面図)': '1',
  地形図: '2',
  外観: '3',
  /**
   * 賃貸では `その他室内・内装`
   */
  室内: '4',
  バス: '5',
  トイレ: '6',
  洗面: '7',
  キッチン: '8',
  エントランス: '9',
  収納: '10',
  玄関: '11',
  庭: '12',
  駐車場: '13',
  共有部分: '14',
  その他設備: '15',
  眺望: '16',
  バルコニー: '17',
  周辺環境: '18',
  公図実測図: '19',
  リビング: '20',
  その他: '21',
  /**
   * 賃貸では `アクセス図`
   */
  地図: '22',
  交通アクセス図: '23',
  路線図: '24',
  構造: '25',
  外観パース図: '26',
  CM横画像: '27',
  SUUMOCM画像: '28',
  /**
   * 賃貸では `その他画像`
   */
  周辺写真: '29',
  /**
   * 賃貸では `ロビー`
   */
  前面道路含む外観: '30',
  /**
   * 賃貸では `セキュリティ`
   */
  '建物プラン例(外観写真)': '31',
  '建物プラン例(内観写真)': '32',
  '建物プラン例(パース・外観)': '33',
  '建物プラン例(パース・内観)': '34',
  '建物プラン例(間取り図)': '35',
  その他建物プラン例: '36',
  寝室: '37',
  子供部屋: '38',
  '同仕様画像(外観)': '39',
  '同仕様画像(リビング)': '40',
  '同仕様画像(バス)': '41',
  '同仕様画像(キッチン)': '42',
  '同仕様画像(その他内観)': '43',
  その他現地: '44',
  'モデルルーム・モデルハウス写真': '45',
  その他内観: '46',
  ロビー: '47',
  '発電・温水設備': '48',
  '冷暖房・空調設備': '49',
  防犯設備: '50',
  分譲済街並み写真: '51',
  航空写真: '52',
  高台写真: '53',
  '完成予想図(内観)': '54',
  街並完成予想図: '55',
  全体区画図: '70',
  間取り: '71',
  区画図: '72',
  '区画図+建物プラン例': '73',
} as const

export type Enum = ValueOf<typeof Enum>
