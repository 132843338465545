import { miraieFields } from '@terass/common/src'
import { swapKeyValue } from '@terass/common/src/utils'

export const BkSbtKbnOptions = () => {
  return (
    <>
      {(['buy'] as const).map((category) => (
        <optgroup key={category} label={{ buy: '売買' }[category]}>
          {miraieFields.bk_sbt_kbn.values.map(
            (bk_sbt_kbn) =>
              bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)'] &&
              miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === category && (
                <option key={bk_sbt_kbn} value={bk_sbt_kbn}>
                  {swapKeyValue(miraieFields.bk_sbt_kbn.Enum)[bk_sbt_kbn]}
                </option>
              ),
          )}
        </optgroup>
      ))}
    </>
  )
}
