import { Button, ButtonProps } from '@chakra-ui/react'
import { identity } from '@terass/common/src/utils'

import { useDuplicateV1Import } from '@/hooks/useDuplicateV1Import'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const DuplicateButton = (props: ButtonProps) => {
  const v1import = useV1ImportDocument(identity)
  const { duplicateV1Import, isPending } = useDuplicateV1Import({ v1import })

  return <Button onClick={duplicateV1Import} isLoading={isPending} {...props} />
}
