import { miraieFields } from '@terass/common/src'
import { Suspense } from 'react'

import { SearchableSelect } from '../../controls'

import { ErrorMessage } from './ErrorMessage'
import { EmptySelect, LoadingSelect } from './controls'
import { useAzaList, useAddressFormContext, useWatchAddressForm } from './hooks'

export const AzaSelect = () => {
  const ooazaCode = useWatchAddressForm('ooazaCode')

  if (ooazaCode === miraieFields.aza_cd.ooaza.Code.未選択)
    return <EmptySelect>大字を選択してください</EmptySelect>

  return (
    <Suspense fallback={<LoadingSelect />}>
      <ExistsAzaSelect />
    </Suspense>
  )
}

const ExistsAzaSelect = () => {
  const {
    register,
    formState: { errors },
  } = useAddressFormContext()
  const options = useAzaList()
  if (options.length === 0)
    return <EmptySelect>字・丁目がありません</EmptySelect>

  return (
    <>
      <SearchableSelect
        isError={!!errors.azaCode}
        {...register('azaCode', { required: '住所の入力は必須です' })}
      >
        {options
          .filter(({ data: { isObsolete } }) => !isObsolete)
          .map(({ id: code, data: { name } }) => (
            <option key={code} id={code} value={code}>
              {name}
            </option>
          ))}
      </SearchableSelect>
      {errors.azaCode && <ErrorMessage>{errors.azaCode.message}</ErrorMessage>}
    </>
  )
}
