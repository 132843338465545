import { officeNames } from '@terass/common/src/tera-three/office'

export const ShopSelectOptions = () => (
  <>
    {officeNames.map((name) => (
      <option key={name} value={name}>
        {name}
      </option>
    ))}
  </>
)
