import { InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import {
  EnumRadios,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  TextInput,
  UnselectedRadio,
} from '@/components/form/controls'

export const ChokiYuryo = () => {
  return (
    <FormGroup>
      <FormGroupHeading>
        長期優良住宅（ネットレポートのみ掲載）
      </FormGroupHeading>

      <FormControl fieldName="long_highquality_housing_kbn" fieldset>
        <FormLabel>長期優良住宅区分</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.long_highquality_housing_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <MiraieFieldCollapse
        name={['long_highquality_housing_kbn']}
        display={(long_highquality_housing_kbn) =>
          !!long_highquality_housing_kbn
        }
      >
        <FormGroup nested>
          <FormControl fieldName="long_highquality_housing_partiton_name">
            <InputGroup>
              <InputLeftAddon>棟(区画名)</InputLeftAddon>
              <TextInput />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </MiraieFieldCollapse>
    </FormGroup>
  )
}
