import { useQuery } from '@tanstack/react-query'

import { useOperator } from '@/hooks/useOperator'
import { fetchMediationContract } from '@/utils/functions'

export const useMedContracts = () => {
  const { email } = useOperator()

  return useQuery({
    queryKey: ['medContracts', email],
    queryFn: fetchMediationContract,
  })
}
