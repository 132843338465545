import { Button } from '@chakra-ui/react'
import { BsTrash } from 'react-icons/bs'

import { useImageControlsContext } from '@/components/form/BukkenImages/Controls/ImageControlsContext'
import { useBukkenImagesFieldArray } from '@/components/form/BukkenImages/hooks'

export function RemoveImageButton() {
  const { index } = useImageControlsContext()
  const { remove } = useBukkenImagesFieldArray()

  return (
    <Button
      leftIcon={<BsTrash />}
      title="この行を削除する"
      size="sm"
      onClick={() => remove(index)}
    >
      削除
    </Button>
  )
}
