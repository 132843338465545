import * as bk_sbt_kbn from './enum'

export const getCategory = (bk_sbt_kbn: bk_sbt_kbn.Enum) =>
  categoryMap[bk_sbt_kbn]

export const isChintai = (bk_sbt_kbn: bk_sbt_kbn.Enum) =>
  getCategory(bk_sbt_kbn) === 'rent'

const categoryMap = {
  [bk_sbt_kbn.Enum.売地]: 'buy',
  [bk_sbt_kbn.Enum.売戸建住宅]: 'buy',
  [bk_sbt_kbn.Enum.売マンション]: 'buy',
  [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: 'buy',
  [bk_sbt_kbn.Enum.居住用]: 'rent',
} as const satisfies Record<bk_sbt_kbn.Enum, 'buy' | 'rent'>
