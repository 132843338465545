import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'

import { ConfirmAlertDialog } from '@/components/form/ConfirmAlertDialog'
import { ModalContent } from '@/components/form/Shisetsu/ModalContent'
import {
  useShisetsuForm,
  Shisetsu,
} from '@/components/form/Shisetsu/useShisetsuForm'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'
import { useV1ImportParam } from '@/hooks/useV1ImportParam'
import { updateImport } from '@/utils/import'

export function EditShisetsuImagesModal({
  onCloseComplete,
}: Pick<ModalProps, 'onCloseComplete'>) {
  const importId = useV1ImportParam()

  const { setValue, trigger } = useMiraieFormContext()
  const { isOpen: isModalOpen, onClose: onModalClose } = useDisclosure({
    defaultIsOpen: true,
  })
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure()
  const methods = useShisetsuForm()
  const {
    reset,
    formState: { isDirty },
  } = methods
  const confirmCloseModal = () => {
    isDirty ? onAlertOpen() : onModalClose()
  }
  const abortEdit = () => {
    onAlertClose()
    onModalClose()
    reset()
  }
  const updateShisetsu = async (shisetsu: Shisetsu[]) => {
    setValue('shisetsu', shisetsu)
    const validationResult = await trigger(['shisetsu'])
    if (!validationResult) throw new Error('validation failed')

    await updateImport(importId, { 'formData.shisetsu': shisetsu })
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={confirmCloseModal}
        onCloseComplete={onCloseComplete}
        size="5xl"
      >
        <FormProvider {...methods}>
          <ModalOverlay />
          <ModalContent
            updateShisetsu={updateShisetsu}
            onModalClose={onModalClose}
          />
        </FormProvider>
      </Modal>
      <ConfirmAlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        onAbort={abortEdit}
      />
    </>
  )
}
