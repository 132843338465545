import { omit } from '../../../utils'

/**
 * 画像オブジェクトが削除用パラメータであるかどうか
 * @example
 * ```ts
 * isRemover({ img_no: '1010' }) // -> true
 * isRemover({ img_no: '1010', img_title: 'Title' }) // -> false
 * ```
 */
export function isRemover(image: { img_no: string }) {
  return !Object.keys(omit(image, ['img_no'])).length
}
