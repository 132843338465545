import { Box, Card, CardBody } from '@chakra-ui/react'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { sectionIds } from '@/components/form/SectionBar'
import { Preview } from '@/components/form/Shisetsu/Preview'
import { ShisetsuButton } from '@/components/form/Shisetsu/ShisetsuButton'

export const ShisetsuSection: React.FC = () => {
  return (
    <Box as="section" id={sectionIds.shisetsuSection}>
      <FormSectionHeading>周辺環境</FormSectionHeading>
      <Card>
        <CardBody as={FormGroup}>
          <Preview />
          <ShisetsuButton variant="outline" alignSelf="start" />
        </CardBody>
      </Card>
    </Box>
  )
}
