import { Input, InputProps } from '@chakra-ui/react'

import { useFormControlContext } from '@/components/form/controls/FormControl'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'

export const TextInput = (props: InputProps) => {
  const { fieldName } = useFormControlContext()
  if (!fieldName) throw new Error()

  const { register } = useMiraieFormContext()

  return <Input {...register(fieldName)} {...props} />
}

/**
 * InputGroup が内部的に id で判定しているため必要
 * ないと border-radius などのスタイルが上書きされなくなる
 */
TextInput.id = Input.id
