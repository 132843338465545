import { Select as ChakraSelect, SelectProps } from '@chakra-ui/react'

import { useFormControlContext } from '@/components/form/controls/FormControl'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'

export const Select = (props: SelectProps) => {
  const { fieldName } = useFormControlContext()
  if (!fieldName) throw new Error()

  const { register } = useMiraieFormContext()

  return <ChakraSelect {...register(fieldName)} {...props} />
}
