import { HStack } from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'

import { BaitaiForm } from '@/components/form/BaitaiForm'
import { MiraieFormApplyButton } from '@/components/form/MiraieFormApplyButton'
import { MiraieFormSubmitButton } from '@/components/form/MiraieFormSubmitButton'
import { MiraieFormValidateButton } from '@/components/form/MiraieFormValidateButton'

export const Route = createFileRoute('/bukkens/$importId/edit/')({
  component: EditForm,
})

export function EditForm() {
  return (
    <BaitaiForm>
      <HStack mt={4} position="sticky" bottom={0}>
        <MiraieFormSubmitButton>下書き保存</MiraieFormSubmitButton>
        <MiraieFormApplyButton />
        <MiraieFormValidateButton>
          入力内容の確認（エラーチェック）
        </MiraieFormValidateButton>
      </HStack>
    </BaitaiForm>
  )
}
