import { Box, Card, CardBody } from '@chakra-ui/react'

import { EnsenEki } from '@/components/form/EnsenEki/EnsenEki'
import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { sectionIds } from '@/components/form/SectionBar'

export const EnsenEkiSection: React.FC = () => {
  return (
    <Box as="section" id={sectionIds.ensenEkiSection}>
      <FormSectionHeading>沿線・駅</FormSectionHeading>
      <Card>
        <CardBody as={FormGroup}>
          <EnsenEki index="1" />
          <EnsenEki index="2" />
          <EnsenEki index="3" />
          <EnsenEki index="4" />
        </CardBody>
      </Card>
    </Box>
  )
}
