import { Box, BoxProps } from '@chakra-ui/react'

import { useFormControlContext } from '@/components/form/controls/FormControl'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export type FieldLengthProps = {
  maxLength: number
} & BoxProps

export const FieldLength = ({ maxLength, ...props }: FieldLengthProps) => {
  const { fieldName } = useFormControlContext()
  if (!fieldName) throw new Error()

  const [fieldValue = ''] = useWatchMiraieFields({ name: [fieldName] })
  if (typeof fieldValue === 'object') throw new Error()

  const { length } = String(fieldValue)

  return (
    <Box as="span" {...props}>
      (
      <Box
        as="span"
        fontWeight="bold"
        color={maxLength < length ? 'red' : 'green'}
      >
        {length}
      </Box>
      /{maxLength})
    </Box>
  )
}
