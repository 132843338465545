import { miraieFields } from '@terass/common/src'

import { createContext } from '@/hooks/useContext'
import { gridTemplate } from '@/utils/gridTemplate'

export type ImageControlsContext = {
  category: miraieFields.images.category.Enum
  index: number
}

export const [Provider, useImageControlsContext] =
  createContext<ImageControlsContext>()

export const { style: gridTemplateStyle, getArea: getImageControlsArea } =
  gridTemplate`
"${'dragHandle'} ${'_imageFile'} ${'img_sbt_kbn'} ${'img_kokai_kbn'}" auto
"${'dragHandle'} ${'_imageFile'} ${'img_title'}   ${'img_kokai_kbn'}" 1fr
"${'dragHandle'} ${'_imageFile'} ${'img_com'}     ${'img_kokai_kbn'}" 1fr
/ min-content    1fr              auto               auto
`
