import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import {
  BooleanEnumCheckbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  Textarea,
} from '@/components/form/controls'

export const MizumawariKahiControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>水回り</FormGroupHeading>
      <FormControl fieldset>
        <CheckboxGroup>
          {mizumawariCheckFields.map((fieldName) => (
            <BooleanEnumCheckbox key={fieldName} fieldName={fieldName}>
              {
                {
                  reform_renovation_kanou_mizumawari_flg: '水回りの位置変更',
                  reform_renovation_kanou_kitchen_flg: 'キッチン',
                  reform_renovation_kanou_yokusitsu_flg: '浴室',
                  reform_renovation_kanou_toire_flg: 'トイレ',
                  reform_renovation_kanou_washroom_flg: '洗面所',
                  reform_renovation_kanou_water_pipe_flg: '給排水道管',
                  reform_renovation_kanou_water_heater_flg: '給湯器',
                }[fieldName]
              }
            </BooleanEnumCheckbox>
          ))}
        </CheckboxGroup>
        {mizumawariCheckFields.map((fieldName) => (
          <PortalErrorMessages key={fieldName} fieldName={fieldName} />
        ))}
      </FormControl>
      <FormGroup nested>
        <FormControl fieldName="reform_renovation_kanou_mizumawari_naiyo">
          <FormLabel>内容</FormLabel>
          <Textarea />
          <PortalErrorMessages />
        </FormControl>
      </FormGroup>
    </FormGroup>
  )
}

const mizumawariCheckFields = [
  'reform_renovation_kanou_mizumawari_flg',
  'reform_renovation_kanou_kitchen_flg',
  'reform_renovation_kanou_yokusitsu_flg',
  'reform_renovation_kanou_toire_flg',
  'reform_renovation_kanou_washroom_flg',
  'reform_renovation_kanou_water_pipe_flg',
  'reform_renovation_kanou_water_heater_flg',
] as const satisfies readonly (keyof PostMiraieV1ImportForm)[]
