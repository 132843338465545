import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const HikiwatashiNengetsuCollapse = ({
  children,
}: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['hikiwatashi_kbn']}
      display={(hikiwatashi_kbn) =>
        hikiwatashi_kbn === miraieFields.hikiwatashi_kbn.Enum.指定有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
