import { Heading, Stack, Text } from '@chakra-ui/react'
import { useNavigate } from '@tanstack/react-router'

import { BackToTopButton } from './BackToTopButton'
import { Main } from './Main'

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <Main>
      <Stack mt={4}>
        <Heading size="md">404 NOT FOUND</Heading>
        <Text>お探しのページが見つかりませんでした。</Text>
        <BackToTopButton onClick={() => navigate({ to: '/' })} />
      </Stack>
    </Main>
  )
}
