import { Box, Card, CardBody } from '@chakra-ui/react'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { sectionIds } from '@/components/form/SectionBar'
import { EditSetsubiTokkiButton } from '@/components/form/Setsubi/EditSetsubiTokkiButton'
import { SetsubiTokkiTags } from '@/components/form/Setsubi/SetsubiTokkiTags'

export const SetsubiSection: React.FC = () => {
  return (
    <Box as="section" id={sectionIds.setsubiSection}>
      <FormSectionHeading>設備・特記</FormSectionHeading>
      <Card>
        <CardBody as={FormGroup}>
          <SetsubiTokkiTags />
          <EditSetsubiTokkiButton variant="outline" alignSelf="start" />
        </CardBody>
      </Card>
    </Box>
  )
}
