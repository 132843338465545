/**
 * 間取区分
 */
export const Enum = {
  ワンルーム: '1',
  K: '2',
  DK: '3',
  LK: '4',
  LDK: '5',
  SK: '6',
  SDK: '7',
  SLK: '8',
  SLDK: '9',
  未定: '99',
} as const
