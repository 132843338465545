import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { GenbaToiawaseBikoContents } from '@/components/form/General/GenbaToiawaseBikoContents'
import {
  FieldLength,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  TextInput,
  Textarea,
} from '@/components/form/controls'

export const GenbaToiawaseControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>現場問い合わせ先情報</FormGroupHeading>
      <FormControl fieldName="genba_toiawase_name">
        <FormLabel>名称</FormLabel>
        <Textarea />
        <FieldLength maxLength={miraieFields.genba_toiawase_name.maxLength} />
        <PortalErrorMessages />
      </FormControl>

      <FormControl fieldName="genba_toiawase_tel">
        <FormLabel>電話番号</FormLabel>
        <TextInput />
        <PortalErrorMessages />
      </FormControl>

      <GenbaToiawaseBikoContents>
        <FormControl fieldName="genba_toiawase_biko">
          <FormLabel>備考</FormLabel>
          <Textarea />
          <FieldLength maxLength={miraieFields.genba_toiawase_biko.maxLength} />
          <PortalErrorMessages />
        </FormControl>
      </GenbaToiawaseBikoContents>
    </FormGroup>
  )
}
