import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const CarParkingSellDetailCollapse = ({
  children,
}: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse name={['parking_sell_kbn']} display={Boolean}>
      {children}
    </MiraieFieldCollapse>
  )
}
