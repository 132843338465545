/**
 * 賃貸SUUMO内優先画像区分
 */
export const Enum = {
  間取: '1',
  外観: '2',
  内観: '3',
  ネット基本画像1: '4',
  ネット基本画像2: '5',
  ネット基本画像3: '6',
  追加画像1: '10',
  追加画像2: '11',
  追加画像3: '12',
  追加画像4: '13',
  追加画像5: '14',
  追加画像6: '15',
  追加画像7: '16',
  追加画像8: '17',
} as const
