import { Text, Flex, InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  TextInput,
  NumberInput,
  Select,
  EnumOptions,
  PlaceholderOption,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'

export const Running: React.FC = () => {
  return (
    <FormGroup>
      <FormGroupHeading>ランニングコスト</FormGroupHeading>
      <RunningForm index="1" />
      <RunningForm index="2" />
      <RunningForm index="3" />
      <RunningForm index="4" />
      <RunningForm index="5" />
    </FormGroup>
  )
}

const RunningForm: React.FC<{ index: '1' | '2' | '3' | '4' | '5' }> = ({
  index,
}) => {
  return (
    <FormControl fieldset>
      <FormLabel>{`名目${index}`}</FormLabel>
      <Flex gap={4} align="center">
        <FormControl fieldName={`running_name_${index}`}>
          <TextInput />
        </FormControl>
        <FormControl fieldName={`running_price_${index}`}>
          <InputGroup>
            <NumberInput />
            <InputRightAddon>円</InputRightAddon>
          </InputGroup>
        </FormControl>
        <Text>/</Text>
        <FormControl fieldName={`running_kbn_${index}`}>
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.running_kbn.Enum} />
          </Select>
        </FormControl>
      </Flex>
      <PortalErrorMessages fieldName={`running_name_${index}`} />
      <PortalErrorMessages fieldName={`running_price_${index}`} />
      <PortalErrorMessages fieldName={`running_kbn_${index}`} />
    </FormControl>
  )
}
