import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const ParkingSenyoContents = ({ children }: PropsWithChildren) => {
  const [bk_sbt_kbn, suumo_c_open_kbn] = useWatchMiraieFields({
    name: ['bk_sbt_kbn', 'suumo_c_open_kbn'],
  })
  const display =
    bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション &&
    suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載

  return display && children
}
