import { Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CSSProperties } from 'react'

import {
  PanelImage,
  PanelImageProps,
} from '@/components/form/ImagesLayout/PanelImage'
import { PanelImageSelectModal } from '@/components/form/ImagesLayout/PanelImageSelectModal'

export type PanelImageSortableProps = {
  id: string
} & PanelImageProps

export const PanelImageSortable = ({
  id,
  ...props
}: PanelImageSortableProps) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id })
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? 'grabbing' : 'pointer',
    opacity: isDragging ? 0.5 : undefined,
  }
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <PanelImage
        ref={setNodeRef}
        style={style}
        transformOrigin="0 0"
        onClick={onOpen}
        {...attributes}
        {...listeners}
        {...props}
      />
      <Modal {...{ isOpen, onClose }} size="4xl">
        <ModalOverlay />
        <PanelImageSelectModal id={id} />
      </Modal>
    </>
  )
}
