import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react'

import {
  ShisetsuFieldPath,
  useShisetsuFormContext,
} from '@/components/form/Shisetsu/useShisetsuForm'

type TextareaProps = ChakraTextareaProps & {
  fieldName: ShisetsuFieldPath
}

export const Textarea = ({ fieldName, ...props }: TextareaProps) => {
  const { register } = useShisetsuFormContext()

  return <ChakraTextarea {...register(fieldName)} {...props} />
}
