import { CircularProgress, ColorProps, Icon } from '@chakra-ui/react'
import { MdDone, MdError } from 'react-icons/md'
import { TbCircleDotted } from 'react-icons/tb'

export const MutationStatusIcon = ({
  status,
}: {
  status: 'idle' | 'pending' | 'success' | 'error'
}) => {
  const size = '1rem'
  if (status === 'pending')
    return <CircularProgress isIndeterminate size={size} />

  const color: ColorProps['color'] = {
    idle: 'gray.500',
    success: 'green.500',
    error: 'red.500',
  }[status]
  const icon = {
    idle: TbCircleDotted,
    success: MdDone,
    error: MdError,
  }[status]

  return <Icon color={color} boxSize={size} as={icon} />
}
