import { miraieFields } from '@terass/common/src'
import { Suspense } from 'react'

import { SearchableSelect } from '../../controls'

import { ErrorMessage } from './ErrorMessage'
import { EmptySelect, LoadingSelect } from './controls'
import {
  useAddressFormContext,
  useShikuchosonList,
  useWatchAddressForm,
} from './hooks'

export const ShikuchosonSelect = () => {
  const prefectureCode = useWatchAddressForm('prefectureCode')

  if (prefectureCode === miraieFields.gyosei_cd.prefecture.Code.未選択)
    return <EmptySelect>都道府県を選択してください</EmptySelect>

  return (
    <Suspense fallback={<LoadingSelect />}>
      <ExistsShikuchosonSelect />
    </Suspense>
  )
}

const ExistsShikuchosonSelect = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useAddressFormContext()
  const { onChange, ...other } = register('shikuchosonCode', {
    required: '住所の入力は必須です',
  })
  const options = useShikuchosonList()
  if (options.length === 0)
    return <EmptySelect>市区町村がありません</EmptySelect>

  return (
    <>
      <SearchableSelect
        isError={!!errors.shikuchosonCode}
        onChange={(e) => {
          // 下位の住所を未選択にしてから、react hook formのonChangeを呼ぶ
          setValue('ooazaCode', miraieFields.aza_cd.ooaza.Code.未選択)
          setValue('azaCode', miraieFields.aza_cd.aza.Code.未選択)
          onChange(e)
        }}
        {...other}
      >
        {options
          .filter(({ data: { isObsolete } }) => !isObsolete)
          .map(({ id: code, data: { name } }) => (
            <option key={code} id={code} value={code}>
              {name}
            </option>
          ))}
      </SearchableSelect>
      {errors.shikuchosonCode && (
        <ErrorMessage>{errors.shikuchosonCode.message}</ErrorMessage>
      )}
    </>
  )
}
