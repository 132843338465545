import { InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  EnumOptions,
  EnumRadios,
  FieldLength,
  FormControl,
  FormHelperText,
  FormLabel,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const Homes = () => {
  return (
    <MiraieFieldCollapse
      name={['bk_sbt_kbn']}
      display={(bk_sbt_kbn) =>
        bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
      }
    >
      <FormGroup>
        <FormGroupHeading>HOME'S専用項目</FormGroupHeading>

        <FormControl fieldName="homes_catch_copy">
          <FormLabel>物件特長ネット用</FormLabel>
          <Textarea />
          <FieldLength maxLength={miraieFields.homes_catch_copy.maxLength} />
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldContents
          name={['homes_c_tatemono_hyoji_flg']}
          display={(homes_c_tatemono_hyoji_flg) =>
            homes_c_tatemono_hyoji_flg ===
            miraieFields.homes_c_tatemono_hyoji_flg.Enum.する
          }
        >
          <FormControl fieldName="homes_staff_com_sbt" fieldset>
            <FormLabel>スタッフコメント</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.homes_staff_com_sbt.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldContents
            name={['homes_staff_com_sbt']}
            display={(homes_staff_com_sbt) => !!homes_staff_com_sbt}
          >
            <FormControl fieldName="homes_staff_com">
              <Textarea />
              <FieldLength maxLength={miraieFields.homes_staff_com.maxLength} />
              <PortalErrorMessages />
            </FormControl>
          </MiraieFieldContents>
        </MiraieFieldContents>

        <FormControl fieldName="homes_biko">
          <FormLabel>備考ネット用</FormLabel>
          <Textarea />
          <FieldLength maxLength={miraieFields.homes_biko.maxLength} />
          <PortalErrorMessages />
        </FormControl>

        <FormControl fieldName="url_kbn" fieldset>
          <FormLabel>URL</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.url_kbn.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['url_kbn']}
          display={(url_kbn) => !!url_kbn}
        >
          <FormGroup nested>
            <FormControl fieldName="homes_url">
              <InputGroup>
                <InputLeftAddon>URL</InputLeftAddon>
                <TextInput />
              </InputGroup>
              <PortalErrorMessages />
            </FormControl>
          </FormGroup>
        </MiraieFieldCollapse>

        <FormControl fieldName="homes_shozoku_group">
          <FormLabel>所属グループ</FormLabel>
          <TextInput />
          <PortalErrorMessages />
        </FormControl>

        <FormControl fieldName="homes_shisetsu_minimum">
          <FormLabel>周辺施設画像最低出稿枚数</FormLabel>
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.homes_shisetsu_minimum.Enum} />
          </Select>
          <FormHelperText>
            HOMESへの出稿画像が30枚を超えた場合、以下の枚数まで周辺施設画像を出稿する。
          </FormHelperText>
          <PortalErrorMessages />
        </FormControl>
      </FormGroup>
    </MiraieFieldCollapse>
  )
}
