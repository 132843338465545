import { Svg, SvgProps } from '@/components/Svg'

export const RakumachiIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 122 121" fill="none" {...props}>
    <path
      d="M3.912 73.16H118.216V86.472H3.912V73.16ZM52.936 62.92H68.552V120.392H52.936V62.92ZM48.2 78.92L60.744 84.424C49.096 99.528 29.64 112.584 10.696 119.368C8.392 115.528 3.784 109.64 0.456 106.568C18.888 101.32 38.344 90.696 48.2 78.92ZM73.16 79.304C83.272 90.44 102.856 100.552 121.544 105.544C118.216 108.488 113.48 114.504 111.304 118.344C92.36 111.944 72.648 99.4 60.488 85.192L73.16 79.304ZM50.12 44.232V52.936H71.112V44.232H50.12ZM50.12 24.776V33.352H71.112V24.776H50.12ZM35.912 13H86.088V64.712H35.912V13ZM53.576 0.199997L70.856 2.12C68.168 9.032 65.48 15.56 63.304 20.04L49.736 17.608C51.272 12.36 52.936 5.448 53.576 0.199997ZM3.016 17.096L13.512 8.26399C21.064 13.512 30.152 21.192 34.248 27.208L22.984 37.064C19.272 31.048 10.568 22.728 3.016 17.096ZM82.76 47.688L91.72 37.448C101.192 42.184 114.632 49.736 121.288 55.112L111.816 66.632C105.672 61.128 92.616 52.936 82.76 47.688ZM104.392 6.6L117.704 14.536C110.92 21.96 102.6 29.64 96.072 34.76L84.68 27.208C90.952 21.96 99.528 13.256 104.392 6.6ZM0.712 54.472C8.52 50.76 20.04 44.616 31.304 38.6L35.528 50.888C26.568 56.392 16.584 62.408 8.392 67.144L0.712 54.472Z"
      fill="black"
    />
  </Svg>
)
