import { useLoadScript as _useLoadScript } from '@react-google-maps/api'

import { config } from '@/config'

const googleMapConfig: Parameters<typeof _useLoadScript>[0] = {
  googleMapsApiKey: config.firebase.apiKey,
  libraries: ['places'],
}

export const useLoadScript = () => _useLoadScript(googleMapConfig)
