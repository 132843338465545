import { Prefecture } from '@terass/common/src/firestore/Address'
import { doc, onSnapshot, query } from 'firebase/firestore'

import { useWatchAddressCodes } from './useWatchAddressCodes'

import { useObserver } from '@/hooks/useObserver'
import { DocumentNotFoundError } from '@/utils/errors'
import { getCollection } from '@/utils/firestore'

export const usePrefectureList = (): { id: string; data: Prefecture }[] =>
  useObserver({
    observerKey: ['prefectureList'],
    observerFn: (onChange, onError) =>
      onSnapshot(
        query(getCollection('address')),
        (snapshot) =>
          onChange(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })),
          ),
        onError,
      ),
  })

export const usePrefecture = (): Prefecture => {
  const { prefectureCode } = useWatchAddressCodes()
  return useObserver({
    observerKey: ['prefecture', prefectureCode],
    observerFn: (onChange, onError, { observerKey: [, prefectureCode] }) =>
      onSnapshot(doc(getCollection('address'), prefectureCode), (snapshot) => {
        const data = snapshot.data()
        if (data) onChange(data)
        else onError(new DocumentNotFoundError())
      }),
  })
}
