import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const ShokyakuJokenContents = ({ children }: PropsWithChildren) => {
  const [athome_c_open_kbn] = useWatchMiraieFields({
    name: ['athome_c_open_kbn'],
  })
  const display =
    athome_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載 ||
    athome_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載期間外
  return display && children
}
