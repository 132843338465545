import { ValueOf } from '../../../../types'
import * as bk_sbt_kbn from '../../bk_sbt_kbn'
import * as category from '../category'

import { values } from './values'

export const categoryMap: {
  [K in (typeof values)[number]]: {
    [B in ValueOf<typeof bk_sbt_kbn.Enum>]: category.Enum | null
  }
} = {
  1010: {
    [bk_sbt_kbn.Enum.売地]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.売マンション]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.居住用]: category.Enum['間取'],
  },
  1020: {
    [bk_sbt_kbn.Enum.売地]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.売マンション]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.居住用]: category.Enum['外観'],
  },
  1030: {
    [bk_sbt_kbn.Enum.売地]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.売マンション]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.居住用]: category.Enum['内観'],
  },
  2001: {
    [bk_sbt_kbn.Enum.売地]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.売マンション]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum['内外観・その他'],
    [bk_sbt_kbn.Enum.居住用]: category.Enum['内外観・その他'],
  },
  get [2002]() {
    return this[2001]
  },
  get [2003]() {
    return this[2001]
  },
  get [2004]() {
    return this[2001]
  },
  get [2005]() {
    return this[2001]
  },
  get [2006]() {
    return this[2001]
  },
  get [2007]() {
    return this[2001]
  },
  get [2008]() {
    return this[2001]
  },
  get [2009]() {
    return this[2001]
  },
  get [2010]() {
    return this[2001]
  },
  get [2011]() {
    return this[2001]
  },
  get [2012]() {
    return this[2001]
  },
  get [2013]() {
    return this[2001]
  },
  get [2014]() {
    return this[2001]
  },
  get [2015]() {
    return this[2001]
  },
  get [2016]() {
    return this[2001]
  },
  get [2017]() {
    return this[2001]
  },
  get [2018]() {
    return this[2001]
  },
  get [2019]() {
    return this[2001]
  },
  get [2020]() {
    return this[2001]
  },
  get [2021]() {
    return this[2001]
  },
  get [2022]() {
    return this[2001]
  },
  get [2023]() {
    return this[2001]
  },
  get [2024]() {
    return this[2001]
  },
  get [2025]() {
    return this[2001]
  },
  get [2026]() {
    return this[2001]
  },
  get [2027]() {
    return this[2001]
  },
  get [2028]() {
    return this[2001]
  },
  get [2029]() {
    return this[2001]
  },
  get [2030]() {
    return this[2001]
  },
  get [2031]() {
    return this[2001]
  },
  get [2032]() {
    return this[2001]
  },
  get [2033]() {
    return this[2001]
  },
  get [2034]() {
    return this[2001]
  },
  get [2035]() {
    return this[2001]
  },
  get [2036]() {
    return this[2001]
  },
  get [2037]() {
    return this[2001]
  },
  get [2038]() {
    return this[2001]
  },
  get [2039]() {
    return this[2001]
  },
  get [2040]() {
    return this[2001]
  },
  get [2041]() {
    return this[2001]
  },
  get [2042]() {
    return this[2001]
  },
  get [2043]() {
    return this[2001]
  },
  get [2044]() {
    return this[2001]
  },
  get [2045]() {
    return this[2001]
  },
  get [2046]() {
    return this[2001]
  },
  get [2047]() {
    return this[2001]
  },
  get [2048]() {
    return this[2001]
  },
  get [2049]() {
    return this[2001]
  },
  get [2050]() {
    return this[2001]
  },
  get [2051]() {
    return this[2001]
  },
  get [2052]() {
    return this[2001]
  },
  get [2053]() {
    return this[2001]
  },
  get [2054]() {
    return this[2001]
  },
  get [2055]() {
    return this[2001]
  },
  get [2056]() {
    return this[2001]
  },
  get [2057]() {
    return this[2001]
  },
  get [2058]() {
    return this[2001]
  },
  get [2059]() {
    return this[2001]
  },
  get [2060]() {
    return this[2001]
  },
  get [2061]() {
    return this[2001]
  },
  get [2062]() {
    return this[2001]
  },
  get [2063]() {
    return this[2001]
  },
  get [2064]() {
    return this[2001]
  },
  get [2065]() {
    return this[2001]
  },
  get [2066]() {
    return this[2001]
  },
  get [2067]() {
    return this[2001]
  },
  get [2068]() {
    return this[2001]
  },
  get [2069]() {
    return this[2001]
  },
  get [2070]() {
    return this[2001]
  },
  get [2071]() {
    return this[2001]
  },
  get [2072]() {
    return this[2001]
  },
  get [2073]() {
    return this[2001]
  },
  get [2074]() {
    return this[2001]
  },
  get [2075]() {
    return this[2001]
  },
  get [2076]() {
    return this[2001]
  },
  get [2077]() {
    return this[2001]
  },
  get [2078]() {
    return this[2001]
  },
  get [2079]() {
    return this[2001]
  },
  get [2080]() {
    return this[2001]
  },
  get [2081]() {
    return this[2001]
  },
  get [2082]() {
    return this[2001]
  },
  get [2083]() {
    return this[2001]
  },
  get [2084]() {
    return this[2001]
  },
  get [2085]() {
    return this[2001]
  },
  get [2086]() {
    return this[2001]
  },
  get [2087]() {
    return this[2001]
  },
  get [2088]() {
    return this[2001]
  },
  get [2089]() {
    return this[2001]
  },
  get [2090]() {
    return this[2001]
  },
  get [2091]() {
    return this[2001]
  },
  get [2092]() {
    return this[2001]
  },
  get [2093]() {
    return this[2001]
  },
  get [2094]() {
    return this[2001]
  },
  get [2095]() {
    return this[2001]
  },
  get [2096]() {
    return this[2001]
  },
  3001: {
    [bk_sbt_kbn.Enum.売地]: null,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.構造,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.構造,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.構造,
    [bk_sbt_kbn.Enum.居住用]: null,
  },
  get [3002]() {
    return this[3001]
  },
  get [3003]() {
    return this[3001]
  },
  get [3004]() {
    return this[3001]
  },
  get [3005]() {
    return this[3001]
  },
  get [3006]() {
    return this[3001]
  },
  4001: {
    [bk_sbt_kbn.Enum.売地]: null,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.設備,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.設備,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.設備,
    [bk_sbt_kbn.Enum.居住用]: null,
  },
  get [4002]() {
    return this[4001]
  },
  get [4003]() {
    return this[4001]
  },
  get [4004]() {
    return this[4001]
  },
  get [4005]() {
    return this[4001]
  },
  get [4006]() {
    return this[4001]
  },
  5001: {
    [bk_sbt_kbn.Enum.売地]: category.Enum.現地案内図,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.現地案内図,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.現地案内図,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.現地案内図,
    [bk_sbt_kbn.Enum.居住用]: category.Enum.会社間流通14,
  },
  5002: {
    [bk_sbt_kbn.Enum.売地]: category.Enum.現地案内図,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.現地案内図,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.現地案内図,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.現地案内図,
    [bk_sbt_kbn.Enum.居住用]: null,
  },
  5003: {
    [bk_sbt_kbn.Enum.売地]: category.Enum.交通アクセス図,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.交通アクセス図,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.交通アクセス図,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.交通アクセス図,
    [bk_sbt_kbn.Enum.居住用]: null,
  },
  5004: {
    [bk_sbt_kbn.Enum.売地]: category.Enum.路線図,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.路線図,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.路線図,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.路線図,
    [bk_sbt_kbn.Enum.居住用]: null,
  },
  6001: {
    [bk_sbt_kbn.Enum.売地]: category.Enum.売主コメント,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.売主コメント,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.売主コメント,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.売主コメント,
    [bk_sbt_kbn.Enum.居住用]: null,
  },
  get [6002]() {
    return this[6001]
  },
  get [6003]() {
    return this[6001]
  },
  get [6004]() {
    return this[6001]
  },
  get [6005]() {
    return this[6001]
  },
  get [6006]() {
    return this[6001]
  },
  get [6007]() {
    return this[6001]
  },
  7001: {
    [bk_sbt_kbn.Enum.売地]: category.Enum.CM横画像,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.CM横画像,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.CM横画像,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.CM横画像,
    [bk_sbt_kbn.Enum.居住用]: null,
  },
  7002: {
    [bk_sbt_kbn.Enum.売地]: category.Enum.プレゼント画像,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.プレゼント画像,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.プレゼント画像,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.プレゼント画像,
    [bk_sbt_kbn.Enum.居住用]: category.Enum.会社間流通15,
  },
  7003: {
    [bk_sbt_kbn.Enum.売地]: category.Enum.パンフレット画像,
    [bk_sbt_kbn.Enum.売戸建住宅]: category.Enum.パンフレット画像,
    [bk_sbt_kbn.Enum.売マンション]: category.Enum.パンフレット画像,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: category.Enum.パンフレット画像,
    [bk_sbt_kbn.Enum.居住用]: null,
  },
}
