import { useQuery } from '@tanstack/react-query'

import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'
import { fetchMediationContractById } from '@/utils/functions'

export const useMedContract = () => {
  const id = useV1ImportDocument(
    ({ __tera_three: { baikaiKeiyakuId } }) => baikaiKeiyakuId,
  )

  return useQuery({
    queryKey: ['medContractById', id],
    queryFn() {
      if (id === undefined) return null
      return fetchMediationContractById(id)
    },
  })
}
