import { ValueOf } from '../../../types'

export const Enum = {
  売地: '11',
  売戸建住宅: '12',
  売マンション: '13',
  '戸建て(2戸以上)': '17',
  居住用: '32',
} as const

export type Enum = ValueOf<typeof Enum>

export const values = Object.values(Enum)
