/**
 * 購入サポート概要区分
 */
export const Enum = {
  定期点検: '01',
  地盤品質保証: '02',
  設備保証: '03',
  中古物件建物保証: '04',
  長期建物保証: '05',
  '24時間駆けつけ対応': '06',
  住み替えローン: '07',
  買い替えつなぎ融資: '08',
  住宅ローン事前審査一括申込: '09',
  ファイナンシャルプランナー個別相談: '10',
  '税理士・弁護士個別相談': '11',
  'オンライン接客・内見': '12',
  内見時自宅送迎: '13',
  'ハウスクリーニング(買替時売却サポート)': '14',
  '物件買取サービス(買替時売却サポート)': '15',
  '買取保証(買替時売却サポート)': '16',
  売却時付帯サービス引継ぎ保証: '17',
  '擁壁調査(買替時売却サポート)': '18',
  '建物調査(買替時売却サポート)': '19',
  'ホームステージング(買替時売却サポート)': '21',
  'リペアサービス(買替時売却サポート)': '22',
} as const
