import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  EnumRadios,
  UnselectedRadio,
} from '../controls'

import { ChurinjoPriceCollapse } from '@/components/form/PriceAndCost/ChurinjoPriceCollapse'
import { ChurinjoRyokin } from '@/components/form/PriceAndCost/ChurinjoRyokin'

export const ChurinjoRyokinControl: React.FC = () => {
  return (
    <>
      <FormControl fieldName="churinjo_yuryo_flg" fieldset>
        <FormLabel>有料・無料</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.churinjo_yuryo_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <ChurinjoPriceCollapse>
        <ChurinjoRyokin />
      </ChurinjoPriceCollapse>
    </>
  )
}
