import { ArrowBackIcon } from '@chakra-ui/icons'
import { Button, ButtonProps } from '@chakra-ui/react'

export const BackToTopButton = (props: ButtonProps) => (
  <Button
    variant="link"
    leftIcon={<ArrowBackIcon />}
    alignSelf="flex-start"
    {...props}
  >
    トップに戻る
  </Button>
)
