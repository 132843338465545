import { Flex } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { GaisoBikoCollapse } from './GaisoBikoCollapse'

import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  BooleanEnumCheckbox,
  CheckboxGroup,
  EnumRadios,
  FormControl,
  FormHelperText,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  Textarea,
  UnselectedRadio,
  MonthOptions,
  PlaceholderOption,
  YearOptions,
} from '@/components/form/controls'

export const GaisoControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>外装</FormGroupHeading>
      <FormControl fieldset>
        <FormLabel>実施年月</FormLabel>
        <Flex gap={2}>
          <FormControl fieldName="reform_gaiso_ym_year">
            <Select>
              <PlaceholderOption />
              <YearOptions />
            </Select>
          </FormControl>
          <FormControl fieldName="reform_gaiso_ym_month">
            <Select>
              <PlaceholderOption />
              <MonthOptions />
            </Select>
          </FormControl>
        </Flex>
        <FormHelperText>
          HOME'Sフォーマット使用ポータルはリフォーム実施年月が未来の年月の場合はリフォーム情報は反映されません。
        </FormHelperText>
        <PortalErrorMessages fieldName="reform_gaiso_ym_year" />
        <PortalErrorMessages fieldName="reform_gaiso_ym_month" />
      </FormControl>
      <FormControl fieldset>
        <FormLabel>実施箇所</FormLabel>
        <CheckboxGroup>
          {gaisoCheckFields.map((fieldName) => (
            <BooleanEnumCheckbox key={fieldName} fieldName={fieldName}>
              {
                {
                  reform_gaiheki_flg: '外壁',
                  reform_yane_flg: '屋根',
                  reform_gaiso_flg: 'その他',
                }[fieldName]
              }
            </BooleanEnumCheckbox>
          ))}
        </CheckboxGroup>
        {gaisoCheckFields.map((fieldName) => (
          <PortalErrorMessages key={fieldName} fieldName={fieldName} />
        ))}
      </FormControl>
      <GaisoBikoCollapse>
        <FormGroup nested>
          <FormControl fieldName="reform_gaiso_biko">
            <FormLabel>その他</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </GaisoBikoCollapse>
      <MiraieFieldContents
        name={['bk_sbt_kbn', 'suumo_c_open_kbn']}
        display={(bk_sbt_kbn, suumo_c_open_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.居住用 ||
          miraieFields.c_open_kbn.isOpen(suumo_c_open_kbn)
        }
      >
        <FormControl fieldName="reform_daikibo_flg" fieldset>
          <FormLabel>大規模修繕</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.reform_daikibo_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>
      </MiraieFieldContents>
      <FormControl fieldName="reform_gaiso_enforcement_content">
        <FormLabel>実施内容</FormLabel>
        <Textarea />
        <PortalErrorMessages />
      </FormControl>
    </FormGroup>
  )
}

const gaisoCheckFields = [
  'reform_gaiheki_flg',
  'reform_yane_flg',
  'reform_gaiso_flg',
] as const satisfies readonly (keyof PostMiraieV1ImportForm)[]
