import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

// 楽待は賃貸もしくは戸建て(2戸以上)の場合は表示しない
export const RakumachiRadioGroupContents = ({
  children,
}: PropsWithChildren) => {
  const isChintai = useIsChintai()
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })

  return (
    <>
      {!isChintai &&
        bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)'] &&
        children}
    </>
  )
}

// athomeは戸建て(2戸以上)の場合は表示しない
export const AthomeRadioGroupContents = ({ children }: PropsWithChildren) => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })

  return (
    <>
      {bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)'] &&
        children}
    </>
  )
}

// Homesは戸建て(2戸以上)の場合は表示しない
export const HomesRadioGroupContents = ({ children }: PropsWithChildren) => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })

  return (
    <>
      {bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)'] &&
        children}
    </>
  )
}
