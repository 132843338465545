import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import {
  BooleanEnumCheckbox,
  CheckboxGroup,
  EnumRadios,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const Flat35 = () => {
  return (
    <FormGroup>
      <FormGroupHeading>フラット35/フラット35S/フラット50</FormGroupHeading>

      <FormControl fieldName="flat35_kbn" fieldset>
        <FormLabel>フラット35区分</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.flat35_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <MiraieFieldCollapse
        name={['flat35_kbn']}
        display={(flat35_kbn) =>
          flat35_kbn === miraieFields.flat35_kbn.Enum.フラット35 ||
          flat35_kbn === miraieFields.flat35_kbn.Enum.フラット35S ||
          flat35_kbn === miraieFields.flat35_kbn.Enum.フラット50
        }
      >
        <FormGroup nested>
          <MiraieFieldCollapse
            name={['flat35_kbn']}
            display={(flat35_kbn) =>
              flat35_kbn === miraieFields.flat35_kbn.Enum.フラット35S
            }
          >
            <FormControl fieldset>
              <FormLabel>フラット35S</FormLabel>
              <CheckboxGroup>
                <BooleanEnumCheckbox fieldName="flat35_barrierfree_flg">
                  バリアフリー性
                </BooleanEnumCheckbox>
                <BooleanEnumCheckbox fieldName="flat35_energysaving_flg">
                  省エネルギー性
                </BooleanEnumCheckbox>
                <BooleanEnumCheckbox fieldName="flat35_earthquakeproof_flg">
                  耐震性
                </BooleanEnumCheckbox>
                <BooleanEnumCheckbox fieldName="flat35_durability_and_variability_flg">
                  耐久性・可変性
                </BooleanEnumCheckbox>
              </CheckboxGroup>
            </FormControl>
          </MiraieFieldCollapse>

          <FormControl fieldName="flat35_loan_biko">
            <FormLabel>住宅ローンのご案内</FormLabel>
            <Textarea />
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </MiraieFieldCollapse>
    </FormGroup>
  )
}
