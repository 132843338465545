import { Svg, SvgProps } from '@/components/Svg'

export const SuumoIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 141 134" fill="none" {...props}>
    <path
      d="M53.5909 28.6818C53.2273 25.0151 51.6667 22.1667 48.9091 20.1364C46.1515 18.1061 42.4091 17.0909 37.6818 17.0909C34.4697 17.0909 31.7576 17.5455 29.5455 18.4545C27.3333 19.3333 25.6364 20.5606 24.4545 22.1364C23.303 23.7121 22.7273 25.5 22.7273 27.5C22.6667 29.1667 23.0152 30.6212 23.7727 31.8636C24.5606 33.1061 25.6364 34.1818 27 35.0909C28.3636 35.9697 29.9394 36.7424 31.7273 37.4091C33.5152 38.0455 35.4242 38.5909 37.4545 39.0455L45.8182 41.0455C49.8788 41.9545 53.6061 43.1667 57 44.6818C60.3939 46.197 63.3333 48.0606 65.8182 50.2727C68.303 52.4848 70.2273 55.0909 71.5909 58.0909C72.9849 61.0909 73.697 64.5303 73.7273 68.4091C73.697 74.1061 72.2424 79.0455 69.3636 83.2273C66.5152 87.3788 62.3939 90.6061 57 92.9091C51.6364 95.1818 45.1667 96.3182 37.5909 96.3182C30.0758 96.3182 23.5303 95.1667 17.9545 92.8636C12.4091 90.5606 8.07576 87.1515 4.95455 82.6364C1.86364 78.0909 0.242424 72.4697 0.090909 65.7727H19.1364C19.3485 68.8939 20.2424 71.5 21.8182 73.5909C23.4242 75.6515 25.5606 77.2121 28.2273 78.2727C30.9242 79.303 33.9697 79.8182 37.3636 79.8182C40.697 79.8182 43.5909 79.3333 46.0455 78.3636C48.5303 77.3939 50.4545 76.0455 51.8182 74.3182C53.1818 72.5909 53.8636 70.6061 53.8636 68.3636C53.8636 66.2727 53.2424 64.5152 52 63.0909C50.7879 61.6667 49 60.4545 46.6364 59.4545C44.303 58.4545 41.4394 57.5455 38.0455 56.7273L27.9091 54.1818C20.0606 52.2727 13.8636 49.2879 9.31818 45.2273C4.77273 41.1667 2.51515 35.697 2.54545 28.8182C2.51515 23.1818 4.01515 18.2576 7.04545 14.0455C10.1061 9.83333 14.303 6.54545 19.6364 4.18182C24.9697 1.81818 31.0303 0.63636 37.8182 0.63636C44.7273 0.63636 50.7576 1.81818 55.9091 4.18182C61.0909 6.54545 65.1212 9.83333 68 14.0455C70.8788 18.2576 72.3636 23.1364 72.4545 28.6818H53.5909Z"
      fill="black"
    />
    <path
      d="M14.5717 113.382C9.05391 113.619 4.77263 118.283 5.00918 123.801C5.24573 129.319 9.91053 133.6 15.4283 133.364L14.5717 113.382ZM15.4283 133.364L35.9855 132.482L35.1289 112.501L14.5717 113.382L15.4283 133.364ZM121.96 37.0212L121.685 32.5656L101.723 33.7976L101.998 38.2531L121.96 37.0212ZM35.9855 132.482C86.3067 130.325 125.062 87.2929 121.96 37.0212L101.998 38.2531C104.411 77.3534 74.2676 110.823 35.1289 112.501L35.9855 132.482Z"
      fill="#6FB92C"
    />
    <line
      x1="111.224"
      y1="22.1404"
      x2="94.1404"
      y2="39.7756"
      stroke="#6FB92C"
      strokeWidth="20"
      strokeLinecap="round"
    />
    <line
      x1="113.142"
      y1="21.9901"
      x2="130.516"
      y2="39.3398"
      stroke="#6FB92C"
      strokeWidth="20"
      strokeLinecap="round"
    />
  </Svg>
)
