import { Outlet } from '@tanstack/react-router'
import {
  isInvalidEmailDomainErrorMessage,
  isRoleRequiredErrorMessage,
} from '@terass/common/src/auth'
import { FirebaseError } from 'firebase/app'
import { getAuth, getRedirectResult } from 'firebase/auth'

import { useMasqueradeEmail } from '@/hooks/useOwnUser'
import { useUserRole } from '@/hooks/useUser'
import { toast } from '@/utils/standaloneToast'

export const AuthenticatedLayout = () => {
  const role = useUserRole()
  const masquerade = useMasqueradeEmail()

  if (role === 'assistant' && !masquerade) return null

  return <Outlet />
}

export const authLoader = async () => {
  await getRedirectResult(getAuth()).catch((err: unknown) => {
    if (!(err instanceof FirebaseError)) throw err

    if (isRoleRequiredErrorMessage(err.message))
      return toast({
        title: 'Terass Cloudのアカウントが必要です。',
        description:
          'Terass Cloudのアカウント作成後に再度ログインしてください。',
        status: 'error',
        position: 'top',
        duration: null,
      })
    if (isInvalidEmailDomainErrorMessage(err.message))
      return toast({
        description:
          'terass.com以外の Googleアカウントでログインすることはできません。',
        status: 'error',
        position: 'top',
      })

    throw err
  })

  return null
}
