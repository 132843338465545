/**
 * オープンハウス区分
 */
export const Enum = {
  指定無し: '1',
  オープンハウス: '2',
  モデルルーム: '3',
  モデルハウス: '4',
  現地見学会: '5',
  現地案内会: '6',
  現地販売会: '7',
  オープンルーム: '8',
} as const
