import { useCallback, useRef, useSyncExternalStore } from 'react'

import { readAsDataURL } from '@/utils/readAsDataURL'

export type UseFileAsDataURIProps = {
  file: File | undefined
}

export const useFileAsDataURI = ({ file }: UseFileAsDataURIProps) => {
  const ref = useRef<string>()
  const dataURI = useSyncExternalStore(
    useCallback(
      (onChange) => {
        if (!file) {
          ref.current = undefined
          onChange()
          return () => {}
        }

        const reader = new FileReader()
        readAsDataURL(reader, file).then((dataURI) => {
          ref.current = dataURI
          onChange()
        })

        return () => reader.abort()
      },
      [file],
    ),
    () => ref.current,
  )

  return {
    dataURI,
  }
}
