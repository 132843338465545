import { ValueOf } from '../../types'

export const Enum = {
  '★なし（省エネ基準未達成）': '0',
  '★（省エネ基準達成）': '1',
  '★★（削減率10％達成）': '2',
  '★★★（削減率20％達成）': '3',
  '★★★★（削減率30％達成））': '4',
  '★★★★★（削減率40％達成）': '5',
  '★★★★★★（削減率50％達成）': '6',
} as const

export type Enum = ValueOf<typeof Enum>
