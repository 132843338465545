import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { WatchMiraieFields } from '@/components/form/WatchMiraieFields'
import {
  EnumOptions,
  EnumRadios,
  FormControl,
  FormHelperText,
  FormLabel,
  MonthOptions,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  UnselectedRadio,
  YearOptions,
} from '@/components/form/controls'
import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const Tatemono = () => {
  const isChintai = useIsChintai()
  const [bk_sbt_kbn] = useWatchMiraieFields({
    name: ['bk_sbt_kbn'],
  })

  return (
    <FormGroup>
      <FormGroupHeading>建物情報</FormGroupHeading>

      <FormControl fieldset>
        <FormLabel>階建部屋</FormLabel>
        <Flex gap={2}>
          <FormControl
            fieldName="chijo_kai"
            isRequired={
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
            }
          >
            <InputGroup>
              <InputLeftAddon>地上</InputLeftAddon>
              <NumberInput />
              <InputRightAddon>階</InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl fieldName="chika_kai">
            <InputGroup>
              <InputLeftAddon>地下</InputLeftAddon>
              <NumberInput />
              <InputRightAddon>階</InputRightAddon>
            </InputGroup>
          </FormControl>
        </Flex>
        <PortalErrorMessages fieldName="chijo_kai" />
        <PortalErrorMessages fieldName="chika_kai" />
      </FormControl>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション ||
          miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'rent'
        }
      >
        <FormControl fieldset>
          <FormLabel>階建部屋</FormLabel>
          <Flex gap={2}>
            <FormControl fieldName="shozai_kai_kbn" isRequired>
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.shozai_kai_kbn.Enum} />
              </Select>
            </FormControl>
            <FormControl fieldName="shozai_kai">
              <InputGroup>
                <NumberInput />
                <InputRightAddon>階部分</InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl fieldName="room_no">
              <InputGroup>
                <TextInput />
                <InputRightAddon>号室</InputRightAddon>
              </InputGroup>
            </FormControl>
          </Flex>
          <PortalErrorMessages fieldName="shozai_kai_kbn" />
          <PortalErrorMessages fieldName="shozai_kai" />
          <PortalErrorMessages fieldName="room_no" />
        </FormControl>
      </MiraieFieldContents>

      <WatchMiraieFields name={['shinchiku_flg']}>
        {(shinchiku_flg) => (
          <FormControl
            fieldset
            isRequired={shinchiku_flg === miraieFields.shinchiku_flg.Enum.中古}
          >
            <FormLabel>築年月</FormLabel>
            <Flex gap={2}>
              <FormControl fieldName="chiku_ymd_year">
                <Select>
                  <PlaceholderOption />
                  <YearOptions era />
                </Select>
              </FormControl>

              <FormControl fieldName="chiku_ymd_month">
                <Select>
                  <PlaceholderOption />
                  <MonthOptions />
                </Select>
              </FormControl>
            </Flex>
            <PortalErrorMessages fieldName="chiku_ymd_year" />
            <PortalErrorMessages fieldName="chiku_ymd_month" />
          </FormControl>
        )}
      </WatchMiraieFields>

      <WatchMiraieFields name={['bk_sbt_kbn', 'sakimono_flg']}>
        {(bk_sbt_kbn, sakimono_flg) => (
          <FormControl
            fieldName="tate_kozo_kbn"
            isRequired={
              (bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション &&
                sakimono_flg === miraieFields.sakimono_flg.Enum.先物) ||
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
            }
          >
            <FormLabel>建物構造</FormLabel>
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.tate_kozo_kbn.Enum} />
            </Select>
            <FormHelperText>
              {isChintai
                ? '物件種目：マンションで「木造」「軽量鉄骨」にて掲載する場合、建物構造は「その他」で入稿する必要があります。'
                : '「新築売マンション」「中古売マンション」「新築売公団」「中古売公団」「新築売公社」「中古売公社」「リゾートマンション」の場合、「木造」「ブロック」「軽量鉄骨」はエラーになります。'}
            </FormHelperText>
            <PortalErrorMessages />
          </FormControl>
        )}
      </WatchMiraieFields>

      <MiraieFieldCollapse
        name={['tate_kozo_kbn']}
        display={(tate_kozo_kbn) =>
          tate_kozo_kbn === miraieFields.tate_kozo_kbn.Enum.その他
        }
      >
        <FormControl fieldName="tate_kozo_other_setsumei">
          <InputGroup>
            <InputLeftAddon>その他</InputLeftAddon>
            <TextInput />
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>
      </MiraieFieldCollapse>

      <MiraieFieldContents
        name={['bk_sbt_kbn', 'suumo_c_open_kbn']}
        display={(bk_sbt_kbn, suumo_c_open_kbn) =>
          (bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション) &&
          miraieFields.c_open_kbn.isOpen(suumo_c_open_kbn)
        }
      >
        <FormControl fieldset>
          <FormLabel>建物構造(一部)</FormLabel>
          <Flex gap={2}>
            <FormControl fieldName="tate_ichibu_kozo_kbn">
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.tate_ichibu_kozo_kbn.Enum} />
              </Select>
            </FormControl>
            <MiraieFieldCollapse
              name={['tate_ichibu_kozo_kbn']}
              display={(tate_ichibu_kozo_kbn) =>
                tate_ichibu_kozo_kbn ===
                miraieFields.tate_ichibu_kozo_kbn.Enum.その他
              }
            >
              <FormControl fieldName="tate_ichibu_kozo_other_setsumei">
                <InputGroup>
                  <InputLeftAddon>その他</InputLeftAddon>
                  <TextInput />
                </InputGroup>
              </FormControl>
            </MiraieFieldCollapse>
          </Flex>
          <PortalErrorMessages fieldName="tate_ichibu_kozo_kbn" />
          <PortalErrorMessages fieldName="tate_ichibu_kozo_other_setsumei" />
        </FormControl>
      </MiraieFieldContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn', 'suumo_c_open_kbn']}
        display={(bk_sbt_kbn, suumo_c_open_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
            ? miraieFields.c_open_kbn.isOpen(suumo_c_open_kbn)
            : bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
        }
      >
        <FormControl fieldset>
          <FormLabel>工法</FormLabel>
          <Flex gap={2}>
            <FormControl fieldName="tate_koho_kbn">
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.tate_koho_kbn.Enum} />
              </Select>
            </FormControl>
            <MiraieFieldCollapse
              name={['bk_sbt_kbn', 'tate_koho_kbn']}
              display={(bk_sbt_kbn, tate_koho_kbn) =>
                bk_sbt_kbn !==
                  miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)'] &&
                tate_koho_kbn === miraieFields.tate_koho_kbn.Enum.その他
              }
            >
              <FormControl fieldName="tate_koho_other_biko">
                <InputGroup>
                  <InputLeftAddon>その他</InputLeftAddon>
                  <TextInput />
                </InputGroup>
              </FormControl>
            </MiraieFieldCollapse>
          </Flex>
          <PortalErrorMessages fieldName="tate_koho_kbn" />
          <PortalErrorMessages fieldName="tate_koho_other_biko" />
        </FormControl>
      </MiraieFieldContents>
      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)'] ||
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
        }
      >
        <FormControl fieldName="is_renewable_energy_flg">
          <FormLabel>再エネ設備</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.is_renewable_energy_flg.Enum} />
          </RadioGroup>
        </FormControl>
        <FormControl fieldName="energy_performance_from_kbn">
          <FormLabel>エネルギー消費性能</FormLabel>
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.energy_performance_from_kbn.Enum} />
          </Select>
        </FormControl>
        <FormControl fieldName="insulation_performance_from_kbn">
          <FormLabel>断熱区分</FormLabel>
          <Select>
            <PlaceholderOption />
            <EnumOptions
              enum={miraieFields.insulation_performance_from_kbn.Enum}
            />
          </Select>
        </FormControl>
        <FormControl fieldName="estimated_utility_costs_from">
          <FormLabel>目安光熱費</FormLabel>
          <InputGroup>
            <NumberInput />
            <InputRightAddon>万円</InputRightAddon>
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>
      </MiraieFieldContents>
    </FormGroup>
  )
}
