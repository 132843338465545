import { Stack, Heading, Text } from '@chakra-ui/react'
import { ErrorRouteComponent, useNavigate } from '@tanstack/react-router'

import { BackToTopButton } from './BackToTopButton'
import { Header } from './Header'
import { Main } from './Main'

export const ErrorFallback: ErrorRouteComponent = ({ reset }) => {
  const navigate = useNavigate()
  const onClick = async () => {
    reset()
    await navigate({ to: '/' })
  }
  return (
    <>
      <Header />
      <Main>
        <Stack mt={4}>
          <Heading size="md">予期せぬエラーが発生しました。</Heading>
          <Text>お手数ですがサポートまでお問い合わせください。</Text>
          <BackToTopButton onClick={onClick} />
        </Stack>
      </Main>
    </>
  )
}
