import { miraieFields } from '@terass/common/src'
import { swapKeyValue } from '@terass/common/src/utils'

import { useWatchCreateBaitaiKeisaiForm } from '@/hooks/useCreateBaitaiKeisaiForm'

export const BkItemKbnOptions = () => {
  const bk_sbt_kbn = useWatchCreateBaitaiKeisaiForm('bk_sbt_kbn')

  return (
    <>
      {miraieFields.bk_item_kbn.values.map(
        (bk_item_kbn) =>
          miraieFields.bk_item_kbn.isAvailable({ bk_item_kbn, bk_sbt_kbn }) && (
            <option key={bk_item_kbn} value={bk_item_kbn}>
              {swapKeyValue(miraieFields.bk_item_kbn.Enum)[bk_item_kbn]}
            </option>
          ),
      )}
    </>
  )
}
