import { useMutation } from '@tanstack/react-query'
import { miraieFields } from '@terass/common/src'
import { portals } from '@terass/common/src/portal'
import {
  getMaxDate,
  mapDate,
  validateCloseDate,
} from '@terass/common/src/tera-seven/fields/keisaiKikan'
import { callThis } from '@terass/common/src/utils'
import {
  addDays,
  formatDate,
  parseDateString,
} from '@terass/common/src/utils/date'

import { useMedContract } from '@/hooks/useMedContract'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'
import { getServerTimestamp } from '@/utils/functions'

export const useValidateMutation = (onSuccess: () => void) => {
  const { data: teraThree } = useMedContract()
  const { trigger, getValues, clearErrors, setError } = useMiraieFormContext()

  return useMutation({
    mutationFn: async () => {
      if (
        teraThree?.status &&
        teraThree.status !== '契約完了' &&
        teraThree.status !== 'REINS掲載済み'
      )
        throw new Error(
          '媒介契約のステータスが契約完了または REINS 掲載済みのどちらかでないと公開できません。',
        )

      const validateResult = await trigger()
      if (!validateResult)
        throw new Error(
          '入力内容に誤りがあります。媒体掲載を公開できませんでした。',
        )

      const { teraSeven, ...formData } = getValues()
      if (
        !miraieFields.images.isUniqueMd5([
          ...(formData.images || []),
          ...(formData.shisetsu || []),
        ])
      )
        throw new Error(
          '画像が重複しています。画像または周辺環境から重複している画像を別の画像に差し替えてください。',
        )

      const serverDate = new Date(await getServerTimestamp())
      const contractEndDate = teraThree?.endDate
        ? parseDateString(teraThree.endDate)
        : null
      const keisaiKikan = mapDate(teraSeven.keisaiKikan)
      const maxDate = getMaxDate(serverDate, 31, contractEndDate)
      const errorMessage = `掲載終了日は ${formatDate(callThis(serverDate, addDays, 1))} ~ ${formatDate(maxDate)} の期間内である必要があります。`
      const validateCloseDateResult = portals
        .filter(
          (portal) =>
            formData[`${portal}_c_open_kbn`] ===
            miraieFields.c_open_kbn.Enum.掲載,
        )
        .reduce((result, portal) => {
          const endDate = keisaiKikan[portal].to
          if (endDate && validateCloseDate(endDate, serverDate, maxDate)) {
            clearErrors(`teraSeven.keisaiKikan.${portal}.to`)
            return result
          }

          setError(`teraSeven.keisaiKikan.${portal}.to`, {
            message: errorMessage,
          })
          return false
        }, true)
      if (!validateCloseDateResult)
        throw new Error('掲載終了日を修正してください。')
    },
    onSuccess,
  })
}
