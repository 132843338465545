import { SelectProps } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  getImageControlsArea,
  useImageControlsContext,
} from '@/components/form/BukkenImages/Controls/ImageControlsContext'
import { Select } from '@/components/form/BukkenImages/Controls/Select'
import { useBukkenImagesContext } from '@/components/form/BukkenImages/hooks'

export const ImgSbtKbnSelect = (props: SelectProps) => {
  const { category, index } = useImageControlsContext()
  const { bk_sbt_kbn } = useBukkenImagesContext()

  return (
    <Select
      fieldName={`${category}.${index}.img_sbt_kbn`}
      gridArea={getImageControlsArea('img_sbt_kbn')}
      {...props}
    >
      {miraieFields.images.img_sbt_kbn.groupNames.map((groupName) => {
        const values = miraieFields.images.img_sbt_kbn
          .getGroupedValues({
            groupName,
            bk_sbt_kbn,
          })
          .filter((img_sbt_kbn) =>
            miraieFields.images.img_sbt_kbn.isAvailable({
              img_sbt_kbn,
              bk_sbt_kbn,
              category,
            }),
          )
        return (
          values.length && (
            <optgroup key={groupName} label={groupName}>
              {values.map((img_sbt_kbn) => (
                <option key={img_sbt_kbn} value={img_sbt_kbn}>
                  {miraieFields.images.img_sbt_kbn.getLabel({
                    img_sbt_kbn,
                    bk_sbt_kbn,
                  })}
                </option>
              ))}
            </optgroup>
          )
        )
      })}
    </Select>
  )
}
