import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ShikimashiCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['shikimashi_kbn']}
      display={(shikimashi_kbn) =>
        shikimashi_kbn === miraieFields.shikimashi_kbn.Enum.ペット飼育 ||
        shikimashi_kbn === miraieFields.shikimashi_kbn.Enum.喫煙者 ||
        shikimashi_kbn === miraieFields.shikimashi_kbn.Enum.子供
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
