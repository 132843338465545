import { Center, CenterProps } from '@chakra-ui/react'

export const PortalIconContainer = (props: CenterProps) => (
  <Center
    padding={1}
    bgColor="white"
    border="1px solid"
    borderColor="gray.200"
    borderRadius="full"
    width={7}
    height={7}
    {...props}
  />
)
