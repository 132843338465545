import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const GenbaToiawaseBikoContents = ({ children }: PropsWithChildren) => {
  const [suumo_c_open_kbn] = useWatchMiraieFields({
    name: ['suumo_c_open_kbn'],
  })
  const display = suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載

  return <>{display && children}</>
}
