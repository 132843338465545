import pino from 'pino'

import { normalizeOptions } from './options'
import { LoggerOptions } from './types'

export const getLogger = (options: LoggerOptions): pino.Logger => {
  const opts = normalizeOptions(options)

  const pinoOptions: pino.LoggerOptions = {
    level: opts.level,
    browser: {
      asObject: false,
    },
  }

  return pino(pinoOptions)
}
