/**
 * オープンハウス日程区分
 */
export const Enum = {
  期間指定: '1',
  毎週土日祝: '2',
  毎週土日: '3',
  日時指定: '4',
  公開中: '5',
} as const
