/**
 * バルコニー方向区分
 */
export const Enum = {
  北: '1',
  北東: '2',
  東: '3',
  南東: '4',
  南: '5',
  南西: '6',
  西: '7',
  北西: '8',
} as const
