import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { WatchMiraieFields } from '@/components/form/WatchMiraieFields'
import {
  // BooleanEnumCheckbox,
  EnumOptions,
  EnumRadios,
  FormControl,
  FormHelperText,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  UnselectedRadio,
} from '@/components/form/controls'

export const FukaJouhouControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>物件付加情報</FormGroupHeading>
      <FormControl fieldName="sakimono_flg">
        <FormLabel>先物物件</FormLabel>
        <Select isDisabled>
          <EnumOptions enum={miraieFields.sakimono_flg.Enum} />
        </Select>
        <PortalErrorMessages />
      </FormControl>
      <MiraieFieldContents
        name={['sakimono_flg']}
        display={(sakimono_flg) =>
          sakimono_flg !== miraieFields.sakimono_flg.Enum.先物
        }
      >
        <FormGroup>
          <FormControl fieldName="torihiki_kbn">
            <FormLabel>取引態様</FormLabel>
            <Select isDisabled>
              <EnumOptions enum={miraieFields.torihiki_kbn.Enum} />
            </Select>
            <PortalErrorMessages />
          </FormControl>
          <FormGroup nested>
            <FormControl fieldName="torihiki_hosoku_biko">
              <FormLabel>備考</FormLabel>
              <TextInput />
              <PortalErrorMessages />
            </FormControl>
          </FormGroup>
        </FormGroup>
      </MiraieFieldContents>
      <WatchMiraieFields name={['sakimono_flg']}>
        {(sakimono_flg) => (
          <FormControl
            fieldName="kyakutsuke_pass_flg"
            isDisabled={sakimono_flg === miraieFields.sakimono_flg.Enum.先物}
            fieldset
            isRequired
          >
            <FormLabel>客付</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.kyakutsuke_pass_flg.Enum} />
            </RadioGroup>
            <FormHelperText>
              先物（仲介）物件の場合、「客付け可」で入稿はできません。
            </FormHelperText>
            <PortalErrorMessages />
          </FormControl>
        )}
      </WatchMiraieFields>
      {/* TODO 投資用に対応するまで非表示 */}
      {/* <MiraieFieldContents */}
      {/*  name={['bk_sbt_kbn']}*/}
      {/*  display={(bk_sbt_kbn) =>*/}
      {/*    miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'buy'*/}
      {/*  }*/}
      {/* > */}
      {/*  <FormControl fieldName="toshi_flg">*/}
      {/*    <FormLabel>投資用物件</FormLabel>*/}
      {/*    <BooleanEnumCheckbox>投資用物件</BooleanEnumCheckbox>*/}
      {/*    <PortalErrorMessages />*/}
      {/*  </FormControl>*/}
      {/* </MiraieFieldContents> */}
    </FormGroup>
  )
}
