import { Text } from '@chakra-ui/react'

import { EkiCard } from './EkiCard'
import { useNearbyEkis } from './useNearbyEkis'

import { LatLng } from '@/utils/googleMap'

type Props = {
  map: google.maps.Map
  position: LatLng
  setHoveredEkiId: (ekiId: string | undefined) => void
}

export const EkiOptions = ({ map, position, setHoveredEkiId }: Props) => {
  const { data: ekis } = useNearbyEkis(map, position)
  if (ekis.length === 0) return <Text>付近の駅を取得できませんでした。</Text>

  return ekis.map((eki) => (
    <EkiCard setHoveredEkiId={setHoveredEkiId} key={eki.place_id} eki={eki} />
  ))
}
