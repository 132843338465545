import { Button, ButtonProps } from '@chakra-ui/react'
import { getAuth, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth'

export const LoginButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      onClick={() => signInWithRedirect(getAuth(), new GoogleAuthProvider())}
    >
      ログイン
    </Button>
  )
}
