import { Box, Grid, GridProps, Text, Tooltip } from '@chakra-ui/react'
import { portals } from '@terass/common/src/portal'

import { Contents } from '@/components/Contents'
import { useFormControlContext } from '@/components/form/controls/FormControl'
import { PortalIconContainer, PortalIcon } from '@/components/icons'
import { MiraieFieldPath, useMiraieFormError } from '@/hooks/useMiraieForm'

type Props = {
  fieldName?: MiraieFieldPath
} & GridProps

export const PortalErrorMessages = ({
  fieldName: fieldNameProp,
  ...props
}: Props) => {
  const { fieldName: _fieldName } = useFormControlContext()
  const fieldName = fieldNameProp ?? _fieldName
  if (!fieldName) throw new Error()

  const error = useMiraieFormError(fieldName)

  if (!error?.types && !error) return null

  return (
    <Grid
      gap={1}
      templateColumns="auto 1fr"
      alignItems="center"
      mt={2}
      {...props}
    >
      {(['公取', 'logParam', 'validate', ...portals] as const).map((portal) => {
        const portalErrorMessage = error.types?.[portal]
        if (!portalErrorMessage) return null

        return (
          <Contents key={portal}>
            <Tooltip
              hasArrow
              label={
                portal === '公取' ? '各ポータル共通のエラーです' : undefined
              }
            >
              <Box>
                <PortalIconContainer>
                  <PortalIcon portal={portal} />
                </PortalIconContainer>
              </Box>
            </Tooltip>
            <Text size="sm" color="red">
              {portalErrorMessage}
            </Text>
          </Contents>
        )
      })}
    </Grid>
  )
}
