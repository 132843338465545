import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { Suspense } from 'react'
import { FormProvider } from 'react-hook-form'

import { ConfirmAlertDialog } from '../../ConfirmAlertDialog'

import { AddressDisplay } from './AddressDisplay'
import { AddressDisplayText } from './AddressDisplayText'
import { AddressFormModalContent } from './AddressFormModalContent'
import { AddressForm, useAddressForm } from './hooks/useAddressForm'

import { useIsEdit } from '@/hooks/useIsEdit'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'
import { useUpdateLatLngByAddress } from '@/hooks/useUpdateLatLngByAddress'

export const SelectAddress = () => {
  const { setValue } = useMiraieFormContext()
  const isDisabled = !useIsEdit()

  const methods = useAddressForm()
  const {
    formState: { isDirty },
    reset,
  } = methods

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure()

  const updateLatLng = useUpdateLatLngByAddress()

  const setAddressValues = ({
    prefectureCode,
    shikuchosonCode,
    ooazaCode,
    azaCode,
    banchi,
    chiban,
    partitionName,
  }: AddressForm) => {
    setValue('gyosei_cd', `${prefectureCode}${shikuchosonCode}`)
    setValue('aza_cd', `${ooazaCode}${azaCode}`)
    setValue('banchi', banchi)
    setValue('chiban', chiban)
    setValue('partition_name', partitionName)
    updateLatLng({
      prefectureCode,
      shikuchosonCode,
      ooazaCode,
      azaCode,
      chiban,
      banchi,
      partition_name: partitionName,
    })
    onModalClose()
  }

  const onClose = () => (isDirty ? onAlertOpen() : onModalClose())
  const abortEdit = () => {
    onAlertClose()
    onModalClose()
    reset()
  }

  return (
    <Flex direction="column" gap={4}>
      <Suspense
        fallback={<AddressDisplayText>住所を取得中…</AddressDisplayText>}
      >
        <AddressDisplay />
      </Suspense>
      <Button variant="outline" onClick={onModalOpen} isDisabled={isDisabled}>
        所在地の編集
      </Button>
      <Modal isOpen={isModalOpen} onClose={onClose} size="4xl">
        <ModalOverlay />

        <FormProvider {...methods}>
          <AddressFormModalContent setAddressValues={setAddressValues} />
        </FormProvider>
      </Modal>
      <ConfirmAlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        onAbort={abortEdit}
      />
    </Flex>
  )
}
