import { Portal } from '@terass/common/src/portal'

import { BooleanEnumCheckbox } from '@/components/form/controls'

export const RoomNoFlgCheckbox: React.FC<{
  portal: Extract<Portal, 'homes' | 'athome' | 'suumo'>
}> = ({ portal }) => (
  <BooleanEnumCheckbox fieldName={`${portal}_c_room_no_show_flg`}>
    部屋番号を表示する
  </BooleanEnumCheckbox>
)
