import { useForm, useFormContext, useWatch } from 'react-hook-form'

import { useWatchAddressCodes } from './useWatchAddressCodes'

import { useMiraieFormContext } from '@/hooks/useMiraieForm'

export type AddressForm = {
  prefectureCode: string
  shikuchosonCode: string
  ooazaCode: string
  azaCode: string
  banchi: string
  chiban: string
  partitionName: string
}

export const useAddressForm = () => {
  const { prefectureCode, shikuchosonCode, ooazaCode, azaCode } =
    useWatchAddressCodes()
  const { getValues } = useMiraieFormContext()
  const [banchi, chiban, partition_name] = getValues([
    'banchi',
    'chiban',
    'partition_name',
  ])
  return useForm<AddressForm>({
    values: {
      prefectureCode,
      shikuchosonCode,
      ooazaCode,
      azaCode,
      banchi: banchi ?? '',
      chiban: chiban ?? '',
      partitionName: partition_name ?? '',
    },
    // エラー時にフォーカスされると、フォーカスされた枠の色でエラーかどうか分かりづらくなるため
    shouldFocusError: false,
  })
}

export const useAddressFormContext = () => useFormContext<AddressForm>()

export const useWatchAddressForm = <T extends keyof AddressForm>(name: T) =>
  useWatch<AddressForm, T>({ name })
