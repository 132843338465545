import { code } from './code'

/** this code is generated semi-automatically. 
    we recommend that you DO NOT EDIT it manually. */
export const group = {
  'TV/通信': [
    '1020',
    '1021',
    '1028',
    '1053',
    '1099',
    '1244',
    '1275',
    '1276',
    '1277',
    '1278',
    '1279',
    '1280',
    '1282',
    '1456',
    '1457',
    '1458',
    '1607',
    '1637',
    '1704',
    '2809',
    '2810',
  ],
  エコ関連: [
    '1404',
    '1405',
    '1408',
    '1409',
    '1410',
    '1411',
    '1450',
    '1666',
    '1667',
    '2511',
  ],
  'キッチン/給湯': [
    '1013',
    '1039',
    '1040',
    '1058',
    '1059',
    '1066',
    '1069',
    '1071',
    '1081',
    '1215',
    '1216',
    '1217',
    '1218',
    '1219',
    '1220',
    '1225',
    '1227',
    '1228',
    '1229',
    '1230',
    '1231',
    '1232',
    '1233',
    '1299',
    '1300',
    '1301',
    '1372',
    '1373',
    '1374',
    '1375',
    '1376',
    '1377',
    '1378',
    '1379',
    '1380',
    '1381',
    '1383',
    '1386',
    '1624',
    '1646',
    '1647',
    '1670',
    '1693',
    '1694',
    '1695',
  ],
  バス: [
    '1014',
    '1022',
    '1056',
    '1065',
    '1206',
    '1207',
    '1208',
    '1224',
    '1288',
    '1289',
    '1290',
    '1291',
    '1292',
    '1293',
    '1294',
    '1295',
    '1387',
    '1388',
    '1390',
    '1391',
    '1392',
    '1626',
    '1656',
    '1668',
    '1671',
    '1700',
    '2010',
  ],
  トイレ: [
    '1034',
    '1083',
    '1092',
    '1209',
    '1210',
    '1212',
    '1223',
    '1296',
    '1393',
    '1394',
    '1601',
    '1657',
    '1697',
    '1712',
    '2761',
  ],
  'バス/トイレ': ['1023', '1024', '1221'],
  洗面: [
    '1025',
    '1077',
    '1078',
    '1297',
    '1395',
    '1396',
    '1397',
    '1398',
    '1399',
    '1400',
    '1401',
    '1402',
    '1403',
  ],
  冷暖房: [
    '1010',
    '1011',
    '1012',
    '1036',
    '1042',
    '1073',
    '1074',
    '1075',
    '1213',
    '1214',
    '1240',
    '1241',
    '1242',
    '1243',
    '1307',
    '1308',
    '1309',
    '1310',
    '1311',
    '1385',
    '1389',
    '1602',
    '1625',
    '1627',
    '1628',
    '1629',
    '1630',
    '1631',
    '1638',
    '1702',
    '1703',
  ],
  セキュリティ: [
    '1018',
    '1060',
    '1064',
    '1067',
    '1070',
    '1283',
    '1284',
    '1286',
    '1287',
    '1340',
    '1341',
    '1342',
    '1343',
    '1344',
    '1345',
    '1609',
    '1621',
    '1622',
    '1665',
    '2018',
    '2019',
    '2020',
    '2057',
    '2427',
    '2432',
    '2438',
    '2485',
    '2592',
    '2718',
    '2732',
    '2759',
    '2768',
    '2791',
    '2802',
  ],
  '家具/家電': [
    '1031',
    '1044',
    '1047',
    '1054',
    '1087',
    '1222',
    '1245',
    '1304',
    '1305',
    '1384',
    '1451',
    '1452',
    '1453',
    '1634',
    '1639',
    '1640',
    '1641',
    '1642',
    '1644',
    '1673',
  ],
  間取: [
    '1019',
    '1094',
    '1248',
    '1346',
    '1347',
    '1348',
    '1349',
    '1350',
    '1351',
    '1352',
    '1353',
    '1354',
    '1355',
    '1356',
    '1357',
    '1358',
    '1359',
    '1360',
    '1361',
    '1362',
    '1363',
    '1364',
    '1365',
    '1366',
    '1367',
    '1368',
    '1369',
    '1370',
    '1371',
    '2024',
    '2029',
    '2058',
    '2059',
    '2415',
    '2416',
    '2417',
    '2418',
    '2585',
    '2767',
    '2805',
    '2807',
  ],
  収納: [
    '1008',
    '1009',
    '1030',
    '1093',
    '1096',
    '1254',
    '1255',
    '1256',
    '1258',
    '1259',
    '1260',
    '1261',
    '1262',
    '1264',
    '1265',
    '1266',
    '1270',
    '1271',
    '1382',
    '1441',
    '1442',
    '1443',
    '1444',
    '1445',
    '1446',
    '1447',
    '1448',
    '1635',
  ],
  専有設備: [
    '1007',
    '1032',
    '1249',
    '1272',
    '1306',
    '1407',
    '1424',
    '1425',
    '1426',
    '1427',
    '1438',
    '1439',
    '1449',
    '1623',
    '2542',
  ],
  '共用設備/施設': [
    '1015',
    '1055',
    '1061',
    '1062',
    '1246',
    '1247',
    '1250',
    '1273',
    '1274',
    '1285',
    '1313',
    '1314',
    '1315',
    '1316',
    '1317',
    '1318',
    '1320',
    '1321',
    '1322',
    '1323',
    '1324',
    '1325',
    '1326',
    '1327',
    '1328',
    '1329',
    '1330',
    '1331',
    '1332',
    '1333',
    '1412',
    '1603',
    '1604',
    '1605',
    '1620',
    '1645',
    '1655',
    '1658',
    '1659',
    '1660',
    '1661',
    '1662',
    '1663',
    '1664',
    '1674',
    '1675',
    '1676',
    '1677',
    '1678',
    '1679',
    '1680',
    '1681',
    '1682',
    '1683',
    '1684',
    '1685',
    '1686',
    '1687',
    '1688',
    '1689',
    '1690',
    '1691',
    '1698',
    '1699',
    '1715',
    '2056',
  ],
  ライフライン: [
    '1001',
    '1002',
    '1003',
    '1004',
    '1005',
    '1006',
    '1016',
    '1017',
    '1200',
    '1201',
    '1202',
    '1203',
    '1204',
    '1205',
    '1226',
    '1649',
    '1650',
    '1651',
    '1652',
    '1653',
    '1654',
    '1710',
    '1711',
  ],
  '建物・構造': [
    '1319',
    '1436',
    '1437',
    '2015',
    '2428',
    '2430',
    '2431',
    '2498',
    '2499',
    '2500',
    '2503',
    '2504',
    '2505',
    '2506',
    '2507',
    '2508',
    '2515',
    '2516',
    '2524',
    '2525',
    '2526',
    '2527',
    '2528',
    '2529',
    '2530',
    '2531',
    '2533',
    '2534',
    '2535',
    '2536',
    '2537',
    '2564',
    '2565',
    '2566',
    '2748',
    '2749',
    '2763',
    '2794',
    '2798',
    '2799',
    '2804',
    '2839',
  ],
  '庭/テラス/バルコニー': [
    '1027',
    '1043',
    '1048',
    '1049',
    '1050',
    '1051',
    '1097',
    '1098',
    '1236',
    '1237',
    '1302',
    '1303',
    '1334',
    '1335',
    '1336',
    '1337',
    '1338',
    '1339',
    '1413',
    '1414',
    '1415',
    '1417',
    '1418',
    '1669',
    '1692',
    '1696',
    '1713',
    '1714',
  ],
  '窓・ガラス': [
    '1068',
    '1084',
    '1234',
    '1251',
    '1428',
    '1429',
    '1430',
    '1431',
    '1432',
    '1433',
    '1608',
    '1648',
    '1701',
  ],
  床: [
    '1086',
    '1238',
    '1239',
    '1253',
    '1406',
    '1419',
    '1423',
    '1522',
    '1606',
    '1632',
    '1633',
    '1672',
    '2023',
    '2538',
    '2701',
    '2790',
  ],
  壁: ['1252', '1420', '1421', '1422', '1435', '2509', '2843'],
  天井: ['1235', '1434', '2512', '2519', '2520', '2521', '2523', '2793'],
  楽器: ['1312', '2002', '2009', '2413', '2780'],
  契約条件: [
    '1618',
    '2008',
    '2017',
    '2025',
    '2026',
    '2407',
    '2408',
    '2414',
    '2604',
    '2605',
    '2610',
    '2614',
    '2618',
    '2619',
    '2705',
    '2706',
    '2787',
    '2789',
    '2823',
    '2842',
  ],
  'リフォーム/リノベ': [
    '2593',
    '2594',
    '2595',
    '2596',
    '2597',
    '2598',
    '2599',
    '2600',
    '2601',
  ],
  採光: [
    '2419',
    '2420',
    '2421',
    '2422',
    '2423',
    '2582',
    '2583',
    '2587',
    '2588',
    '2589',
    '2590',
    '2591',
  ],
  駐車場: [
    '2439',
    '2440',
    '2443',
    '2444',
    '2447',
    '2448',
    '2571',
    '2573',
    '2712',
    '2715',
    '2716',
    '2811',
    '2812',
    '2834',
    '2836',
    '2837',
    '2838',
    '2846',
    '2847',
  ],
  近接道路: [
    '2007',
    '2054',
    '2055',
    '2517',
    '2518',
    '2574',
    '2577',
    '2578',
    '2579',
    '2580',
    '2581',
    '2751',
  ],
  '耐震/防災': [
    '2034',
    '2429',
    '2489',
    '2490',
    '2491',
    '2493',
    '2495',
    '2496',
    '2497',
    '2502',
    '2513',
    '2514',
    '2779',
    '2792',
  ],
  '特徴/その他': [
    '2032',
    '2033',
    '2433',
    '2434',
    '2435',
    '2455',
    '2456',
    '2457',
    '2510',
    '2561',
    '2562',
    '2702',
    '2719',
  ],
  '入居/引渡': ['2602', '2603', '2606', '2607', '2608', '2609', '2615', '2808'],
  ペット: ['2001', '2783', '2784', '2813', '2814', '2815'],
  入居者: [
    '2003',
    '2004',
    '2005',
    '2006',
    '2011',
    '2031',
    '2050',
    '2051',
    '2400',
    '2401',
    '2402',
    '2403',
    '2404',
    '2405',
    '2406',
    '2409',
    '2410',
    '2411',
    '2720',
    '2733',
    '2734',
    '2735',
    '2736',
    '2781',
    '2782',
    '2844',
  ],
  費用関連: [
    '2016',
    '2721',
    '2722',
    '2723',
    '2724',
    '2725',
    '2726',
    '2727',
    '2728',
    '2729',
    '2730',
    '2745',
    '2766',
    '2786',
    '2788',
  ],
  '評価/証明': [
    '2492',
    '2494',
    '2501',
    '2704',
    '2731',
    '2756',
    '2760',
    '2795',
    '2796',
    '2801',
    '2803',
    '2816',
    '2818',
    '2820',
    '2821',
    '2822',
    '2825',
    '2826',
    '2827',
    '2828',
    '2829',
    '2830',
    '2831',
    '2835',
  ],
  '用途/タイプ': [
    '2014',
    '2027',
    '2028',
    '2437',
    '2546',
    '2547',
    '2616',
    '2617',
    '2703',
    '2806',
  ],
  '交通/アクセス': [
    '2458',
    '2459',
    '2460',
    '2461',
    '2462',
    '2463',
    '2464',
    '2465',
    '2466',
    '2467',
    '2486',
    '2487',
    '2488',
    '2797',
  ],
  周辺環境: [
    '2013',
    '2451',
    '2454',
    '2710',
    '2711',
    '2737',
    '2738',
    '2740',
    '2750',
    '2752',
    '2753',
    '2754',
    '2755',
    '2800',
    '2832',
    '2833',
  ],
  '立地/眺望': [
    '2030',
    '2436',
    '2450',
    '2453',
    '2468',
    '2469',
    '2470',
    '2471',
    '2472',
    '2473',
    '2477',
    '2478',
    '2479',
    '2480',
    '2481',
    '2482',
    '2483',
    '2484',
    '2539',
    '2540',
    '2611',
    '2612',
    '2613',
    '2739',
    '2746',
    '2747',
    '2840',
    '2841',
  ],
} as const satisfies Record<string, readonly (keyof typeof code)[]>
