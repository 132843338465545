import { getAnalytics } from 'firebase/analytics'
import { initializeApp, getApps, getApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
  // persistentLocalCache,
  // persistentMultipleTabManager,
} from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

import { config } from '../config'
import logger from '../logger'

const isInitialized = getApps().length > 0
if (!isInitialized) {
  const app = initializeApp(config.firebase)
  getAnalytics(app)
  initializeFirestore(app, {
    ignoreUndefinedProperties: true,
    // 安定運用するまでキャッシュは無効にしておく
    // localCache: persistentLocalCache({
    //   tabManager: persistentMultipleTabManager(),
    // }),
  })
}

const app = getApp()
export const functions = getFunctions(app, 'asia-northeast1')

export const initializeFirebase = () => {
  if (config.isEmulator) {
    logger.info('Use emulator')
    connectFunctionsEmulator(functions, 'localhost', 5002)
    connectFirestoreEmulator(getFirestore(), 'localhost', 8080)
    connectAuthEmulator(getAuth(), 'http://localhost:9099')
    connectStorageEmulator(getStorage(), 'localhost', 9199)
  }
}
