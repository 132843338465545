/**
 * 地勢区分
 */
export const Enum = {
  平坦: '1',
  高台: '2',
  低地: '3',
  雛段: '4',
  傾斜地: '5',
  その他: '9',
} as const
