import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import {
  EnumRadios,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const PaymentExample = () => {
  return (
    <FormGroup>
      <FormGroupHeading>支払い例</FormGroupHeading>

      <FormControl fieldName="payment_example_flg" fieldset>
        <FormLabel>支払い例</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.payment_example_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <MiraieFieldCollapse
        name={['payment_example_flg']}
        display={(payment_example_flg) =>
          payment_example_flg ===
          miraieFields.payment_example_flg.Enum.支払い例有
        }
      >
        <FormGroup nested>
          <FormControl fieldName="payment_example_bk_info">
            <FormLabel>例示部 物件(住戸)情報</FormLabel>
            <Textarea />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="payment_example_price">
            <FormLabel>例示部 金額</FormLabel>
            <Textarea />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="payment_example_rate">
            <FormLabel>例示部 金利</FormLabel>
            <Textarea />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="payment_example_amount_of_payment">
            <FormLabel>支払額部</FormLabel>
            <Textarea />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="payment_example_loan_biko">
            <FormLabel>住宅ローンのご案内</FormLabel>
            <Textarea />
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </MiraieFieldCollapse>
    </FormGroup>
  )
}
