import { VisuallyHiddenInput } from '@chakra-ui/react'
import { omit } from '@terass/common/src/utils'
import { ChangeEventHandler } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'

export type ImageInputFieldProps<T extends FieldValues> = {
  fieldName: FieldPath<T>
}

export const ImageInputField = <T extends FieldValues>({
  fieldName,
}: ImageInputFieldProps<T>) => {
  const field = omit(
    useController<FieldValues, string>({ name: fieldName }).field,
    ['value'],
  )
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.item(0)
    if (!file) return
    if (!/^image\/(gif|png|jpeg)$/.test(file.type)) return

    field.onChange(file)
  }

  return (
    <VisuallyHiddenInput
      type="file"
      id={fieldName}
      accept="image/gif,image/png,image/jpeg"
      {...field}
      onChange={onChange}
    />
  )
}
