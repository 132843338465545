import { ValueOf } from '../../types'

export const Enum = {
  '★': '1',
  '★★': '2',
  '★★★': '3',
  '★★★★': '4',
  '★★★★★': '5',
  '★★★★★★': '6',
  '★★★★★★★': '7',
} as const

export type Enum = ValueOf<typeof Enum>
