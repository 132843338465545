import { createContext } from '@/hooks/useContext'
import { CreateBaitaiKeisaiForm } from '@/hooks/useCreateBaitaiKeisaiForm'

export type CreateBaitaiKeisaiFormControlContextProps = {
  fieldName: keyof CreateBaitaiKeisaiForm
}

export const [
  CreateBaitaiKeisaiFormControlContextProvider,
  useCreateBaitaiKeisaiFormControlContext,
] = createContext<CreateBaitaiKeisaiFormControlContextProps>()
