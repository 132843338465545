/**
 * 建物構造区分
 */
export const Enum = {
  木造: '1',
  ブロック: '2',
  鉄骨: '3',
  RC: '4',
  SRC: '5',
  PC: '6',
  HPC: '7',
  軽量鉄骨: '8',
  ALC: '9',
  その他: '99',
} as const
