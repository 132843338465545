import { Portal } from '../../../portal'
import * as bk_sbt_kbn from '../bk_sbt_kbn'

/** 設備・特記 */
type PortalState = { [key in Portal]?: 'true' | 'key' }
export type SetsubiTokki = {
  name: string
} & {
  [K in bk_sbt_kbn.Enum]?: PortalState
}

/** this code is generated semi-automatically. 
    we recommend that you DO NOT EDIT it manually. */
export const code = {
  '1001': {
    '11': {
      athome: 'true',
    },
    '12': {
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '電気',
  },
  '1002': {
    '11': {
      athome: 'true',
    },
    '12': {
      athome: 'true',
    },
    '17': {},
    name: '上水道',
  },
  '1003': {
    '11': {
      homes: 'true',
      athome: 'true',
    },
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {},
    '32': {
      homes: 'true',
    },
    name: '下水道',
  },
  '1004': {
    '11': {
      homes: 'true',
      athome: 'true',
    },
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '浄化槽',
  },
  '1005': {
    '11': {
      homes: 'true',
      athome: 'true',
    },
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '側溝',
  },
  '1006': {
    '11': {
      homes: 'true',
      athome: 'true',
    },
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '汲取',
  },
  '1007': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '室内洗濯機置場',
  },
  '1008': {
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'トランクルーム',
  },
  '1009': {
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: 'グルニエ',
  },
  '1010': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '堀ごたつ',
  },
  '1011': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '冷房',
  },
  '1012': {
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: '暖房',
  },
  '1013': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '給湯',
  },
  '1014': {
    '12': {
      homes: 'key',
      athome: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '追焚機能',
  },
  '1015': {
    '12': {
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'エレベーター',
  },
  '1016': {
    '11': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '都市ガス',
  },
  '1017': {
    '11': {
      homes: 'true',
      athome: 'true',
    },
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'プロパンガス',
  },
  '1018': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'オートロック',
  },
  '1019': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'ロフト',
  },
  '1020': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'CATV',
  },
  '1021': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'BS',
  },
  '1022': {
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: 'シャワー',
  },
  '1023': {
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'B/T別室',
  },
  '1024': {
    '32': {
      athome: 'true',
    },
    name: 'B/T同室',
  },
  '1025': {
    '32': {
      athome: 'true',
    },
    name: '洗面所',
  },
  '1027': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'バルコニー',
  },
  '1028': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '有線放送',
  },
  '1030': {
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'key',
    },
    name: '収納スペース',
  },
  '1031': {
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'true',
    },
    name: '冷蔵庫',
  },
  '1032': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'key',
    },
    name: '室外洗濯機置場',
  },
  '1034': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: 'トイレ',
  },
  '1036': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'エアコン',
  },
  '1039': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'IHクッキングヒータ',
  },
  '1040': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    name: '浄水器',
  },
  '1042': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '床暖房',
  },
  '1043': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '2面バルコニー',
  },
  '1044': {
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'key',
    },
    name: '洗濯乾燥機',
  },
  '1047': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'key',
    },
    name: '衣類乾燥機',
  },
  '1048': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '両面バルコニー',
  },
  '1049': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: '3面バルコニー',
  },
  '1050': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: 'テラス',
  },
  '1051': {
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '庭',
  },
  '1053': {
    '32': {
      athome: 'true',
    },
    name: '電話',
  },
  '1054': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'ベッド付',
  },
  '1055': {
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'コインランドリー',
  },
  '1056': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '浴室乾燥機',
  },
  '1058': {
    '32': {
      suumo: 'key',
      athome: 'key',
    },
    name: 'ガスコンロ可',
  },
  '1059': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'カウンタキッチン',
  },
  '1060': {
    '12': {
      suumo: 'true',
      homes: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'モニタ付インターホン',
  },
  '1061': {
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '宅配BOX',
  },
  '1062': {
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '駐輪場',
  },
  '1064': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'カードキー',
  },
  '1065': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'サウナ',
  },
  '1066': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: '食器乾燥機',
  },
  '1067': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      homes: 'key',
      athome: 'true',
    },
    name: 'モニタ付オートロック',
  },
  '1068': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    name: '複層ガラス',
  },
  '1069': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'key',
    },
    name: 'ディスポーザー',
  },
  '1070': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    name: 'ディンプルキー',
  },
  '1071': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'key',
    },
    name: '食器洗浄乾燥機',
  },
  '1073': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: 'FF暖房',
  },
  '1074': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'セントラルヒーティング',
  },
  '1075': {
    '32': {},
    name: 'ボイラー',
  },
  '1077': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'key',
    },
    name: '洗髪洗面化粧台',
  },
  '1078': {
    '32': {
      athome: 'true',
    },
    name: '洗面台',
  },
  '1081': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'システムキッチン',
  },
  '1083': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '温水洗浄便座',
  },
  '1084': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'key',
    },
    name: '出窓',
  },
  '1086': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'フローリング',
  },
  '1087': {
    '12': {
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'true',
    },
    name: '照明器具',
  },
  '1092': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: 'トイレ2箇所',
  },
  '1093': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '床下収納',
  },
  '1094': {
    '12': {
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      athome: 'true',
    },
    name: '地下室',
  },
  '1096': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'ウォークインクローゼット',
  },
  '1097': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'ルーフバルコニー',
  },
  '1098': {
    '12': {
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '専用庭',
  },
  '1099': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'CS',
  },
  '1200': {
    '11': {
      homes: 'true',
    },
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '共同ガス',
  },
  '1201': {
    '11': {
      homes: 'true',
    },
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '天然ガス',
  },
  '1202': {
    '11': {
      suumo: 'true',
      homes: 'true',
    },
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '公営水道',
  },
  '1203': {
    '11': {
      suumo: 'true',
      homes: 'true',
    },
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '井戸',
  },
  '1204': {
    '11': {
      homes: 'true',
    },
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: 'その他水道',
  },
  '1205': {
    '11': {
      homes: 'true',
    },
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: 'その他排水',
  },
  '1206': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: 'バス',
  },
  '1207': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '共同バス',
  },
  '1208': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: 'ユニットバス',
  },
  '1209': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '共同トイレ',
  },
  '1210': {
    name: '水洗トイレ',
  },
  '1212': {
    name: '汲取トイレ',
  },
  '1213': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '冷房のみ',
  },
  '1214': {
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: '暖房のみ',
  },
  '1215': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '電気コンロ',
  },
  '1216': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: 'コンロ一口',
  },
  '1217': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'コンロ二口',
  },
  '1218': {
    '12': {
      homes: 'key',
      athome: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    name: 'コンロ三口',
  },
  '1219': {
    '12': {
      homes: 'key',
      athome: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    name: 'コンロ四口以上',
  },
  '1220': {
    name: 'コンロ設置済',
  },
  '1221': {
    '32': {
      suumo: 'true',
    },
    name: '脱衣所',
  },
  '1222': {
    '32': {
      suumo: 'true',
    },
    name: '洗濯機',
  },
  '1223': {
    '32': {},
    name: '暖房便座',
  },
  '1224': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '高温差湯式',
  },
  '1225': {
    name: '3点給湯',
  },
  '1226': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'オール電化',
  },
  '1227': {
    '32': {},
    name: '対面キッチン',
  },
  '1228': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '独立キッチン',
  },
  '1229': {
    '32': {
      suumo: 'key',
    },
    name: 'L字キッチン',
  },
  '1230': {
    '32': {
      suumo: 'true',
    },
    name: '電子レンジ',
  },
  '1231': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'グリル',
  },
  '1232': {
    '32': {
      suumo: 'key',
    },
    name: 'ガスレンジ',
  },
  '1233': {
    '32': {},
    name: '瞬間湯沸器',
  },
  '1234': {
    '32': {
      suumo: 'true',
    },
    name: '天窓',
  },
  '1235': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '吹抜',
  },
  '1236': {
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    name: 'ウッドテラス',
  },
  '1237': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'サンルーム',
  },
  '1238': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: '全室フローリング',
  },
  '1239': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: 'クッションフロア',
  },
  '1240': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: '全室エアコン',
  },
  '1241': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'エアコン2基',
  },
  '1242': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'エアコン3基',
  },
  '1243': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'エアコン4基以上',
  },
  '1244': {
    '32': {
      suumo: 'true',
    },
    name: '電話2回線',
  },
  '1245': {
    '32': {},
    name: '各居室照明',
  },
  '1246': {
    '32': {
      suumo: 'true',
    },
    name: '玄関ホール',
  },
  '1247': {
    '13': {},
    '32': {
      suumo: 'true',
    },
    name: '玄関ポーチ',
  },
  '1248': {
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '可動間仕切',
  },
  '1249': {
    '32': {},
    name: 'ピクチャーレール',
  },
  '1250': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: 'リードフック',
  },
  '1251': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {},
    '32': {
      homes: 'true',
    },
    name: 'ペアサッシ',
  },
  '1252': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '内装コンクリート打',
  },
  '1253': {
    '12': {},
    '32': {
      suumo: 'true',
    },
    name: 'フラットフロア',
  },
  '1254': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: 'クローゼット',
  },
  '1255': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '押入',
  },
  '1256': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '天袋',
  },
  '1258': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'true',
    },
    name: '全居室収納',
  },
  '1259': {
    '12': {
      suumo: 'true',
    },
    '13': {
      athome: 'true',
    },
    '17': {},
    '32': {
      suumo: 'true',
    },
    name: '屋根裏収納',
  },
  '1260': {
    '32': {
      suumo: 'true',
    },
    name: '物置',
  },
  '1261': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {},
    name: 'シューズインクローク',
  },
  '1262': {
    name: 'タンス',
  },
  '1264': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '収納1間半',
  },
  '1265': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '収納2間',
  },
  '1266': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '収納2間半',
  },
  '1270': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: 'クローゼット2箇所',
  },
  '1271': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: 'クローゼット3箇所',
  },
  '1272': {
    '32': {},
    name: 'インターホン',
  },
  '1273': {
    '32': {
      suumo: 'key',
    },
    name: 'ゴミ置場',
  },
  '1274': {
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: 'バイク置場',
  },
  '1275': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      homes: 'key',
      athome: 'key',
    },
    name: 'インターネット接続可',
  },
  '1276': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {},
    '32': {
      homes: 'key',
    },
    name: 'ADSLインターネット',
  },
  '1277': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {},
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: 'ISDNインターネット',
  },
  '1278': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {},
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'CATVインターネット',
  },
  '1279': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    name: '光インターネット',
  },
  '1280': {
    name: '地上デジタル',
  },
  '1282': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'key',
    },
    name: 'CATV電話',
  },
  '1283': {
    '12': {
      suumo: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '防犯カメラ',
  },
  '1284': {
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '防犯シャッター',
  },
  '1285': {
    '32': {
      suumo: 'true',
    },
    name: '内階段',
  },
  '1286': {
    name: 'シリンダーキー',
  },
  '1287': {
    name: 'テンキー',
  },
  '1288': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'key',
    },
    name: 'TV付バス',
  },
  '1289': {
    '32': {
      suumo: 'true',
    },
    name: 'バス2箇所',
  },
  '1290': {
    name: 'バスサイズ1418',
  },
  '1291': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'key',
    },
    '17': {},
    '32': {
      homes: 'true',
    },
    name: 'バスサイズ1625',
  },
  '1292': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    name: 'オートバス',
  },
  '1293': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: 'ジェットバス',
  },
  '1294': {
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '多機能シャワー',
  },
  '1295': {
    name: '防湿機能付換気扇',
  },
  '1296': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    name: 'タンクレストイレ',
  },
  '1297': {
    '32': {
      suumo: 'true',
    },
    name: 'ダブルボウル',
  },
  '1299': {
    '32': {
      suumo: 'true',
    },
    name: '電気クッカー',
  },
  '1300': {
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      athome: 'true',
    },
    name: 'アイランドキッチン',
  },
  '1301': {
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '外国製システムキッチン',
  },
  '1302': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '屋上テラス',
  },
  '1303': {
    '12': {
      suumo: 'true',
    },
    name: 'パティオ',
  },
  '1304': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '家具付',
  },
  '1305': {
    '32': {
      suumo: 'true',
    },
    name: 'テレビ',
  },
  '1306': {
    name: '手すり',
  },
  '1307': {
    '32': {
      suumo: 'true',
    },
    name: '暖炉',
  },
  '1308': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'ガス暖房',
  },
  '1309': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: '石油暖房',
  },
  '1310': {
    '32': {},
    name: '灯油FF',
  },
  '1311': {
    '32': {},
    name: 'ガスFF',
  },
  '1312': {
    '32': {
      suumo: 'key',
    },
    name: '防音設備',
  },
  '1313': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: 'ペット用出入口',
  },
  '1314': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: 'ペット足洗場',
  },
  '1315': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: 'ペットトイレ',
  },
  '1316': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: 'プール',
  },
  '1317': {
    name: 'トレーニングルーム',
  },
  '1318': {
    '13': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: 'アスレチックルーム',
  },
  '1319': {
    '12': {
      homes: 'true',
      athome: 'key',
    },
    '13': {
      homes: 'key',
      athome: 'key',
    },
    '17': {},
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '外断熱',
  },
  '1320': {
    '12': {
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'エレベーター2基',
  },
  '1321': {
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: 'キッズルーム',
  },
  '1322': {
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: 'ゲストルーム',
  },
  '1323': {
    '13': {
      suumo: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'シアタールーム',
  },
  '1324': {
    '32': {
      suumo: 'true',
    },
    name: '集会所',
  },
  '1325': {
    '32': {
      suumo: 'true',
    },
    name: 'ストックヤード',
  },
  '1326': {
    '32': {
      suumo: 'true',
    },
    name: '大浴場',
  },
  '1327': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '敷地内遊場',
  },
  '1328': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '車寄',
  },
  '1329': {
    '32': {
      suumo: 'true',
    },
    name: 'タイヤ置場',
  },
  '1330': {
    '32': {
      suumo: 'true',
    },
    name: '風除室',
  },
  '1331': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'AED付',
  },
  '1332': {
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: 'クリーニングボックス',
  },
  '1333': {
    '32': {
      suumo: 'true',
    },
    name: 'ゴミ回収サービス',
  },
  '1334': {
    '12': {
      suumo: 'key',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '庭10坪以上',
  },
  '1335': {
    '12': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '庭30坪以上',
  },
  '1336': {
    '12': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '庭50坪以上',
  },
  '1337': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '南庭',
  },
  '1338': {
    '12': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '坪庭',
  },
  '1339': {
    '12': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '中庭',
  },
  '1340': {
    '12': {
      suumo: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'ダブルロックキー',
  },
  '1341': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '玄関リモコンキー',
  },
  '1342': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: 'ドアタッチキーシステム',
  },
  '1343': {
    '12': {
      suumo: 'key',
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '静脈認証',
  },
  '1344': {
    '12': {
      suumo: 'key',
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '指紋認証',
  },
  '1345': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'key',
    },
    name: '防犯ガラス',
  },
  '1346': {
    '32': {
      suumo: 'true',
    },
    name: 'LDK12畳以上',
  },
  '1347': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: 'LDK15畳以上',
  },
  '1348': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: 'LDK18畳以上',
  },
  '1349': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: 'LDK20畳以上',
  },
  '1350': {
    '32': {
      suumo: 'true',
    },
    name: 'LDK25畳以上',
  },
  '1351': {
    '32': {
      suumo: 'true',
    },
    name: '2階リビング',
  },
  '1352': {
    '32': {
      suumo: 'true',
    },
    name: '2階LDK',
  },
  '1353': {
    '32': {
      suumo: 'true',
    },
    name: 'LDK2箇所',
  },
  '1354': {
    '32': {
      suumo: 'true',
    },
    name: 'リビング吹抜',
  },
  '1355': {
    '32': {
      suumo: 'true',
    },
    name: 'リビングの隣和室',
  },
  '1356': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '和室',
  },
  '1357': {
    '32': {
      suumo: 'true',
    },
    name: '続和室',
  },
  '1358': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '和室8畳以上',
  },
  '1359': {
    '32': {
      suumo: 'true',
    },
    name: '畳コーナー',
  },
  '1360': {
    '32': {
      suumo: 'true',
    },
    name: '床の間',
  },
  '1361': {
    '32': {
      suumo: 'true',
    },
    name: '茶室',
  },
  '1362': {
    '32': {
      suumo: 'true',
    },
    name: '寝室8畳以上',
  },
  '1363': {
    '32': {
      suumo: 'true',
    },
    name: '寝室10畳以上',
  },
  '1364': {
    '32': {
      suumo: 'true',
    },
    name: '家事室',
  },
  '1365': {
    '32': {
      suumo: 'true',
    },
    name: 'フリースペース',
  },
  '1366': {
    '32': {
      suumo: 'true',
    },
    name: '書斎',
  },
  '1367': {
    '32': {
      suumo: 'true',
    },
    name: '土間',
  },
  '1368': {
    '32': {
      suumo: 'true',
    },
    name: '2ドア1ルーム',
  },
  '1369': {
    '32': {
      suumo: 'true',
    },
    name: '全居室洋室',
  },
  '1370': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '全居室6畳以上',
  },
  '1371': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '全居室8畳以上',
  },
  '1372': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'オープンキッチン',
  },
  '1373': {
    '32': {
      suumo: 'key',
    },
    name: 'U字型キッチン',
  },
  '1374': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      athome: 'true',
    },
    name: 'II型キッチン',
  },
  '1375': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '2WAYキッチン',
  },
  '1376': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '3WAYキッチン',
  },
  '1377': {
    '32': {
      suumo: 'key',
    },
    name: 'ガラストップコンロ',
  },
  '1378': {
    '32': {
      suumo: 'true',
    },
    name: 'キッチン5畳以上',
  },
  '1379': {
    '32': {
      suumo: 'true',
    },
    name: 'キッチン2箇所',
  },
  '1380': {
    '32': {
      suumo: 'true',
    },
    name: '勝手口',
  },
  '1381': {
    '32': {
      suumo: 'true',
    },
    name: 'キッチンに窓',
  },
  '1382': {
    '12': {
      suumo: 'key',
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '食品庫',
  },
  '1383': {
    '32': {
      suumo: 'true',
    },
    name: '備付食器棚',
  },
  '1384': {
    '32': {
      suumo: 'true',
    },
    name: '耐震ラッチ吊戸棚',
  },
  '1385': {
    '32': {
      suumo: 'true',
    },
    name: 'キッチン足元暖風機',
  },
  '1386': {
    '32': {
      suumo: 'true',
    },
    name: 'キッチン未使用',
  },
  '1387': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '浴室に窓',
  },
  '1388': {
    '32': {
      suumo: 'true',
    },
    name: '檜風呂',
  },
  '1389': {
    '32': {
      athome: 'true',
    },
    name: 'プロパンガス暖房',
  },
  '1390': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    name: 'ミストサウナ',
  },
  '1391': {
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'オーディオバス',
  },
  '1392': {
    '32': {
      suumo: 'true',
    },
    name: '浴室未使用',
  },
  '1393': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: 'トイレ3箇所',
  },
  '1394': {
    '32': {
      suumo: 'true',
    },
    name: 'トイレ未使用',
  },
  '1395': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '洗面所独立',
  },
  '1396': {
    '32': {
      suumo: 'true',
    },
    name: '2階洗面台',
  },
  '1397': {
    '32': {
      suumo: 'true',
    },
    name: '洗面所に窓',
  },
  '1398': {
    '32': {
      suumo: 'true',
    },
    name: '洗面所にドア',
  },
  '1399': {
    '32': {
      suumo: 'true',
    },
    name: '2WAY洗面所',
  },
  '1400': {
    '32': {
      suumo: 'true',
    },
    name: '3WAY洗面所',
  },
  '1401': {
    '32': {
      suumo: 'true',
    },
    name: '洗面化粧台',
  },
  '1402': {
    '32': {
      suumo: 'true',
    },
    name: '三面鏡付洗面化粧台',
  },
  '1403': {
    '32': {
      suumo: 'true',
    },
    name: '縦型照明付洗面化粧台',
  },
  '1404': {
    '32': {
      suumo: 'true',
    },
    name: '熱交換集中換気システム',
  },
  '1405': {
    '32': {
      suumo: 'true',
    },
    name: 'パッシブ換気',
  },
  '1406': {
    '32': {
      suumo: 'true',
    },
    name: '床下換気',
  },
  '1407': {
    name: '囲炉裏',
  },
  '1408': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '太陽光発電システム',
  },
  '1409': {
    '32': {
      suumo: 'true',
    },
    name: 'エコウィル',
  },
  '1410': {
    '32': {
      suumo: 'true',
    },
    name: 'エコキュート',
  },
  '1411': {
    '32': {
      suumo: 'true',
    },
    name: 'エコジョーズ',
  },
  '1412': {
    '32': {
      suumo: 'true',
    },
    name: '屋外電源',
  },
  '1413': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'ワイドバルコニー',
  },
  '1414': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: 'L字型バルコニー',
  },
  '1415': {
    '12': {
      suumo: 'key',
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '南面バルコニー',
  },
  '1417': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '2WAYバルコニー',
  },
  '1418': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: 'インナーバルコニー',
  },
  '1419': {
    '32': {
      suumo: 'true',
    },
    name: 'じゅうたん張',
  },
  '1420': {
    '32': {
      suumo: 'true',
    },
    name: '無垢材使用',
  },
  '1421': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '自然素材',
  },
  '1422': {
    '32': {
      suumo: 'true',
    },
    name: '活性炭敷込',
  },
  '1423': {
    '32': {
      suumo: 'true',
    },
    name: '琉球畳',
  },
  '1424': {
    '32': {
      suumo: 'true',
    },
    name: '室内らせん階段',
  },
  '1425': {
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: 'リビング階段',
  },
  '1426': {
    '32': {
      suumo: 'true',
    },
    name: '昇降機付階段',
  },
  '1427': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: 'ホームエレベーター',
  },
  '1428': {
    '32': {
      suumo: 'true',
    },
    name: '雨戸',
  },
  '1429': {
    '32': {
      suumo: 'true',
    },
    name: 'シャッター',
  },
  '1430': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'key',
    },
    name: '電動シャッター',
  },
  '1431': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: 'ハイサッシ',
  },
  '1432': {
    '32': {
      suumo: 'true',
    },
    name: '耐震扉',
  },
  '1433': {
    '32': {
      suumo: 'true',
    },
    name: '親子扉',
  },
  '1434': {
    '32': {
      suumo: 'true',
    },
    name: '勾配天井',
  },
  '1435': {
    '32': {
      suumo: 'true',
    },
    name: '腰壁',
  },
  '1436': {
    '32': {
      suumo: 'true',
    },
    name: '四寸柱',
  },
  '1437': {
    '32': {
      suumo: 'true',
    },
    name: '大黒柱',
  },
  '1438': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '室内物干機',
  },
  '1439': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'シーリングファン',
  },
  '1441': {
    '12': {
      homes: 'key',
      athome: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: 'ウォークインクローゼット2箇所',
  },
  '1442': {
    '12': {
      homes: 'key',
      athome: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
    },
    name: 'ウォークスルークローゼット',
  },
  '1443': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'シューズボックス',
  },
  '1444': {
    '32': {
      suumo: 'key',
    },
    name: '天井高シューズクローゼット',
  },
  '1445': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '玄関収納',
  },
  '1446': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '納戸',
  },
  '1447': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '階段下収納',
  },
  '1448': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '昇降ウォール収納',
  },
  '1449': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '間接照明',
  },
  '1450': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '人感照明センサー',
  },
  '1451': {
    '32': {
      suumo: 'true',
    },
    name: 'オートライト',
  },
  '1452': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'ダウンライト',
  },
  '1453': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'フットライト',
  },
  '1456': {
    '32': {
      suumo: 'key',
    },
    name: 'LAN',
  },
  '1457': {
    '32': {
      suumo: 'true',
    },
    name: '携帯電話システム',
  },
  '1458': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'マルチメディアコンセント',
  },
  '1522': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '二重床構造',
  },
  '1601': {
    name: '男女別トイレ',
  },
  '1602': {
    name: '個別空調',
  },
  '1603': {
    name: 'クレーン',
  },
  '1604': {
    name: 'リフト',
  },
  '1605': {
    name: '動力有',
  },
  '1606': {
    name: '高床式プラットホーム',
  },
  '1607': {
    '32': {
      suumo: 'key',
    },
    name: '無線LAN',
  },
  '1608': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: 'シャッター雨戸',
  },
  '1609': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: '火災警報器/報知機',
  },
  '1618': {
    name: '建築条件有',
  },
  '1620': {
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'key',
    },
    name: '24時間ゴミ出可',
  },
  '1621': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '電子ロック',
  },
  '1622': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '電子キー',
  },
  '1623': {
    '32': {
      suumo: 'true',
    },
    name: '玄関手すり',
  },
  '1624': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'ガスオーブン',
  },
  '1625': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'キッチン床暖房',
  },
  '1626': {
    '32': {
      suumo: 'true',
    },
    name: '浴室床暖房',
  },
  '1627': {
    '12': {
      homes: 'key',
    },
    '13': {
      homes: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '蓄熱式床暖房',
  },
  '1628': {
    '32': {
      suumo: 'true',
    },
    name: '蓄熱式暖房',
  },
  '1629': {
    '32': {
      suumo: 'true',
    },
    name: '温水ルームヒーター',
  },
  '1630': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'ビルトインエアコン',
  },
  '1631': {
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '灯油ボイラー',
  },
  '1632': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '一部フローリング',
  },
  '1633': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
    },
    name: 'フローリング張替',
  },
  '1634': {
    '32': {
      suumo: 'true',
    },
    name: '壁面ベッド',
  },
  '1635': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '17': {},
    '32': {
      suumo: 'key',
      athome: 'true',
    },
    name: 'シューズWIC',
  },
  '1637': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: 'ネット専用回線',
  },
  '1638': {
    '32': {
      suumo: 'true',
    },
    name: 'ファンコンベクタ',
  },
  '1639': {
    '32': {
      suumo: 'true',
    },
    name: 'カーテン付',
  },
  '1640': {
    '32': {
      suumo: 'true',
    },
    name: 'ブラインド付',
  },
  '1641': {
    '32': {
      suumo: 'true',
    },
    name: 'ロールスクリーン付',
  },
  '1642': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: '家電付',
  },
  '1644': {
    '32': {
      suumo: 'true',
    },
    name: '全室照明付',
  },
  '1645': {
    '32': {},
    name: '敷地内ゴミ置場',
  },
  '1646': {
    name: '食洗機',
  },
  '1647': {
    '32': {
      suumo: 'true',
    },
    name: 'オーブンレンジ',
  },
  '1648': {
    '32': {
      suumo: 'true',
    },
    name: '防音サッシ',
  },
  '1649': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {},
    '17': {
      suumo: 'true',
    },
    name: '私設水道',
  },
  '1650': {
    '11': {
      suumo: 'true',
      athome: 'true',
    },
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '13': {},
    '17': {
      suumo: 'true',
    },
    '32': {},
    name: '本下水',
  },
  '1651': {
    '11': {
      suumo: 'true',
      athome: 'true',
    },
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '13': {},
    '17': {
      suumo: 'true',
    },
    '32': {},
    name: '集中浄化槽',
  },
  '1652': {
    '11': {
      suumo: 'true',
      athome: 'true',
    },
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '13': {},
    '17': {
      suumo: 'true',
    },
    '32': {},
    name: '個別浄化槽',
  },
  '1653': {
    '11': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {},
    name: '集中LPG',
  },
  '1654': {
    '11': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {},
    name: '個別LPG',
  },
  '1655': {
    name: 'レンタルビデオ',
  },
  '1656': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: 'バス無',
  },
  '1657': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: 'トイレ無',
  },
  '1658': {
    '13': {
      suumo: 'true',
    },
    name: '施設内貸会議室',
  },
  '1659': {
    name: '荷積スペース',
  },
  '1660': {
    name: 'ドックレベラー',
  },
  '1661': {
    name: '無停電電源装置',
  },
  '1662': {
    '13': {
      suumo: 'true',
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: '共用パーティールーム',
  },
  '1663': {
    '13': {
      suumo: 'true',
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: 'フィットネス施設',
  },
  '1664': {
    '13': {
      suumo: 'key',
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: '託児所',
  },
  '1665': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '非接触型ICカードキー',
  },
  '1666': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '省エネ給湯器',
  },
  '1667': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: 'EV車充電設備',
  },
  '1668': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'true',
      athome: 'true',
    },
    '17': {},
    '32': {
      homes: 'true',
      athome: 'key',
    },
    name: '浴室暖房',
  },
  '1669': {
    '32': {},
    name: 'ベランダ',
  },
  '1670': {
    '12': {
      homes: 'key',
    },
    '13': {
      homes: 'true',
    },
    '17': {},
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'ガスコンロ付',
  },
  '1671': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      athome: 'true',
    },
    name: 'シャワールーム',
  },
  '1672': {
    '13': {
      homes: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: 'フローリング風フロアタイル',
  },
  '1673': {
    '32': {
      suumo: 'true',
    },
    name: 'ダイニングテーブル付',
  },
  '1674': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'アルコーブ',
  },
  '1675': {
    '32': {
      suumo: 'true',
    },
    name: 'ドライエリア',
  },
  '1676': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '屋上',
  },
  '1677': {
    '12': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '縁側',
  },
  '1678': {
    '32': {
      suumo: 'true',
    },
    name: '離',
  },
  '1679': {
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '畑',
  },
  '1680': {
    '32': {
      suumo: 'true',
    },
    name: '倉庫',
  },
  '1681': {
    name: '蔵',
  },
  '1682': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '融雪機',
  },
  '1683': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '融雪槽',
  },
  '1684': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '家庭菜園',
  },
  '1685': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '温泉付',
  },
  '1686': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '露天風呂',
  },
  '1687': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '共用露天風呂',
  },
  '1688': {
    '13': {
      suumo: 'true',
    },
    name: '共用施設充実',
  },
  '1689': {
    '11': {
      suumo: 'true',
    },
    name: '温泉権利付',
  },
  '1690': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '温泉引込可',
  },
  '1691': {
    name: '大型トラック搬入可',
  },
  '1692': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: 'ウッドデッキ',
  },
  '1693': {
    '32': {
      athome: 'true',
    },
    name: '都市ガス給湯',
  },
  '1694': {
    '32': {
      athome: 'true',
    },
    name: 'プロパンガス給湯',
  },
  '1695': {
    '32': {
      athome: 'true',
    },
    name: '灯油給湯',
  },
  '1696': {
    '13': {
      athome: 'true',
    },
    name: '屋上庭園',
  },
  '1697': {
    name: '多機能トイレ',
  },
  '1698': {
    name: '人荷用エレベータ',
  },
  '1699': {
    name: '施設内喫煙所',
  },
  '1700': {
    '32': {
      athome: 'true',
    },
    name: '共同シャワールーム',
  },
  '1701': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {},
    name: '全居室複層ガラス',
  },
  '1702': {
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: '灯油',
  },
  '1703': {
    name: '集中空調',
  },
  '1704': {
    '32': {
      suumo: 'key',
    },
    name: '高速インターネット',
  },
  '1710': {
    '11': {},
    '12': {},
    '13': {},
    name: '本下水接続可',
  },
  '1711': {
    '11': {},
    '12': {},
    '13': {},
    name: '吸込',
  },
  '1712': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '節水型トイレ',
  },
  '1713': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: 'バルコニー・屋上に水栓あり',
  },
  '1714': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '10㎡以上の庭',
  },
  '1715': {
    '12': {
      homes: 'true',
    },
    name: '家庭用燃料電池',
  },
  '2001': {
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'ペット相談',
  },
  '2002': {
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '楽器相談',
  },
  '2003': {
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'key',
    },
    name: '女性限定',
  },
  '2004': {
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '法人契約限定',
  },
  '2005': {
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '法人契約希望',
  },
  '2006': {
    '32': {
      athome: 'true',
    },
    name: '非喫煙者限定',
  },
  '2007': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'ロードヒーター',
  },
  '2008': {
    '11': {
      athome: 'key',
    },
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'key',
      athome: 'true',
    },
    name: 'IT重説',
  },
  '2009': {
    '32': {},
    name: '楽器可',
  },
  '2010': {
    '12': {
      suumo: 'key',
      athome: 'key',
    },
    '13': {
      suumo: 'key',
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: 'バス1坪以上',
  },
  '2011': {
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'key',
    },
    name: '二人入居可',
  },
  '2013': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'key',
    },
    name: '閑静な住宅街',
  },
  '2014': {
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '分譲タイプ',
  },
  '2015': {
    '13': {
      homes: 'true',
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    name: '外観タイル張',
  },
  '2016': {
    '32': {
      suumo: 'key',
    },
    name: 'フリーレント',
  },
  '2017': {
    '13': {
      homes: 'true',
      athome: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '事務所使用可',
  },
  '2018': {
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: '管理人常駐',
  },
  '2019': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      athome: 'true',
    },
    name: '管理人日勤',
  },
  '2020': {
    '13': {
      athome: 'true',
    },
    '32': {
      athome: 'true',
    },
    name: '管理人巡回',
  },
  '2023': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      homes: 'key',
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'バリアフリー',
  },
  '2024': {
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'メゾネット',
  },
  '2025': {
    '32': {},
    name: '飲食店可',
  },
  '2026': {
    '32': {},
    name: '飲食店不可',
  },
  '2027': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    name: '二世帯向',
  },
  '2028': {
    '32': {},
    name: 'リゾート向',
  },
  '2029': {
    '11': {
      athome: 'true',
    },
    name: '分割可',
  },
  '2030': {
    '13': {
      suumo: 'key',
      athome: 'key',
    },
    '32': {
      suumo: 'key',
      athome: 'key',
    },
    name: '最上階',
  },
  '2031': {
    '32': {
      athome: 'key',
    },
    name: '高齢者相談',
  },
  '2032': {
    name: '土曜日利用可',
  },
  '2033': {
    name: '土日・祝日利用可',
  },
  '2034': {
    name: '耐震構造（新耐震基準）',
  },
  '2050': {
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '男性限定',
  },
  '2051': {
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '学生限定',
  },
  '2054': {
    '11': {
      athome: 'true',
    },
    '12': {
      athome: 'true',
    },
    name: 'セットバック要',
  },
  '2055': {
    '11': {
      athome: 'true',
    },
    '12': {
      athome: 'true',
    },
    name: 'セットバック済',
  },
  '2056': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    name: '24時間換気システム',
  },
  '2057': {
    '12': {
      suumo: 'key',
      athome: 'key',
    },
    '13': {
      suumo: 'key',
      athome: 'key',
    },
    '17': {},
    '32': {
      athome: 'key',
    },
    name: '24時間セキュリティ',
  },
  '2058': {
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '振分',
  },
  '2059': {
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: '角部屋',
  },
  '2400': {
    '32': {
      homes: 'true',
    },
    name: '学生希望',
  },
  '2401': {
    '32': {
      suumo: 'true',
    },
    name: '単身者可',
  },
  '2402': {
    '32': {
      homes: 'true',
    },
    name: '単身者希望',
  },
  '2403': {
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: '単身者限定',
  },
  '2404': {
    '32': {
      homes: 'true',
    },
    name: '二人入居不可',
  },
  '2405': {
    name: '子供可',
  },
  '2406': {
    name: '子供不可',
  },
  '2407': {
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: 'ルームシェア可',
  },
  '2408': {
    '32': {
      homes: 'true',
    },
    name: 'ルームシェア不可',
  },
  '2409': {
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '高齢者歓迎',
  },
  '2410': {
    '32': {
      homes: 'true',
    },
    name: '高齢者限定',
  },
  '2411': {
    '32': {},
    name: '高齢者不可',
  },
  '2413': {
    name: 'ピアノ可',
  },
  '2414': {
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '事務所不可',
  },
  '2415': {
    '32': {
      suumo: 'true',
    },
    name: '1フロア1住戸',
  },
  '2416': {
    '32': {
      suumo: 'true',
    },
    name: '1フロア2住戸',
  },
  '2417': {
    '13': {
      suumo: 'key',
      homes: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '南東角部屋',
  },
  '2418': {
    '13': {
      suumo: 'key',
      homes: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '南西角部屋',
  },
  '2419': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '2面採光',
  },
  '2420': {
    '12': {
      suumo: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '3面採光',
  },
  '2421': {
    '32': {
      suumo: 'key',
    },
    name: '南面3室',
  },
  '2422': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
    },
    name: '全室南東向',
  },
  '2423': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
    },
    name: '全室南向',
  },
  '2427': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '32': {},
    name: 'セキュリティシステム',
  },
  '2428': {
    '12': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '24工法',
  },
  '2429': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '17': {},
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'true',
    },
    name: '免震構造',
  },
  '2430': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '外装コンクリート打',
  },
  '2431': {
    '12': {
      athome: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'デザイナーズ物件',
  },
  '2432': {
    name: '管理人非常勤',
  },
  '2433': {
    name: 'オーナー住居同居',
  },
  '2434': {
    name: 'オーナー住居近隣',
  },
  '2435': {
    name: 'オーナー住居その他',
  },
  '2436': {
    '32': {
      suumo: 'key',
    },
    name: '高層タワー物件',
  },
  '2437': {
    name: '大規模物件',
  },
  '2438': {
    '13': {
      homes: 'key',
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      homes: 'key',
      athome: 'true',
    },
    name: 'フロントサービス',
  },
  '2439': {
    '32': {},
    name: '屋内駐車場',
  },
  '2440': {
    name: '屋外駐車場',
  },
  '2443': {
    name: '地上駐車場',
  },
  '2444': {
    '32': {
      suumo: 'key',
    },
    name: '地下駐車場',
  },
  '2447': {
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
    },
    name: '駐車場リモコン付シャッター',
  },
  '2448': {
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
    },
    name: '駐車場シャッター',
  },
  '2450': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '花火が見える',
  },
  '2451': {
    '11': {},
    '12': {},
    '13': {},
    '17': {},
    name: '公園そば',
  },
  '2453': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '海が見える',
  },
  '2454': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '海に近い',
  },
  '2455': {
    name: '訳あって割安',
  },
  '2456': {
    name: 'お勧め物件',
  },
  '2457': {
    name: '高額物件',
  },
  '2458': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '始発駅',
  },
  '2459': {
    '32': {
      suumo: 'true',
    },
    name: '2駅利用可',
  },
  '2460': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '2沿線利用可',
  },
  '2461': {
    '32': {
      suumo: 'true',
    },
    name: '3駅以上利用可',
  },
  '2462': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '3沿線以上利用可',
  },
  '2463': {
    '32': {
      suumo: 'true',
    },
    name: '路面電車沿線',
  },
  '2464': {
    '32': {
      suumo: 'true',
    },
    name: 'バス2路線',
  },
  '2465': {
    '32': {
      suumo: 'true',
    },
    name: '100円バス路線',
  },
  '2466': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '駅まで平坦',
  },
  '2467': {
    '32': {
      suumo: 'true',
    },
    name: '駅前',
  },
  '2468': {
    '32': {
      suumo: 'true',
    },
    name: '雛段に立地',
  },
  '2469': {
    '32': {
      suumo: 'true',
    },
    name: '南雛段に立地',
  },
  '2470': {
    '11': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: '角地',
  },
  '2471': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '高台に立地',
  },
  '2472': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '平坦地',
  },
  '2473': {
    '11': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: '整形地',
  },
  '2477': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '前面棟無',
  },
  '2478': {
    '32': {
      suumo: 'true',
    },
    name: '電線埋設',
  },
  '2479': {
    '32': {
      suumo: 'true',
    },
    name: '1種低層',
  },
  '2480': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '田園風景',
  },
  '2481': {
    '11': {
      suumo: 'true',
      athome: 'key',
    },
    '12': {
      suumo: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '区画整理地内',
  },
  '2482': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '大型タウン内',
  },
  '2483': {
    '32': {
      suumo: 'true',
    },
    name: '風致地区',
  },
  '2484': {
    '32': {
      suumo: 'true',
    },
    name: '文教地区',
  },
  '2485': {
    '32': {
      suumo: 'true',
    },
    name: '防犯強化地域',
  },
  '2486': {
    '32': {
      suumo: 'true',
    },
    name: 'バス停徒歩3分以内',
  },
  '2487': {
    '32': {
      suumo: 'true',
    },
    name: '駅徒歩10分以内',
  },
  '2488': {
    '32': {
      suumo: 'true',
    },
    name: '駅徒歩5分以内',
  },
  '2489': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '17': {},
    '32': {
      suumo: 'true',
      homes: 'true',
    },
    name: '耐震構造',
  },
  '2490': {
    '12': {
      homes: 'true',
      athome: 'true',
    },
    '13': {
      homes: 'key',
      athome: 'true',
    },
    '17': {},
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '制震構造',
  },
  '2491': {
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '100年耐用コンクリート',
  },
  '2492': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: '長期優良住宅',
  },
  '2493': {
    '32': {
      suumo: 'true',
    },
    name: '耐震補強工事済',
  },
  '2494': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '建設住宅性能評価付',
  },
  '2495': {
    '32': {
      suumo: 'true',
    },
    name: '耐火構造',
  },
  '2496': {
    '32': {
      suumo: 'true',
    },
    name: '準耐火構造',
  },
  '2497': {
    '32': {
      suumo: 'true',
    },
    name: '簡易耐火構造',
  },
  '2498': {
    '12': {
      suumo: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '高気密高断熱住宅',
  },
  '2499': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '高気密住宅',
  },
  '2500': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '高断熱住宅',
  },
  '2501': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '設計住宅性能評価付',
  },
  '2502': {
    '32': {
      suumo: 'true',
    },
    name: '耐風構造',
  },
  '2503': {
    '32': {
      suumo: 'true',
    },
    name: '通気断熱WB工法',
  },
  '2504': {
    name: '北方型住宅',
  },
  '2505': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'アウトポール工法',
  },
  '2506': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'ノンホルムアルデヒド',
  },
  '2507': {
    '32': {
      suumo: 'true',
    },
    name: 'ホルムアルデヒド対策',
  },
  '2508': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '逆梁工法',
  },
  '2509': {
    '32': {
      suumo: 'true',
    },
    name: '珪藻土塗壁',
  },
  '2510': {
    '32': {
      suumo: 'true',
    },
    name: '更新対策',
  },
  '2511': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '省エネルギー対策',
  },
  '2512': {
    '12': {
      suumo: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      athome: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '天井高3m以上',
  },
  '2513': {
    '32': {
      suumo: 'true',
    },
    name: '無落雪建築',
  },
  '2514': {
    '32': {
      suumo: 'true',
    },
    name: '劣化対策',
  },
  '2515': {
    '32': {
      suumo: 'true',
    },
    name: '1階高床式',
  },
  '2516': {
    '32': {
      suumo: 'true',
    },
    name: 'メーターモジュール',
  },
  '2517': {
    '32': {
      suumo: 'true',
    },
    name: '間口10m以上',
  },
  '2518': {
    '32': {
      suumo: 'true',
    },
    name: '間口8m以上',
  },
  '2519': {
    '32': {
      suumo: 'true',
    },
    name: '折上天井',
  },
  '2520': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '天井高2.5m以上',
  },
  '2521': {
    '12': {
      suumo: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '天井高2.7m以上',
  },
  '2523': {
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '二重天井構造',
  },
  '2524': {
    '12': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '26工法',
  },
  '2525': {
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: 'ログハウス',
  },
  '2526': {
    name: '書院造',
  },
  '2527': {
    name: '数奇屋造',
  },
  '2528': {
    '32': {
      suumo: 'true',
    },
    name: '町屋',
  },
  '2529': {
    '12': {},
    '13': {},
    '32': {
      suumo: 'true',
    },
    name: '平屋',
  },
  '2530': {
    name: '輸入住宅',
  },
  '2531': {
    '32': {
      suumo: 'true',
    },
    name: 'オープン外構',
  },
  '2533': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '外壁サイディング',
  },
  '2534': {
    '32': {
      suumo: 'true',
    },
    name: '光触媒塗装',
  },
  '2535': {
    '32': {
      suumo: 'true',
    },
    name: 'ベタ基礎',
  },
  '2536': {
    '11': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '地盤調査済',
  },
  '2537': {
    name: '入母屋造',
  },
  '2538': {
    '32': {
      suumo: 'true',
    },
    name: 'スキップフロア',
  },
  '2539': {
    '13': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '高層階',
  },
  '2540': {
    '13': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '上階無',
  },
  '2542': {
    '13': {},
    '32': {
      suumo: 'true',
    },
    name: 'スロップシンク',
  },
  '2546': {
    '32': {
      suumo: 'key',
    },
    name: '事務所付住宅',
  },
  '2547': {
    '32': {
      suumo: 'true',
    },
    name: '店舗付住宅',
  },
  '2561': {
    '32': {
      suumo: 'true',
    },
    name: '専有面積30坪以上',
  },
  '2562': {
    '32': {
      suumo: 'true',
    },
    name: '専有面積25坪以上',
  },
  '2564': {
    '32': {
      suumo: 'true',
    },
    name: '築2年以内',
  },
  '2565': {
    '32': {
      suumo: 'true',
    },
    name: '築3年以内',
  },
  '2566': {
    '32': {
      suumo: 'true',
    },
    name: '築5年以内',
  },
  '2571': {
    '32': {
      suumo: 'key',
    },
    name: '大型車可駐車場',
  },
  '2573': {
    '13': {
      athome: 'true',
    },
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: '来客用パーキング',
  },
  '2574': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '前道6m以上',
  },
  '2577': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '南側道路面す',
  },
  '2578': {
    name: '東南側道路面す',
  },
  '2579': {
    name: '南西側道路面す',
  },
  '2580': {
    '12': {},
    name: '両面道路',
  },
  '2581': {
    name: '前面道路との高低差無',
  },
  '2582': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
    },
    name: '南向',
  },
  '2583': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
    },
    name: '全室南西向',
  },
  '2585': {
    '13': {
      suumo: 'key',
      homes: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '3方角住戸',
  },
  '2587': {
    '32': {
      suumo: 'key',
    },
    name: '南面2室',
  },
  '2588': {
    '32': {
      suumo: 'key',
    },
    name: '南面4室',
  },
  '2589': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    name: '全室2面採光',
  },
  '2590': {
    '32': {
      suumo: 'key',
    },
    name: '南面リビング',
  },
  '2591': {
    '32': {
      suumo: 'true',
    },
    name: 'ライトコート',
  },
  '2592': {
    '32': {
      suumo: 'true',
    },
    name: '防犯モデルマンション',
  },
  '2593': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
    },
    name: 'リノベーション',
  },
  '2594': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '内装リフォーム済',
  },
  '2595': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '外装リフォーム済',
  },
  '2596': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '内外装リフォーム済',
  },
  '2597': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '内装リフォーム後渡',
  },
  '2598': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '外装リフォーム後渡',
  },
  '2599': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'true',
    },
    name: '内外装リフォーム後渡',
  },
  '2600': {
    '32': {
      suumo: 'true',
    },
    name: '畳張替済',
  },
  '2601': {
    '32': {
      suumo: 'true',
    },
    name: '壁紙貼替済',
  },
  '2602': {
    '11': {
      suumo: 'key',
    },
    name: '即引渡可',
  },
  '2603': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      suumo: 'key',
    },
    name: '即入居可',
  },
  '2604': {
    '11': {
      suumo: 'true',
    },
    name: '建物プラン例有',
  },
  '2605': {
    '11': {
      suumo: 'true',
      homes: 'true',
    },
    name: '建築条件無',
  },
  '2606': {
    '11': {
      suumo: 'true',
    },
    name: '更地渡',
  },
  '2607': {
    '13': {
      suumo: 'true',
    },
    name: 'スケルトン渡',
  },
  '2608': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '年度内入居可',
  },
  '2609': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '年内入居可',
  },
  '2610': {
    '12': {},
    name: '注文住宅メーカー施工',
  },
  '2611': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '眺望良好',
  },
  '2612': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '通風良好',
  },
  '2613': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '陽当り良好',
  },
  '2614': {
    name: 'オーナーチェンジ',
  },
  '2615': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    name: '現在満室',
  },
  '2616': {
    '11': {
      suumo: 'true',
    },
    name: '集合住宅用地',
  },
  '2617': {
    name: '居抜き物件',
  },
  '2618': {
    name: '建築条件付',
  },
  '2619': {
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    name: '保証人不要',
  },
  '2701': {
    '32': {
      suumo: 'true',
    },
    name: 'OAフロア',
  },
  '2702': {
    name: '24時間利用可',
  },
  '2703': {
    '32': {
      athome: 'true',
    },
    name: 'SOHO向き',
  },
  '2704': {
    name: '住宅性能保証付',
  },
  '2705': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    name: '再建築不可',
  },
  '2706': {
    '11': {
      athome: 'true',
    },
    name: '建築不可',
  },
  '2710': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: 'リバーサイド',
  },
  '2711': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'true',
    },
    name: '緑豊かな住宅地',
  },
  '2712': {
    '12': {},
    '13': {},
    '32': {
      suumo: 'key',
    },
    name: '駐車場2台無料',
  },
  '2715': {
    '13': {
      suumo: 'key',
      homes: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'true',
    },
    name: '平面駐車場',
  },
  '2716': {
    '32': {
      suumo: 'key',
    },
    name: '駐車場1台無料',
  },
  '2718': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      suumo: 'key',
    },
    name: '24時間有人管理',
  },
  '2719': {
    '32': {
      suumo: 'true',
    },
    name: '当社管理物件',
  },
  '2720': {
    '32': {
      suumo: 'true',
    },
    name: '学生相談',
  },
  '2721': {
    '32': {
      suumo: 'true',
    },
    name: '学生敷金不要',
  },
  '2722': {
    '32': {
      suumo: 'true',
    },
    name: '学生敷金1ヶ月',
  },
  '2723': {
    '32': {
      suumo: 'true',
    },
    name: '学生敷金2ヶ月',
  },
  '2724': {
    '17': {},
    '32': {
      suumo: 'true',
    },
    name: '仲介手数料不要',
  },
  '2725': {
    '32': {
      suumo: 'true',
    },
    name: '仲介手数料半月分',
  },
  '2726': {
    '32': {
      suumo: 'true',
    },
    name: '初期費用5万円以下',
  },
  '2727': {
    '32': {
      suumo: 'true',
    },
    name: '初期費用10万円以下',
  },
  '2728': {
    '32': {
      suumo: 'true',
    },
    name: '初期費用15万円以下',
  },
  '2729': {
    '32': {
      suumo: 'true',
    },
    name: '初期費用20万円以下',
  },
  '2730': {
    '32': {
      suumo: 'true',
    },
    name: '初期費用30万円以下',
  },
  '2731': {
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '特定優良賃貸住宅',
  },
  '2732': {
    '13': {},
    '32': {
      suumo: 'true',
    },
    name: '24時間緊急通報システム',
  },
  '2733': {
    '32': {},
    name: '外国人可',
  },
  '2734': {
    '32': {},
    name: '外国人不可',
  },
  '2735': {
    name: '水商売可',
  },
  '2736': {
    name: '水商売不可',
  },
  '2737': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '避暑地',
  },
  '2738': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '都市近郊',
  },
  '2739': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '開発分譲地内',
  },
  '2740': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '市街地が近い',
  },
  '2745': {
    '12': {
      suumo: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'true',
    },
    name: 'フラット35Sに対応',
  },
  '2746': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '湖/池が見える',
  },
  '2747': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '山が見える',
  },
  '2748': {
    '12': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: '2階建',
  },
  '2749': {
    '12': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: '3階建以上',
  },
  '2750': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '周辺交通量少なめ',
  },
  '2751': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '整備された歩道',
  },
  '2752': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: 'ゴルフ場が近い',
  },
  '2753': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: 'スキー場が近い',
  },
  '2754': {
    '11': {
      suumo: 'true',
    },
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: 'テニスコートが近い',
  },
  '2755': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: 'スーパーが近い',
  },
  '2756': {
    name: 'インスペクション済',
  },
  '2759': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {},
    name: 'セキュリティ充実',
  },
  '2760': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: '適合リノベーション',
  },
  '2761': {
    '12': {
      suumo: 'true',
      homes: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '高機能トイレ',
  },
  '2763': {
    '12': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: '古民家風',
  },
  '2766': {
    '12': {
      suumo: 'true',
    },
    '13': {
      suumo: 'true',
    },
    '17': {
      suumo: 'true',
    },
    name: 'エコポイント対象住宅',
  },
  '2767': {
    '13': {
      suumo: 'key',
      homes: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: '四方角部屋',
  },
  '2768': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
    },
    '17': {},
    '32': {
      suumo: 'key',
      homes: 'key',
    },
    name: 'セキュリティ会社加入済',
  },
  '2779': {
    name: 'SUUMO以外の災害時住宅支援サイトへの掲載',
  },
  '2780': {
    '32': {
      homes: 'true',
    },
    name: '楽器不可',
  },
  '2781': {
    '32': {
      homes: 'true',
    },
    name: '単身者不可',
  },
  '2782': {
    '32': {
      homes: 'true',
    },
    name: '法人不可',
  },
  '2783': {
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '32': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    name: 'ペット対応',
  },
  '2784': {
    '13': {
      homes: 'key',
    },
    '32': {
      homes: 'true',
    },
    name: 'ペット不可',
  },
  '2786': {
    '11': {
      homes: 'true',
    },
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    name: '手付金保証有',
  },
  '2787': {
    '32': {
      homes: 'true',
    },
    name: '保証人要',
  },
  '2788': {
    '32': {
      homes: 'true',
    },
    name: '家賃保証付',
  },
  '2789': {
    '32': {
      homes: 'key',
    },
    name: 'マンスリー可',
  },
  '2790': {
    name: 'フリーアクセス有',
  },
  '2791': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    name: '有人警備',
  },
  '2792': {
    '13': {
      athome: 'key',
    },
    '32': {
      athome: 'true',
    },
    name: '高強度コンクリート',
  },
  '2793': {
    name: 'システム天井',
  },
  '2794': {
    name: '整形無柱空間',
  },
  '2795': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: '建築確認完了検査済証',
  },
  '2796': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      athome: 'true',
    },
    name: '耐震基準適合証明書有',
  },
  '2797': {
    '13': {
      athome: 'key',
    },
    '32': {
      athome: 'true',
    },
    name: 'シャトルバス',
  },
  '2798': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '32': {
      athome: 'true',
    },
    name: 'スケルトンインフィル',
  },
  '2799': {
    '12': {
      athome: 'true',
    },
    '17': {},
    '32': {
      athome: 'true',
    },
    name: 'スマートハウス',
  },
  '2800': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    '32': {
      athome: 'true',
    },
    name: '隣接建物距離2m以上',
  },
  '2801': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'key',
    },
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: 'フラット35・S適合証明書',
  },
  '2802': {
    '13': {},
    '32': {},
    name: 'コンシェルジュサービス',
  },
  '2803': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: '法適合状況調査報告書',
  },
  '2804': {
    '13': {},
    name: '階下に住戸無',
  },
  '2805': {
    '12': {},
    '17': {},
    name: '間取変更可能',
  },
  '2806': {
    '32': {
      suumo: 'true',
      athome: 'true',
    },
    name: 'シェアハウス',
  },
  '2807': {
    '32': {
      suumo: 'key',
    },
    name: 'カスタマイズ可',
  },
  '2808': {
    '32': {
      suumo: 'key',
      athome: 'key',
    },
    name: 'DIY可',
  },
  '2809': {
    '32': {
      suumo: 'key',
      homes: 'key',
      athome: 'key',
    },
    name: 'インターネット使用料不要',
  },
  '2810': {
    '32': {
      suumo: 'key',
      homes: 'true',
    },
    name: 'CATV使用料不要',
  },
  '2811': {
    name: '舗装済駐車場',
  },
  '2812': {
    name: '舗装無駐車場',
  },
  '2813': {
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    name: '小型犬可',
  },
  '2814': {
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    name: '大型犬可',
  },
  '2815': {
    '13': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '32': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    name: '猫可',
  },
  '2816': {
    '12': {
      suumo: 'key',
      homes: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: '低炭素住宅',
  },
  '2818': {
    '12': {
      athome: 'key',
    },
    '13': {
      athome: 'key',
    },
    name: '瑕疵保険検査基準適合',
  },
  '2820': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: '建築士建物検査報告書',
  },
  '2821': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: '新築・増改築設計図書',
  },
  '2822': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: '修繕・点検記録',
  },
  '2823': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '空き家バンク登録物件',
  },
  '2825': {
    name: '省エネラベル',
  },
  '2826': {
    '12': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'true',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'true',
    },
    '32': {
      homes: 'true',
      athome: 'true',
    },
    name: 'BELS/省エネ基準適合',
  },
  '2827': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: '瑕疵保険(国交省指定)保証利用可',
  },
  '2828': {
    '12': {
      suumo: 'key',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: '瑕疵保険(国交省指定)付',
  },
  '2829': {
    '12': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '13': {
      suumo: 'key',
      homes: 'true',
      athome: 'true',
    },
    '17': {
      suumo: 'key',
    },
    name: '瑕疵保証(不動産会社独自)有',
  },
  '2830': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    name: '設備保証利用可',
  },
  '2831': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    name: '設備保証付',
  },
  '2832': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: '総合病院徒歩10分以内',
  },
  '2833': {
    '11': {
      suumo: 'key',
    },
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: '小学校徒歩10分以内',
  },
  '2834': {
    name: '障がい者等用駐車場',
  },
  '2835': {
    '12': {
      athome: 'true',
    },
    '13': {
      athome: 'true',
    },
    name: '安心Ｒ住宅',
  },
  '2836': {
    '17': {
      suumo: 'key',
    },
    name: '駐車2台可能',
  },
  '2837': {
    '17': {
      suumo: 'key',
    },
    name: '駐車3台以上可能',
  },
  '2838': {
    '12': {
      suumo: 'key',
    },
    '13': {
      suumo: 'key',
    },
    '17': {
      suumo: 'key',
    },
    name: 'ハイルーフ駐車可能',
  },
  '2839': {
    name: '新築・築浅',
  },
  '2840': {
    name: 'ランドマーク',
  },
  '2841': {
    name: '大通り沿い',
  },
  '2842': {
    name: '分割可能',
  },
  '2843': {
    name: 'アンティーク仕様',
  },
  '2844': {
    '32': {
      suumo: 'key',
    },
    name: 'LGBTフレンドリー',
  },
  '2846': {
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: '自走式立体駐車場',
  },
  '2847': {
    '12': {
      homes: 'true',
    },
    '13': {
      homes: 'true',
    },
    '32': {
      homes: 'true',
    },
    name: 'ガレージ',
  },
} as const satisfies Record<string, SetsubiTokki>
