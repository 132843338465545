import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const TatoKokokuContents = ({ children }: PropsWithChildren) => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })
  const display = bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
  return <>{display && children}</>
}
