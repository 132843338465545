/**
 * 用途地域区分
 */
export const Enum = {
  近隣商業: '4',
  商業: '5',
  準工業: '6',
  工業: '7',
  工専: '8',
  指定無: '9',
  '1種低層': '11',
  '2種低層': '12',
  '1種中高': '13',
  '2種中高': '14',
  '1種住居': '15',
  '2種住居': '16',
  準住居: '17',
  田園住居: '18',
} as const
