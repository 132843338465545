import { Checkbox, Tooltip } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { ChangeEventHandler } from 'react'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  PortalErrorMessages,
} from '@/components/form/controls'
import { useIsEdit } from '@/hooks/useIsEdit'
import {
  useMiraieFormController,
  useWatchMiraieFields,
} from '@/hooks/useMiraieForm'
import { useOperator } from '@/hooks/useOperator'
import { useUserRole } from '@/hooks/useUser'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'
import { officeToShop } from '@/utils/officeToShop'

export const PortalStaff = () => {
  const operator = useOperator()
  const shopId = useV1ImportDocument(({ shopId }) => shopId)
  const isSameShop = officeToShop(operator.office) === shopId
  const {
    field: { value, ...field },
  } = useMiraieFormController({
    name: 'portal_staff',
  })
  const role = useUserRole()
  const isChecked = value != null
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    field.onChange(
      e.target.checked ? { name: operator.id, kana: operator.id } : null,
    )
  const isEdit = useIsEdit()
  const isDisabled = !isEdit || !isSameShop

  return (
    <FormGroup>
      <FormGroupHeading>ポータル物件担当</FormGroupHeading>
      <FormControl isDisabled={isDisabled}>
        <Checkbox {...{ ...field, onChange, isChecked }}>
          担当者設定を行う
        </Checkbox>
        {!isSameShop && (
          <FormHelperText>
            エージェントの所属拠点と掲載先の店舗が異なるため、担当者設定を行うことができません。
          </FormHelperText>
        )}
      </FormControl>
      <MiraieFieldContents
        name={['portal_staff']}
        display={(portal_staff) => portal_staff != null}
      >
        <FormControl fieldset>
          <FormLabel>担当者設定</FormLabel>
          <FormHelperText mb={4}>
            掲載設定になっているポータルのみ、担当者設定を行うことができます。
            <br />
            チェックを入れた場合、
            {role === 'assistant'
              ? '代理操作しているエージェントが自動で担当者に設定されます。'
              : '自身が自動で担当者に設定されます。'}
            <br />
            担当者設定を行うには、あらかじめ各ポータルサイト上で、担当者登録を行っておく必要があります。
            <br />
            担当者設定に失敗した場合でも、Terass
            Converter上ではフィードバックできません。
          </FormHelperText>
          <CheckboxGroup>
            <AthomeStaffCheckbox />
            <SuumoStaffCheckbox />
            <HomesStaffCheckbox />
          </CheckboxGroup>
          <PortalErrorMessages fieldName="portal_staff" />
        </FormControl>
      </MiraieFieldContents>
    </FormGroup>
  )
}

const AthomeStaffCheckbox = () => {
  const operator = useOperator()
  const [athome_c_open_kbn] = useWatchMiraieFields({
    name: ['athome_c_open_kbn'],
  })
  const {
    field: { value, ...field },
  } = useMiraieFormController({
    name: 'portal_staff.athome_pro_staff_cd',
  })
  const isChecked = Boolean(value)
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    field.onChange(e.target.checked ? operator.athomeStaffId : null)
  const isEdit = useIsEdit()
  const hasAthomeId = operator.athomeStaffId !== null
  const isDisabled =
    !isEdit ||
    !hasAthomeId ||
    athome_c_open_kbn !== miraieFields.c_open_kbn.Enum.掲載

  return (
    <FormControl isDisabled={isDisabled}>
      <Checkbox {...{ ...field, isChecked, onChange }}>
        {/* FIXME: Tooltipの中にCheckboxを入れたいが、ツールチップが消えなくなるバグが発生する */}
        <Tooltip
          isDisabled={hasAthomeId}
          label="athomeの担当者設定を行うには、Terass Cloudにエージェントのathome担当者IDを設定する必要があります。"
        >
          athome
        </Tooltip>
      </Checkbox>
    </FormControl>
  )
}

const SuumoStaffCheckbox = () => {
  const operator = useOperator()
  const [suumo_c_open_kbn] = useWatchMiraieFields({
    name: ['suumo_c_open_kbn'],
  })
  const {
    field: { value, ...field },
  } = useMiraieFormController({
    name: 'portal_staff.suumo_b_staff_cd',
  })
  const isChecked = Boolean(value)
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    field.onChange(e.target.checked ? operator.name : null)
  const isEdit = useIsEdit()
  const isDisabled =
    !isEdit || suumo_c_open_kbn !== miraieFields.c_open_kbn.Enum.掲載

  return (
    <FormControl isDisabled={isDisabled}>
      <Checkbox {...{ ...field, isChecked, onChange }}>SUUMO</Checkbox>
    </FormControl>
  )
}

const HomesStaffCheckbox = () => {
  const operator = useOperator()
  const [homes_c_open_kbn] = useWatchMiraieFields({
    name: ['homes_c_open_kbn'],
  })
  const {
    field: { value, ...field },
  } = useMiraieFormController({
    name: 'portal_staff.homes_staff_name',
  })
  const isChecked = Boolean(value)
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    field.onChange(e.target.checked ? operator.name : null)
  const isEdit = useIsEdit()
  const isDisabled =
    !isEdit || homes_c_open_kbn !== miraieFields.c_open_kbn.Enum.掲載

  return (
    <FormControl isDisabled={isDisabled}>
      <Checkbox {...{ ...field, isChecked, onChange }}>Homes</Checkbox>
    </FormControl>
  )
}
