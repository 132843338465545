import { callThis } from '../../../utils'
import { isDateBefore, isSameDate } from '../../../utils/date'

/**
 * 掲載終了日の検証
 * @param endDate 掲載終了日
 * @param today 今日
 * @param maxDate 掲載可能な最大日
 * @returns 日付を単位として `today` < `endDate` <= `maxDate` を満たすかどうか
 */
export function validateCloseDate(endDate: Date, today: Date, maxDate: Date) {
  return (
    callThis(today, isDateBefore, endDate) &&
    (callThis(endDate, isSameDate, maxDate) ||
      callThis(endDate, isDateBefore, maxDate))
  )
}
