import { Box, Card, CardBody } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { Chokaihi } from './Chokaihi'
import { Ichijikin } from './Ichijikin'
import { Internets } from './Internets'
import { Onsen } from './Onsen'
import { Other } from './Other'
import { Parkings } from './Parkings'
import { Price } from './Price'
import { Rent } from './Rent'
import { Running } from './Running'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionDescription } from '@/components/form/FormSectionDescription'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { sectionIds } from '@/components/form/SectionBar'

export const CostFormSection: React.FC = () => {
  return (
    <Box as="section" id={sectionIds.costFormSection}>
      <FormSectionHeading>価格・費用関連</FormSectionHeading>
      <FormSectionDescription>
        課税対象項目は、税込みで記入ください。
      </FormSectionDescription>
      <Card>
        <CardBody as={FormGroup}>
          {/* 賃料 */}
          <Rent />
          {/* 価格 */}
          <Price />
          {/* その他 */}
          <Other />
          {/* 一時金 */}
          <Ichijikin />
          {/* ランニングコスト */}
          <Running />
          {/* 町会費 */}
          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.居住用 &&
              bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
            }
          >
            <Chokaihi />
          </MiraieFieldContents>
          {/* インターネット・CATV・有線放送 */}
          <Internets />
          {/* 温泉 */}
          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.居住用 &&
              bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
            }
          >
            <Onsen />
          </MiraieFieldContents>
          {/* 駐車場・駐輪場・バイク置場 */}
          <Parkings />
        </CardBody>
      </Card>
    </Box>
  )
}
