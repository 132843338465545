import { useModalContentFormContext } from '@/components/form/Setsubi/ModalContentFormContext'
import { isCodeVisible } from '@/components/form/Setsubi/logic'
import { useWatchSetsubiTokkiForm } from '@/components/form/Setsubi/useSetsubiTokkiForm'

export const SetsubiGroupContents = ({
  children,
  codes,
}: {
  children: React.ReactNode
  codes: readonly string[]
}) => {
  const { bk_sbt_kbn } = useModalContentFormContext()
  const portalsFilter = useWatchSetsubiTokkiForm('portalsFilter')
  const display = codes.some((code) =>
    isCodeVisible({ code, bk_sbt_kbn, portalsFilter }),
  )

  return <>{display && children}</>
}
