import type {
  FetchMediaPublishedRequestFutureForRent,
  FetchMediationContract,
  FetchMediationContractById,
  FetchTeraThreeAgent,
  FetchTeraThreeAgents,
  GetServerTimestamp,
  PostShisetsu,
  MiraieValidate,
  PublishMediaCoverage,
  UpdateTeraThreeMediaCampaign,
} from '@terass/common/src/functions'
import { httpsCallable } from 'firebase/functions'

import { functions } from './firebase'

export const fetchMediationContract = () =>
  httpsCallable<never, Awaited<ReturnType<FetchMediationContract>>>(
    functions,
    'fetchMediationContractFunction',
  )().then(({ data }) => data)

export const fetchMediationContractById = (id: string) =>
  httpsCallable<
    Parameters<FetchMediationContractById>[0],
    Awaited<ReturnType<FetchMediationContractById>>
  >(
    functions,
    'fetchMediationContractByIdFunction',
  )(id).then(({ data }) => data)

export const fetchMediaPublishedRequestFutureForRent = () =>
  httpsCallable<
    never,
    Awaited<ReturnType<FetchMediaPublishedRequestFutureForRent>>
  >(functions, 'fetchMediaPublishedRequestFutureForRent')().then(
    ({ data }) => data,
  )

export const fetchTeraThreeAgents = () =>
  httpsCallable<never, Awaited<ReturnType<FetchTeraThreeAgents>>>(
    functions,
    'fetchTeraThreeAgents',
  )().then(({ data }) => data)

export const fetchAgent: FetchTeraThreeAgent = (params) =>
  httpsCallable<
    Parameters<FetchTeraThreeAgent>[0],
    Awaited<ReturnType<FetchTeraThreeAgent>>
  >(
    functions,
    'fetchTeraThreeAgent',
  )(params).then(({ data }) => data)

export const publishMediaCoverage: PublishMediaCoverage = (params) =>
  httpsCallable<
    Parameters<PublishMediaCoverage>[0],
    Awaited<ReturnType<PublishMediaCoverage>>
  >(
    functions,
    'publishMediaCoverage',
  )(params).then(({ data }) => data)

export const getServerTimestamp: GetServerTimestamp = () =>
  httpsCallable<never, number>(functions, 'getServerTimestamp')().then(
    ({ data }) => data,
  )

export const updateTeraThreeMediaCampaign: UpdateTeraThreeMediaCampaign = (
  params,
) =>
  httpsCallable<Parameters<UpdateTeraThreeMediaCampaign>[0], void>(
    functions,
    'updateTeraThreeMediaCampaign',
  )(params).then(({ data }) => data)

export const postShisetsu: PostShisetsu = (params) =>
  httpsCallable<Parameters<PostShisetsu>[0], void>(
    functions,
    'postShisetsu',
  )(params).then(({ data }) => data)

export const miraieValidate: MiraieValidate = (params) =>
  httpsCallable<
    Parameters<PublishMediaCoverage>[0],
    Awaited<ReturnType<PublishMediaCoverage>>
  >(
    functions,
    'miraieValidate',
  )(params).then(({ data }) => data)
