import { Collapse } from '@chakra-ui/react'

import {
  MiraieFieldPath,
  MiraieFieldPathValues,
  useWatchMiraieFields,
} from '@/hooks/useMiraieForm'

export const MiraieFieldCollapse = <T extends readonly MiraieFieldPath[]>({
  name,
  display,
  children,
}: {
  name: readonly [...T]
  display: (...values: MiraieFieldPathValues<T>) => boolean
  children: React.ReactNode
}) => (
  <Collapse
    in={display(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...(useWatchMiraieFields({ name }) as MiraieFieldPathValues<T>),
    )}
    unmountOnExit
  >
    {children}
  </Collapse>
)
