import { Flex, InputGroup, InputRightAddon } from '@chakra-ui/react'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { MansionKosuContents } from './MansionKosuContents'
import { SekoCompanyContents } from './SekoCompanyContents'

import { ChintaiAkiKosuContents } from '@/components/form/General/ChintaiAkiKosuContents'
import { ChintaiZenKosuContents } from '@/components/form/General/ChintaiZenKosuContents'
import { DesignCompanyContents } from '@/components/form/General/DesignCompanyContents'
import { JimushoKosuContents } from '@/components/form/General/JimushoKosuContents'
import { KosuUnit } from '@/components/form/General/KosuUnit'
import { OperationKosuContents } from '@/components/form/General/OperationKosuContents'
import { SectionName } from '@/components/form/General/SectionName'
import { SokosuContents } from '@/components/form/General/SokosuContents'
import {
  FormControl,
  FormLabel,
  NumberInput,
  PortalErrorMessages,
  TextInput,
} from '@/components/form/controls'

export const KukakuControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>
        <SectionName />
      </FormGroupHeading>
      <ChintaiZenKosuContents>
        <FormControl fieldName="chintai_zen_kosu">
          <FormLabel>総戸数</FormLabel>
          <NumberInput />
          <PortalErrorMessages />
        </FormControl>
      </ChintaiZenKosuContents>
      <ChintaiAkiKosuContents>
        <FormControl fieldName="chintai_aki_kosu">
          <FormLabel>空戸数</FormLabel>
          <NumberInput />
          <PortalErrorMessages />
        </FormControl>
      </ChintaiAkiKosuContents>
      <SokosuContents>
        <FormControl fieldName="sokosu">
          <FormLabel>
            総<KosuUnit />数
          </FormLabel>
          <InputGroup>
            <NumberInput />
            <InputRightAddon>
              <KosuUnit />
            </InputRightAddon>
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>
      </SokosuContents>
      <JimushoKosuContents>
        <FormControl fieldName="jimusho_kosu">
          <FormLabel>事務所等戸数</FormLabel>
          <InputGroup>
            <NumberInput />
            <InputRightAddon>戸</InputRightAddon>
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>
      </JimushoKosuContents>
      <MansionKosuContents>
        <FormControl fieldName="to_sokosu">
          <FormLabel>棟総戸数</FormLabel>
          <InputGroup>
            <TextInput />
            <InputRightAddon>戸</InputRightAddon>
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>
      </MansionKosuContents>
      <OperationKosuContents>
        <FormControl fieldName="operation_kosu">
          <FormLabel>稼働戸数</FormLabel>
          <InputGroup>
            <NumberInput />
            <InputRightAddon>戸</InputRightAddon>
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>
      </OperationKosuContents>
      <SekoCompanyContents>
        <FormControl fieldName="seko_company">
          <FormLabel>施工会社</FormLabel>
          <TextInput />
          <PortalErrorMessages />
        </FormControl>
      </SekoCompanyContents>
      <FormControl fieldset>
        <FormLabel>施主</FormLabel>
        <Flex gap={2}>
          <FormControl fieldName="seshu_1">
            <TextInput />
          </FormControl>
          <FormControl fieldName="seshu_2">
            <TextInput />
          </FormControl>
        </Flex>
        <PortalErrorMessages fieldName="seshu_1" />
        <PortalErrorMessages fieldName="seshu_2" />
      </FormControl>
      <DesignCompanyContents>
        <FormControl fieldName="design_company">
          <FormLabel>設計会社</FormLabel>
          <TextInput />
          <PortalErrorMessages />
        </FormControl>
      </DesignCompanyContents>
    </FormGroup>
  )
}
