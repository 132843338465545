import { FieldPath, FieldValues } from 'react-hook-form'

import { createContext } from '@/hooks/useContext'

export type ImageInputContext<T extends FieldValues> = {
  fieldName: FieldPath<T>
}

const [Provider, _useImageInputContext] =
  createContext<ImageInputContext<FieldValues>>()

export { Provider }

export function useImageInputContext<
  T extends FieldValues,
>(): ImageInputContext<T>
export function useImageInputContext() {
  return _useImageInputContext()
}
