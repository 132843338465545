import { FormGroupHeading } from '../FormGroupHeading'

import { CATVInit } from './CATVInit'
import { CATVMonthly } from './CATVMonthly'
import { InternetInit } from './InternetInit'
import { InternetMonthly } from './InternetMonthly'
import { YusenInit } from './YusenInit'
import { YusenMonthly } from './YusenMonthly'

import { FormGroup } from '@/components/form/FormGroup'
import { InternetContents } from '@/components/form/PriceAndCost/InternetContents'

export const Internets: React.FC = () => {
  return (
    <InternetContents>
      <FormGroup>
        <FormGroupHeading>インターネット・有線放送・CATV</FormGroupHeading>
        {/* インターネット初期費用 */}
        <InternetInit />
        {/* インターネット定額料金 */}
        <InternetMonthly />
        {/* 有線放送初期費用 */}
        <YusenInit />
        {/* 有線放送定額料金 */}
        <YusenMonthly />
        {/* CATV初期費用 */}
        <CATVInit />
        {/* CATV定額料金 */}
        <CATVMonthly />
      </FormGroup>
    </InternetContents>
  )
}
