import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ShinchikuFlagCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['bk_sbt_kbn']}
      display={(bk_sbt_kbn) => bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.売地}
    >
      {children}
    </MiraieFieldCollapse>
  )
}
