import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const ParkingKozoContents = ({ children }: PropsWithChildren) => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })
  const display = bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.売地
  return display && children
}
