import { miraieFields } from '@terass/common/src'

import { convertToMiraieShisetsu } from './convertToMiraieShisetsu'

import {
  Shisetsu,
  ShisetsuWithFile,
} from '@/components/form/Shisetsu/useShisetsuForm'
import { MapService, isActiveShisetsu } from '@/components/form/Shisetsu/utils'
import { uploadImageFile } from '@/utils/storage'

export async function createActiveShisetsuAndUploadImage(
  shisetsu: ShisetsuWithFile[],
  lat: number | undefined | null,
  lng: number | undefined | null,
  mapService: MapService,
  id: string,
) {
  const activeShisetsuPromises: Promise<Shisetsu>[] = shisetsu
    .filter(isActiveShisetsu)
    .map(async (x, i) => {
      const shisetsu_no = miraieFields.shisetsu.shisetsu_no.values[i]
      if (shisetsu_no === undefined) throw new Error('shisetsu_no is undefined')

      const shisetsuWithoutNo = await convertToMiraieShisetsu(
        x,
        lat,
        lng,
        mapService,
      )

      return {
        ...shisetsuWithoutNo,
        shisetsu_no,
        ...(x._imageFile &&
          (await uploadImageFile(x._imageFile, id).then(({ url, md5 }) => ({
            img_data_url: url,
            img_in_md5: md5,
          })))),
      }
    })

  return await Promise.all(activeShisetsuPromises)
}
