import { Card, CardBody, Text, VStack, useModalContext } from '@chakra-ui/react'

import { useEnsenEkiContext } from './useEnsenEkiContext'
import { EkiResult } from './useNearbyEkis'
import { findEnsenByEki } from './utils'

import { useMiraieFormContext } from '@/hooks/useMiraieForm'

type Props = {
  eki: EkiResult
  setHoveredEkiId: (ekiId: string | undefined) => void
}

export const EkiCard = ({ eki, setHoveredEkiId }: Props) => {
  const { setValue } = useMiraieFormContext()
  const { onClose } = useModalContext()
  const { index } = useEnsenEkiContext()
  const ekiLat = eki.geometry?.location?.lat()
  const ekiLng = eki.geometry?.location?.lng()

  if (eki.name === undefined) return null

  // '駅'を除く
  const ekiName = eki.name.at(-1) === '駅' ? eki.name.slice(0, -1) : eki.name
  const ensen = findEnsenByEki(ekiName)
  if (!ensen) return null

  return (
    <Card
      onClick={() => {
        setValue(`ensen_name_${index}`, ensen)
        setValue(`eki_name_${index}`, ekiName)

        if (!ekiLat || !ekiLng) {
          onClose()
          return
        }
        onClose()
      }}
      onMouseOver={() => setHoveredEkiId(eki.place_id)}
      onMouseOut={() => setHoveredEkiId(undefined)}
      width="90%"
      _hover={{ borderColor: 'gray.300', bgColor: 'gray.100' }}
      mb={4}
    >
      <CardBody>
        <VStack alignItems="flex-start">
          <Text fontWeight="bold">{ensen}</Text>
          <Text>{`${ekiName}駅`}</Text>
          {eki.directDistance ? (
            <Text>直線距離: {`${eki.directDistance}`}m</Text>
          ) : (
            <Text>直線距離を取得できませんでした</Text>
          )}
        </VStack>
      </CardBody>
    </Card>
  )
}
