import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const OnsenPriceCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['onsen_umu_kbn']}
      display={(onsen_umu_kbn) =>
        onsen_umu_kbn === miraieFields.onsen_umu_kbn.Enum.有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
