import { FormGroup } from '@/components/form/FormGroup'
import { BanchiFlgCheckbox } from '@/components/form/Portal/BanchiFlgCheckbox'
import { CloseDateFormControl } from '@/components/form/Portal/CloseDateFormControl'
import { CloseDateInput } from '@/components/form/Portal/CloseDateInput'
import { OpenKubun } from '@/components/form/Portal/OpenKubun'
import { PortalOptionsCollapse } from '@/components/form/Portal/PortalOptionsCollapse'
import { RoomNoFlgCheckbox } from '@/components/form/Portal/RoomNoFlgCheckbox'
import { TatemonoFlgCheckbox } from '@/components/form/Portal/TatemonoFlgCheckbox'
import {
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  PortalErrorMessages,
} from '@/components/form/controls'

export const HomesRadioGroup: React.FC = () => {
  return (
    <FormGroup>
      <OpenKubun portal="homes" />
      <PortalOptionsCollapse portal="homes">
        <FormGroup nested>
          <FormControl fieldset>
            <CheckboxGroup>
              <BanchiFlgCheckbox portal="homes" />
              <TatemonoFlgCheckbox portal="homes" />
              <RoomNoFlgCheckbox portal="homes" />
            </CheckboxGroup>
            <FormHelperText>HOME'Sでは地図検索で表示されます</FormHelperText>
          </FormControl>
          <CloseDateFormControl portal="homes">
            <FormLabel>掲載終了日</FormLabel>
            <CloseDateInput />
            <PortalErrorMessages />
          </CloseDateFormControl>
        </FormGroup>
      </PortalOptionsCollapse>
    </FormGroup>
  )
}
