import { InputGroup, InputLeftAddon, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  EnumRadios,
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  PortalErrorMessages,
  RadioGroup,
  UnselectedRadio,
} from '@/components/form/controls'

export const Kenri = () => {
  return (
    <FormGroup>
      <FormGroupHeading>土地権利</FormGroupHeading>

      <FormControl fieldName="tochi_pow_kbn" fieldset isRequired>
        <FormLabel>土地権利</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.tochi_pow_kbn.Enum} />
        </RadioGroup>
        <FormHelperText>
          「売地」「売地 借地権譲渡」の場合、「所有権」以外はエラーになります。
        </FormHelperText>
        <PortalErrorMessages />
      </FormControl>

      <MiraieFieldCollapse
        name={['bk_sbt_kbn', 'tochi_pow_kbn', 'suumo_c_open_kbn']}
        display={(bk_sbt_kbn, tochi_pow_kbn, suumo_c_open_kbn) =>
          miraieFields.c_open_kbn.isOpen(suumo_c_open_kbn) &&
          tochi_pow_kbn === miraieFields.tochi_pow_kbn.Enum.定期借地権 &&
          (bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション)
        }
      >
        <FormGroup nested>
          <FormControl fieldName="teishaku_jyoto_tokuyaku_flg" fieldset>
            <FormLabel>SUUMO定期借地権</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios
                enum={miraieFields.teishaku_jyoto_tokuyaku_flg.Enum}
              />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </MiraieFieldCollapse>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
        }
      >
        <FormGroup>
          <FormControl fieldName="tochi_pow_mixed_kbn" fieldset>
            <FormLabel>所有権借地権混在</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.tochi_pow_mixed_kbn.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldCollapse name={['tochi_pow_kbn']} display={Boolean}>
            <FormGroup nested>
              <FormControl fieldName="shakuchi_wariai_ritu">
                <InputGroup>
                  <InputLeftAddon>借地権割合</InputLeftAddon>
                  <NumberInput />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </MiraieFieldCollapse>
        </FormGroup>
      </MiraieFieldContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
        }
      >
        <FormGroup>
          <FormControl fieldName="chiekiken_kbn" fieldset>
            <FormLabel>地役権</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.chiekiken_kbn.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldCollapse
            name={['chiekiken_kbn']}
            display={(chiekiken_kbn) =>
              chiekiken_kbn !== undefined &&
              chiekiken_kbn !== miraieFields.chiekiken_kbn.Enum.無
            }
          >
            <FormGroup nested>
              <FormControl fieldName="chiekiken_menseki">
                <InputGroup>
                  <InputLeftAddon>地役権面積</InputLeftAddon>
                  <NumberInput step={miraieFields.chiekiken_menseki.step} />
                  <InputRightAddon>㎡</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </MiraieFieldCollapse>
        </FormGroup>
      </MiraieFieldContents>
    </FormGroup>
  )
}
