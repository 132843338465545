import { Radio, RadioProps } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { swapKeyValue } from '@terass/common/src/utils'

export const HikiwatashiJokenKubunRadios = (props: RadioProps) => {
  return (
    <>
      {(
        [
          miraieFields.hikiwatashi_joken_kbn.Enum.更地渡,
          miraieFields.hikiwatashi_joken_kbn.Enum.現況渡,
          miraieFields.hikiwatashi_joken_kbn.Enum.相談,
        ] as const
      ).map((value) => (
        <Radio key={value} value={value} {...props}>
          {swapKeyValue(miraieFields.hikiwatashi_joken_kbn.Enum)[value]}
        </Radio>
      ))}
    </>
  )
}
