import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const CarParkingDetailCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['parking_state_kbn']}
      display={(parking_state_kbn) =>
        parking_state_kbn === miraieFields.parking_state_kbn.Enum.空有 ||
        parking_state_kbn === miraieFields.parking_state_kbn.Enum.空無 ||
        parking_state_kbn === miraieFields.parking_state_kbn.Enum.近有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
