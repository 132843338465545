import { Card, CardBody, Center, Text } from '@chakra-ui/react'

import { LoginButton } from './auth/LoginButton'

export function LoginPage() {
  return (
    <Card mt={16}>
      <CardBody pb={0}>
        <Text>
          こちらは、株式会社TERASSが運用するTERASSエージェント専用の物件コンバーターです。
        </Text>
        <Text>
          Terass
          Cloudのデータと連携して各ポータルに媒体掲載を行うことができます。
        </Text>
        <Text>
          terass.comのGoogleアカウントを利用してログインしてください。
        </Text>
        <Center p={8}>
          <LoginButton w={48} />
        </Center>
      </CardBody>
    </Card>
  )
}
