import { Box, Card, CardBody } from '@chakra-ui/react'

import { AthomeRadioGroup } from './AthomeRadioGroup'
import { FukaJouhouControls } from './FukaJouhouControls'
import { HomesRadioGroup } from './HomesRadioGroup'
import { SuumoRadioGroup } from './SuumoRadioGroup'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { FormSectionDescription } from '@/components/form/FormSectionDescription'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import {
  AthomeRadioGroupContents,
  HomesRadioGroupContents,
} from '@/components/form/Portal/PortalRadioGroupContents'
import { sectionIds } from '@/components/form/SectionBar'

export const PortalSection = () => {
  return (
    <Box as="section" id={sectionIds.portalSection}>
      <FormSectionHeading>掲載設定</FormSectionHeading>
      <FormSectionDescription>
        媒体掲載を行うプラットフォームを選択してください
      </FormSectionDescription>
      <Card>
        <CardBody as={FormGroup}>
          <FormGroupHeading>ポータル毎の掲載指定</FormGroupHeading>
          <FormGroup gap={8}>
            <SuumoRadioGroup />
            <HomesRadioGroupContents>
              <HomesRadioGroup />
            </HomesRadioGroupContents>
            <AthomeRadioGroupContents>
              <AthomeRadioGroup />
            </AthomeRadioGroupContents>
            {
              // <RakumachiRadioGroupContents>
              // <RakumachiRadioGroup />
              // </RakumachiRadioGroupContents>
            }
          </FormGroup>
          <FukaJouhouControls />
        </CardBody>
      </Card>
    </Box>
  )
}
