import { miraieFields } from '@terass/common/src'

import { createContext } from '@/hooks/useContext'

type ModalContentFormContextProps = {
  bk_sbt_kbn: miraieFields.bk_sbt_kbn.Enum
}

export const [ModalContentFormContextProvider, useModalContentFormContext] =
  createContext<ModalContentFormContextProps>('modalContentFormContext')
