import { TextareaProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

import {
  getImageControlsArea,
  useImageControlsContext,
} from '@/components/form/BukkenImages/Controls/ImageControlsContext'
import { Textarea } from '@/components/form/BukkenImages/Controls/Textarea'

export const ImgTitleTextarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function ImgTitleTextarea(props, ref) {
    const { category, index } = useImageControlsContext()
    return (
      <Textarea
        fieldName={`${category}.${index}.img_title`}
        placeholder="タイトル(50文字)"
        gridArea={getImageControlsArea('img_title')}
        {...props}
        ref={ref}
      />
    )
  },
)
