import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  Grid,
  GridProps,
  InputGroup,
  InputRightAddon,
  Tooltip,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { miraieFields } from '@terass/common/src'

import {
  ImageControlsContext,
  Provider,
  getImageControlsArea,
  gridTemplateStyle,
} from '@/components/form/Shisetsu/ImageControlsContext'
import { ImageInput } from '@/components/form/Shisetsu/ImageInput'
import { NumberInput } from '@/components/form/Shisetsu/controls/NumberInput'
import { Select } from '@/components/form/Shisetsu/controls/Select'
import { Textarea } from '@/components/form/Shisetsu/controls/Textarea'
import {
  Shisetsu,
  useShisetsuFormContext,
  useWatchShisetsuForm,
} from '@/components/form/Shisetsu/useShisetsuForm'
import { EnumOptions } from '@/components/form/controls'
import { useV1ImportParam } from '@/hooks/useV1ImportParam'
import { postShisetsu } from '@/utils/functions'
import { toast } from '@/utils/standaloneToast'
import { deleteFile } from '@/utils/storage'

type ImageControlsProps = ImageControlsContext &
  GridProps & {
    updateShisetsu: (shisetsu: Shisetsu[]) => Promise<void>
  }

export const ImageControls = ({
  index,
  updateShisetsu,
  ...props
}: ImageControlsProps) => {
  const { setValue, getValues } = useShisetsuFormContext()
  const importId = useV1ImportParam()
  const directDistance = useWatchShisetsuForm(
    `shisetsu.${index}.directDistance`,
  )
  const removeMutation = useMutation({
    mutationFn: async () => {
      const remover = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        shisetsu_no: miraieFields.shisetsu.shisetsu_no.values[index]!,
      }
      await postShisetsu({
        importId,
        shisetsu: [remover],
      })

      const img_data_url = getValues(`shisetsu.${index}.img_data_url`)
      if (img_data_url) await deleteFile(img_data_url)

      await updateShisetsu(
        getValues('initialShisetsu').map((shisetsu, i) =>
          i === index ? remover : shisetsu,
        ),
      )
      setValue(`shisetsu.${index}.shisetsu_name`, undefined, {
        shouldDirty: true,
      })
      setValue(`shisetsu.${index}.shisetsu_kyori`, undefined, {
        shouldDirty: true,
      })
      setValue(`shisetsu.${index}._imageFile`, undefined, {
        shouldDirty: true,
      })
      setValue(`shisetsu.${index}.img_data_url`, undefined, {
        shouldDirty: true,
      })
      setValue(`shisetsu.${index}.img_in_md5`, undefined, {
        shouldDirty: true,
      })
      setValue(`shisetsu.${index}.directDistance`, undefined, {
        shouldDirty: true,
      })
      setValue(`shisetsu.${index}.latLng`, undefined, {
        shouldDirty: true,
      })
    },
    onError: () => {
      toast({
        description: '画像を削除できませんでした',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
    },
  })
  const clear = () => removeMutation.mutate()

  return (
    <Provider value={{ index }}>
      <Grid gridTemplate={gridTemplateStyle} gap={2} {...props}>
        <ImageInput />
        <Select
          fieldName={`shisetsu.${index}.shisetsu_sbt_kbn`}
          gridArea={getImageControlsArea('shisetsu_sbt_kbn')}
        >
          <EnumOptions enum={miraieFields.shisetsu.shisetsu_sbt_kbn.Enum} />
        </Select>
        {directDistance ? (
          <Tooltip label="直線距離を表示しています。保存時に徒歩距離に自動変換されます。">
            <InputGroup gridArea={getImageControlsArea('shisetsu_kyori')}>
              <NumberInput
                fieldName={`shisetsu.${index}.directDistance`}
                isDisabled={true}
                isReadOnly={true}
                variant="filled"
              />
              <InputRightAddon>m</InputRightAddon>
            </InputGroup>
          </Tooltip>
        ) : (
          <InputGroup gridArea={getImageControlsArea('shisetsu_kyori')}>
            <NumberInput
              fieldName={`shisetsu.${index}.shisetsu_kyori`}
              placeholder="徒歩距離"
            />
            <InputRightAddon>m</InputRightAddon>
          </InputGroup>
        )}
        <Textarea
          fieldName={`shisetsu.${index}.shisetsu_name`}
          placeholder="周辺施設名称（60文字）"
          gridArea={getImageControlsArea('shisetsu_name')}
        />
        <Button
          variant="ghost"
          onClick={clear}
          isLoading={removeMutation.isPending}
        >
          <DeleteIcon />
        </Button>
      </Grid>
    </Provider>
  )
}
