import { InputProps } from '@chakra-ui/react'

import { TextInput } from '@/components/form/controls'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const OpenHouseToDateInput = (props: InputProps) => {
  const [fromDate] = useWatchMiraieFields({ name: ['openhouse_from_date'] })

  return <TextInput type="date" min={fromDate} {...props} />
}
