import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const CarParkingSellContents = ({ children }: PropsWithChildren) => {
  const isChintai = useIsChintai()
  const [suumo_c_open_kbn] = useWatchMiraieFields({
    name: ['suumo_c_open_kbn'],
  })
  const display =
    (suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載 ||
      suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載期間外) &&
    !isChintai

  return display && children
}
