import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  FormHelperText,
  PortalErrorMessages,
  NumberInput,
  Textarea,
  FieldLength,
  RadioGroup,
  UnselectedRadio,
  EnumRadios,
  TextInput,
} from '../controls'

import { Hosoku } from './Hosoku'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { HaniEnumCheckbox } from '@/components/form/PriceAndCost/HaniEnumCheckbox'
import { KanrihiContents } from '@/components/form/PriceAndCost/KanrihiContents'
import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const Price: React.FC = () => {
  const [sakimono_flg] = useWatchMiraieFields({ name: ['sakimono_flg'] })
  const isChintai = useIsChintai()
  if (isChintai) return null

  return (
    <FormGroup>
      <FormGroupHeading>価格</FormGroupHeading>
      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
        }
      >
        <MiraieFieldContents
          name={['suumo_c_open_kbn']}
          display={(suumo_c_open_kbn) =>
            suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
          }
        >
          <FormControl fieldName="hanbaiki_name">
            <FormLabel>販売期名</FormLabel>
            <Textarea />
            <FieldLength maxLength={miraieFields.hanbaiki_name.maxLength} />
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <FormControl fieldName="price_status_kbn">
          <FormLabel>価格状態区分</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.price_status_kbn.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>
      </MiraieFieldContents>

      <FormControl fieldset isRequired>
        <FormLabel>価格</FormLabel>

        <FormGroup>
          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
            }
          >
            <FormControl fieldName="price_hani_kbn">
              <HaniEnumCheckbox>料金を範囲で指定する</HaniEnumCheckbox>
              <PortalErrorMessages />
            </FormControl>
          </MiraieFieldContents>

          <Flex gap={2} alignItems="center">
            <FormControl fieldName="price" isRequired>
              <InputGroup>
                <InputLeftAddon>税込</InputLeftAddon>
                <NumberInput
                  isDisabled={
                    sakimono_flg !== miraieFields.sakimono_flg.Enum.先物
                  }
                />
                <InputRightAddon>万円</InputRightAddon>
              </InputGroup>
            </FormControl>

            <MiraieFieldContents
              name={['price_hani_kbn']}
              display={(price_hani_kbn) =>
                price_hani_kbn === miraieFields.price_hani_kbn.Enum['〜']
              }
            >
              <Text>~</Text>

              <FormControl fieldName="price_to">
                <InputGroup>
                  <InputLeftAddon>税込</InputLeftAddon>
                  <NumberInput
                    isDisabled={
                      sakimono_flg !== miraieFields.sakimono_flg.Enum.先物
                    }
                  />
                  <InputRightAddon>万円</InputRightAddon>
                </InputGroup>
              </FormControl>
            </MiraieFieldContents>
          </Flex>
        </FormGroup>

        <FormHelperText>
          {sakimono_flg === miraieFields.sakimono_flg.Enum.自社
            ? 'Terass Converterからは変更できません。'
            : ''}
        </FormHelperText>
        <PortalErrorMessages fieldName="price" />
        <PortalErrorMessages fieldName="price_to" />
      </FormControl>

      <Hosoku />

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
        }
      >
        <MiraieFieldContents
          name={['suumo_c_open_kbn']}
          display={(suumo_c_open_kbn) =>
            suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
          }
        >
          <FormControl fieldName="price_biko">
            <FormLabel>価格補足</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="suumo_price_biko">
            <FormLabel>価格備考</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <FormControl fieldName="shikikin_hoshokin_kbn">
          <FormLabel>保証金又は敷金</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.shikikin_hoshokin_kbn.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['shikikin_hoshokin_kbn']}
          display={(shikikin_hoshokin_kbn) =>
            !!shikikin_hoshokin_kbn &&
            shikikin_hoshokin_kbn !== miraieFields.shikikin_hoshokin_kbn.Enum.無
          }
        >
          <FormGroup nested>
            <FormControl fieldName="shikikin_hoshokin_hani_kbn">
              <HaniEnumCheckbox>金額を範囲で指定する</HaniEnumCheckbox>
              <PortalErrorMessages />
            </FormControl>

            <Flex gap={2} alignItems="center">
              <FormControl fieldName="shikikin_hoshokin_from">
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>万円</InputRightAddon>
                </InputGroup>
              </FormControl>
              <MiraieFieldContents
                name={['shikikin_hoshokin_hani_kbn']}
                display={(shikikin_hoshokin_hani_kbn) =>
                  shikikin_hoshokin_hani_kbn ===
                  miraieFields.shikikin_hoshokin_hani_kbn.Enum['〜']
                }
              >
                <Text>~</Text>

                <FormControl fieldName="shikikin_hoshokin_to">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>万円</InputRightAddon>
                  </InputGroup>
                </FormControl>
              </MiraieFieldContents>
            </Flex>

            <PortalErrorMessages fieldName="shikikin_hoshokin_from" />
            <PortalErrorMessages fieldName="shikikin_hoshokin_to" />
          </FormGroup>
        </MiraieFieldCollapse>

        <FormControl fieldset>
          <FormLabel>区画最多価格帯</FormLabel>

          <FormGroup nested>
            {([1, 2, 3, 4, 5] as const).map((n) => (
              <FormControl key={n} fieldName={`tato_saita_price_${n}`}>
                <FormLabel>価格帯{n}</FormLabel>
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>万円台</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            ))}

            <FormControl fieldName="tato_saita_price_com">
              <FormLabel>フリーコメント</FormLabel>
              <Textarea />
              <FieldLength
                maxLength={miraieFields.tato_saita_price_com.maxLength}
              />
              <FormHelperText>
                <Text>
                  ※販売状態が、新規分譲、分譲中かつ販売戸数が１０以上の場合、必須
                </Text>
                <Text>
                  ※最多価格帯が６つ以上ある場合、６つめ以降を入力してください
                </Text>
                <Text>例: 6700万円台、6900万円台</Text>
              </FormHelperText>
              <PortalErrorMessages />
            </FormControl>

            <FormGroup nested>
              <FormControl fieldName="tato_ikkatsu_saita_price_kukakusu">
                <FormLabel>戸数</FormLabel>
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>戸</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </FormGroup>
        </FormControl>
      </MiraieFieldContents>

      <KanrihiContents>
        <FormControl fieldName="kanrihi" isRequired>
          <FormLabel>管理費</FormLabel>
          <InputGroup>
            <NumberInput />
            <InputRightAddon>円 / 月</InputRightAddon>
          </InputGroup>
          <FormHelperText>
            「なし」の場合、「0」円で入力してください。
          </FormHelperText>
          <PortalErrorMessages />
        </FormControl>

        <FormControl fieldName="tsumitatekin" isRequired>
          <FormLabel>修繕積立金</FormLabel>
          <InputGroup>
            <NumberInput />
            <InputRightAddon>円 / 月</InputRightAddon>
          </InputGroup>
          <FormHelperText>
            「なし」の場合、「0」円で入力してください。
          </FormHelperText>
          <PortalErrorMessages />
        </FormControl>

        <FormGroup nested>
          <FormControl fieldName="tsumitatekikin">
            <FormLabel>基金</FormLabel>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>円</InputRightAddon>
            </InputGroup>
            <FormHelperText>
              「なし」の場合、「0」円で入力してください。
            </FormHelperText>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </KanrihiContents>
    </FormGroup>
  )
}
