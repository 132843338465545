import { Svg, SvgProps } from '@/components/Svg'

export const HomesIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 143 143" fill="none" {...props}>
    <rect
      x="22"
      y="17"
      width="108"
      height="106"
      stroke="#FF6B00"
      strokeWidth="10"
    />
    <rect x="59" width="33" height="143" fill="white" />
    <rect
      x="143"
      y="53"
      width="33"
      height="143"
      transform="rotate(90 143 53)"
      fill="white"
    />
    <path
      d="M48 99V65.747L78 37.9528L108 65.747V99H48Z"
      stroke="#FF6B00"
      strokeWidth="8"
    />
    <circle cx="78" cy="77" r="9" stroke="#FF6B00" strokeWidth="8" />
    <rect x="61" y="77" width="33" height="17" fill="white" />
    <line x1="69" y1="77" x2="69" y2="96" stroke="#FF6B00" strokeWidth="8" />
    <line x1="87" y1="76" x2="87" y2="95" stroke="#FF6B00" strokeWidth="8" />
  </Svg>
)
