import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const HoshokinCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['hoshokin_flg']}
      display={(hoshokin_flg) =>
        hoshokin_flg === miraieFields.hoshokin_flg.Enum.有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
