/**
 * 土地最適用途区分
 */
export const Enum = {
  住宅用地: '1',
  マンション用地: '2',
  ビル用地: '3',
  店舗用地: '4',
  工場用地: '5',
  配送センター: '6',
  営業所: '7',
  保養所: '8',
  事務所用地: '10',
  別荘用地: '11',
  倉庫用地: '12',
  資材置場用地: '13',
  家庭菜園用地: '14',
  アパート用地: '15',
  社宅社員寮: '16',
  病院診療所: '17',
  畑農地: '18',
  事業用地: '19',
  駐車場用地: '20',
  リゾート向: '55',
} as const
