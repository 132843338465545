import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'
import { ChangeEventHandler } from 'react'
import { FieldPathByValue } from 'react-hook-form'

import { useFormControlContext } from '@/components/form/controls/FormControl'
import { useMiraieFormController } from '@/hooks/useMiraieForm'

export type BooleanEnumCheckboxProps = {
  fieldName?: FieldPathByValue<
    PostMiraieV1ImportForm,
    (typeof booleanEnum)[keyof typeof booleanEnum] | undefined
  >
} & CheckboxProps

export const BooleanEnumCheckbox = ({
  fieldName: fieldNameProp,
  ...props
}: BooleanEnumCheckboxProps) => {
  const { fieldName: _fieldName } = useFormControlContext()
  const fieldName = fieldNameProp ?? _fieldName
  if (!fieldName) throw new Error()

  const {
    field: { value, ...field },
  } = useMiraieFormController({
    name: fieldName,
  })
  const isChecked = value === booleanEnum.true
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    field.onChange(e.target.checked ? booleanEnum.true : booleanEnum.false)

  return (
    <Checkbox
      {...field}
      {...{ isChecked, onChange }}
      id={fieldName}
      {...props}
    />
  )
}

const booleanEnum = {
  true: '1',
  false: '0',
} as const
