/**
 * 配列を指定された長さまでに分割した配列の配列を取得する
 * @example
 * ```ts
 * chunk([1, 2, 3, 4, 5], 2)
 * // -> [[1, 2], [3, 4], [5]]
 * ```
 */
export const chunk = <T>(array: T[], length: number): T[][] => {
  if (array.length <= length) return [array]

  return [array.slice(0, length), ...chunk(array.slice(length), length)]
}
