import { InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  TextInput,
} from '@/components/form/controls'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const Mototsuke = () => {
  const [sakimono_flg] = useWatchMiraieFields({ name: ['sakimono_flg'] })
  const isRequired = sakimono_flg === miraieFields.sakimono_flg.Enum.先物
  return (
    <FormGroup>
      <FormGroupHeading>元付業者</FormGroupHeading>
      <FormControl fieldset isRequired={isRequired}>
        <FormLabel>元付業者(手入力)</FormLabel>
        <FormGroup nested>
          <FormControl
            fieldName="mototsuke_gyousha_name"
            isRequired={isRequired}
          >
            <InputGroup>
              <InputLeftAddon>業者名</InputLeftAddon>
              <TextInput />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl
            fieldName="mototsuke_gyousha_name_kana"
            isRequired={isRequired}
          >
            <InputGroup>
              <InputLeftAddon>業者名カナ</InputLeftAddon>
              <TextInput />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl
            fieldName="mototsuke_gyousha_tel"
            isRequired={isRequired}
          >
            <InputGroup>
              <InputLeftAddon>電話番号</InputLeftAddon>
              <TextInput />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </FormControl>

      <FormControl fieldName="mototsuke_tanto_name" isRequired={isRequired}>
        <FormLabel>元付担当者名</FormLabel>
        <TextInput />
        <PortalErrorMessages />
      </FormControl>
    </FormGroup>
  )
}
