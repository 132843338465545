/**
 * 駐車場タイプ区分
 */
export const Enum = {
  平置: '1',
  自走式: '2',
  機械式: '3',
  タワー式: '4',
  時間式: '5',
  その他: '6',
} as const
