import { V1Import } from '@terass/common/src/firestore/V1Import'
import { doc, onSnapshot } from 'firebase/firestore'

import { useV1ImportParam } from './useV1ImportParam'

import { asDataTag, observerOptions, useObserver } from '@/hooks/useObserver'
import { DocumentNotFoundError } from '@/utils/errors'
import { getCollection } from '@/utils/firestore'

export const useV1ImportDocument = <T>(selector: (value: V1Import) => T): T => {
  const importId = useV1ImportParam()
  return useObserver(v1importObserverOptions(importId), selector)
}

export const v1importObserverOptions = (importId: string) =>
  observerOptions({
    observerKey: ['v1import', importId],
    observerFn: (onChange, onError) =>
      onSnapshot(doc(getCollection('v1import'), importId), (snapshot) => {
        const data = snapshot.data()
        if (data) onChange(data)
        else onError(new DocumentNotFoundError())
      }),
    dataType: asDataTag<V1Import>(),
  })
