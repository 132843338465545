import { Portal } from '@terass/common/src/portal'

import { BooleanEnumCheckbox } from '@/components/form/controls'

export const TatemonoFlgCheckbox: React.FC<{
  portal: Extract<Portal, 'homes' | 'athome' | 'suumo'>
}> = ({ portal }) => (
  <BooleanEnumCheckbox fieldName={`${portal}_c_tatemono_hyoji_flg`}>
    建物名を表示する
  </BooleanEnumCheckbox>
)
