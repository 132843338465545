import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

const theme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'pink',
  }),
  {
    fonts: {
      heading: 'Noto Sans JP',
      body: 'Noto Sans JP',
    },
  },
)

export default theme
