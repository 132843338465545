import { Box, Card, CardBody } from '@chakra-ui/react'

import { AddressControls } from './AddressControls'
import { BukkenControls } from './BukkenControls'
import { JokyoControls } from './JokyoControls'
import { KukakuControls } from './KukakuControls'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { GenbaToiawaseControls } from '@/components/form/General/GenbaToiawaseControls'
import { TatoKokokuContents } from '@/components/form/General/TatoKokokuContents'
import { TatoKokokuControls } from '@/components/form/General/TatoKokokuControls'
import { sectionIds } from '@/components/form/SectionBar'

export const GeneralFormSection = () => {
  return (
    <Box as="section" id={sectionIds.generalFormSection}>
      <FormSectionHeading>基本</FormSectionHeading>
      <Card>
        <CardBody as={FormGroup}>
          <BukkenControls />
          <AddressControls />
          <KukakuControls />
          <JokyoControls />
          <TatoKokokuContents>
            <TatoKokokuControls />
            <GenbaToiawaseControls />
          </TatoKokokuContents>
        </CardBody>
      </Card>
    </Box>
  )
}
