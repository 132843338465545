import { useSuspenseQuery } from '@tanstack/react-query'

import { LatLng, getDirectDistance, nearbySearch } from '@/utils/googleMap'

export type EkiResult = google.maps.places.PlaceResult & {
  /** map中心からの距離 */
  directDistance?: number
}

export const useNearbyEkis = (map: google.maps.Map, position: LatLng) =>
  useSuspenseQuery({
    queryKey: ['useNearbyEkis', position],
    queryFn() {
      return nearbySearch(
        map,
        [{ type: 'train_station' }, { type: 'subway_station' }],
        5000,
        position,
      ).then((ekis) =>
        ekis
          // map中心からの距離を追加
          .map<EkiResult>((eki) => {
            const lat = eki.geometry?.location?.lat()
            const lng = eki.geometry?.location?.lng()
            const mapCenter = map.getCenter()
            const centerLat = mapCenter?.lat()
            const centerLng = mapCenter?.lng()
            if (!lat || !lng || !centerLat || !centerLng) return eki

            const directDistance = getDirectDistance(
              { lat: centerLat, lng: centerLng },
              { lat, lng },
            )
            return {
              ...eki,
              directDistance,
            }
          })
          .sort((a, b) =>
            a.directDistance && b.directDistance
              ? a.directDistance - b.directDistance
              : 0,
          ),
      )
    },
  })
