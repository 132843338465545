import { Text } from '@chakra-ui/react'
import { InfoWindowF, MarkerF } from '@react-google-maps/api'
import { Fragment } from 'react'

import { useNearbyEkis } from './useNearbyEkis'

import { LatLng } from '@/utils/googleMap'

type Props = {
  map: google.maps.Map
  position: LatLng
  hoveredEkiId?: string
}

export const EkiMarkers = ({ map, position, hoveredEkiId }: Props) => {
  const { data: ekis } = useNearbyEkis(map, position)

  return ekis.map((eki) => {
    const lat = eki.geometry?.location?.lat()
    const lng = eki.geometry?.location?.lng()
    if (!lat || !lng) return null
    return (
      <Fragment key={eki.place_id}>
        {hoveredEkiId === eki.place_id && (
          <InfoWindowF
            position={{ lat, lng }}
            options={{
              pixelOffset: { width: 0, height: -40, equals: () => true },
            }}
          >
            <Text>{`${eki.name}`}</Text>
          </InfoWindowF>
        )}
        <MarkerF
          position={{
            lat,
            lng,
          }}
        />
      </Fragment>
    )
  })
}
