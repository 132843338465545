import { Flex, InputGroup, InputRightAddon, Text } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import {
  EnumOptions,
  FormControl,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  Select,
  TextInput,
} from '@/components/form/controls'
import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const Madori = () => {
  const isChintai = useIsChintai()

  return (
    <MadoriContents>
      <FormGroup>
        <FormGroupHeading>部屋間取詳細</FormGroupHeading>
        {roomIndexList.map((i) => (
          <FormControl key={i} fieldset>
            <FormLabel>部屋{i}</FormLabel>
            <Flex gap={2} alignItems="center">
              <FormControl fieldName={`room_type_kbn_${i}`}>
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.room_type_kbn.Enum} />
                </Select>
              </FormControl>

              <FormControl fieldName={`room_josu_${i}`}>
                <InputGroup>
                  <NumberInput step={miraieFields.room_josu.step} />
                  <InputRightAddon>帖</InputRightAddon>
                </InputGroup>
              </FormControl>

              <Text width={12} flexShrink={0}>
                所在階
              </Text>

              {isChintai && (
                <FormControl fieldName={`room_shozaikai_kbn_${i}`}>
                  <Select>
                    <PlaceholderOption />
                    <EnumOptions enum={miraieFields.shozai_kai_kbn.Enum} />
                  </Select>
                </FormControl>
              )}

              <FormControl fieldName={`room_shozaikai_${i}`}>
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>階</InputRightAddon>
                </InputGroup>
              </FormControl>
            </Flex>
            <PortalErrorMessages fieldName={`room_type_kbn_${i}`} />
            <PortalErrorMessages fieldName={`room_josu_${i}`} />
            {isChintai && (
              <PortalErrorMessages fieldName={`room_shozaikai_kbn_${i}`} />
            )}
            <PortalErrorMessages fieldName={`room_shozaikai_${i}`} />
          </FormControl>
        ))}

        <FormControl fieldName="madori_biko">
          <FormLabel>備考</FormLabel>
          <TextInput />
          <PortalErrorMessages />
        </FormControl>

        {/* 2戸以上は特殊形式だが、今回は非対応とする */}
      </FormGroup>
    </MadoriContents>
  )
}

const roomIndexList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const

const MadoriContents = ({ children }: React.PropsWithChildren) => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })
  const display = bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
  return <>{display && children}</>
}
