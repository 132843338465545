import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const JimushoKosuContents = ({ children }: PropsWithChildren) => {
  const [bk_sbt_kbn, homes_c_open_kbn] = useWatchMiraieFields({
    name: ['bk_sbt_kbn', 'homes_c_open_kbn'],
  })
  const isMansion = bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
  const isChintai = useIsChintai()
  const isHomesOpen = homes_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
  const display = (isChintai && isHomesOpen) || isMansion

  return display && children
}
