import { Textarea as ChakraTextarea, TextareaProps } from '@chakra-ui/react'

import { useFormControlContext } from '@/components/form/controls/FormControl'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'

export const Textarea = (props: TextareaProps) => {
  const { fieldName } = useFormControlContext()
  if (!fieldName) throw new Error()

  const { register } = useMiraieFormContext()

  return <ChakraTextarea {...register(fieldName)} {...props} />
}
