import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export type SvgProps = HTMLChakraProps<'svg'>

export const Svg = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  />
)
