import { InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
  RadioGroup,
  EnumRadios,
  UnselectedRadio,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { ChokaihiHiyoCollapse } from '@/components/form/PriceAndCost/ChokaihiHiyoCollapse'
import { ChokaihiTypeCollapse } from '@/components/form/PriceAndCost/ChokaihiTypeCollapse'

export const Chokaihi: React.FC = () => {
  return (
    <FormGroup>
      <FormGroupHeading>町会費</FormGroupHeading>
      <FormControl fieldName="chokaihi_umu_kbn">
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.chokaihi_umu_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <ChokaihiTypeCollapse>
        <FormGroup nested>
          <FormControl fieldName="chokaihi_kbn" fieldset>
            <FormLabel>タイプ</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.chokaihi_kbn.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <ChokaihiHiyoCollapse>
            <FormControl fieldName="chokaihi_price">
              <FormLabel>費用</FormLabel>
              <InputGroup>
                <NumberInput />
                <InputRightAddon>円 / 月</InputRightAddon>
              </InputGroup>
              <PortalErrorMessages />
            </FormControl>
          </ChokaihiHiyoCollapse>
        </FormGroup>
      </ChokaihiTypeCollapse>
    </FormGroup>
  )
}
