import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react'

import { MasqueradeInfo } from '@/components/auth/MasqueradeInfo'
import { SelectMasquerade } from '@/components/auth/SelectMasquerade'
import { useMasqueradeEmail } from '@/hooks/useOwnUser'
import { useUserRole } from '@/hooks/useUser'

export const MasqueradeAlert = () => {
  const masquerade = useMasqueradeEmail()
  const role = useUserRole()
  if (role !== 'assistant') return null

  if (!masquerade)
    return (
      <>
        <Alert status="error" justifyContent="center" height={16}>
          <AlertIcon />
          <AlertDescription>
            <SelectMasquerade />
          </AlertDescription>
        </Alert>
      </>
    )

  return <MasqueradeInfo />
}
