import { FormControl, FormControlProps } from '@/components/form/controls'
import { useUserRole } from '@/hooks/useUser'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const SuumoOptionsFormControl = (props: FormControlProps) => {
  const role = useUserRole()
  const status = useV1ImportDocument(({ status }) => status)
  const isDisabled = role === 'agent' && status === 'published'
  return <FormControl {...(isDisabled && { isDisabled })} {...props} />
}
