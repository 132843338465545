import {
  Badge,
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'
import { miraieFields } from '@terass/common/src'
import { V1Import } from '@terass/common/src/firestore/V1Import'
import { portals } from '@terass/common/src/portal'
import { BsThreeDots } from 'react-icons/bs'

import terass from '@/assets/images/terass.jpg'
import { PortalIcon, PortalIconContainer } from '@/components/icons'
import { useDuplicateV1Import } from '@/hooks/useDuplicateV1Import'
import { observerClient } from '@/hooks/useObserver'
import { useUserRole } from '@/hooks/useUser'
import { v1importObserverOptions } from '@/hooks/useV1ImportDocument'
import { priceToJpString } from '@/utils/utils'

type Props = {
  id: string
  v1import: V1Import
}

export const BukkenCard: React.FC<Props> = ({ id, v1import }) => {
  const {
    formData: {
      tatemono_name,
      price: _price,
      images,
      sakimono_flg,
      bk_sbt_kbn,
    },
    teraSeven,
    status,
  } = v1import
  const imageUrl = images?.find((image) => image.img_data_url)?.img_data_url
  const { duplicateV1Import, isPending, reset } = useDuplicateV1Import({
    v1import,
  })
  const role = useUserRole()
  const isChintai = miraieFields.bk_sbt_kbn.isChintai(bk_sbt_kbn)

  const price = miraieFields.price.normalizePrice(_price, isChintai)

  return (
    <>
      <Card border="solid 1px" borderColor="gray.400" overflow="hidden">
        <CardBody p={0} position="relative">
          <Box
            position="relative"
            transitionDuration="normal"
            transitionProperty="common"
            _hover={{
              bgColor: 'gray.100',
            }}
          >
            <Link
              to="/bukkens/$importId"
              params={{ importId: id }}
              state={(history) => {
                observerClient.setInitialValue(
                  v1importObserverOptions(id),
                  v1import,
                )
                return history
              }}
            >
              <Flex mr={8}>
                <Box width="20%">
                  {imageUrl ? (
                    <Image
                      width="100%"
                      height="100%"
                      src={imageUrl}
                      aspectRatio={1}
                      objectFit="contain"
                      alt="物件画像"
                    />
                  ) : (
                    <>
                      <Image src={terass} alt="blank image" />
                      <Text align="center">no image</Text>
                    </>
                  )}
                </Box>
                <Box width="80%" pl={1} my={3}>
                  <Flex gap={3}>
                    {sakimono_flg === '1' && (
                      <Badge borderRadius={4} px={4} variant="subtle">
                        先物
                      </Badge>
                    )}

                    {isChintai && (
                      <Badge borderRadius={4} px={4} variant="solid">
                        賃貸
                      </Badge>
                    )}
                  </Flex>
                  <Tooltip label={tatemono_name ?? '---'} placement="top">
                    <Text fontSize="3xl" fontWeight="700" isTruncated>
                      {tatemono_name ?? '---'}
                    </Text>
                  </Tooltip>
                  <Text fontWeight="700" fontSize="4xl">
                    {isChintai ? '賃料: ' : '物件価格: '}
                    {price ? priceToJpString(price) : '---'}
                  </Text>

                  {portals.map((portal) => {
                    const from = teraSeven.keisaiKikan?.[portal]?.from
                    const to = teraSeven.keisaiKikan?.[portal]?.to

                    if (!from && !to) {
                      return null
                    }

                    return (
                      <Grid
                        key={portal}
                        templateColumns="1fr 3fr 4fr 1fr 4fr"
                        mr={40}
                      >
                        <GridItem>
                          <PortalIconContainer>
                            <PortalIcon portal={portal} />
                          </PortalIconContainer>
                        </GridItem>
                        <Text fontWeight="700" fontSize="xl" textAlign="center">
                          掲載期間：
                        </Text>
                        <Text fontSize="xl" textAlign="center">
                          {from}
                        </Text>
                        <Text fontSize="xl" textAlign="center">
                          〜
                        </Text>
                        <Text fontSize="xl" textAlign="center">
                          {to}
                        </Text>
                      </Grid>
                    )
                  })}
                </Box>
              </Flex>
            </Link>
          </Box>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<BsThreeDots />}
              variant="ghost"
              colorScheme="gray"
              rounded="full"
              aspectRatio={1}
              position="absolute"
              right={0}
              top={0}
              p={0}
              m={2}
            />
            <Portal>
              <MenuList>
                {status !== 'closed' && (
                  <Link to="/bukkens/$importId/edit" params={{ importId: id }}>
                    <MenuItem>編集</MenuItem>
                  </Link>
                )}
                {role !== 'agent' && (
                  <MenuItem onClick={duplicateV1Import} isDisabled={isPending}>
                    複製編集
                    {isPending && <Spinner />}
                  </MenuItem>
                )}
              </MenuList>
            </Portal>
          </Menu>
        </CardBody>
      </Card>
      <Modal
        isOpen={isPending}
        onClose={reset}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>複製中...</ModalHeader>
          <ModalBody>
            <Flex gap={2} alignItems="center">
              <Spinner size="sm" />
              媒体掲載を複製しています
            </Flex>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  )
}
