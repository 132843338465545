import { Flex } from '@chakra-ui/react'

import { SakimonoBaitaiKeisaiIraiContextProvider } from '@/components/chintai/SakimonoBaitaiKeisaiIraiContext'
import { SakimonoBaitaiKeisaiIraiListItemCard } from '@/components/chintai/SakimonoBaitaiKeisaiIraiListItemCard'
import { useSakimonoBaitaiKeisaiIraiList } from '@/hooks/useSakimonoBaitaiKeisaiIraiList'

export const SakimonoBaitaiKeisaiIraiList = () => {
  const { data } = useSakimonoBaitaiKeisaiIraiList()
  if (!data.length) return <>先物媒体掲載依頼がありません。</>

  return (
    <Flex direction="column" gap={4}>
      {data.map((item) => (
        <SakimonoBaitaiKeisaiIraiContextProvider key={item.id} value={item}>
          <SakimonoBaitaiKeisaiIraiListItemCard />
        </SakimonoBaitaiKeisaiIraiContextProvider>
      ))}
    </Flex>
  )
}
