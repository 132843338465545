import { Grid, Text } from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'

import { BukkenCard } from '@/components/BukkenCard'
import { useOperator } from '@/hooks/useOperator'
import { useV1ImportCollection } from '@/hooks/useV1ImportCollection'
import { NavigationButtonGroup } from '@/routes/_layout/-components'
import { cursorParamsSchema } from '@/routes/_layout/-cursorParamsSchema'

export const Route = createFileRoute('/_layout/futures/publishes/')({
  validateSearch: cursorParamsSchema,
  component: FuturePublishes,
})

function FuturePublishes() {
  const { direction = 'previous', cursor } = Route.useSearch()
  const navigate = Route.useNavigate()
  const { email } = useOperator()
  const { items, ...pageResult } = useV1ImportCollection({
    direction,
    cursor,
    where: {
      status: 'published',
      readerEmail: email,
      sakimono: true,
    },
    orderBy: {
      field: 'publishedAt',
      direction: 'desc',
    },
    onNext: (snapshot) => {
      navigate({
        to: Route.to,
        search: { direction: 'next', cursor: snapshot.id },
      })
    },
    onPrevious: (snapshot) => {
      navigate({
        to: Route.to,
        search: { direction: 'previous', cursor: snapshot.id },
      })
    },
  })
  if (!items.length) return <Text>掲載物件がありません。</Text>

  return (
    <Grid justifyItems="stretch" gap={3}>
      {items.map(({ id, data }) => (
        <BukkenCard key={id} id={id} v1import={data} />
      ))}
      <NavigationButtonGroup
        {...pageResult}
        firstPage={() => navigate({ to: Route.to })}
        lastPage={() =>
          navigate({ to: Route.to, search: { direction: 'next' } })
        }
      />
    </Grid>
  )
}
