import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react'

import {
  ShisetsuFieldPath,
  useShisetsuFormContext,
  useShisetsuFormState,
} from '@/components/form/Shisetsu/useShisetsuForm'
import { getFieldError } from '@/utils/getFieldError'

type InputProps = ChakraInputProps & {
  fieldName: ShisetsuFieldPath
}

export const NumberInput = ({ fieldName, ...props }: InputProps) => {
  const { register } = useShisetsuFormContext()
  const { errors } = useShisetsuFormState({ name: fieldName })
  const error = getFieldError(errors, fieldName)

  return (
    <ChakraInput
      type="number"
      {...register(fieldName, {
        setValueAs: (value: string) =>
          value !== '' ? Number(value) : undefined,
      })}
      isInvalid={!!error?.message}
      {...props}
    />
  )
}

NumberInput.id = ChakraInput.id
