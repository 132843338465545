import { Flex, InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { HanbaiScheduleCommentContents } from '@/components/form/General/HanbaiScheduleCommentContents'
import { TorokuChusenCollapse } from '@/components/form/General/TorokuChusenCollapse'
import { YokokuKokokuCollapse } from '@/components/form/General/YokokuKokokuCollapse'
import {
  DateOptions,
  EnumOptions,
  EnumRadios,
  FieldLength,
  FormControl,
  FormLabel,
  MonthOptions,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  Textarea,
  YearOptions,
} from '@/components/form/controls'

export const TatoKokokuControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>多棟広告・販売状態</FormGroupHeading>

      <FormControl fieldName="tato_kokoku_kbn">
        <FormLabel>多棟広告販売状況</FormLabel>
        <RadioGroup>
          <EnumRadios enum={miraieFields.tato_kokoku_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <YokokuKokokuCollapse>
        <FormControl fieldName="yokoku_kokoku">
          <FormLabel>予告広告補足コメント</FormLabel>
          <Textarea />
          <FieldLength maxLength={miraieFields.yokoku_kokoku.maxLength} />
          <PortalErrorMessages />
        </FormControl>
      </YokokuKokokuCollapse>

      <FormControl fieldName="hanbai_kosu">
        <FormLabel>販売戸数</FormLabel>
        <InputGroup>
          <NumberInput />
          <InputRightAddon>戸</InputRightAddon>
        </InputGroup>
        <PortalErrorMessages />
      </FormControl>

      <FormControl fieldName="hanbai_hoshiki_kbn">
        <FormLabel>販売方式</FormLabel>
        <RadioGroup>
          <EnumRadios enum={miraieFields.hanbai_hoshiki_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <FormControl fieldset>
        <FormLabel>販売/先着申込登録受付</FormLabel>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>開始年月</FormLabel>
            <Flex gap={2}>
              <FormControl fieldName="hanbai_start_ym_year">
                <Select>
                  <PlaceholderOption />
                  <YearOptions />
                </Select>
              </FormControl>

              <FormControl fieldName="hanbai_start_ym_month">
                <Select>
                  <PlaceholderOption />
                  <MonthOptions />
                </Select>
              </FormControl>

              <FormControl fieldName="hanbai_start_jun_kbn">
                <Select>
                  <PlaceholderOption />
                  <DateOptions />
                  <EnumOptions enum={miraieFields.hanbai_start_jun_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>
            {(
              [
                'hanbai_start_ym_year',
                'hanbai_start_ym_month',
                'hanbai_start_jun_kbn',
              ] as const
            ).map((fieldName) => (
              <PortalErrorMessages key={fieldName} fieldName={fieldName} />
            ))}
          </FormControl>

          <TorokuChusenCollapse>
            <FormGroup>
              <FormControl fieldset>
                <FormLabel>終了年月</FormLabel>
                <Flex gap={2}>
                  <FormControl fieldName="touroku_end_ym_year">
                    <Select>
                      <PlaceholderOption />
                      <YearOptions />
                    </Select>
                  </FormControl>

                  <FormControl fieldName="touroku_end_ym_month">
                    <Select>
                      <PlaceholderOption />
                      <MonthOptions />
                    </Select>
                  </FormControl>

                  <FormControl fieldName="touroku_end_d">
                    <Select>
                      <PlaceholderOption />
                      <DateOptions />
                    </Select>
                  </FormControl>
                </Flex>
                {(
                  [
                    'touroku_end_ym_year',
                    'touroku_end_ym_month',
                    'touroku_end_d',
                  ] as const
                ).map((fieldName) => (
                  <PortalErrorMessages key={fieldName} fieldName={fieldName} />
                ))}
              </FormControl>

              <FormControl fieldset>
                <FormLabel>抽選年月</FormLabel>
                <Flex gap={2}>
                  <FormControl fieldName="chusen_ym_year">
                    <Select>
                      <PlaceholderOption />
                      <YearOptions />
                    </Select>
                  </FormControl>

                  <FormControl fieldName="chusen_ym_month">
                    <Select>
                      <PlaceholderOption />
                      <MonthOptions />
                    </Select>
                  </FormControl>

                  <FormControl fieldName="chusen_jun_kbn">
                    <Select>
                      <PlaceholderOption />
                      <DateOptions />
                      <EnumOptions enum={miraieFields.chusen_jun_kbn.Enum} />
                    </Select>
                  </FormControl>
                </Flex>
                {(
                  [
                    'chusen_ym_year',
                    'chusen_ym_month',
                    'chusen_jun_kbn',
                  ] as const
                ).map((fieldName) => (
                  <PortalErrorMessages key={fieldName} fieldName={fieldName} />
                ))}
              </FormControl>
            </FormGroup>
          </TorokuChusenCollapse>
        </FormGroup>
      </FormControl>

      <HanbaiScheduleCommentContents>
        <FormControl fieldName="hanbai_schedule_comment">
          <FormLabel>販売スケジュールコメント</FormLabel>
          <Textarea />
          <FieldLength
            maxLength={miraieFields.hanbai_schedule_comment.maxLength}
          />
          <PortalErrorMessages />
        </FormControl>
      </HanbaiScheduleCommentContents>
    </FormGroup>
  )
}
