import { Box, Button, Flex } from '@chakra-ui/react'
import { createFileRoute, Outlet, Link } from '@tanstack/react-router'
import { Suspense } from 'react'

import { CSpinner, PageHeading } from '@/components/Atoms'
import { CreateSakimonoButton } from '@/components/sakimono/CreateSakimonoButton'
import { useUserRole } from '@/hooks/useUser'
import { MediaCoverageMenu } from '@/routes/_layout/-components'

export const Route = createFileRoute('/_layout')({
  component: Top,
})

export function Top() {
  const role = useUserRole()
  return (
    <>
      <Flex justifyContent="space-between" my={2}>
        <PageHeading title="媒体掲載" />

        <Flex gap={2}>
          {role !== 'agent' && (
            <>
              <Link to="/contracts">
                <Button>媒介契約一覧</Button>
              </Link>
              <CreateSakimonoButton>先物掲載新規作成</CreateSakimonoButton>
            </>
          )}
        </Flex>
      </Flex>
      <MediaCoverageMenu />
      <Box mt={2} mb={8}>
        <Suspense fallback={<CSpinner />}>
          <Outlet />
        </Suspense>
      </Box>
    </>
  )
}
