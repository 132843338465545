import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ReformAriCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['reform_flg']}
      display={(reform_flg) => reform_flg === miraieFields.reform_flg.Enum.有}
    >
      {children}
    </MiraieFieldCollapse>
  )
}
