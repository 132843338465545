import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { PropsWithChildren } from 'react'
import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary'

export const SakimonoBaitaiKeisaiIraiListErrorBoundary = (
  props: PropsWithChildren<ErrorBoundaryProps>,
) => {
  const { reset } = useQueryErrorResetBoundary()

  return <ErrorBoundary onReset={reset} {...props} />
}
