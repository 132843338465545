import { User } from '@terass/common/src/firestore/User'
import { doc, onSnapshot } from 'firebase/firestore'
import { useMemo } from 'react'

import { useObserver } from '@/hooks/useObserver'
import { useUser } from '@/hooks/useUser'
import { DocumentNotFoundError } from '@/utils/errors'
import { getCollection } from '@/utils/firestore'
import { updateUser } from '@/utils/user'

export const useOwnUser = <T>(selector: (user: User) => T): T => {
  const uid = useUser(({ uid }) => uid)
  return useObserver(
    {
      observerKey: ['ownUser', uid],
      observerFn: (onChange, onError) =>
        onSnapshot(doc(getCollection('users'), uid), (snapshot) => {
          const data = snapshot.data()
          if (data) onChange(data)
          else onError(new DocumentNotFoundError())
        }),
    },
    selector,
  )
}

export const useMasqueradeEmail = () =>
  useOwnUser(({ masquerade }) => masquerade)

export const useUpdateOwnUser = () => {
  const uid = useUser(({ uid }) => uid)
  return useMemo(() => updateUser.bind(null, uid), [uid])
}
