import { Flex, Text, InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
  Select,
  EnumOptions,
  PlaceholderOption,
} from '../controls'

import { CarParkingBunjoRyokin } from './CarParkingBunjoRyokin'

export const CarParkingSellRyokin: React.FC = () => {
  return (
    <>
      <FormControl fieldset>
        <FormLabel>管理費</FormLabel>
        <Flex gap={4} align="center">
          <FormControl fieldName="parking_kanrihi">
            <InputGroup>
              <NumberInput />
              <InputRightAddon>円</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <Text>/</Text>

          <FormControl fieldName="parking_kanrihi_tani_kbn">
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.parking_kanrihi_tani_kbn.Enum} />
            </Select>
            <PortalErrorMessages />
          </FormControl>
        </Flex>
      </FormControl>

      <FormControl fieldset>
        <FormLabel>修繕積立金</FormLabel>
        <Flex gap={4} align="center">
          <FormControl fieldName="parking_tsumitatekin">
            <InputGroup>
              <NumberInput />
              <InputRightAddon>円</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <Text>/</Text>

          <FormControl fieldName="parking_tsumitatekin_tani_kbn">
            <Select>
              <PlaceholderOption />
              <EnumOptions
                enum={miraieFields.parking_tsumitatekin_tani_kbn.Enum}
              />
            </Select>
            <PortalErrorMessages />
          </FormControl>
        </Flex>
      </FormControl>

      <FormControl fieldName="parking_tsumitatekikin">
        <FormLabel>修繕積立基金</FormLabel>
        <InputGroup>
          <NumberInput />
          <InputRightAddon>円</InputRightAddon>
        </InputGroup>
        <PortalErrorMessages />
      </FormControl>

      <CarParkingBunjoRyokin />
    </>
  )
}
