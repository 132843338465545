import { Image, ImageProps } from '@chakra-ui/react'
import { typeGuard } from '@terass/common/src/utils'
import { FieldPath, FieldValues, useWatch } from 'react-hook-form'

import { useImageInputContext } from '@/components/form/controls/ImageInput/ImageInputContext'
import { useFileAsDataURI } from '@/hooks/useFileAsDataURI'

export type ImageFileProps<T extends FieldValues> = Omit<ImageProps, 'src'> & {
  src: FieldPath<T>
}

export const ImageFile = <T extends FieldValues>({
  src: srcProp,
  ...props
}: ImageFileProps<T>) => {
  const watchedSrc: unknown = useWatch({ name: srcProp })
  const file: unknown = useWatch({ name: useImageInputContext().fieldName })
  if (!isFileOrUndefined(file) || !isStringOrUndefined(watchedSrc))
    throw new Error()
  const { dataURI } = useFileAsDataURI({ file })
  const src = dataURI || watchedSrc

  return <Image {...props} src={src} />
}

const isStringOrUndefined = typeGuard((arg) =>
  typeof arg === 'string' ? arg : undefined,
)

const isFileOrUndefined = typeGuard((arg) =>
  arg instanceof File ? arg : undefined,
)
