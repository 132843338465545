import { Flex, InputGroup, InputRightAddon, Text } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
  Select,
  EnumOptions,
  PlaceholderOption,
} from '../controls'

import { HaniEnumCheckbox } from './HaniEnumCheckbox'

import { FormGroup } from '@/components/form/FormGroup'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const CarParkingRyokin: React.FC = () => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })
  const isChintai = useIsChintai()

  return (
    <>
      <FormControl fieldset>
        <FormLabel>料金</FormLabel>
        <FormGroup nested>
          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.居住用
            }
          >
            <FormControl fieldName="parking_ryokin">
              <InputGroup>
                <NumberInput />
                <InputRightAddon>円 / 月</InputRightAddon>
              </InputGroup>
            </FormControl>
          </MiraieFieldContents>

          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.居住用
            }
          >
            <FormControl fieldName="parking_ryokin_hani_kbn">
              <HaniEnumCheckbox>料金を範囲で指定する</HaniEnumCheckbox>
              <PortalErrorMessages />
            </FormControl>

            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地
              }
            >
              <FormControl fieldName="parking_to_ryokin">
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>円 / 月</InputRightAddon>
                </InputGroup>
              </FormControl>
            </MiraieFieldContents>

            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.売地
              }
            >
              <Flex gap={2} align="center">
                <FormControl fieldName="parking_ryokin">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>円 / 月</InputRightAddon>
                  </InputGroup>
                </FormControl>

                <MiraieFieldContents
                  name={['parking_ryokin_hani_kbn']}
                  display={(parking_ryokin_hani_kbn) =>
                    parking_ryokin_hani_kbn ===
                    miraieFields.parking_ryokin_hani_kbn.Enum['～']
                  }
                >
                  <Text>～</Text>

                  <FormControl fieldName="parking_to_ryokin">
                    <InputGroup>
                      <NumberInput />
                      <InputRightAddon>円 / 月</InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </MiraieFieldContents>
              </Flex>
            </MiraieFieldContents>
          </MiraieFieldContents>

          <PortalErrorMessages fieldName="parking_ryokin" />
          <PortalErrorMessages fieldName="parking_to_ryokin" />
        </FormGroup>
      </FormControl>

      {(bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
        bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション ||
        isChintai) && (
        <>
          <FormControl fieldset>
            <FormLabel>礼金</FormLabel>
            <Flex gap={4} align="center">
              <FormControl fieldName="parking_reikin">
                <NumberInput step={miraieFields.parking_reikin.step} />
                <PortalErrorMessages />
              </FormControl>

              <FormControl fieldName="parking_reikin_tani_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions
                    enum={miraieFields.parking_reikin_tani_kbn.Enum}
                  />
                </Select>
                <PortalErrorMessages />
              </FormControl>
            </Flex>
          </FormControl>

          <FormControl fieldset>
            <FormLabel>敷金</FormLabel>
            <Flex gap={4} align="center">
              <FormControl fieldName="parking_shikikin">
                <NumberInput step={miraieFields.parking_shikikin.step} />
                <PortalErrorMessages />
              </FormControl>

              <FormControl fieldName="parking_shikikin_tani_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions
                    enum={miraieFields.parking_shikikin_tani_kbn.Enum}
                  />
                </Select>
                <PortalErrorMessages />
              </FormControl>
            </Flex>
          </FormControl>
        </>
      )}
    </>
  )
}
