import { useModalContentFormContext } from '@/components/form/Setsubi/ModalContentFormContext'
import { isCodeVisible } from '@/components/form/Setsubi/logic'
import { useWatchSetsubiTokkiForm } from '@/components/form/Setsubi/useSetsubiTokkiForm'

export const SetsubiCodeContents = ({
  code,
  children,
}: {
  code: string
  children: React.ReactNode
}) => {
  const portalsFilter = useWatchSetsubiTokkiForm('portalsFilter')
  const { bk_sbt_kbn } = useModalContentFormContext()
  const display = isCodeVisible({ code, bk_sbt_kbn, portalsFilter })

  return <>{display && children}</>
}
