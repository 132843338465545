import { Flex, InputGroup, InputRightAddon, Text } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { HaniEnumCheckbox } from '../PriceAndCost/HaniEnumCheckbox'

import { FormGroup } from '@/components/form/FormGroup'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { WatchMiraieFields } from '@/components/form/WatchMiraieFields'
import {
  EnumOptions,
  FormControl,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  Select,
} from '@/components/form/controls'

export const NobeyukaMenseki = () => {
  return (
    <MiraieFieldContents
      name={['bk_sbt_kbn']}
      display={(bk_sbt_kbn) =>
        bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
        bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
      }
    >
      <FormGroup>
        <WatchMiraieFields name={['bk_sbt_kbn']}>
          {(bk_sbt_kbn) => (
            <FormControl
              fieldset
              isRequired={
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
              }
            >
              <FormLabel>延床面積</FormLabel>
              <Flex gap={4} direction="column">
                {bk_sbt_kbn ===
                miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)'] ? (
                  <FormGroup>
                    <FormControl fieldName="taten_menseki_hani_kbn">
                      <HaniEnumCheckbox>面積を範囲で指定する</HaniEnumCheckbox>
                      <PortalErrorMessages />
                    </FormControl>

                    <FormControl fieldset>
                      <Flex gap={2} align="center">
                        <FormControl fieldName="taten_menseki">
                          <InputGroup>
                            <NumberInput
                              step={miraieFields.taten_menseki.step}
                            />
                            <InputRightAddon>㎡</InputRightAddon>
                          </InputGroup>
                        </FormControl>

                        <MiraieFieldContents
                          name={['taten_menseki_hani_kbn']}
                          display={(taten_menseki_hani_kbn) =>
                            taten_menseki_hani_kbn ===
                            miraieFields.taten_menseki_hani_kbn.Enum['～']
                          }
                        >
                          <Text>～</Text>

                          <FormControl fieldName="taten_menseki_to">
                            <InputGroup>
                              <NumberInput
                                step={miraieFields.taten_menseki_to.step}
                              />
                              <InputRightAddon>㎡</InputRightAddon>
                            </InputGroup>
                          </FormControl>
                        </MiraieFieldContents>
                      </Flex>

                      <PortalErrorMessages fieldName="taten_menseki" />
                      <PortalErrorMessages fieldName="taten_menseki_to" />
                    </FormControl>
                  </FormGroup>
                ) : (
                  <FormControl fieldName="taten_menseki">
                    <InputGroup>
                      <NumberInput step={miraieFields.taten_menseki.step} />
                      <InputRightAddon>㎡</InputRightAddon>
                    </InputGroup>
                    <PortalErrorMessages />
                  </FormControl>
                )}
              </Flex>
            </FormControl>
          )}
        </WatchMiraieFields>
        <FormControl fieldName="nobeyuka_kbn">
          <FormLabel>測定方法</FormLabel>
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.nobeyuka_kbn.Enum} />
          </Select>
          <PortalErrorMessages />
        </FormControl>
      </FormGroup>
    </MiraieFieldContents>
  )
}
