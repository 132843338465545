import { LatLng } from './types'

/**
 * 住所文字列から緯度、軽度を取得する
 * 取得に失敗した場合や結果が無い場合rejectする
 */
export const getLatLngByAddress = (address: string): Promise<LatLng> => {
  const geoCoder = new google.maps.Geocoder()
  return new Promise((resolve, reject) => {
    geoCoder.geocode(
      {
        address,
      },
      (result, status) => {
        if (status !== google.maps.GeocoderStatus.OK || !result?.[0]) {
          reject('Failed to get lat lng')
          return
        }
        resolve({
          lat: result[0].geometry.location.lat(),
          lng: result[0].geometry.location.lng(),
        })
      },
    )
  })
}
