import { Flex, Text, InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
  Select,
  EnumOptions,
  PlaceholderOption,
} from '../controls'

export const ChurinjoRyokin: React.FC = () => {
  return (
    <FormControl fieldset>
      <FormLabel>料金</FormLabel>
      <Flex gap={4} align="center">
        <FormControl fieldName="churinjo_price">
          <InputGroup>
            <NumberInput />
            <InputRightAddon>円</InputRightAddon>
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>

        <Text>/</Text>

        <FormControl fieldName="churinjo_price_kbn">
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.churinjo_price_kbn.Enum} />
          </Select>
          <PortalErrorMessages />
        </FormControl>
      </Flex>
    </FormControl>
  )
}
