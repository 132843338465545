import {
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps,
} from '@chakra-ui/react'

import { useCreateBaitaiKeisaiFormControlContext } from '@/components/CreateBaitaiKeisaiForm/FormControlContext'
import { useCreateBaitaiKeisaiFormState } from '@/hooks/useCreateBaitaiKeisaiForm'

export const FormErrorMessage = (props: FormErrorMessageProps) => {
  const { fieldName } = useCreateBaitaiKeisaiFormControlContext()
  const { errors } = useCreateBaitaiKeisaiFormState({ name: fieldName })

  return (
    <ChakraFormErrorMessage {...props}>
      {errors[fieldName]?.message}
    </ChakraFormErrorMessage>
  )
}
