import { Flex } from '@chakra-ui/react'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import {
  FormControl,
  FormHelperText,
  FormLabel,
  MonthOptions,
  PlaceholderOption,
  PortalErrorMessages,
  Select,
  Textarea,
  YearOptions,
} from '@/components/form/controls'

export const SonotaControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>その他</FormGroupHeading>
      <FormControl fieldset>
        <FormLabel>実施年月</FormLabel>
        <Flex gap={2}>
          <FormControl fieldName="reform_other_ym_year">
            <Select>
              <PlaceholderOption />
              <YearOptions />
            </Select>
          </FormControl>
          <FormControl fieldName="reform_other_ym_month">
            <Select>
              <PlaceholderOption />
              <MonthOptions />
            </Select>
          </FormControl>
        </Flex>
        <FormHelperText>
          HOME'Sフォーマット使用ポータルはリフォーム実施年月が未来の年月の場合はリフォーム情報は反映されません。
        </FormHelperText>
        <PortalErrorMessages fieldName="reform_other_ym_year" />
        <PortalErrorMessages fieldName="reform_other_ym_month" />
      </FormControl>
      <FormControl fieldName="reform_other_enforcement_content">
        <FormLabel>実施内容</FormLabel>
        <Textarea />
        <PortalErrorMessages />
      </FormControl>
    </FormGroup>
  )
}
