import z from 'zod'

export type TeraSeven = z.infer<typeof TeraSeven>
export const TeraSeven = z.object({
  keisaiKikan: z
    .object({
      suumo: optionalDateRange(),
      homes: optionalDateRange(),
      athome: optionalDateRange(),
      rakumachi: optionalDateRange(),
    })
    .optional(),
})

function optionalDateRange() {
  return z
    .object({
      from: optionalString(),
      to: optionalString(),
    })
    .optional()
}

function optionalString() {
  return z.string().optional()
}
