import { ErrorPortal } from '@terass/common/src/portal'
import { exhaustiveCheck } from '@terass/common/src/utils'

import { SvgProps } from '@/components/Svg'
import { AthomeIcon } from '@/components/icons/AthomeIcon'
import { CommonErrorIcon } from '@/components/icons/CommonErrorIcon'
import { HomesIcon } from '@/components/icons/HomesIcon'
import { RakumachiIcon } from '@/components/icons/RakumachiIcon'
import { SuumoIcon } from '@/components/icons/SuumoIcon'

export type PortalIconProps = {
  portal: ErrorPortal
} & SvgProps

export const PortalIcon = ({ portal, ...props }: PortalIconProps) => {
  switch (portal) {
    case 'suumo':
      return <SuumoIcon {...props} />
    case 'athome':
      return <AthomeIcon {...props} />
    case 'homes':
      return <HomesIcon {...props} />
    case 'rakumachi':
      return <RakumachiIcon {...props} />
    case '公取':
      return <CommonErrorIcon {...props} />
    case 'logParam':
      return <CommonErrorIcon {...props} />
    case 'validate':
      return <CommonErrorIcon {...props} />
    default:
      throw exhaustiveCheck(portal)
  }
}
