import { createContext } from '@/hooks/useContext'
import { gridTemplate } from '@/utils/gridTemplate'

export type ImageControlsContext = {
  index: number
}

export const [Provider, useImageControlsContext] =
  createContext<ImageControlsContext>()

export const { style: gridTemplateStyle, getArea: getImageControlsArea } =
  gridTemplate`
  "${'_imageFile'} ${'shisetsu_sbt_kbn'} ${'shisetsu_kyori'} ${'button'}" auto
  "${'_imageFile'} ${'shisetsu_name'}   ${'shisetsu_name'} ${'shisetsu_name'}" 1fr
  / min-content    4fr              3fr                  min-content
  `
