import {
  FieldError,
  FieldErrors,
  FieldPath,
  FieldValues,
  get,
} from 'react-hook-form'

export function getFieldError<T extends FieldValues>(
  errors: FieldErrors<T>,
  path: FieldPath<T>,
): FieldError | undefined {
  return get(errors, path)
}
