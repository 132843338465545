import {
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps,
} from '@chakra-ui/react'

import { useFormControlContext } from '@/components/form/controls/FormControl'
import { useMiraieFormController } from '@/hooks/useMiraieForm'

export const RadioGroup = (props: RadioGroupProps) => {
  const { fieldName } = useFormControlContext()
  if (!fieldName) throw new Error()

  const { field } = useMiraieFormController({ name: fieldName })
  const value = field.value != null ? String(field.value) : undefined

  return (
    <ChakraRadioGroup
      {...field}
      value={value}
      display="inline-flex"
      flexDirection="column"
      gap={2}
      {...props}
    />
  )
}
