import { Button, ButtonProps } from '@chakra-ui/react'

import { useMiraieFormState } from '@/hooks/useMiraieForm'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const MiraieFormSubmitButton = (props: ButtonProps) => {
  const { isSubmitting } = useMiraieFormState()
  const status = useV1ImportDocument(({ status }) => status)

  return status === 'draft' ? (
    <Button
      type="submit"
      colorScheme="green"
      isLoading={isSubmitting}
      {...props}
    />
  ) : null
}
