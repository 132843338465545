import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const KeiyakuKikanCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse name={['teishaku_keiyaku_flg']} display={Boolean}>
      {children}
    </MiraieFieldCollapse>
  )
}
