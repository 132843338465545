import { Box, Card, CardBody } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { sectionIds } from '../SectionBar'

import { FormSectionDescription } from '@/components/form/FormSectionDescription'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import {
  EnumOptions,
  FormControl,
  FormLabel,
  Select,
} from '@/components/form/controls'

export const CategorySection: React.FC = () => {
  return (
    <Box as="section" id={sectionIds.categoryField}>
      <FormSectionHeading>基本種別</FormSectionHeading>
      <FormSectionDescription>
        これらの項目はTerass Converterからは変更できません。
      </FormSectionDescription>
      <Card>
        <CardBody>
          <FormGroup>
            <FormControl fieldName="bk_sbt_kbn">
              <FormLabel>物件種別</FormLabel>
              <Select isDisabled>
                <EnumOptions enum={miraieFields.bk_sbt_kbn.Enum} />
              </Select>
            </FormControl>
            <FormControl fieldName="bk_item_kbn">
              <FormLabel>物件種目</FormLabel>
              <Select isDisabled>
                <EnumOptions enum={miraieFields.bk_item_kbn.Enum} />
              </Select>
            </FormControl>
          </FormGroup>
        </CardBody>
      </Card>
    </Box>
  )
}
