import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  EnumRadios,
  UnselectedRadio,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { ChurinjoDetailCollapse } from '@/components/form/PriceAndCost/ChurinjoDetailCollapse'
import { ChurinjoRyokinControl } from '@/components/form/PriceAndCost/ChurinjoRyokinControl'

export const Churinjo: React.FC = () => {
  return (
    <>
      <FormControl fieldName="churinjo_umu_kbn" fieldset>
        <FormLabel>駐輪場</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.churinjo_umu_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <ChurinjoDetailCollapse>
        <FormGroup nested>
          <ChurinjoRyokinControl />
        </FormGroup>
      </ChurinjoDetailCollapse>
    </>
  )
}
