import * as bk_sbt_kbn from '../../bk_sbt_kbn'

import { categoryMap } from './categoryMap'
import { values } from './values'

type Values = {
  img_no: (typeof values)[number]
  bk_sbt_kbn: bk_sbt_kbn.Enum
}

export const getCategory = ({ img_no, bk_sbt_kbn }: Values) =>
  categoryMap[img_no][bk_sbt_kbn]
