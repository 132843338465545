import {
  Card,
  Text,
  Stack,
  CardBody,
  HStack,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { swapKeyValue } from '@terass/common/src/utils'
import { useState } from 'react'

import { PlaceCondition, MapService, Place } from './utils'

export const PlaceAccordionItem = ({
  placeCondition,
  addPlace,
  mapService,
}: {
  placeCondition: PlaceCondition
  addPlace: (place: Place) => void
  mapService: MapService
}) => {
  const [places, setPlaces] = useState<Place[]>([])

  const showPlaces = () => {
    if (places.length > 0) return

    mapService.nearbySearch(placeCondition, setPlaces)
  }

  return (
    <AccordionItem>
      <AccordionButton onClick={showPlaces}>
        <Box as="span" flex="1" textAlign="left">
          {
            swapKeyValue(miraieFields.shisetsu.shisetsu_sbt_kbn.Enum)[
              placeCondition.kubun
            ]
          }
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4} gap={4} display="flex" flexDirection="column">
        {places.map((place, i) => (
          <Card
            key={i}
            _hover={{ borderColor: 'gray.300', bgColor: 'gray.100' }}
            width="100%"
          >
            <CardBody
              as={HStack}
              onClick={() => {
                addPlace(place)
              }}
            >
              <Stack>
                <Text fontWeight="bold">{place.name}</Text>
                <Text>
                  直線距離:
                  {place.directDistance}m
                </Text>
              </Stack>
            </CardBody>
          </Card>
        ))}
      </AccordionPanel>
    </AccordionItem>
  )
}
