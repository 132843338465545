import { useCallback, useSyncExternalStore } from 'react'

export function useObserver<TData, const TObserverKey extends ObserverKey>(
  options: ObserverOptions<TData, TObserverKey>,
): TData

export function useObserver<
  TData,
  const TObserverKey extends ObserverKey,
  TSelect,
>(
  options: ObserverOptions<TData, TObserverKey>,
  selector: (data: TData) => TSelect,
): TSelect

export function useObserver<
  TData,
  const TObserverKey extends ObserverKey,
  TSelect,
>(
  options: ObserverOptions<TData, TObserverKey>,
  selector?: (data: TData) => TSelect,
): TData | TSelect {
  const subscription = observerClient.subscribe(options)
  return useSyncExternalStore(
    useCallback((onChange) => subscription.subscribe(onChange), [subscription]),
    () =>
      selector
        ? selector(subscription.suspenseValue)
        : subscription.suspenseValue,
  )
}

export function observerOptions<TData, const TObserverKey extends ObserverKey>(
  options: ObserverOptions<TData, TObserverKey>,
) {
  return options
}

type ObserverKey = readonly unknown[]

type Unsubscribe = () => void

type ObserverOptions<TData, TObserverKey extends ObserverKey> = {
  observerKey: TObserverKey
  observerFn: (
    onChange: (data: TData) => void,
    onError: (error: unknown) => void,
    context: {
      observerKey: TObserverKey
    },
  ) => Unsubscribe
  gcTime?: number
  observerKeyHashFn?: (observerKey: TObserverKey) => string
  dataType?: TaggedData<TData>
}

type TaggedData<TData> = {
  [dataTag]?: TData
}

declare const dataTag: unique symbol

export function asDataTag<TData>(): TaggedData<TData> {
  return {}
}

type SubscriptionState<T = unknown> =
  | {
      state: 'pending'
      promise: Promise<T>
    }
  | {
      state: 'fulfilled'
      value: T
    }
  | {
      state: 'error'
      error: unknown
    }

function getSubscriptionHash<TData, TObserverKey extends ObserverKey>({
  observerKey,
  observerKeyHashFn = JSON.stringify,
}: ObserverOptions<TData, TObserverKey>) {
  return observerKeyHashFn(observerKey)
}

export class ObserverClient {
  #subscriptionCache = new SubscriptionCache()

  subscribe<TData, TObserverKey extends ObserverKey>(
    options: ObserverOptions<TData, TObserverKey>,
  ) {
    const subscription = this.#subscriptionCache.get(options)
    subscription.mount()
    return subscription
  }

  setInitialValue<TData, TObserverKey extends ObserverKey>(
    options: ObserverOptions<TData, TObserverKey>,
    value: TData,
  ) {
    this.#subscriptionCache.get(options).setInitialValue(value)
  }
}

export class SubscriptionCache {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #cacheStore = new Map<string, Subscription<any, any>>()

  get<TData, TObserverKey extends ObserverKey>(
    options: ObserverOptions<TData, TObserverKey>,
  ): Subscription<TData, TObserverKey> {
    const key = getSubscriptionHash(options)
    const cache = this.#cacheStore.get(key)
    if (cache)
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return cache as Subscription<TData, TObserverKey>

    const sub = new Subscription(options, this)
    this.#cacheStore.set(key, sub)
    return sub
  }

  delete<TData, TObserverKey extends ObserverKey>(
    options: ObserverOptions<TData, TObserverKey>,
  ) {
    this.#cacheStore.delete(getSubscriptionHash(options))
  }
}

type InitialValue<TData> =
  | {
      initialized: false
    }
  | {
      initialized: true
      value: TData
    }

export class Subscription<TData, TObserverKey extends ObserverKey> {
  #listeners = new Set<() => void>()
  #gcId: number | null = null
  #unsubscribe: Unsubscribe | null = null
  #resolvers = Promise.withResolvers<TData>()
  #options: ObserverOptions<TData, TObserverKey>
  #cacheStore: SubscriptionCache
  #state: SubscriptionState<TData> = {
    state: 'pending',
    promise: this.#resolvers.promise,
  }
  #initialValue: InitialValue<TData> = {
    initialized: false,
  }

  constructor(
    options: ObserverOptions<TData, TObserverKey>,
    cacheStore: SubscriptionCache,
  ) {
    this.#options = options
    this.#cacheStore = cacheStore
  }

  #setState(state: SubscriptionState<TData>) {
    this.#state = state
    this.trigger()
  }

  mount() {
    if (this.#unsubscribe) return

    const { resolve, reject } = this.#resolvers
    this.#unsubscribe = this.#options.observerFn(
      (data) => {
        resolve(data)
        this.#setState({
          state: 'fulfilled',
          value: data,
        })
      },
      (error) => {
        reject(error)
        this.#setState({
          state: 'error',
          error,
        })
      },
      { observerKey: this.#options.observerKey },
    )
  }

  subscribe(onChange: () => void) {
    this.#listeners.add(onChange)
    if (this.#gcId !== null) {
      clearTimeout(this.#gcId)
      this.#gcId = null
    }
    return () => {
      this.#listeners.delete(onChange)
      if (!this.#listeners.size) {
        this.#gcId = window.setTimeout(() => {
          this.#unsubscribe?.()
          this.#cacheStore.delete(this.#options)
        }, this.#options.gcTime)
      }
    }
  }

  setInitialValue(value: TData) {
    this.#initialValue = {
      initialized: true,
      value,
    }
    this.trigger()
  }

  trigger() {
    this.#listeners.forEach((listener) => listener())
  }

  get suspenseValue(): TData {
    if (this.#state.state === 'pending') {
      if (this.#initialValue.initialized) return this.#initialValue.value
      else throw this.#state.promise
    }
    if (this.#state.state === 'error') throw this.#state.error
    return this.#state.value
  }
}

export const observerClient = new ObserverClient()
