import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const ColumnSuumoSectionContents = ({ children }: PropsWithChildren) => {
  const [suumo_c_open_kbn] = useWatchMiraieFields({
    name: ['suumo_c_open_kbn'],
  })
  const isChintai = useIsChintai()
  const isOpenSuumo =
    suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載 ||
    suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載期間外
  const display = !isChintai && isOpenSuumo

  return <>{display && children}</>
}
