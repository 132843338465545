import { useSuspenseQuery } from '@tanstack/react-query'
import { miraieFields } from '@terass/common/src'
import { PostMiraieV1Import } from '@terass/common/src/miraie/PostMiraieV1Import'
import { doc, getDoc } from 'firebase/firestore'

import { useWatchAddressCodes } from '@/components/form/General/Address/hooks/useWatchAddressCodes'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'
import { getCollection } from '@/utils/firestore'

type AddressPath = {
  prefectureCode: string
  shikuchosonCode: string
  ooazaCode: string
  azaCode: string
}
async function getAddressName({
  prefectureCode,
  shikuchosonCode,
  ooazaCode,
  azaCode,
}: AddressPath) {
  if (prefectureCode === miraieFields.gyosei_cd.prefecture.Code.未選択) {
    return ''
  }

  const prefRef = doc(getCollection('address'), prefectureCode)
  const prefNameBase = (await getDoc(prefRef)).data()?.name ?? ''
  const prefName = prefNameBase === '' ? prefNameBase : `${prefNameBase} `

  if (shikuchosonCode === miraieFields.gyosei_cd.shikuchoson.Code.未選択) {
    return prefName
  }

  const shikuRef = doc(
    getCollection('address', prefectureCode, 'shikuchoson'),
    shikuchosonCode,
  )
  const shikuNameBase = (await getDoc(shikuRef)).data()?.name ?? ''
  const shikuName = shikuNameBase.replace('　', '')

  if (ooazaCode === miraieFields.aza_cd.ooaza.Code.未選択) {
    return `${prefName}${shikuName}`
  }

  const ooazaRef = doc(
    getCollection(
      'address',
      prefectureCode,
      'shikuchoson',
      shikuchosonCode,
      'ooaza',
    ),
    ooazaCode,
  )
  const ooazaName = (await getDoc(ooazaRef)).data()?.name ?? ''

  if (azaCode === miraieFields.aza_cd.aza.Code.未選択) {
    return `${prefName}${shikuName}${ooazaName}`
  }

  const azaRef = doc(
    getCollection(
      'address',
      prefectureCode,
      'shikuchoson',
      shikuchosonCode,
      'ooaza',
      ooazaCode,
      'aza',
    ),
    azaCode,
  )
  const azaName = (await getDoc(azaRef)).data()?.name ?? ''

  return `${prefName}${shikuName}${ooazaName}${azaName}`
}

export const useAddressName = () => {
  const codes = useWatchAddressCodes()
  const [banchi, chiban, partition_name] = useMiraieFormContext().getValues([
    'banchi',
    'chiban',
    'partition_name',
  ])

  const addressName = useSuspenseQuery({
    queryKey: [
      'useAddressName',
      codes.prefectureCode,
      codes.shikuchosonCode,
      codes.ooazaCode,
      codes.azaCode,
    ],
    queryFn() {
      return getAddressName(codes)
    },
  })

  return `${addressName.data}${banchi ?? ''}${chiban ?? ''}${partition_name ?? ''}`
}

export type FullAddressPath = AddressPath & {
  banchi: PostMiraieV1Import['banchi']
  chiban: PostMiraieV1Import['chiban']
  partition_name: PostMiraieV1Import['partition_name']
}

export async function getFullAddressName(addressPath: FullAddressPath) {
  const addressName = await getAddressName(addressPath)
  return `${addressName}${addressPath.banchi}${addressPath.chiban}${addressPath.partition_name}`
}
