import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { useMatchRoute, useNavigate } from '@tanstack/react-router'

export function MediaCoverageMenu() {
  const matchRoute = useMatchRoute()
  const matchedRoute = types
    .flatMap((type) => statuses.map((status) => [type, status] as const))
    .find(([type, status]) => matchRoute({ to: `/${type}/${status}` }))
  const navigate = useNavigate()
  // FIXME: マッチするパスでしか使わない場合 undefined になることはないはずだが、 Link コンポーネントからの遷移だと undefined になってしまうことがある
  if (!matchedRoute) return null

  const [routeType, routeStatus] = matchedRoute

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        掲載種別
      </MenuButton>
      <MenuList>
        <MenuOptionGroup type="radio" title="契約種別" value={routeType}>
          {types.map((type) => (
            <MenuItemOption
              key={type}
              value={type}
              onClick={() =>
                navigate({ to: `/${type}/${routeStatus}` as const })
              }
            >
              {typeLabel[type]}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        <MenuOptionGroup type="radio" title="掲載状態" value={routeStatus}>
          {statuses.map((status) => (
            <MenuItemOption
              key={status}
              value={status}
              onClick={() =>
                navigate({ to: `/${routeType}/${status}` as const })
              }
            >
              {statusLabel[status]}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

const types = ['contracts', 'futures'] as const

const typeLabel = {
  futures: '先物掲載',
  contracts: '売却媒介契約',
} as const

const statuses = ['drafts', 'publishes', 'closes'] as const

const statusLabel = {
  drafts: '下書き',
  publishes: '掲載中',
  closes: '掲載停止中',
}
