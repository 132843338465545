import { PrefectureEnum } from './enum'

export const ekiSupportedPrefectures: {
  [x: string]: boolean
} = {
  [PrefectureEnum.北海道]: false,
  [PrefectureEnum.青森県]: false,
  [PrefectureEnum.岩手県]: false,
  [PrefectureEnum.宮城県]: false,
  [PrefectureEnum.秋田県]: false,
  [PrefectureEnum.山形県]: false,
  [PrefectureEnum.福島県]: false,
  [PrefectureEnum.茨城県]: false,
  [PrefectureEnum.栃木県]: false,
  [PrefectureEnum.群馬県]: false,
  [PrefectureEnum.埼玉県]: false,
  [PrefectureEnum.千葉県]: false,
  [PrefectureEnum.東京都]: false,
  [PrefectureEnum.神奈川県]: false,
  [PrefectureEnum.新潟県]: false,
  [PrefectureEnum.富山県]: false,
  [PrefectureEnum.石川県]: false,
  [PrefectureEnum.福井県]: false,
  [PrefectureEnum.山梨県]: false,
  [PrefectureEnum.長野県]: false,
  [PrefectureEnum.岐阜県]: true,
  [PrefectureEnum.静岡県]: true,
  [PrefectureEnum.愛知県]: true,
  [PrefectureEnum.三重県]: true,
  [PrefectureEnum.滋賀県]: false,
  [PrefectureEnum.京都府]: false,
  [PrefectureEnum.大阪府]: false,
  [PrefectureEnum.兵庫県]: false,
  [PrefectureEnum.奈良県]: false,
  [PrefectureEnum.和歌山県]: false,
  [PrefectureEnum.鳥取県]: false,
  [PrefectureEnum.島根県]: false,
  [PrefectureEnum.岡山県]: false,
  [PrefectureEnum.広島県]: false,
  [PrefectureEnum.山口県]: false,
  [PrefectureEnum.徳島県]: false,
  [PrefectureEnum.香川県]: false,
  [PrefectureEnum.愛媛県]: false,
  [PrefectureEnum.高知県]: false,
  [PrefectureEnum.福岡県]: false,
  [PrefectureEnum.佐賀県]: false,
  [PrefectureEnum.長崎県]: false,
  [PrefectureEnum.熊本県]: false,
  [PrefectureEnum.大分県]: false,
  [PrefectureEnum.宮崎県]: false,
  [PrefectureEnum.鹿児島県]: false,
  [PrefectureEnum.沖縄県]: false,
}
