import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { miraieFields } from '@terass/common/src'
import { V1Import } from '@terass/common/src/firestore/V1Import'
import {
  DocumentReference,
  DocumentSnapshot,
  UpdateData,
  doc,
  getDoc,
  orderBy,
  query,
  snapshotEqual,
  where,
} from 'firebase/firestore'

import {
  UsePaginatedQueryProps,
  usePaginatedQuery,
} from '@/hooks/usePaginatedQuery'
import { getCollection } from '@/utils/firestore'

export type UseV1ImportCollectionProps = {
  where: {
    status: V1Import['status']
    readerEmail: string
    sakimono: boolean
  }
  orderBy: {
    field: keyof Pick<V1Import, 'draftedAt' | 'publishedAt' | 'closedAt'>
    direction: 'asc' | 'desc'
  }
  cursor: string | undefined
} & Pick<
  UsePaginatedQueryProps<V1Import>,
  'direction' | 'onNext' | 'onPrevious'
>

export function useV1ImportCollection(props: UseV1ImportCollectionProps) {
  const {
    where: { status, readerEmail, sakimono },
    cursor,
    direction,
    onNext,
    onPrevious,
  } = props
  const cursorSnapshot = useCursorSnapshot(
    cursor ? doc(getCollection('v1import'), cursor) : null,
  )
  const setCursorSnapshot = useSetCursorSnapshot<V1Import>()

  return usePaginatedQuery({
    queryDeps: [
      status,
      readerEmail,
      props.orderBy.field,
      props.orderBy.direction,
      sakimono,
    ],
    query: query(
      getCollection('v1import'),
      where('status' satisfies keyof V1Import, '==', status),
      where(
        '__readers' satisfies keyof V1Import,
        'array-contains',
        readerEmail,
      ),
      where(
        'formData.sakimono_flg' satisfies keyof UpdateData<V1Import>,
        '==',
        miraieFields.sakimono_flg.Enum[sakimono ? '先物' : '自社'],
      ),
      orderBy(
        props.orderBy.field satisfies keyof V1Import,
        props.orderBy.direction,
      ),
    ),
    pageSize: 10,
    onNext: (snapshot) => {
      setCursorSnapshot(snapshot)
      onNext(snapshot)
    },
    onPrevious: (snapshot) => {
      setCursorSnapshot(snapshot)
      onPrevious(snapshot)
    },
    cursorSnapshot,
    direction,
  })
}

function cursorSnapshotQueryOptions<T>(cursorRef: DocumentReference<T> | null) {
  return queryOptions({
    queryKey: ['firestore', 'DocumentSnapshot', cursorRef?.path],
    queryFn: () => (cursorRef ? getDoc(cursorRef) : null),
    structuralSharing: (oldData, newData) =>
      oldData instanceof DocumentSnapshot && newData instanceof DocumentSnapshot
        ? snapshotEqual(oldData, newData)
          ? oldData
          : newData
        : newData,
  })
}

function useCursorSnapshot<T>(cursorRef: DocumentReference<T> | null) {
  return useSuspenseQuery(cursorSnapshotQueryOptions(cursorRef)).data
}

function useSetCursorSnapshot<T>() {
  const queryClient = useQueryClient()
  return (snapshot: DocumentSnapshot<T>) =>
    queryClient.setQueryData(
      cursorSnapshotQueryOptions(snapshot.ref).queryKey,
      snapshot,
    )
}
