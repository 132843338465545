import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  EnumOptions,
  FormControl,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  Select,
} from '@/components/form/controls'

export const BunjouHanbai = () => {
  return (
    <FormGroup>
      <FormGroupHeading>分譲販売</FormGroupHeading>

      <FormControl fieldName="ikkatsu_flg">
        <FormLabel>分譲販売</FormLabel>
        <Select>
          <PlaceholderOption />
          <EnumOptions enum={miraieFields.ikkatsu_flg.Enum} />
        </Select>
        <PortalErrorMessages />
      </FormControl>

      <MiraieFieldCollapse
        name={['ikkatsu_flg']}
        display={(ikkatsu_flg) =>
          ikkatsu_flg === miraieFields.ikkatsu_flg.Enum.一括分譲販売
        }
      >
        <FormGroup nested>
          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
            }
          >
            <RangeInput
              label="販売価格帯"
              from="ikkatsu_hanbai_price_from"
              to="ikkatsu_hanbai_price_to"
              suffix="円"
            />
          </MiraieFieldContents>

          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
            }
          >
            <RangeInput
              label="管理費帯"
              from="ikkatsu_mansion_kanrihi_from"
              to="ikkatsu_mansion_kanrihi_to"
              suffix="円"
            />
            <RangeInput
              label="積立金帯"
              from="ikkatsu_mansion_tsumitatekin_from"
              to="ikkatsu_mansion_tsumitatekin_to"
              suffix="円"
            />
          </MiraieFieldContents>

          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
            }
          >
            <RangeInput
              label="販売専有/建物面積帯"
              from="ikkatsu_hanbai_menseki_from"
              to="ikkatsu_hanbai_menseki_to"
              suffix="㎡"
              step={miraieFields.ikkatsu_hanbai_menseki.step}
            />
          </MiraieFieldContents>

          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
            }
          >
            <RangeInput
              label="販売土地面積帯"
              from="ikkatsu_hanbai_tochi_from"
              to="ikkatsu_hanbai_tochi_to"
              suffix="㎡"
              step={miraieFields.ikkatsu_hanbai_tochi.step}
            />
          </MiraieFieldContents>
        </FormGroup>
      </MiraieFieldCollapse>

      <FormGroup nested>
        <FormControl fieldName="ikkatsu_bunjo_kukakusu">
          <InputGroup>
            <InputLeftAddon>区画数/戸数</InputLeftAddon>
            <NumberInput />
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>
      </FormGroup>
    </FormGroup>
  )
}

type RangeInputProps = {
  label: string
  from: keyof PostMiraieV1ImportForm
  to: keyof PostMiraieV1ImportForm
  suffix: string
  step?: number
}

const RangeInput = ({ label, from, to, suffix, step }: RangeInputProps) => (
  <FormControl fieldset>
    <FormLabel>{label}</FormLabel>
    <Flex gap={2} alignItems="center">
      <FormControl fieldName={from}>
        <InputGroup>
          <NumberInput step={step} />
          <InputRightAddon>{suffix}</InputRightAddon>
        </InputGroup>
      </FormControl>
      <Text>～</Text>
      <FormControl fieldName={to}>
        <InputGroup>
          <NumberInput step={step} />
          <InputRightAddon>{suffix}</InputRightAddon>
        </InputGroup>
      </FormControl>
    </Flex>
    <PortalErrorMessages fieldName={from} />
    <PortalErrorMessages fieldName={to} />
  </FormControl>
)
