import * as bk_sbt_kbn from '../../bk_sbt_kbn'
import * as category from '../category'

import { availableMap } from './availableMap'
import * as img_sbt_kbn from './enum'

type Values = {
  img_sbt_kbn: img_sbt_kbn.Enum
  bk_sbt_kbn: bk_sbt_kbn.Enum
  category: category.Enum
}

export const isAvailable = ({
  img_sbt_kbn,
  bk_sbt_kbn,
  category,
}: Values): boolean => availableMap[img_sbt_kbn][bk_sbt_kbn][category] === true
