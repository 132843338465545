import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  ModalBody,
  ModalContent,
  ModalHeader,
  useModalContext,
  Tooltip,
  Text,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { FormEventHandler } from 'react'

import { Contents } from '@/components/Contents'
import { ModalContentFormContextProvider } from '@/components/form/Setsubi/ModalContentFormContext'
import { PortalCodeCount } from '@/components/form/Setsubi/PortalCodeCount'
import { PortalSearchKeyIconContainer } from '@/components/form/Setsubi/PortalSearchKeyIconContainer'
import { SetsubiCodeContents } from '@/components/form/Setsubi/SetsubiCodeContents'
import { SetsubiGroupContents } from '@/components/form/Setsubi/SetsubiGroupContents'
import { useSetsubiTokkiFormContext } from '@/components/form/Setsubi/useSetsubiTokkiForm'
import { PortalIcon } from '@/components/icons'

type Props = {
  updateCodes: (codes: string[]) => void
  bk_sbt_kbn: miraieFields.bk_sbt_kbn.Enum
}

export const ModalContentForm = ({ updateCodes, bk_sbt_kbn }: Props) => {
  const { handleSubmit, register } = useSetsubiTokkiFormContext()
  const onSubmit: FormEventHandler = (e) => {
    e.stopPropagation()

    handleSubmit(({ codes }) => {
      updateCodes(codes)
    })(e)
  }
  const { onClose } = useModalContext()

  return (
    <ModalContentFormContextProvider value={{ bk_sbt_kbn }}>
      <ModalContent>
        <Contents as="form" onSubmit={onSubmit}>
          <ModalHeader
            borderColor="gray.200"
            borderStyle="solid"
            borderBottomWidth="thin"
          >
            <Text>設備・特記</Text>
            <FormControl as="fieldset">
              <FormLabel as="legend">ポータル絞込</FormLabel>
              <Grid
                gap={2}
                templateColumns="repeat(3, 1fr)"
                width="min-content"
              >
                {(['suumo', 'homes', 'athome'] as const).map((portal) => (
                  <Grid key={portal} justifyItems="center">
                    <Checkbox
                      value={portal}
                      {...register('portalsFilter')}
                      id={`portals-filter-${portal}`}
                    >
                      <Flex display="inline-flex" alignItems="center" gap={1}>
                        {
                          {
                            suumo: 'SUUMO',
                            homes: "HOME'S",
                            athome: 'athome',
                          }[portal]
                        }
                        <PortalSearchKeyIconContainer>
                          <PortalIcon portal={portal} />
                        </PortalSearchKeyIconContainer>
                      </Flex>
                    </Checkbox>
                    <Box fontWeight="normal" fontSize="md">
                      <PortalCodeCount portal={portal} />
                      <span>/</span>
                      {
                        {
                          suumo: 60,
                          athome: 80,
                          homes: '制限なし',
                        }[portal]
                      }
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </FormControl>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Grid
              mx="auto"
              rowGap={2}
              columnGap={4}
              templateColumns={{
                md: 'repeat(3, 1fr)',
                base: 'repeat(2, 1fr)',
              }}
              alignItems="center"
              justifyContent="center"
            >
              {Object.entries(miraieFields.setsubi_kbn_renketsu.group).map(
                ([group, codes]) => (
                  <SetsubiGroupContents key={group} codes={codes}>
                    <Box as="fieldset" display="contents">
                      <FormLabel
                        as="legend"
                        fontWeight="bold"
                        fontSize="lg"
                        gridColumn="1 / -1"
                        mt={2}
                        mb={0}
                      >
                        {group}
                      </FormLabel>
                      {codes.map((code) => {
                        const setsubiTokki =
                          miraieFields.setsubi_kbn_renketsu.code[code]

                        const portalState = setsubiTokki[bk_sbt_kbn]

                        return (
                          <SetsubiCodeContents key={code} code={code}>
                            <Flex gap={1} overflow="hidden">
                              <Checkbox
                                value={code}
                                flexGrow={1}
                                {...register('codes')}
                                id={`setsubi-codes-${code}`}
                                overflow="hidden"
                                sx={{
                                  '.chakra-checkbox__label': {
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  },
                                }}
                              >
                                {setsubiTokki.name}
                              </Checkbox>

                              {(['suumo', 'homes', 'athome'] as const).map(
                                (portal) =>
                                  portalState?.[portal] && (
                                    <Tooltip
                                      key={portal}
                                      label={
                                        portalState[portal] === 'key'
                                          ? 'ポータル側エンドサイトより、物件検索する際に絞り込み検索できる設備・特記の項目です。'
                                          : undefined
                                      }
                                    >
                                      <Box>
                                        <PortalSearchKeyIconContainer
                                          searchKey={
                                            portalState[portal] === 'key'
                                          }
                                        >
                                          <PortalIcon portal={portal} />
                                        </PortalSearchKeyIconContainer>
                                      </Box>
                                    </Tooltip>
                                  ),
                              )}
                            </Flex>
                          </SetsubiCodeContents>
                        )
                      })}
                    </Box>
                  </SetsubiGroupContents>
                ),
              )}
            </Grid>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button colorScheme="gray" onClick={onClose}>
              キャンセル
            </Button>
            <Button type="submit">完了</Button>
          </ModalFooter>
        </Contents>
      </ModalContent>
    </ModalContentFormContextProvider>
  )
}
