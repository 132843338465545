import { Flex, Tag } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const SetsubiTokkiTags = () => {
  const [setsubi_kbn_renketsu] = useWatchMiraieFields({
    name: ['setsubi_kbn_renketsu'],
  })
  if (!setsubi_kbn_renketsu) return null

  return (
    <Flex gap={2} wrap="wrap">
      {setsubi_kbn_renketsu.map((code) => {
        const name = miraieFields.setsubi_kbn_renketsu.getDetail(code)?.name

        return (
          name && (
            <Tag
              key={code}
              colorScheme="gray"
              variant="outline"
              whiteSpace="nowrap"
            >
              {name}
            </Tag>
          )
        )
      })}
    </Flex>
  )
}
