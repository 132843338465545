import { BoxProps } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FieldLength } from '@/components/form/controls'
import { useIsChintai } from '@/hooks/useIsChintai'

export const OpenHouseBikoLength = (props: BoxProps) => {
  const isChintai = useIsChintai()

  return (
    <FieldLength
      maxLength={
        miraieFields.openhouse_biko.maxLength[isChintai ? 'chintai' : 'baibai']
      }
      {...props}
    />
  )
}
