import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { ChangeEventHandler } from 'react'
import { useController } from 'react-hook-form'

import { useImageControlsContext } from '@/components/form/BukkenImages/Controls/ImageControlsContext'
import { BukkenImagesForm } from '@/components/form/BukkenImages/types'

export type ImgKokaiKbnCheckboxProps = CheckboxProps

export const ImgKokaiKbnCheckbox = ({
  children,
  ...props
}: ImgKokaiKbnCheckboxProps) => {
  const { category, index } = useImageControlsContext()
  const name = `${category}.${index}.img_kokai_kbn` as const
  const {
    field: { value, ...field },
  } = useController<BukkenImagesForm, typeof name>({ name })
  const isChecked = value !== miraieFields.images.img_kokai_kbn.Enum.非公開
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    field.onChange(
      miraieFields.images.img_kokai_kbn.Enum[
        e.target.checked ? '公開' : '非公開'
      ],
    )

  return (
    <Checkbox {...{ ...field, isChecked, onChange, ...props }}>
      {children || '公開'}
    </Checkbox>
  )
}
