/**
 * CSS の grid-template プロパティを扱いやすくするユーティリティ関数
 * @example
 * ```ts
 * const { style, getArea } = gridTemplate`
 * "${'a'} ${'b'} ${'c'}" 1fr
 * "${'a'} ${'b'} ${'d'}" 2fr
 * /30px   40px   50px
 * `
 * ```
 */
export const gridTemplate = <Area extends string>(
  literals: TemplateStringsArray,
  ...areas: Area[]
) => ({
  getArea: <T extends Area>(area: T) => area,
  get style() {
    return literals
      .reduce((acc, literal, index) => {
        const area = areas[index]
        return `${acc}${literal}${area ?? ''}`
      }, '')
      .trim()
  },
})
