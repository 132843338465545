import { useSuspenseQuery } from '@tanstack/react-query'

import { useMasqueradeEmail } from '@/hooks/useOwnUser'
import { useUser, useUserRole } from '@/hooks/useUser'
import { fetchAgent } from '@/utils/functions'

export const useOperator = () => {
  const role = useUserRole()
  const userEmail = useUser((user) => user.email)
  const masqueradeEmail = useMasqueradeEmail()

  const agentEmail = role === 'agent' ? userEmail : masqueradeEmail
  if (!agentEmail) throw new Error('agent email is empty')
  return useSuspenseQuery({
    queryKey: ['operator', agentEmail],
    queryFn: () => fetchAgent(agentEmail),
  }).data
}
