import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export type TatemonoNamePerPortalContentsProps = PropsWithChildren<{
  portal: 'suumo' | 'athome' | 'homes'
}>

export const TatemonoNamePerPortalContents = ({
  portal,
  children,
}: TatemonoNamePerPortalContentsProps) => {
  const [c_open_kbn] = useWatchMiraieFields({ name: [`${portal}_c_open_kbn`] })
  const display = c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
  return display && children
}
