import { Portal, portals } from '../../../portal'

import { Enum } from './enum'

type OpenKubuns = {
  [P in Portal as `${P}_c_open_kbn`]?: Enum
}

/**
 * すべてのポータルで **掲載されていない** かどうかを返す
 * 一つでも掲載中であれば `false`
 * すべてが掲載中でなければ `true`
 */
export function isAllClosed(openKubuns: OpenKubuns) {
  return portals.every(
    (portal) => openKubuns[`${portal}_c_open_kbn`] !== Enum.掲載,
  )
}
