import { formatYearToEra } from '@terass/common/src/utils/date'

export type YearOptionsProps = {
  min?: number
  max?: number
  era?: boolean
}

export const YearOptions = ({
  min = MIN_DEFAULT,
  max = MAX_DEFAULT,
  era,
}: YearOptionsProps) => {
  return (
    <>
      {[...new Array(max - min + 1).keys()].map((value) => {
        const year = max - value
        return (
          <option key={value} value={year}>
            {year}年{era && `(${formatYearToEra(year)})`}
          </option>
        )
      })}
    </>
  )
}

/** 明治元年 */
const MIN_DEFAULT = 1868

/** 3年後 */
const MAX_DEFAULT = new Date().getFullYear() + 3
