import dayjs from 'dayjs'

/**
 * 日付を `YYYY-MM-DD` 形式に整形する
 * @example
 * ```ts
 * format(new Date()) // 2023-01-23
 * ```
 */
export function formatDate(date: Date) {
  return dayjs(date).format('YYYY-MM-DD')
}
