import { InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
  Select,
  EnumOptions,
  PlaceholderOption,
  RadioGroup,
  UnselectedRadio,
  EnumRadios,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { CarParkingRyokin } from '@/components/form/PriceAndCost/CarParkingRyokin'
import { CarParkingRyokinCollapse } from '@/components/form/PriceAndCost/CarParkingRyokinCollapse'
import { ParkingKozoContents } from '@/components/form/PriceAndCost/ParkingKozoContents'
import { ParkingSenyoContents } from '@/components/form/PriceAndCost/ParkingSenyoContents'
import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const CarParkingRyokinControl: React.FC = () => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })
  const isChintai = useIsChintai()

  return (
    <>
      {(bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション ||
        isChintai) && (
        <FormControl fieldName="parking_keiyaku_kbn">
          <FormLabel>契約必須</FormLabel>
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.parking_keiyaku_kbn.Enum} />
          </Select>
          <PortalErrorMessages />
        </FormControl>
      )}

      <FormControl fieldset>
        <FormGroup>
          <FormControl fieldName="parking_kyori">
            <FormLabel>駐車場距離</FormLabel>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>m</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="parking_daisu">
            <FormLabel>駐車可能台数</FormLabel>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>台</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </FormControl>

      <ParkingSenyoContents>
        <FormControl fieldName="parking_senyo_flg" fieldset>
          <FormLabel>専用使用権付</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.parking_senyo_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>
      </ParkingSenyoContents>

      {
        /**
         * 駐車場料金を入力するとエラーになるため一旦非表示
         * https://terass.slack.com/archives/C04MQ5ZH3LN/p1715925838993789
         */
        // eslint-disable-next-line no-constant-binary-expression
        false && (
          <FormControl fieldName="parking_yuryo_flg" fieldset>
            <FormLabel>有料・無料</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.parking_yuryo_flg.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>
        )
      }

      {/* 料金 */}
      <CarParkingRyokinCollapse>
        <FormGroup nested>
          <CarParkingRyokin />
        </FormGroup>
      </CarParkingRyokinCollapse>

      {isChintai && (
        <FormControl fieldName="parking_type_kbn">
          <FormLabel>タイプ</FormLabel>
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.parking_type_kbn.Enum} />
          </Select>
          <PortalErrorMessages />
        </FormControl>
      )}

      <ParkingKozoContents>
        <FormControl fieldName="parking_kozo_kbn">
          <FormLabel>構造</FormLabel>
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.parking_kozo_kbn.Enum} />
          </Select>
          <PortalErrorMessages />
        </FormControl>
      </ParkingKozoContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn', 'suumo_c_open_kbn']}
        display={(bk_sbt_kbn, suumo_c_open_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.居住用 &&
          miraieFields.c_open_kbn.isOpen(suumo_c_open_kbn)
        }
      >
        <FormControl fieldName="parking_biko_kbn">
          <FormLabel>駐車場備考</FormLabel>
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.parking_biko_kbn.Enum} />
          </Select>
          <PortalErrorMessages />
        </FormControl>
      </MiraieFieldContents>
    </>
  )
}
