import { Radio } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { BanchiFlgCheckbox } from '@/components/form/Portal/BanchiFlgCheckbox'
import { CloseDateFormControl } from '@/components/form/Portal/CloseDateFormControl'
import { CloseDateInput } from '@/components/form/Portal/CloseDateInput'
import { MapSearchFlgCheckbox } from '@/components/form/Portal/MapSearchFlgCheckbox'
import { OpenKubun } from '@/components/form/Portal/OpenKubun'
import { PortalOptionsCollapse } from '@/components/form/Portal/PortalOptionsCollapse'
import { RoomNoFlgCheckbox } from '@/components/form/Portal/RoomNoFlgCheckbox'
import { SuumoOptionsFormControl } from '@/components/form/Portal/SuumoOptionsFormControl'
import { TatemonoFlgCheckbox } from '@/components/form/Portal/TatemonoFlgCheckbox'
import {
  BooleanEnumCheckbox,
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
} from '@/components/form/controls'
import { useIsChintai } from '@/hooks/useIsChintai'

export const SuumoRadioGroup = () => {
  const isChintai = useIsChintai()

  return (
    <FormGroup>
      <OpenKubun portal="suumo" />

      <PortalOptionsCollapse portal="suumo">
        <FormGroup nested>
          <FormControl fieldset>
            {isChintai ? (
              <>
                <CheckboxGroup>
                  <TatemonoFlgCheckbox portal="suumo" />
                  <RoomNoFlgCheckbox portal="suumo" />
                  <MapSearchFlgCheckbox portal="suumo" />
                </CheckboxGroup>
                <FormHelperText>SUUMOでは番地は表示されません</FormHelperText>
              </>
            ) : (
              <>
                <CheckboxGroup>
                  <BanchiFlgCheckbox portal="suumo" />
                  <MapSearchFlgCheckbox portal="suumo" />
                </CheckboxGroup>
                <FormHelperText>SUUMOでは建物名は表示されます</FormHelperText>
                <FormHelperText>
                  SUUMOでは部屋番号は表示されません
                </FormHelperText>
              </>
            )}
          </FormControl>
          {!isChintai && (
            <SuumoOptionsFormControl fieldName="suumonet_keisai_kbn" fieldset>
              <FormLabel>オプション</FormLabel>
              <FormHelperText mt={0} mb={2}>
                {
                  'TERASS Converterは現在ネットレポートに対応していません。\nネットレポートをご希望の場合は、アシスタントにご連絡ください。'
                }
              </FormHelperText>
              <RadioGroup>
                <Radio
                  key={
                    miraieFields.suumonet_keisai_kbn.Enum.SUUMOネットレポート
                  }
                  value={
                    miraieFields.suumonet_keisai_kbn.Enum.SUUMOネットレポート
                  }
                  isDisabled
                >
                  ネットレポート
                </Radio>
                <Radio
                  key={miraieFields.suumonet_keisai_kbn.Enum.SUUMOネットコマ}
                  value={miraieFields.suumonet_keisai_kbn.Enum.SUUMOネットコマ}
                >
                  ネットコマ
                </Radio>
              </RadioGroup>
              <FormGroup nested mt={2}>
                <CheckboxGroup>
                  <BooleanEnumCheckbox fieldName="search_keisai_flg">
                    検索一覧キャッチ
                  </BooleanEnumCheckbox>
                  <BooleanEnumCheckbox fieldName="staff_keisai_flg">
                    スタッフPR
                  </BooleanEnumCheckbox>
                  <BooleanEnumCheckbox fieldName="osusume_keisai_flg">
                    おすすめピックアップ
                  </BooleanEnumCheckbox>
                </CheckboxGroup>
              </FormGroup>
            </SuumoOptionsFormControl>
          )}
          <CloseDateFormControl portal="suumo">
            <FormLabel>掲載終了日</FormLabel>
            <CloseDateInput />
            <PortalErrorMessages />
          </CloseDateFormControl>
        </FormGroup>
      </PortalOptionsCollapse>
    </FormGroup>
  )
}
