import { ValueOf } from '../../../types'

/**
 * 公開区分
 */
export const Enum = {
  非掲載: '0',
  掲載: '1',
  掲載期間外: '2', // みらいえでの値は掲載保留だが、tera7では掲載期間外の意味で扱う
  エラー有: '3',
} as const

export type Enum = ValueOf<typeof Enum>
