import { Box, Text } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

import { searchableSelectStyle } from '@/components/form/controls'

export const EmptySelect = ({ children }: PropsWithChildren) => (
  <Box {...searchableSelectStyle}>
    <Text color="gray.500">{children}</Text>
  </Box>
)
