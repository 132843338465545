import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const BikeOkibaDetailCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['bk_sbt_kbn', 'athome_c_open_kbn', 'bikeokiba_umu_kbn']}
      display={(bk_sbt_kbn, athome_c_open_kbn, bikeokiba_umu_kbn) =>
        (bikeokiba_umu_kbn === miraieFields.bikeokiba_umu_kbn.Enum.空有 ||
          bikeokiba_umu_kbn === miraieFields.bikeokiba_umu_kbn.Enum.空無 ||
          bikeokiba_umu_kbn === miraieFields.bikeokiba_umu_kbn.Enum.近有) &&
        (miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'rent' ||
          athome_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載 ||
          athome_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載期間外)
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
