import * as bk_sbt_kbn from '../../bk_sbt_kbn'

import * as category from './enum'

/** 物件種別ごとに有効な画像カテゴリかどうか */
export function isAvailable(
  category: category.Enum,
  bk_sbt_kbn: bk_sbt_kbn.Enum,
) {
  return availableMap[category][bk_sbt_kbn]
}

const availableMap: {
  [K in category.Enum]: {
    [B in bk_sbt_kbn.Enum]?: true
  }
} = {
  [category.Enum.外観]: {
    [bk_sbt_kbn.Enum.居住用]: true,
  },
  [category.Enum.間取]: {
    [bk_sbt_kbn.Enum.居住用]: true,
  },
  [category.Enum.内観]: {
    [bk_sbt_kbn.Enum.居住用]: true,
  },
  [category.Enum['内外観・その他']]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売地]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum.居住用]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.構造]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.設備]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.現地案内図]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売地]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.交通アクセス図]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売地]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.路線図]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売地]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.CM横画像]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売地]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.売主コメント]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売地]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.外観パース図]: {},
  [category.Enum.アクセス図]: {},
  [category.Enum.会社間流通14]: {
    [bk_sbt_kbn.Enum.居住用]: true,
  },
  [category.Enum.会社間流通15]: {
    [bk_sbt_kbn.Enum.居住用]: true,
  },
  [category.Enum.プレゼント画像]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売地]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.パンフレット画像]: {
    [bk_sbt_kbn.Enum.売マンション]: true,
    [bk_sbt_kbn.Enum.売地]: true,
    [bk_sbt_kbn.Enum.売戸建住宅]: true,
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: true,
  },
  [category.Enum.全体区画図]: {},
  [category.Enum['住戸・区画画像']]: {},
  [category.Enum.パノラマ用ミニマップ]: {},
  [category.Enum.未分類]: {},
}
