export const groupNames = [
  '内観',
  '外観',
  '設備',
  '地図・図面',
  'その他',
  '土地',
] as const

export type GroupName = (typeof groupNames)[number]
