import {
  Button,
  ModalBody,
  ModalContent as ChakraModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useModalContext,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { Contents } from '@/components/Contents'
import { CategoryHeading } from '@/components/form/BukkenImages/CategoryHeading'
import { ImagesCategory } from '@/components/form/BukkenImages/ImagesCategory'
import {
  BukkenImagesFieldArrayContextProvider,
  useBukkenImagesContext,
  useBukkenImagesFormContext,
} from '@/components/form/BukkenImages/hooks'
import { FormGroup } from '@/components/form/FormGroup'
import { useOperator } from '@/hooks/useOperator'
import { uploadImageFile } from '@/utils/storage'

type ModalContentProps = {
  onModalClose: () => void
}

export const ModalContent = ({ onModalClose }: ModalContentProps) => {
  const { id } = useOperator()
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useBukkenImagesFormContext()
  const { bk_sbt_kbn, updateData } = useBukkenImagesContext()
  const { onClose } = useModalContext()
  const onSubmit: React.FormEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    handleSubmit(
      async ({
        pamphlet_info_caption_kbn,
        present_caption,
        ...imageCategories
      }) => {
        const images = await Promise.all(
          miraieFields.images.category.values.flatMap((category) =>
            miraieFields.images.img_no.values
              .filter(
                (img_no) =>
                  miraieFields.images.img_no.getCategory({
                    img_no,
                    bk_sbt_kbn,
                  }) === category,
              )
              .map(async (img_no, index) => {
                const { _imageFile: imageFile, ...image } =
                  imageCategories[category]?.[index] || {}
                const { img_data_url, img_in_md5 } = {
                  ...image,
                  ...(imageFile &&
                    (await uploadImageFile(imageFile, id).then(
                      ({ url, md5 }) => ({
                        img_data_url: url,
                        img_in_md5: md5,
                      }),
                    ))),
                }
                return img_data_url && img_in_md5
                  ? { ...image, img_data_url, img_in_md5, img_no }
                  : { img_no }
              }),
          ),
        )

        await updateData({ images, present_caption, pamphlet_info_caption_kbn })
        onModalClose()
      },
    )(e)
  }

  return (
    <ChakraModalContent>
      <Contents as="form" onSubmit={onSubmit}>
        <ModalHeader>画像</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormGroup>
            {miraieFields.images.category.values.map(
              (category) =>
                miraieFields.images.category.isAvailable(
                  category,
                  bk_sbt_kbn,
                ) && (
                  <BukkenImagesFieldArrayContextProvider
                    category={category}
                    key={category}
                  >
                    <ImagesCategory
                      category={category}
                      heading={
                        <CategoryHeading {...{ category, bk_sbt_kbn }} />
                      }
                    />
                  </BukkenImagesFieldArrayContextProvider>
                ),
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="gray" mr={4}>
            キャンセル
          </Button>
          <Button type="submit" isLoading={isSubmitting}>
            完了
          </Button>
        </ModalFooter>
      </Contents>
    </ChakraModalContent>
  )
}
