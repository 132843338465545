import { VisuallyHiddenInput } from '@chakra-ui/react'
import { typeGuard } from '@terass/common/src/utils'
import { ComponentProps } from 'react'

export type HiddenImageInputProps = {
  handleImageFiles?: (files: File[]) => void
} & ComponentProps<typeof VisuallyHiddenInput>

export const HiddenImageInput = ({
  handleImageFiles,
  ...props
}: HiddenImageInputProps) => {
  return (
    <VisuallyHiddenInput
      type="file"
      multiple
      accept="image/gif,image/png,image/jpeg"
      onChange={(e) => {
        const { files } = e.target
        const imageFiles = [...new Array(files?.length).keys()]
          .map((i) => files?.[i])
          .filter(
            typeGuard(
              (file, never) =>
                (file && /^image\/(gif|png|jpeg)$/.test(file.type) && file) ||
                never,
            ),
          )
        if (imageFiles.length) handleImageFiles?.(imageFiles)
      }}
      {...props}
    />
  )
}
