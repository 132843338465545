import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  UnselectedRadio,
  EnumRadios,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { BikeOkibaDetailCollapse } from '@/components/form/PriceAndCost/BikeOkibaDetailCollapse'
import { BikeRyokinControl } from '@/components/form/PriceAndCost/BikeRyokinControl'

export const BikeOkiba: React.FC = () => {
  return (
    <>
      <FormControl fieldName="bikeokiba_umu_kbn" fieldset>
        <FormLabel>バイク置場</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.bikeokiba_umu_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <BikeOkibaDetailCollapse>
        <FormGroup nested>
          <BikeRyokinControl />

          <MiraieFieldContents
            name={['bk_sbt_kbn', 'athome_c_open_kbn']}
            display={(bk_sbt_kbn, athome_c_open_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.居住用 &&
              miraieFields.c_open_kbn.isOpen(athome_c_open_kbn)
            }
          >
            <FormControl fieldName="bikeokiba_size_kbn" fieldset>
              <FormLabel>バイク置場サイズ</FormLabel>
              <RadioGroup>
                <UnselectedRadio />
                <EnumRadios enum={miraieFields.bikeokiba_size_kbn.Enum} />
              </RadioGroup>
              <PortalErrorMessages />
            </FormControl>
          </MiraieFieldContents>
        </FormGroup>
      </BikeOkibaDetailCollapse>
    </>
  )
}
