import { Level, LoggerOptions } from './types'

export type NormalizedLoggerOptions = {
  test: boolean
  prod: boolean
  dev: boolean
  level: Level
}

export const normalizeOptions = (
  options: LoggerOptions,
): NormalizedLoggerOptions => {
  const test = !!options.test
  const prod = !!options.prod
  const dev = !prod
  const level = options.level ?? (prod ? 'info' : 'debug')

  return {
    test,
    prod,
    dev,
    level,
  }
}
