/**
 * 間取区分SUUMO
 */
export const Enum = {
  ワンルーム: '1',
  K: '2',
  DK: '3',
  LK: '4',
  LDK: '5',
  KK: '10',
  DKK: '11',
  LKK: '12',
  DDKK: '13',
  LLKK: '14',
  LDKK: '15',
  LDDKK: '16',
  LLDKK: '17',
  LLDDKK: '18',
  未定: '99',
} as const
