import { Box, BoxProps } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { useModalContentFormContext } from '@/components/form/Setsubi/ModalContentFormContext'
import { useWatchSetsubiTokkiForm } from '@/components/form/Setsubi/useSetsubiTokkiForm'

export type PortalCodeCountProps = {
  portal: 'suumo' | 'athome' | 'homes'
} & BoxProps

export const PortalCodeCount = ({ portal, ...props }: PortalCodeCountProps) => {
  const codes = useWatchSetsubiTokkiForm('codes')
  const { bk_sbt_kbn } = useModalContentFormContext()

  const { length } = codes.filter(
    (code) =>
      miraieFields.setsubi_kbn_renketsu.getDetail(code)?.[bk_sbt_kbn]?.[portal],
  )

  return (
    <Box display="inline" {...props}>
      {length}
    </Box>
  )
}
