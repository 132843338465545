import { portals, Portal } from '../../../portal'
import { parseDateString } from '../../../utils/date'

/** 掲載期間オブジェクトの日付文字列を Date オブジェクトに変換する */
export function mapDate(keisaiKikan: KeisaiKikan | undefined) {
  const mapped: KeisaiKikanDate = {
    suumo: {},
    athome: {},
    homes: {},
    rakumachi: {},
  }

  if (keisaiKikan)
    portals.forEach((portal) => {
      const { from, to } = keisaiKikan?.[portal] ?? {}
      if (from) mapped[portal].from = parseDateString(from)
      if (to) mapped[portal].to = parseDateString(to)
    })

  return mapped
}

export type KeisaiKikan = { [P in Portal]?: { from?: string; to?: string } }

export type KeisaiKikanDate = {
  [P in Portal]: { from?: Date; to?: Date }
}
