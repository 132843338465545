import { ValueOf } from '../../../types'

export const Enum = {
  特別用途地区: '11',
  特定用途制限地域: '12',
  高層住居誘導地区: '13',
  高度地区: '14',
  高度利用地区: '15',
  特定街区: '16',
  都市再生特別地区: '17',
  防火地域: '18',
  美観地区: '19',
  風致地区: '20',
  駐車場整備地区: '21',
  臨港地区: '22',
  歴史的風土特別保存地区: '23',
  第一種歴史的風土保存地区: '24',
  第二種歴史的風土保存地区: '25',
  緑地保全地区: '26',
  流通業務地区: '27',
  生産緑地地区: '28',
  伝統的建造物群保存地区: '29',
  航空機騒音障害防止地区: '30',
  航空機騒音障害防止特別地区: '31',
  市街地再開発促進区域: '32',
  土地区画整理促進区域: '33',
  住宅街区整備促進区域: '34',
  拠点業務市街地整備土地区画整理促進区域: '35',
  遊休土地転換利用促進地区: '36',
  文化財保護法: '37',
  古都保存法: '38',
  景観法: '39',
  密集市街地整備法: '40',
  航空法: '41',
  河川法: '42',
  砂防法: '43',
  農地法届出要: '44',
  安全条例: '45',
  宅地造成工事規制区域: '46',
  高層住宅誘致地区: '47',
  準防火地域: '48',
  景観地区: '49',
  準景観地区: '50',
  観光地区: '51',
  文教地区: '52',
  特別緑地保全地区: '53',
  高さ最高限度有: '54',
  高さ最低限度有: '55',
  建ぺい率最低限度有: '56',
  容積率最低限度有: '57',
  敷地面積最高限度有: '58',
  敷地面積最低限度有: '59',
  建物面積最高限度有: '60',
  建物面積最低限度有: '61',
  急傾斜地崩壊危険区域: '62',
  中高層階住居専用地区: '63',
  高層住宅誘導地区: '64',
  日影制限有: '65',
  崖下につき建築制限有: '66',
  崖上につき建築制限有: '67',
  一部都市計画道路: '68',
  一部協定通路: '69',
  不整形地: '70',
  隅切有: '71',
  敷地内段差有: '72',
  接道と段差有: '73',
  壁面後退有: '74',
  建築協定有: '75',
} as const

export type Enum = ValueOf<typeof Enum>
