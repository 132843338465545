import { FormLabelProps, FormLabel as ChakraFormLabel } from '@chakra-ui/react'

import { useFormControlContext } from '@/components/form/controls/FormControl'

export const FormLabel = (props: FormLabelProps) => {
  const { fieldset } = useFormControlContext()

  return (
    <ChakraFormLabel
      as={fieldset ? 'legend' : undefined}
      fontWeight="semibold"
      {...props}
    />
  )
}
