import { ReactNode } from 'react'

import {
  MiraieFieldPath,
  MiraieFieldPathValues,
  useWatchMiraieFields,
} from '@/hooks/useMiraieForm'

export type WatchMiraieFieldsProps<T extends readonly MiraieFieldPath[]> = {
  name: [...T]
  children: (...args: MiraieFieldPathValues<T>) => ReactNode
}

export const WatchMiraieFields = <T extends readonly MiraieFieldPath[]>({
  name,
  children,
}: WatchMiraieFieldsProps<T>) => children(...useWatchMiraieFields({ name }))
