import {
  Stack,
  Flex,
  CheckboxGroup,
  Text,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  FormHelperText,
  PortalErrorMessages,
  TextInput,
  NumberInput,
  Select,
  EnumOptions,
  PlaceholderOption,
  RadioGroup,
  UnselectedRadio,
  EnumRadios,
  BooleanEnumCheckbox,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { KoshinryoCollapse } from '@/components/form/PriceAndCost/KoshinryoCollapse'
import { TokuyuchinCollapse } from '@/components/form/PriceAndCost/TokuyuchinCollapse'
import { useIsChintai } from '@/hooks/useIsChintai'

export const Other: React.FC = () => {
  const isChintai = useIsChintai()
  if (!isChintai) return null

  return (
    <FormGroup>
      <FormGroupHeading>その他</FormGroupHeading>

      <FormControl fieldset>
        <FormLabel>フリーレント</FormLabel>
        <Flex gap={4}>
          <FormControl fieldName="freerent">
            <NumberInput />
          </FormControl>

          <FormControl fieldName="freerent_tani_kbn">
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.freerent_tani_kbn.Enum} />
            </Select>
          </FormControl>
        </Flex>
        <FormHelperText>
          「月から家賃発生」の場合、YYYYMM(例:20年9月の場合「202009」)で年月を入力してください。
        </FormHelperText>

        <PortalErrorMessages fieldName="freerent" />
        <PortalErrorMessages fieldName="freerent_tani_kbn" />
      </FormControl>

      <FormGroup nested>
        <FormControl fieldName="freerent_biko">
          <FormLabel>条件詳細</FormLabel>
          <TextInput />
          <PortalErrorMessages />
        </FormControl>
      </FormGroup>

      <FormControl fieldName="koshinryo_flg" fieldset>
        <FormLabel>更新料</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.koshinryo_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <KoshinryoCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>料金</FormLabel>
            <Flex gap={4}>
              <FormControl fieldName="koshinryo">
                <NumberInput step={miraieFields.koshinryo.step} />
              </FormControl>

              <FormControl fieldName="koshinryo_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.koshinryo_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="koshinryo" />
            <PortalErrorMessages fieldName="koshinryo_kbn" />
          </FormControl>
        </FormGroup>
      </KoshinryoCollapse>

      <FormControl fieldName="tokuyuchin_flg" fieldset>
        <FormLabel>特優賃</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.tokuyuchin_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <TokuyuchinCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>下限/上限</FormLabel>
            <Flex gap={4} align="center">
              <FormControl fieldName="tokuyuchin_from">
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>円</InputRightAddon>
                </InputGroup>
              </FormControl>
              <Text>～</Text>
              <FormControl fieldName="tokuyuchin_to">
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>円</InputRightAddon>
                </InputGroup>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="tokuyuchin_from" />
            <PortalErrorMessages fieldName="tokuyuchin_to" />
          </FormControl>

          <FormControl fieldName="tokuyuchin_kbn" fieldset>
            <FormLabel>料金変動</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.tokuyuchin_kbn.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="tokuyuchin_ritsu">
            <FormLabel>上昇率</FormLabel>
            <InputGroup>
              <NumberInput step={miraieFields.tokuyuchin_ritsu.step} />
              <InputRightAddon>%</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="tokuyuchin_year">
            <FormLabel>家賃補助年数</FormLabel>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>年</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="tokuyuchin_hosoku">
            <FormLabel>補足</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </TokuyuchinCollapse>

      <FormControl fieldset>
        <FormLabel>クレジットカード決済可能項目</FormLabel>
        <Stack gap={2} direction="column">
          <CheckboxGroup>
            <BooleanEnumCheckbox fieldName="credit_shoki_flg">
              初期費用
            </BooleanEnumCheckbox>
            <BooleanEnumCheckbox fieldName="credit_chinryo_flg">
              賃料
            </BooleanEnumCheckbox>
          </CheckboxGroup>

          <Flex gap={2}>
            <CheckboxGroup>
              <BooleanEnumCheckbox fieldName="credit_other_flg">
                その他
              </BooleanEnumCheckbox>
            </CheckboxGroup>

            <FormControl fieldName="credit_other_joken">
              <TextInput />
              <PortalErrorMessages />
            </FormControl>
          </Flex>
        </Stack>
        <PortalErrorMessages fieldName="credit_shoki_flg" />
        <PortalErrorMessages fieldName="credit_chinryo_flg" />
        <PortalErrorMessages fieldName="credit_other_flg" />
        <PortalErrorMessages fieldName="credit_other_joken" />
      </FormControl>
    </FormGroup>
  )
}
