import {
  Button,
  Flex,
  ModalBody,
  ModalContent as ChakraModalContent,
  ModalHeader,
  ModalFooter,
  useModalContext,
  ModalCloseButton,
  HStack,
  Box,
} from '@chakra-ui/react'
import { GoogleMap } from '@react-google-maps/api'
import { FormEventHandler, Suspense, useRef } from 'react'

import { createNewShisetsu } from './modalContentUtil'

import { CSpinner } from '@/components/Atoms'
import { Contents } from '@/components/Contents'
import { ImageControls } from '@/components/form/Shisetsu/ImageControls'
import { ShisetsuOption } from '@/components/form/Shisetsu/ShisetsuOption'
import {
  Shisetsu,
  useShisetsuFormContext,
  useShisetsuFormState,
  useWatchShisetsuForm,
} from '@/components/form/Shisetsu/useShisetsuForm'
import {
  Place,
  createMapService,
  isActiveShisetsu,
} from '@/components/form/Shisetsu/utils'
import { useLoadScript } from '@/hooks/useLoadScript'
import { useOperator } from '@/hooks/useOperator'
import { toast } from '@/utils/standaloneToast'

type Props = {
  updateShisetsu: (shisetsu: Shisetsu[]) => Promise<void>
  onModalClose: () => void
}

export const ModalContent = ({ updateShisetsu, onModalClose }: Props) => {
  const { id } = useOperator()

  const { handleSubmit, setValue, setError, clearErrors } =
    useShisetsuFormContext()
  const { isSubmitting } = useShisetsuFormState()

  const onSubmit: FormEventHandler = (e) => {
    e.stopPropagation()

    clearErrors()
    handleSubmit(async ({ initialShisetsu, shisetsu }) => {
      const shisetsuWithoutDistance = shisetsu
        .map((shisetsu, index) => ({ ...shisetsu, index }))
        .filter(
          (shisetsu) =>
            isActiveShisetsu(shisetsu) &&
            !shisetsu.directDistance &&
            !shisetsu.latLng &&
            !shisetsu.shisetsu_kyori,
        )
      if (shisetsuWithoutDistance.length) {
        shisetsuWithoutDistance.forEach(({ index }) => {
          setError(`shisetsu.${index}.shisetsu_kyori`, {
            message: '距離を入力してください',
          })
        })
        toast({
          description: '距離が入力されていない施設があります',
          status: 'warning',
          position: 'top',
          isClosable: true,
        })
        return
      }

      const newShisetsu = await createNewShisetsu({
        initialShisetsu,
        shisetsu,
        lat,
        lng,
        mapService,
        id,
      })

      await updateShisetsu(newShisetsu)
      onModalClose()
    })(e)
  }
  const { onClose } = useModalContext()

  const shisetsuList = useWatchShisetsuForm('shisetsu')

  const addPlace = ({
    shisetsu_sbt_kbn,
    name,
    directDistance,
    latLng,
  }: Place) => {
    const blankIndex = shisetsuList.findIndex((x) => !isActiveShisetsu(x))
    if (blankIndex < 0) {
      toast({
        description: '施設は最大10件まで登録できます。',
        status: 'warning',
        position: 'top',
        isClosable: true,
      })
      return
    }

    if (
      shisetsuList.some(
        (x) =>
          x.shisetsu_sbt_kbn === shisetsu_sbt_kbn &&
          x.shisetsu_name === name &&
          x.directDistance === directDistance,
      )
    ) {
      toast({
        description: '同じ施設が登録されています。',
        status: 'warning',
        position: 'top',
        isClosable: true,
      })
      return
    }

    setValue(`shisetsu.${blankIndex}.shisetsu_sbt_kbn`, shisetsu_sbt_kbn, {
      shouldDirty: true,
    })
    setValue(`shisetsu.${blankIndex}.shisetsu_name`, name, {
      shouldDirty: true,
    })
    setValue(`shisetsu.${blankIndex}.shisetsu_kyori`, undefined, {
      shouldDirty: true,
    })
    setValue(`shisetsu.${blankIndex}.directDistance`, directDistance, {
      shouldDirty: true,
    })
    setValue(`shisetsu.${blankIndex}.latLng`, latLng, {
      shouldDirty: true,
    })
  }

  const [lat, lng] = useShisetsuFormContext().getValues(['lat', 'lon'])

  const { isLoaded, loadError } = useLoadScript()
  const googleMap = useRef<google.maps.Map | null>(null)
  function onMapLoad(map: google.maps.Map) {
    googleMap.current = map
  }
  const mapService = createMapService(googleMap, lat, lng)
  if (loadError) return 'Error'
  if (!isLoaded) return 'Loading...'

  return (
    <ChakraModalContent>
      <Contents as="form" onSubmit={onSubmit}>
        <Suspense fallback={<CSpinner />}>
          <ModalHeader>周辺環境</ModalHeader>
          <ModalCloseButton />
          <ModalBody height="60vh">
            <HStack height="inherit">
              <Box width="60%" height="inherit" overflowY="scroll">
                <Flex
                  direction="column"
                  gap={4}
                  height="auto"
                  overflowY="scroll"
                >
                  {shisetsuList.map((_, index) => (
                    <ImageControls
                      key={index}
                      index={index}
                      updateShisetsu={updateShisetsu}
                    />
                  ))}
                </Flex>
              </Box>
              <Box overflowY="scroll" width="40%" height="inherit">
                <ShisetsuOption addPlace={addPlace} mapService={mapService} />
              </Box>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} colorScheme="gray" mr={4}>
              キャンセル
            </Button>
            <Button type="submit" isLoading={isSubmitting}>
              完了
            </Button>
          </ModalFooter>
          <GoogleMap onLoad={onMapLoad} />
        </Suspense>
      </Contents>
    </ChakraModalContent>
  )
}
