import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { SelectAddress } from './Address/SelectAddress'

import { LatLngButton } from '@/components/form/General/LatLngButton'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  PortalErrorMessages,
} from '@/components/form/controls'

export const AddressControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>住所</FormGroupHeading>
      <FormControl isRequired>
        <FormLabel>所在地</FormLabel>
        <SelectAddress />
        <FormHelperText>
          未入力の場合、HOME'S、SUUMOに掲載されません。
        </FormHelperText>
        <PortalErrorMessages fieldName="gyosei_cd" />
        <PortalErrorMessages fieldName="aza_cd" />
        <PortalErrorMessages fieldName="banchi" />
        <PortalErrorMessages fieldName="chiban" />
        <PortalErrorMessages fieldName="partition_name" />
      </FormControl>
      <FormControl fieldName="lat">
        <FormLabel>緯度</FormLabel>
        <NumberInput step={miraieFields.lat.step} />
        <PortalErrorMessages />
      </FormControl>
      <FormControl fieldName="lon">
        <FormLabel>経度</FormLabel>
        <NumberInput step={miraieFields.lon.step} />
        <PortalErrorMessages />
      </FormControl>
      <LatLngButton>地図から緯度経度を入力</LatLngButton>
    </FormGroup>
  )
}
