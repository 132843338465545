import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { pick } from '@terass/common/src/utils'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { HoreiKubunEditButton } from '@/components/form/Tochi/HoreiKubunEditButton'
import { HoreiKubunTags } from '@/components/form/Tochi/HoreiKubunTags'
import {
  EnumOptions,
  EnumRadios,
  FieldLength,
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  Textarea,
  TextInput,
  UnselectedRadio,
} from '@/components/form/controls'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const SeigenKeikaku = () => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })

  return (
    <>
      <FormGroup>
        <FormGroupHeading>制限・計画</FormGroupHeading>

        <FormControl fieldset>
          <FormLabel>用途地域</FormLabel>
          <FormGroup nested>
            <FormControl fieldName="yoto_chiki_kbn_1">
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.yoto_chiki_kbn.Enum} />
              </Select>
              <FormHelperText>土地の場合、必須。</FormHelperText>
              <PortalErrorMessages />
            </FormControl>

            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.居住用
              }
            >
              <FormControl fieldName="yoto_chiki_kbn_2">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.yoto_chiki_kbn.Enum} />
                </Select>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldContents>
          </FormGroup>
        </FormControl>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldName="kenchiku_kyoka_reason_kbn">
            <FormLabel>建築許可理由区分</FormLabel>
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.kenchiku_kyoka_reason_kbn.Enum} />
            </Select>
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <FormControl
          fieldName="toshikei_kbn"
          isRequired={bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地}
        >
          <FormLabel>都市計画</FormLabel>
          <Select>
            <PlaceholderOption />
            <EnumOptions enum={miraieFields.toshikei_kbn.Enum} />
          </Select>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地
          }
        >
          <FormControl fieldName="saiteki_yoto_kbn">
            <FormLabel>土地最適用途</FormLabel>
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.saiteki_yoto_kbn.Enum} />
            </Select>
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl
            fieldName="chimoku_kbn"
            isRequired={bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地}
          >
            <FormLabel>地目</FormLabel>
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.chimoku_kbn.Enum} />
            </Select>
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldName="chisei_kbn">
            <FormLabel>地勢</FormLabel>
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.chisei_kbn.Enum} />
            </Select>
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <FormControl fieldset>
          <FormLabel>法令制限</FormLabel>
          <Flex>
            <HoreiKubunEditButton variant="outline">編集</HoreiKubunEditButton>{' '}
            <Flex gap={2} mb={4} pl={2} pr={2} flexWrap="wrap" flexGrow={1}>
              <HoreiKubunTags />
            </Flex>
          </Flex>
        </FormControl>

        <FormControl fieldset>
          <FormLabel>法令に基づくその他制限事項</FormLabel>
          <FormGroup nested>
            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.居住用
              }
            >
              <FormGroup>
                <MiraieFieldContents
                  name={['suumo_c_open_kbn']}
                  display={(suumo_c_open_kbn) =>
                    suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
                  }
                >
                  <FormControl fieldName="suumo_horei_biko">
                    <FormLabel>SUUMO</FormLabel>
                    <Textarea />
                    <FieldLength
                      maxLength={miraieFields.suumo_horei_biko.maxLength}
                    />
                    <PortalErrorMessages />
                  </FormControl>
                </MiraieFieldContents>
                <MiraieFieldContents
                  name={['homes_c_open_kbn']}
                  display={(homes_c_open_kbn) =>
                    homes_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
                  }
                >
                  <FormControl fieldName="homes_horei_biko">
                    <FormLabel>HOMES</FormLabel>
                    <Textarea />
                    <FieldLength
                      maxLength={miraieFields.homes_horei_biko.maxLength}
                    />
                    <PortalErrorMessages />
                  </FormControl>
                </MiraieFieldContents>

                <MiraieFieldContents
                  name={['athome_c_open_kbn']}
                  display={(athome_c_open_kbn) =>
                    athome_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
                  }
                >
                  <FormControl fieldName="athome_horei_biko">
                    <FormLabel>athome</FormLabel>
                    <Textarea />
                    <FieldLength
                      maxLength={miraieFields.athome_horei_biko.maxLength}
                    />
                    <FormHelperText>
                      収まりきらない場合、
                      <Link
                        onClick={() => {
                          const elm = document.getElementById('athome_biko_1')
                          elm?.scrollIntoView({ block: 'center' })
                          elm?.focus()
                        }}
                        textDecoration="underline"
                      >
                        athome備考へ
                      </Link>
                      追記ください。
                    </FormHelperText>
                    <PortalErrorMessages />
                  </FormControl>
                </MiraieFieldContents>
              </FormGroup>
            </MiraieFieldContents>

            <FormControl fieldName="horei_biko">
              <FormLabel>上記以外</FormLabel>
              <Textarea />
              <FieldLength maxLength={miraieFields.horei_biko.maxLength} />
              <PortalErrorMessages />
            </FormControl>
          </FormGroup>
        </FormControl>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地
          }
        >
          <FormControl fieldName="kaihatukyoka_no">
            <FormLabel>許可番号</FormLabel>
            <InputGroup>
              <InputLeftAddon>開発許可番号</InputLeftAddon>
              <TextInput />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn', 'suumo_c_open_kbn']}
          display={(bk_sbt_kbn, suumo_c_open_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
            (bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)'] &&
              suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載)
          }
        >
          <FormGroup>
            <FormControl fieldName="saikenchiku_joken_kbn">
              <FormLabel>再建築条件</FormLabel>
              <Select>
                <PlaceholderOption />
                <EnumOptions
                  enum={
                    bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地
                      ? pick(miraieFields.saikenchiku_joken_kbn.Enum, [
                          '（旧基準）建築基準法第43条但書許可要。事前相談による一次決済取得',
                          '（旧基準）建築基準法第43条但書許可要。一括(包括)許可同意基準に適合',
                          '（旧基準）建築基準法第43条但書許可要',
                          '建築基準法43条2項1号の認定要※建築可能な建物は200㎡以内の専用住宅に限られる',
                          '建築基準法43条2項2号の許可要（一括許可（包括）同意基準に適合）',
                          '建築基準法43条2項2号の許可要（建築審査会の同意が必要）',
                        ])
                      : miraieFields.saikenchiku_joken_kbn.Enum
                  }
                />
              </Select>
              <PortalErrorMessages />
            </FormControl>

            <FormGroup nested>
              <FormControl fieldName="saikenchiku_kyoka_reason">
                <InputGroup>
                  <InputLeftAddon>許可理由</InputLeftAddon>
                  <TextInput />
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </FormGroup>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldName="kokudo_kbn" fieldset>
            <FormLabel>土地国土法</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.kokudo_kbn.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地
          }
        >
          <FormControl
            fieldName="kenchiku_joken_flg"
            fieldset
            isRequired={bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地}
          >
            <FormLabel>建築条件</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.kenchiku_joken_flg.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldName="tato_kenchiku_joken_kbn" fieldset>
            <FormLabel>多棟建築条件</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.tato_kenchiku_joken_kbn.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldCollapse
            name={['tato_kenchiku_joken_kbn', 'suumo_c_open_kbn']}
            display={(tato_kenchiku_joken_kbn, suumo_c_open_kbn) =>
              tato_kenchiku_joken_kbn ===
                miraieFields.tato_kenchiku_joken_kbn.Enum.一部付区画あり &&
              suumo_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
            }
          >
            <FormGroup nested>
              <FormControl fieldName="tato_kenchiku_joken_kukakusu">
                <FormLabel>建築条件区画数</FormLabel>
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>区画</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>

              <FormControl fieldName="tato_kenchiku_joken_freecom">
                <FormLabel>建築条件フリーコメント</FormLabel>
                <Textarea />
                <FieldLength
                  maxLength={miraieFields.tato_kenchiku_joken_freecom.maxLength}
                />
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </MiraieFieldCollapse>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
          }
        >
          <FormControl fieldset>
            <FormLabel>建物プラン</FormLabel>
            <FormGroup nested>
              <FormControl fieldName="tatemono_plan_menseki">
                <InputGroup>
                  <InputLeftAddon>建物面積</InputLeftAddon>
                  <NumberInput step={miraieFields.tatemono_plan_menseki.step} />
                  <InputRightAddon>㎡</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>

              <FormControl fieldName="tatemono_plan_price">
                <InputGroup>
                  <InputLeftAddon>建物価格</InputLeftAddon>
                  <NumberInput />
                  <InputRightAddon>万円</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </FormControl>
        </MiraieFieldContents>

        <FormControl fieldName="frk_warning_biko">
          <FormLabel>注意事項</FormLabel>
          <Textarea />
          <PortalErrorMessages />
        </FormControl>
      </FormGroup>
    </>
  )
}
