import { miraieFields } from '@terass/common/src'

import { useSakimonoBaitaiKeisaiIraiContext } from '@/components/chintai/SakimonoBaitaiKeisaiIraiContext'

export const BkItemKbnOptions = () => {
  const { bk_item_kbn } = useSakimonoBaitaiKeisaiIraiContext()

  return (
    <>
      <option
        value={miraieFields.bk_item_kbn.Enum.貸マンション}
        disabled={bk_item_kbn !== miraieFields.bk_item_kbn.Enum.貸マンション}
      >
        マンション
      </option>
      <option
        value={miraieFields.bk_item_kbn.Enum.貸アパート}
        disabled={bk_item_kbn !== miraieFields.bk_item_kbn.Enum.貸マンション}
      >
        アパート
      </option>
      <option
        value={miraieFields.bk_item_kbn.Enum.貸戸建住宅}
        disabled={bk_item_kbn !== miraieFields.bk_item_kbn.Enum.貸戸建住宅}
      >
        戸建て
      </option>
      <option
        value={miraieFields.bk_item_kbn.Enum.貸テラスハウス}
        disabled={bk_item_kbn !== miraieFields.bk_item_kbn.Enum.貸戸建住宅} // todo 貸テラスハウスはt3にないため判定の変更する必要があるかも？
      >
        テラスハウス
      </option>
    </>
  )
}
