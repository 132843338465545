import { Portal } from '@terass/common/src/portal'

import { BooleanEnumCheckbox } from '@/components/form/controls'

export const BanchiFlgCheckbox: React.FC<{
  portal: Extract<Portal, 'suumo' | 'homes'>
}> = ({ portal }) => (
  <BooleanEnumCheckbox fieldName={`${portal}_c_banchi_flg`}>
    番地を表示する
  </BooleanEnumCheckbox>
)
