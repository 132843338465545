import {
  Stack,
  Flex,
  Text,
  InputGroup,
  InputRightAddon,
  CheckboxGroup,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  NumberInput,
  Select,
  EnumOptions,
  PlaceholderOption,
  RadioGroup,
  UnselectedRadio,
  EnumRadios,
  BooleanEnumCheckbox,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { OnsenDetailCollapse } from '@/components/form/PriceAndCost/OnsenDetailCollapse'
import { OnsenPriceCollapse } from '@/components/form/PriceAndCost/OnsenPriceCollapse'

export const Onsen: React.FC = () => {
  return (
    <FormGroup>
      <FormGroupHeading>温泉</FormGroupHeading>
      <FormControl fieldName="onsen_umu_kbn" fieldset>
        <FormLabel>温泉</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.onsen_umu_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <OnsenDetailCollapse>
        <FormGroup nested>
          <FormControl fieldName="onsen_price_kbn" fieldset>
            <FormLabel>料金</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.onsen_price_kbn.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <OnsenPriceCollapse>
            <FormControl fieldset>
              <FormLabel>料金</FormLabel>
              <Flex gap={4} align="center">
                <FormControl fieldName="onsen_price">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>円</InputRightAddon>
                  </InputGroup>
                  <PortalErrorMessages />
                </FormControl>

                <Text>/</Text>

                <FormControl fieldName="onsen_tani_kbn">
                  <Select>
                    <PlaceholderOption />
                    <EnumOptions enum={miraieFields.onsen_tani_kbn.Enum} />
                  </Select>
                  <PortalErrorMessages />
                </FormControl>
              </Flex>
            </FormControl>
          </OnsenPriceCollapse>
          <FormControl fieldset>
            <FormLabel>仕様</FormLabel>
            <Stack gap={2} direction="column">
              <CheckboxGroup>
                <BooleanEnumCheckbox fieldName="onsen_kakenagashi_flg">
                  かけ流し
                </BooleanEnumCheckbox>
                <BooleanEnumCheckbox fieldName="onsen_kaon_flg">
                  加温
                </BooleanEnumCheckbox>
                <BooleanEnumCheckbox fieldName="onsen_kasui_flg">
                  加水
                </BooleanEnumCheckbox>
                <BooleanEnumCheckbox fieldName="onsen_hakobiyu_flg">
                  運び湯
                </BooleanEnumCheckbox>
                <BooleanEnumCheckbox fieldName="onsen_junkan_flg">
                  循環装置使用
                </BooleanEnumCheckbox>
                <BooleanEnumCheckbox fieldName="onsen_roka_flg">
                  循環ろ過装置使用
                </BooleanEnumCheckbox>
              </CheckboxGroup>
            </Stack>
            <PortalErrorMessages fieldName="onsen_kakenagashi_flg" />
            <PortalErrorMessages fieldName="onsen_kaon_flg" />
            <PortalErrorMessages fieldName="onsen_kasui_flg" />
            <PortalErrorMessages fieldName="onsen_hakobiyu_flg" />
            <PortalErrorMessages fieldName="onsen_junkan_flg" />
            <PortalErrorMessages fieldName="onsen_roka_flg" />
          </FormControl>
        </FormGroup>
      </OnsenDetailCollapse>
    </FormGroup>
  )
}
