import {
  Alert,
  AlertDescription,
  AlertIcon,
  Flex,
  Text,
} from '@chakra-ui/react'
import { useMatchRoute } from '@tanstack/react-router'

import { SelectMasquerade } from '@/components/auth/SelectMasquerade'
import { useOperator } from '@/hooks/useOperator'

export const MasqueradeInfo = () => {
  const { name, email } = useOperator()
  const isReadonly = Boolean(
    useMatchRoute()({
      to: '/bukkens/$importId',
      fuzzy: true,
    }),
  )

  return (
    <Alert justifyContent="center" bg="pink.50" height={16}>
      <AlertIcon color="pink.500" />
      <AlertDescription>
        {isReadonly ? (
          <Flex>
            代理操作対象のエージェント：
            <Text fontWeight="bold">
              {name}（{email}）
            </Text>
          </Flex>
        ) : (
          <SelectMasquerade />
        )}
      </AlertDescription>
    </Alert>
  )
}
