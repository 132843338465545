import {
  MiraieFieldPath,
  MiraieFieldPathValues,
  useWatchMiraieFields,
} from '@/hooks/useMiraieForm'

export const MiraieFieldContents = <T extends readonly MiraieFieldPath[]>({
  name,
  display,
  children,
}: {
  name: readonly [...T]
  display: (...values: MiraieFieldPathValues<T>) => boolean
  children: React.ReactNode
}) => (
  <>
    {display(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...(useWatchMiraieFields({ name }) as MiraieFieldPathValues<T>),
    ) && children}
  </>
)
