import { Portal, portals } from '../../../portal'
import * as c_open_kbn from '../c_open_kbn'

import * as mngr_open_kbn from './enum'

type MngrPortal = Exclude<Portal, 'athome'>

/** athome を除く各ポータルのC向け公開区分に応じたマネージャ公開区分を取得する */
export function getValues(customerOpenKubuns: {
  readonly [P in MngrPortal as `${P}_c_open_kbn`]?: c_open_kbn.Enum
}): {
  [P in MngrPortal as `${P}_mngr_open_kbn`]?: mngr_open_kbn.Enum
} {
  const managerOpenKubuns: ReturnType<typeof getValues> = {}

  portals.forEach((portal) => {
    if (
      portal !== 'athome' &&
      customerOpenKubuns[`${portal}_c_open_kbn`] !== c_open_kbn.Enum.掲載
    )
      managerOpenKubuns[`${portal}_mngr_open_kbn`] = mngr_open_kbn.Enum.非公開
  })

  return managerOpenKubuns
}
