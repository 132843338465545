import { Box, Card, CardBody } from '@chakra-ui/react'

import { Kenri } from './Kenri'
import { Menseki } from './Menseki'
import { SeigenKeikaku } from './SeigenKeikaku'
import { Setsudo } from './Setsudo'
import { Shakuchiken } from './Shakuchiken'
import { ShidoSetbac } from './ShidoSetbac'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { sectionIds } from '@/components/form/SectionBar'
import { TochiSectionContents } from '@/components/form/Tochi/TochiSectionContents'

export const TochiSection: React.FC = () => {
  return (
    <TochiSectionContents>
      <Box as="section" id={sectionIds.tochiSection}>
        <FormSectionHeading>土地</FormSectionHeading>
        <Card>
          <CardBody as={FormGroup}>
            <Kenri />
            <Shakuchiken />
            <Menseki />
            <ShidoSetbac />
            <SeigenKeikaku />
            <Setsudo />
          </CardBody>
        </Card>
      </Box>
    </TochiSectionContents>
  )
}
