import {
  QuerySnapshot,
  onSnapshot,
  query,
  limit,
  limitToLast,
  endBefore,
  startAfter,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Query,
} from 'firebase/firestore'

import { useObserver } from '@/hooks/useObserver'

export type UsePaginatedQueryProps<T> = {
  direction: 'previous' | 'next'
  onNext: (snapshot: QueryDocumentSnapshot<T>) => void
  onPrevious: (snapshot: QueryDocumentSnapshot<T>) => void
  pageSize: number
  queryDeps: readonly unknown[]
  query: Query<T>
  cursorSnapshot: DocumentSnapshot<T> | null
}

export function usePaginatedQuery<T>(props: UsePaginatedQueryProps<T>): {
  hasNext: boolean
  nextPage: () => void
  hasPrevious: boolean
  previousPage: () => void
  items: { id: string; data: T }[]
} {
  const { direction, onNext, onPrevious, pageSize, queryDeps, cursorSnapshot } =
    props
  const querySnapshot: QuerySnapshot<T> = useObserver({
    observerKey: [queryDeps, pageSize, direction, cursorSnapshot?.ref.path],
    observerFn: (onChange, onError) =>
      onSnapshot(
        query(
          props.query,
          ...(!cursorSnapshot
            ? direction === 'previous'
              ? [limit(pageSize + 1)]
              : [limitToLast(pageSize + 1)]
            : direction === 'previous'
              ? [limitToLast(pageSize + 1), endBefore(cursorSnapshot)]
              : [limit(pageSize + 1), startAfter(cursorSnapshot)]),
        ),
        onChange,
        onError,
      ),
  })
  const hasMore = pageSize < querySnapshot.size
  const docs = hasMore
    ? direction === (cursorSnapshot ? 'previous' : 'next')
      ? querySnapshot.docs.toSpliced(0, 1)
      : querySnapshot.docs.slice(0, pageSize)
    : querySnapshot.docs
  const hasNext = cursorSnapshot
    ? direction === 'previous' || hasMore
    : direction === 'previous' && hasMore
  const hasPrevious = cursorSnapshot
    ? direction === 'next' || hasMore
    : direction === 'next' && hasMore
  const nextPage = () => {
    const cursorSnapshot = docs.at(-1)
    if (!cursorSnapshot) return

    onNext(cursorSnapshot)
  }
  const previousPage = () => {
    const cursorSnapshot = docs[0]
    if (!cursorSnapshot) return

    onPrevious(cursorSnapshot)
  }
  const items = docs.map((doc) => ({ id: doc.id, data: doc.data() }))

  return {
    hasNext,
    hasPrevious,
    nextPage,
    previousPage,
    items,
  }
}
