import { Flex, InputGroup, InputRightAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { GenkyoKubunRadios } from './GenkyoKubunRadios'
import { HikiwatashiJokenKubunRadios } from './HikiwatashiJokenKubunRadios'
import { HikiwatashiKubunRadios } from './HikiwatashiKubunRadios'
import { HikiwatashiNengetsuCollapse } from './HikiwatashiNengetsuCollapse'
import { KeiyakugoHikiwatashiCollapse } from './KeiyakugoHikiwatashiCollapse'
import { KeiyakugoKanseiContents } from './KeiyakugoKanseiContents'

import { ChintaiContents } from '@/components/form/General/ChintaiContents'
import { HikiwatashiJokenKubunContents } from '@/components/form/General/HikiwatashiJokenKubunContents'
import { KeiyakuKikanCollapse } from '@/components/form/General/KeiyakuKikanCollapse'
import { ShakuchiKeiyakuDateCollapse } from '@/components/form/General/ShakuchiKeiyakuDateCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  UnselectedRadio,
  EnumRadios,
  EnumOptions,
  PlaceholderOption,
  YearOptions,
  MonthOptions,
} from '@/components/form/controls'

export const JokyoControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>状況</FormGroupHeading>
      <FormControl fieldName="genkyo_kbn" fieldset isRequired>
        <FormLabel>現況</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <GenkyoKubunRadios />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <FormControl fieldName="hikiwatashi_kbn" fieldset isRequired>
        <FormLabel>引渡時期</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <HikiwatashiKubunRadios />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <FormGroup nested>
        <HikiwatashiNengetsuCollapse>
          <FormControl fieldset>
            <FormLabel>引渡年月</FormLabel>
            <Flex gap={2}>
              <FormControl fieldName="hikiwatashi_ymd_year">
                <Select>
                  <PlaceholderOption />
                  <YearOptions />
                </Select>
              </FormControl>
              <FormControl fieldName="hikiwatashi_ymd_month">
                <Select>
                  <PlaceholderOption />
                  <MonthOptions />
                </Select>
              </FormControl>
              <FormControl fieldName="hikiwatashi_ymd_jun_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions
                    enum={miraieFields.hikiwatashi_ymd_jun_kbn.Enum}
                  />
                </Select>
              </FormControl>
            </Flex>
            <PortalErrorMessages fieldName="hikiwatashi_ymd_year" />
            <PortalErrorMessages fieldName="hikiwatashi_ymd_month" />
            <PortalErrorMessages fieldName="hikiwatashi_ymd_jun_kbn" />
          </FormControl>
        </HikiwatashiNengetsuCollapse>
        <KeiyakugoHikiwatashiCollapse>
          <FormGroup>
            <FormControl fieldName="keiyakugo_nyukyo_taiki_month">
              <FormLabel>契約後入居待機予定月数</FormLabel>
              <InputGroup>
                <NumberInput />
                <InputRightAddon>ヶ月</InputRightAddon>
              </InputGroup>
              <PortalErrorMessages />
            </FormControl>
            <KeiyakugoKanseiContents>
              <FormControl fieldName="keiyakugo_kansei_month">
                <FormLabel>契約後完成予定月数</FormLabel>
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>ヶ月</InputRightAddon>
                </InputGroup>
                <FormHelperText>
                  みらいえ引渡時期「契約後完成予定月数」はSUUMOに連動しません。
                </FormHelperText>
                <PortalErrorMessages />
              </FormControl>
            </KeiyakugoKanseiContents>
          </FormGroup>
        </KeiyakugoHikiwatashiCollapse>
      </FormGroup>
      <MiraieFieldContents
        name={['bk_sbt_kbn', 'homes_c_open_kbn']}
        display={(bk_sbt_kbn, homes_c_open_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.居住用
            ? miraieFields.c_open_kbn.isOpen(homes_c_open_kbn)
            : bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
        }
      >
        <FormControl fieldName="hikiwatashi_biko">
          <FormLabel>引渡/入居時期相談内容</FormLabel>
          <TextInput />
          <PortalErrorMessages />
        </FormControl>
      </MiraieFieldContents>
      <HikiwatashiJokenKubunContents>
        <FormControl fieldName="hikiwatashi_joken_kbn" fieldset>
          <FormLabel>引渡条件</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <HikiwatashiJokenKubunRadios />
          </RadioGroup>
          <FormHelperText>
            HOMES/SUUMO向けは引渡条件：更地渡しのみ対応。
          </FormHelperText>
          <PortalErrorMessages />
        </FormControl>
      </HikiwatashiJokenKubunContents>
      <ChintaiContents>
        <FormControl fieldName="teishaku_keiyaku_flg" fieldset>
          <FormLabel>契約期間</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.teishaku_keiyaku_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>
        <KeiyakuKikanCollapse>
          <FormGroup nested>
            <ShakuchiKeiyakuDateCollapse>
              <FormControl fieldName="shakuchi_keiyaku_date">
                <FormLabel>契約満了日</FormLabel>
                <TextInput type="date" />
                <PortalErrorMessages />
              </FormControl>
            </ShakuchiKeiyakuDateCollapse>
            <FormControl fieldset>
              <FormLabel>契約期間</FormLabel>
              <FormGroup>
                <FormControl fieldName="keiyaku_years">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>年</InputRightAddon>
                  </InputGroup>
                  <PortalErrorMessages />
                </FormControl>
                <FormControl fieldName="keiyaku_months">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>ヶ月</InputRightAddon>
                  </InputGroup>
                  <PortalErrorMessages />
                </FormControl>
              </FormGroup>
            </FormControl>
          </FormGroup>
        </KeiyakuKikanCollapse>
        <FormControl fieldName="kaiyaku_date">
          <FormLabel>解約日</FormLabel>
          <TextInput type="date" />
          <PortalErrorMessages />
        </FormControl>
      </ChintaiContents>
    </FormGroup>
  )
}
