import { callThis } from '../../../utils'
import { addDays } from '../../../utils/date'

/**
 * 掲載可能な最大日時を取得する
 * @param today 今日
 * @param extendableMaxDays 延長可能な日数
 * @param contractEndDate 媒介契約の終了日 先物の場合は `null`
 * @returns 掲載可能な最大日
 */
export function getMaxDate(
  today: Date,
  extendableMaxDays: number,
  contractEndDate: Date | null,
) {
  return contractEndDate ?? callThis(today, addDays, extendableMaxDays)
}
