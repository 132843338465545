import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { WatchMiraieFields } from '@/components/form/WatchMiraieFields'
import {
  BooleanEnumCheckbox,
  CheckboxGroup,
  EnumOptions,
  EnumRadios,
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const Other = () => {
  return (
    <FormGroup>
      <FormGroupHeading>その他</FormGroupHeading>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.売戸建住宅 &&
          bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
        }
      >
        <FormControl fieldset>
          <FormLabel>管理</FormLabel>
          <FormGroup nested>
            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
              }
            >
              <WatchMiraieFields name={['bk_sbt_kbn']}>
                {(bk_sbt_kbn) => (
                  <FormControl
                    fieldName="manage_keitai_kbn"
                    fieldset
                    isRequired={
                      bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
                    }
                  >
                    <FormLabel>管理形態</FormLabel>
                    <RadioGroup>
                      <UnselectedRadio />
                      <EnumRadios enum={miraieFields.manage_keitai_kbn.Enum} />
                    </RadioGroup>
                    <PortalErrorMessages />
                  </FormControl>
                )}
              </WatchMiraieFields>
            </MiraieFieldContents>

            <WatchMiraieFields name={['bk_sbt_kbn']}>
              {(bk_sbt_kbn) => (
                <FormControl
                  fieldName="manage_hoshiki_kbn"
                  fieldset
                  isRequired={
                    bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
                  }
                >
                  <FormLabel>管理員</FormLabel>
                  <RadioGroup>
                    <UnselectedRadio />
                    <EnumRadios enum={miraieFields.manage_hoshiki_kbn.Enum} />
                  </RadioGroup>
                  <PortalErrorMessages />
                </FormControl>
              )}
            </WatchMiraieFields>

            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.居住用
              }
            >
              <FormControl fieldName="manage_company_name">
                <InputGroup>
                  <InputLeftAddon>管理会社名</InputLeftAddon>
                  <TextInput />
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldContents>

            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'rent'
              }
            >
              <FormControl fieldName="manage_company_tel">
                <InputGroup>
                  <InputLeftAddon>管理会社TEL</InputLeftAddon>
                  <TextInput />
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldContents>

            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
              }
            >
              <FormControl fieldName="manage_kumiai_umu_flg" fieldset>
                <FormLabel>管理組合有無</FormLabel>
                <RadioGroup>
                  <UnselectedRadio />
                  <EnumRadios enum={miraieFields.manage_kumiai_umu_flg.Enum} />
                </RadioGroup>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldContents>
          </FormGroup>
        </FormControl>
      </MiraieFieldContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'buy'
        }
      >
        <FormGroup>
          <FormControl fieldName="kenchikukakunin_flg">
            <FormLabel>建築確認番号</FormLabel>
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.kenchikukakunin_flg.Enum} />
            </Select>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldCollapse
            name={['kenchikukakunin_flg']}
            display={(kenchikukakunin_flg) =>
              kenchikukakunin_flg === miraieFields.kenchikukakunin_flg.Enum.有
            }
          >
            <FormGroup nested>
              <FormControl fieldName="kenchiku_no">
                <InputGroup>
                  <InputLeftAddon>建築確認番号</InputLeftAddon>
                  <TextInput />
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </MiraieFieldCollapse>
        </FormGroup>
      </MiraieFieldContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
        }
      >
        <FormGroup>
          <FormControl fieldName="tatemonokensa_jisshi_flg">
            <FormLabel>建物検査</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.tatemonokensa_jisshi_flg.Enum} />
            </RadioGroup>
            <FormHelperText>
              「申込対応可」はathomeのみ(他ポータル向けには「未実施」となります)。
            </FormHelperText>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldCollapse
            name={['tatemonokensa_jisshi_flg']}
            display={(tatemonokensa_jisshi_flg) =>
              tatemonokensa_jisshi_flg ===
              miraieFields.tatemonokensa_jisshi_flg.Enum.実施済
            }
          >
            <FormGroup nested>
              <FormControl fieldName="tatemono_kensa_jisshi_date">
                <InputGroup>
                  <InputLeftAddon>建物検査実施日</InputLeftAddon>
                  <TextInput type="date" />
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>

              <MiraieFieldContents
                name={['athome_c_open_kbn']}
                display={(athome_c_open_kbn) =>
                  miraieFields.c_open_kbn.isOpen(athome_c_open_kbn)
                }
              >
                <FormControl
                  fieldName="tatemono_kensa_jisshi_houkokusho_flg"
                  fieldset
                >
                  <FormLabel>報告書</FormLabel>
                  <RadioGroup>
                    <UnselectedRadio />
                    <EnumRadios
                      enum={
                        miraieFields.tatemono_kensa_jisshi_houkokusho_flg.Enum
                      }
                    />
                  </RadioGroup>
                  <PortalErrorMessages />
                </FormControl>
              </MiraieFieldContents>
            </FormGroup>
          </MiraieFieldCollapse>

          <MiraieFieldCollapse
            name={['tatemonokensa_jisshi_flg', 'athome_c_open_kbn']}
            display={(tatemonokensa_jisshi_flg, athome_c_open_kbn) =>
              tatemonokensa_jisshi_flg ===
                miraieFields.tatemonokensa_jisshi_flg.Enum.申込対応可 &&
              miraieFields.c_open_kbn.isOpen(athome_c_open_kbn)
            }
          >
            <FormGroup nested>
              <FormControl
                fieldName="tatemono_kensa_chosaryo_buyer_flg"
                fieldset
              >
                <FormLabel>検査料買主負担</FormLabel>
                <RadioGroup>
                  <UnselectedRadio />
                  <EnumRadios
                    enum={miraieFields.tatemono_kensa_chosaryo_buyer_flg.Enum}
                  />
                </RadioGroup>
                <PortalErrorMessages />
              </FormControl>
            </FormGroup>
          </MiraieFieldCollapse>
        </FormGroup>
      </MiraieFieldContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn', 'athome_c_open_kbn']}
        display={(bk_sbt_kbn, athome_c_open_kbn) =>
          miraieFields.c_open_kbn.isOpen(athome_c_open_kbn) &&
          (bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション)
        }
      >
        <FormControl fieldName="kashi_hosho_f_kbn" fieldset>
          <FormLabel>瑕疵保証(不動産会社独自)による保証</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.kashi_hosho_f_kbn.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['kashi_hosho_f_kbn']}
          display={(kashi_hosho_f_kbn) =>
            kashi_hosho_f_kbn === miraieFields.kashi_hosho_f_kbn.Enum.有 ||
            kashi_hosho_f_kbn === miraieFields.kashi_hosho_f_kbn.Enum.利用可
          }
        >
          <FormGroup nested>
            <FormControl fieldset>
              <FormLabel>保証期間</FormLabel>
              <Flex gap={2}>
                <FormControl fieldName="kashi_hosho_f_years">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>年</InputRightAddon>
                  </InputGroup>
                </FormControl>
                <FormControl fieldName="kashi_hosho_f_months">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>ヶ月</InputRightAddon>
                  </InputGroup>
                </FormControl>
              </Flex>
              <PortalErrorMessages fieldName="kashi_hosho_f_years" />
              <PortalErrorMessages fieldName="kashi_hosho_f_months" />
            </FormControl>

            <FormControl fieldset>
              <FormLabel>保証範囲</FormLabel>
              <CheckboxGroup>
                <BooleanEnumCheckbox
                  fieldName="kashi_hosho_f_tatemono_flg"
                  children="建物"
                />
                <BooleanEnumCheckbox
                  fieldName="kashi_hosho_f_tochi_flg"
                  children="土地"
                />
                <BooleanEnumCheckbox
                  fieldName="kashi_hosho_f_other_flg"
                  children="その他"
                />
              </CheckboxGroup>
            </FormControl>

            <FormControl fieldName="kashi_hosho_f_other_naiyo">
              <FormLabel>保証範囲詳細</FormLabel>
              <Textarea />
              <PortalErrorMessages />
            </FormControl>

            <MiraieFieldCollapse
              name={['kashi_hosho_f_kbn']}
              display={(kashi_hosho_f_kbn) =>
                kashi_hosho_f_kbn === miraieFields.kashi_hosho_f_kbn.Enum.有
              }
            >
              <FormControl fieldName="kashi_hosho_f_kensa_jisshi_date">
                <InputGroup>
                  <InputLeftAddon>建物検査実施日</InputLeftAddon>
                  <TextInput type="date" />
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldCollapse>

            <MiraieFieldCollapse
              name={['kashi_hosho_f_kbn']}
              display={(kashi_hosho_f_kbn) =>
                kashi_hosho_f_kbn === miraieFields.kashi_hosho_f_kbn.Enum.利用可
              }
            >
              <FormControl
                fieldName="kashi_hosho_f_hokenryo_buyer_flg"
                fieldset
              >
                <FormLabel>保証料買主負担</FormLabel>
                <RadioGroup>
                  <UnselectedRadio />
                  <EnumRadios
                    enum={miraieFields.kashi_hosho_f_hokenryo_buyer_flg.Enum}
                  />
                </RadioGroup>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldCollapse>
          </FormGroup>
        </MiraieFieldCollapse>

        <FormControl fieldName="kashi_hosho_k_kbn" fieldset>
          <FormLabel>瑕疵保険(国交省指定)による保証</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.kashi_hosho_k_kbn.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['kashi_hosho_k_kbn']}
          display={(kashi_hosho_k_kbn) =>
            kashi_hosho_k_kbn === miraieFields.kashi_hosho_k_kbn.Enum.有 ||
            kashi_hosho_k_kbn === miraieFields.kashi_hosho_k_kbn.Enum.利用可
          }
        >
          <FormGroup nested>
            <FormControl fieldset>
              <FormLabel>保証期間</FormLabel>
              <Flex gap={2}>
                <FormControl fieldName="kashi_hosho_k_years">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>年</InputRightAddon>
                  </InputGroup>
                </FormControl>
                <FormControl fieldName="kashi_hosho_k_months">
                  <InputGroup>
                    <NumberInput />
                    <InputRightAddon>ヶ月</InputRightAddon>
                  </InputGroup>
                </FormControl>
              </Flex>
              <PortalErrorMessages fieldName="kashi_hosho_k_years" />
              <PortalErrorMessages fieldName="kashi_hosho_k_months" />
            </FormControl>

            <FormControl fieldset>
              <FormLabel>保証範囲</FormLabel>
              <CheckboxGroup>
                <BooleanEnumCheckbox
                  fieldName="kashi_hosho_k_kouzou_flg"
                  children="構造躯体"
                />
                <BooleanEnumCheckbox
                  fieldName="kashi_hosho_k_amamori_flg"
                  children="雨漏り"
                />
                <BooleanEnumCheckbox
                  fieldName="kashi_hosho_k_kyuhaikan_flg"
                  children="給排水管路"
                />
                <BooleanEnumCheckbox
                  fieldName="kashi_hosho_k_shiroari_flg"
                  children="シロアリ"
                />
                <BooleanEnumCheckbox
                  fieldName="kashi_hosho_k_other_flg"
                  children="その他"
                />
              </CheckboxGroup>
            </FormControl>

            <FormControl fieldName="kashi_hosho_k_other_naiyo">
              <FormLabel>保証範囲詳細</FormLabel>
              <Textarea />
              <PortalErrorMessages />
            </FormControl>

            <MiraieFieldCollapse
              name={['kashi_hosho_k_kbn']}
              display={(kashi_hosho_k_kbn) =>
                kashi_hosho_k_kbn === miraieFields.kashi_hosho_k_kbn.Enum.有
              }
            >
              <FormControl fieldName="kashi_hosho_k_kensa_jisshi_date">
                <InputGroup>
                  <InputLeftAddon>建物検査実施日</InputLeftAddon>
                  <TextInput type="date" />
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldCollapse>

            <MiraieFieldCollapse
              name={['kashi_hosho_k_kbn']}
              display={(kashi_hosho_k_kbn) =>
                kashi_hosho_k_kbn === miraieFields.kashi_hosho_k_kbn.Enum.利用可
              }
            >
              <FormControl
                fieldName="kashi_hosho_k_hokenryo_buyer_flg"
                fieldset
              >
                <FormLabel>保証料買主負担</FormLabel>
                <RadioGroup>
                  <UnselectedRadio />
                  <EnumRadios
                    enum={miraieFields.kashi_hosho_k_hokenryo_buyer_flg.Enum}
                  />
                </RadioGroup>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldCollapse>
          </FormGroup>
        </MiraieFieldCollapse>

        <FormControl fieldName="choki_yuryo_nintei_flg" fieldset>
          <FormLabel>長期優良住宅認定通知書</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.choki_yuryo_nintei_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['choki_yuryo_nintei_flg']}
          display={(choki_yuryo_nintei_flg) =>
            choki_yuryo_nintei_flg ===
            miraieFields.choki_yuryo_nintei_flg.Enum.有
          }
        >
          <FormGroup nested>
            <FormControl fieldName="choki_yuryo_nintei_kensa_jisshi_date">
              <InputGroup>
                <InputLeftAddon>建物検査実施日</InputLeftAddon>
                <TextInput type="date" />
              </InputGroup>
              <PortalErrorMessages />
            </FormControl>

            <FormControl fieldName="choki_yuryo_nintei_zokaichiku_flg" fieldset>
              <FormLabel>増改築有無</FormLabel>
              <RadioGroup>
                <UnselectedRadio />
                <EnumRadios
                  enum={miraieFields.choki_yuryo_nintei_zokaichiku_flg.Enum}
                />
              </RadioGroup>
              <PortalErrorMessages />
            </FormControl>

            <MiraieFieldCollapse
              name={['choki_yuryo_nintei_zokaichiku_flg']}
              display={(choki_yuryo_nintei_zokaichiku_flg) =>
                choki_yuryo_nintei_zokaichiku_flg ===
                miraieFields.choki_yuryo_nintei_zokaichiku_flg.Enum.実施
              }
            >
              <FormControl fieldName="choki_yuryo_nintei_zokaichiku_jisshi_date">
                <InputGroup>
                  <InputLeftAddon>増改築実施日</InputLeftAddon>
                  <TextInput type="date" />
                </InputGroup>
                <FormHelperText>
                  実施日が異なる箇所を登録する場合、最も古い増改築実施日を入力してください。
                </FormHelperText>
                <PortalErrorMessages />
              </FormControl>

              <FormControl fieldName="choki_yuryo_nintei_zokaichiku_naiyo">
                <FormLabel>増改築箇所等</FormLabel>
                <Textarea />
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldCollapse>
          </FormGroup>
        </MiraieFieldCollapse>

        <FormControl fieldName="flat35_shomeisho_flg" fieldset>
          <FormLabel>フラット35適合証明書</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.flat35_shomeisho_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['flat35_shomeisho_flg']}
          display={(flat35_shomeisho_flg) =>
            flat35_shomeisho_flg === miraieFields.flat35_shomeisho_flg.Enum.有
          }
        >
          <FormGroup nested>
            <FormControl fieldName="flat35_shomeisho_tatemono_kensa_jisshi_date">
              <InputGroup>
                <InputLeftAddon>建物検査実施日</InputLeftAddon>
                <TextInput type="date" />
              </InputGroup>
              <PortalErrorMessages />
            </FormControl>
          </FormGroup>
        </MiraieFieldCollapse>

        <FormControl fieldName="flat35_s_shomeisho_flg" fieldset>
          <FormLabel>フラット35S適合証明書</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.flat35_s_shomeisho_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['flat35_s_shomeisho_flg']}
          display={(flat35_s_shomeisho_flg) =>
            flat35_s_shomeisho_flg ===
            miraieFields.flat35_s_shomeisho_flg.Enum.有
          }
        >
          <FormGroup nested>
            <FormControl fieldName="flat35_s_shomeisho_tatemono_kensa_jisshi_date">
              <InputGroup>
                <InputLeftAddon>建物検査実施日</InputLeftAddon>
                <TextInput type="date" />
              </InputGroup>
              <PortalErrorMessages />
            </FormControl>
          </FormGroup>
        </MiraieFieldCollapse>

        <FormControl fieldName="taishin_shomeisho_flg" fieldset>
          <FormLabel>耐震基準適合証明書</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.taishin_shomeisho_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['taishin_shomeisho_flg']}
          display={(taishin_shomeisho_flg) =>
            taishin_shomeisho_flg === miraieFields.taishin_shomeisho_flg.Enum.有
          }
        >
          <FormGroup nested>
            <FormControl fieldName="taishin_shomeisho_tatemono_kensa_jisshi_date">
              <InputGroup>
                <InputLeftAddon>建物検査実施日</InputLeftAddon>
                <TextInput type="date" />
              </InputGroup>
              <PortalErrorMessages />
            </FormControl>
          </FormGroup>
        </MiraieFieldCollapse>

        <FormControl fieldName="hou_tekigo_houkokusho_flg" fieldset>
          <FormLabel>法適合状況調査報告書</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.hou_tekigo_houkokusho_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['hou_tekigo_houkokusho_flg']}
          display={(hou_tekigo_houkokusho_flg) =>
            hou_tekigo_houkokusho_flg ===
            miraieFields.hou_tekigo_houkokusho_flg.Enum.有
          }
        >
          <FormGroup nested>
            <FormControl fieldName="hou_tekigo_houkokusho_kensa_jisshi_date">
              <InputGroup>
                <InputLeftAddon>建物検査実施日</InputLeftAddon>
                <TextInput type="date" />
              </InputGroup>
              <PortalErrorMessages />
            </FormControl>
          </FormGroup>
        </MiraieFieldCollapse>

        <FormControl fieldName="kenchikushi_shomeisho_flg" fieldset>
          <FormLabel>建築士等の建物検査報告書</FormLabel>
          <RadioGroup>
            <UnselectedRadio />
            <EnumRadios enum={miraieFields.kenchikushi_shomeisho_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['kenchikushi_shomeisho_flg']}
          display={(kenchikushi_shomeisho_flg) =>
            kenchikushi_shomeisho_flg ===
            miraieFields.kenchikushi_shomeisho_flg.Enum.有
          }
        >
          <FormGroup nested>
            <FormControl fieldName="kenchikushi_shomeisho_tatemono_kensa_jisshi_date">
              <InputGroup>
                <InputLeftAddon>建物検査実施日</InputLeftAddon>
                <TextInput type="date" />
              </InputGroup>
              <PortalErrorMessages />
            </FormControl>
          </FormGroup>
        </MiraieFieldCollapse>
      </MiraieFieldContents>
    </FormGroup>
  )
}
