import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
} from '@chakra-ui/react'

import {
  CreateBaitaiKeisaiFormControlContextProps,
  CreateBaitaiKeisaiFormControlContextProvider,
} from '@/components/CreateBaitaiKeisaiForm/FormControlContext'
import { useCreateBaitaiKeisaiFormState } from '@/hooks/useCreateBaitaiKeisaiForm'

export type FormControlProps = CreateBaitaiKeisaiFormControlContextProps &
  ChakraFormControlProps

export const FormControl = ({ fieldName, ...props }: FormControlProps) => {
  const { errors } = useCreateBaitaiKeisaiFormState({ name: fieldName })
  const isInvalid = Boolean(errors[fieldName]?.message)

  return (
    <CreateBaitaiKeisaiFormControlContextProvider value={{ fieldName }}>
      <ChakraFormControl isInvalid={isInvalid} {...props} />
    </CreateBaitaiKeisaiFormControlContextProvider>
  )
}
