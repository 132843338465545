/**
 * 取引態様区分
 */
export const Enum = {
  売主: '1',
  代理: '2',
  専属専任媒介: '3',
  専任媒介: '4',
  一般媒介: '5',
} as const
