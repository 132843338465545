import { Portal } from '@terass/common/src/portal'

import { BooleanEnumCheckbox } from '@/components/form/controls'

export const MapSearchFlgCheckbox: React.FC<{
  portal: Extract<Portal, 'suumo' | 'athome'>
}> = ({ portal }) => (
  <BooleanEnumCheckbox fieldName={`${portal}_map_serchable_flg`}>
    地図検索で表示する
  </BooleanEnumCheckbox>
)
