/**
 * Call-this operator のような動きを実現する関数
 * @link https://github.com/tc39/proposal-call-this
 * @example
 * ```ts
 * function add(this: number, num: number) {
 *   return this + num
 * }
 *
 * callThis(1, add, 2) // -> 3
 * ```
 */
export function callThis<This, Args extends unknown[], Returns>(
  thisArg: This,
  caller: (this: This, ...args: Args) => Returns,
  ...args: Args
): Returns {
  return caller.call(thisArg, ...args)
}
