import { miraieFields } from '@terass/common/src'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'
import { useForm } from 'react-hook-form'

import { useBukkenImagesContext } from '@/components/form/BukkenImages/hooks/useBukkenImagesContext'
import { BukkenImagesForm, Image } from '@/components/form/BukkenImages/types'

export const useBukkenImagesForm = () => {
  return useForm<BukkenImagesForm>({
    values: createDefaultValues(useBukkenImagesContext()),
  })
}

const createDefaultValues = ({
  images = [],
  bk_sbt_kbn,
  present_caption,
  pamphlet_info_caption_kbn,
}: Pick<
  PostMiraieV1ImportForm,
  'images' | 'bk_sbt_kbn' | 'present_caption' | 'pamphlet_info_caption_kbn'
>): BukkenImagesForm => {
  const imagesMap: ReadonlyMap<Image['img_no'], Image> = images.reduce(
    (map, image) => map.set(image.img_no, image),
    new Map<Image['img_no'], Image>(),
  )
  const formValue: BukkenImagesForm = {
    present_caption,
    pamphlet_info_caption_kbn,
  }

  miraieFields.images.img_no.values.forEach((img_no) => {
    const image = imagesMap.get(img_no)
    if (!image || miraieFields.images.isRemover(image)) return

    const category = miraieFields.images.img_no.getCategory({
      img_no,
      bk_sbt_kbn,
    })
    if (!category) return

    const images = formValue[category] || []
    images.push(image)
    formValue[category] = images
  })

  return formValue
}
