import {
  Prefecture,
  Shikuchoson,
  Ooaza,
  Aza,
} from '@terass/common/src/firestore/Address'
import { User } from '@terass/common/src/firestore/User'
import { V1Import } from '@terass/common/src/firestore/V1Import'
import {
  collection,
  CollectionReference,
  getFirestore,
} from 'firebase/firestore'

export function getCollection(path: 'users'): CollectionReference<User>
export function getCollection(path: 'v1import'): CollectionReference<V1Import>
export function getCollection(path: 'address'): CollectionReference<Prefecture>

export function getCollection(
  ...pathSegments: ['address', string, 'shikuchoson']
): CollectionReference<Shikuchoson>

export function getCollection(
  ...pathSegments: ['address', string, 'shikuchoson', string, 'ooaza']
): CollectionReference<Ooaza>

export function getCollection(
  ...pathSegments: [
    'address',
    string,
    'shikuchoson',
    string,
    'ooaza',
    string,
    'aza',
  ]
): CollectionReference<Aza>

export function getCollection(...pathSegments: [string, ...string[]]) {
  return collection(getFirestore(), ...pathSegments)
}
