import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
} from '@chakra-ui/react'
import { createContext, useContext } from 'react'

import { useIsEdit } from '@/hooks/useIsEdit'
import { MiraieFieldPath, useMiraieFormState } from '@/hooks/useMiraieForm'
import { getFieldError } from '@/utils/getFieldError'

export type FormControlProps = {
  fieldName?: MiraieFieldPath
  fieldset?: boolean
} & ChakraFormControlProps

export const FormControl = ({
  fieldName,
  fieldset,
  as = fieldset ? 'fieldset' : undefined,
  id = fieldName,
  ...props
}: FormControlProps) => {
  const { errors } = useMiraieFormState({
    name: fieldName,
    disabled: !fieldName,
  })
  const isInvalid = Boolean(
    fieldName ? getFieldError(errors, fieldName) : undefined,
  )
  // 編集画面ではない場合はdisabledにする
  const isDisabled = !useIsEdit()

  return (
    <FormControlContext.Provider value={{ fieldName, fieldset }}>
      <ChakraFormControl {...{ as, id, isInvalid, isDisabled }} {...props} />
    </FormControlContext.Provider>
  )
}

const FormControlContext = createContext<
  Pick<FormControlProps, 'fieldName' | 'fieldset'>
>({})

export const useFormControlContext = () => useContext(FormControlContext)
