import { Flex, Radio, Text } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { Portal } from '@terass/common/src/portal'
import { pick } from '@terass/common/src/utils'

import { CloseOpenKubunRadio } from '@/components/form/Portal/CloseOpenKubunRadio'
import {
  EnumRadios,
  FormControl,
  FormHelperText,
  RadioGroup,
} from '@/components/form/controls'
import { PortalLogo } from '@/components/logos/PortalLogo'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const OpenKubun: React.FC<{ portal: Portal }> = ({ portal }) => {
  const status = useV1ImportDocument(({ status }) => status)
  const __response = useV1ImportDocument(({ __response }) => __response)
  const portalStatus =
    __response?.open_status?.[`${portal}_flg`]?.after ||
    miraieFields.c_open_kbn.Enum.非掲載

  return (
    <Flex direction="column" gap={2}>
      <PortalLogo portal={portal} />
      {status !== 'closed' ? (
        <FormControl fieldName={`${portal}_c_open_kbn`} fieldset>
          {
            {
              [miraieFields.c_open_kbn.Enum.非掲載]: (
                <RadioGroup>
                  <EnumRadios
                    enum={pick(miraieFields.c_open_kbn.Enum, [
                      '非掲載',
                      '掲載',
                    ])}
                  />
                </RadioGroup>
              ),
              [miraieFields.c_open_kbn.Enum.掲載]: (
                <RadioGroup>
                  <Radio value={miraieFields.c_open_kbn.Enum.掲載}>
                    掲載する
                  </Radio>
                  <CloseOpenKubunRadio portal={portal} />
                </RadioGroup>
              ),
              [miraieFields.c_open_kbn.Enum.エラー有]: (
                <FormHelperText>エラーがあります。</FormHelperText>
              ),
              [miraieFields.c_open_kbn.Enum.掲載期間外]: (
                <FormHelperText>
                  このポータルでの掲載は終了しました。
                </FormHelperText>
              ),
            }[portalStatus]
          }
        </FormControl>
      ) : (
        <Text>この媒体掲載はクローズされています。</Text>
      )}
    </Flex>
  )
}
