import {
  Button,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useModalContext,
  Input,
  Flex,
  Stack,
} from '@chakra-ui/react'
import { FormEventHandler } from 'react'

import { FormControl, FormLabel } from '../../controls'
import { ChibanContents } from '../ChibanContents'

import { AzaSelect } from './AzaSelect'
import { OoazaSelect } from './OoazaSelect'
import { PrefectureSelect } from './PrefectureSelect'
import { ShikuchosonSelect } from './ShikuchosonSelect'
import { AddressForm, useAddressFormContext } from './hooks/useAddressForm'

import { Contents } from '@/components/Contents'

type Props = {
  setAddressValues: (address: AddressForm) => void
}

export const AddressFormModalContent = ({ setAddressValues }: Props) => {
  const { handleSubmit, register } = useAddressFormContext()
  const { onClose } = useModalContext()

  const onSubmit: FormEventHandler = (e) => {
    e.stopPropagation()

    handleSubmit((address) => setAddressValues(address))(e)
  }

  return (
    <ModalContent>
      <Contents as="form" onSubmit={onSubmit}>
        <ModalHeader>所在地</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={8}>
            <Flex gap={4}>
              <FormControl>
                <FormLabel>都道府県</FormLabel>
                <PrefectureSelect />
              </FormControl>

              <FormControl>
                <FormLabel>市区町村</FormLabel>
                <ShikuchosonSelect />
              </FormControl>

              <FormControl>
                <FormLabel>大字・通称名</FormLabel>
                <OoazaSelect />
              </FormControl>

              <FormControl>
                <FormLabel>字・丁目</FormLabel>
                <AzaSelect />
              </FormControl>
            </Flex>

            <Stack gap={4}>
              <FormControl>
                <FormLabel>番地以下</FormLabel>
                <Input {...register('banchi')} />
              </FormControl>

              <ChibanContents>
                <FormControl>
                  <FormLabel>地番</FormLabel>
                  <Input {...register('chiban')} />
                </FormControl>
              </ChibanContents>

              <FormControl>
                <FormLabel>街区号棟</FormLabel>
                <Input {...register('partitionName')} />
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter gap={4} mt={4}>
          <Button colorScheme="gray" onClick={onClose}>
            キャンセル
          </Button>

          <Button type="submit">完了</Button>
        </ModalFooter>
      </Contents>
    </ModalContent>
  )
}
