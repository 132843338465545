import { Radio, RadioProps } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { swapKeyValue } from '@terass/common/src/utils'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const GenkyoKubunRadios = (props: RadioProps) => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })
  const options = bk_sbt_kbn ? genkyoKubunOptionsMap[bk_sbt_kbn] : []
  return (
    <>
      {options.map((value) => (
        <Radio key={value} value={value} {...props}>
          {swapKeyValue(miraieFields.genkyo_kbn.Enum)[value]}
        </Radio>
      ))}
    </>
  )
}

const genkyoKubunOptionsMap = {
  [miraieFields.bk_sbt_kbn.Enum.売地]: [
    miraieFields.genkyo_kbn.Enum.更地,
    miraieFields.genkyo_kbn.Enum.古家付,
    miraieFields.genkyo_kbn.Enum.未造成,
  ],
  [miraieFields.bk_sbt_kbn.Enum.売戸建住宅]: [
    miraieFields.genkyo_kbn.Enum.居住中,
    miraieFields.genkyo_kbn.Enum.空,
    miraieFields.genkyo_kbn.Enum.建築中,
    miraieFields.genkyo_kbn.Enum.築後未入居,
    miraieFields.genkyo_kbn.Enum.賃貸中,
    miraieFields.genkyo_kbn.Enum.未着工,
  ],
  get [miraieFields.bk_sbt_kbn.Enum.売マンション]() {
    return this[miraieFields.bk_sbt_kbn.Enum.売戸建住宅]
  },
  get [17 /** 戸建て(2戸以上) */]() {
    return this[miraieFields.bk_sbt_kbn.Enum.売戸建住宅]
  },
  [miraieFields.bk_sbt_kbn.Enum.居住用]: [
    miraieFields.genkyo_kbn.Enum.居住中,
    miraieFields.genkyo_kbn.Enum.空,
    miraieFields.genkyo_kbn.Enum.建築中,
    miraieFields.genkyo_kbn.Enum.使用中,
    miraieFields.genkyo_kbn.Enum.築後未入居,
  ],
} as const
