import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ShikikinCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['shikikin_flg']}
      display={(shikikin_flg) =>
        shikikin_flg === miraieFields.shikikin_flg.Enum.有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
