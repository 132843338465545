import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const SekoCompanyContents = ({ children }: PropsWithChildren) => {
  const [bk_sbt_kbn, homes_c_open_kbn] = useWatchMiraieFields({
    name: ['bk_sbt_kbn', 'homes_c_open_kbn'],
  })
  const display =
    bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.居住用
      ? miraieFields.c_open_kbn.isOpen(homes_c_open_kbn)
      : bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.売地
  return display && children
}
