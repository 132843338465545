import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  HStack,
  Image,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  useModalContext,
  VStack,
} from '@chakra-ui/react'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { useRef, useState } from 'react'

import { useAddressName } from '@/hooks/useAddressName'
import { useLoadScript } from '@/hooks/useLoadScript'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'

const defaultPosition = { lat: 35.68037377794453, lng: 139.76902619789323 } // 東京駅

export const LatLngModalContent = () => {
  const addressName = useAddressName()

  const { getValues } = useMiraieFormContext()
  const [lat, lon] = getValues(['lat', 'lon'])

  const { isLoaded, loadError } = useLoadScript()

  const [position, setPosition] = useState({ ...defaultPosition })

  const [places, setPlaces] = useState<google.maps.places.PlaceResult[]>([])

  const { onClose } = useModalContext()

  const { setValue } = useMiraieFormContext()
  const onSubmit = () => {
    setValue('lat', Number(position.lat?.toFixed(7)))
    setValue('lon', Number(position.lng?.toFixed(7)))
    onClose()
  }

  function onMapClick(e: google.maps.MapMouseEvent) {
    const lat = e.latLng?.lat()
    const lng = e.latLng?.lng()

    if (lat == null || lng == null) return

    setPosition({ lat, lng })
  }

  function onPlaceClick(place: google.maps.places.PlaceResult) {
    const lat = place.geometry?.location?.lat()
    const lng = place.geometry?.location?.lng()

    if (lat == null || lng == null) return

    setPosition({ lat, lng })
    googleMap.current?.panTo({ lat, lng })
    googleMap.current?.setZoom(16)
  }

  const googleMap = useRef<google.maps.Map | null>(null)
  async function onMapLoad(map: google.maps.Map) {
    googleMap.current = map

    const pos = await getStartPosition(
      lat,
      lon,
      addressName,
      new google.maps.places.PlacesService(googleMap.current),
    )

    setPosition(pos)
    googleMap.current?.panTo(pos)
    googleMap.current?.setZoom(16)
  }

  function nearbySearch(keyword: string) {
    if (googleMap.current == null) {
      return
    }

    const service = new google.maps.places.PlacesService(googleMap.current)

    service.textSearch(
      {
        query: keyword,
        location: googleMap.current.getCenter(),
        radius: 5000,
      },
      (result, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) return

        setPlaces(result ?? [])
      },
    )
  }

  if (loadError) return 'Error'
  if (!isLoaded) return 'Loading...'

  return (
    <ModalContent>
      <ModalHeader>緯度/経度 選択</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <HStack>
          <Box width="60%">
            <GoogleMap
              mapContainerStyle={{
                height: '60vh',
                width: '100%',
              }}
              zoom={12}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
              }}
              onClick={onMapClick}
              onLoad={onMapLoad}
            >
              <MarkerF position={position} label="" />
            </GoogleMap>
          </Box>

          <Flex
            direction="column"
            width="40%"
            height="60vh"
            gap={4}
            backgroundColor="gray.100"
            padding={4}
            borderRadius={8}
            overflow="hidden"
          >
            <Center>
              <Input
                type="text"
                placeholder="Search"
                sx={{
                  bg: 'white',
                  zIndex: 1,
                  width: 60,
                  textOverflow: `ellipses`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                }}
                onChange={(e) => nearbySearch(e.target.value)}
              />
            </Center>
            <VStack
              overflowY="scroll"
              width="100%"
              flexGrow={1}
              flex={1}
              direction="column"
              gap={4}
            >
              {places.map(
                (place, i) =>
                  place.geometry?.location && (
                    <Card
                      key={i}
                      _hover={{ borderColor: 'gray.300', bgColor: 'gray.100' }}
                      width="100%"
                    >
                      <CardBody as={HStack} onClick={() => onPlaceClick(place)}>
                        <Image
                          src={place.icon}
                          width={10}
                          verticalAlign="top"
                        />
                        <Stack>
                          <Text fontWeight="bold">{place.name}</Text>
                          <Text>
                            {place.formatted_address ?? place.vicinity}
                          </Text>
                        </Stack>
                      </CardBody>
                    </Card>
                  ),
              )}
            </VStack>
          </Flex>
        </HStack>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} colorScheme="gray" mr={4}>
          キャンセル
        </Button>
        <Button onClick={onSubmit}>完了</Button>
      </ModalFooter>
    </ModalContent>
  )
}

const getStartPosition = (
  lat: number | undefined | null,
  lon: number | undefined | null,
  address: string | undefined,
  service: google.maps.places.PlacesService,
) => {
  if (lat != null && lon != null) {
    return { lat, lng: lon }
  }

  if (!address) return defaultPosition

  return new Promise<{ lat: number; lng: number }>((resolve) => {
    service.findPlaceFromQuery(
      {
        query: address,
        fields: ['geometry'],
      },
      (result, status) => {
        if (
          status !== google.maps.places.PlacesServiceStatus.OK ||
          result == null ||
          result.length === 0
        ) {
          resolve(defaultPosition)
          return
        }

        const lat = result[0]?.geometry?.location?.lat()
        const lng = result[0]?.geometry?.location?.lng()

        if (lat === undefined || lng === undefined) {
          resolve(defaultPosition)
          return
        }

        resolve({ lat, lng })
      },
    )
  })
}
