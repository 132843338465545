import { Radio, RadioProps } from '@chakra-ui/react'

export type EnumRadiosProps = {
  enum: Record<string, string>
} & Omit<RadioProps, 'children'>

export const EnumRadios = ({ enum: enumProp, ...props }: EnumRadiosProps) => (
  <>
    {Object.entries(enumProp).map(([label, value]) => (
      <Radio key={value} value={value} {...props}>
        {label}
      </Radio>
    ))}
  </>
)
