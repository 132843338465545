import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonProps,
  FocusLock,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Spinner,
  StackDivider,
  VStack,
  useMergeRefs,
} from '@chakra-ui/react'
import { Suspense, useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { BsSearch } from 'react-icons/bs'

import { AgentList } from '@/components/auth/SelectMasquerade/AgentList'
import { SelectMasqueradeContent } from '@/components/auth/SelectMasquerade/SelectMasqueradeContent'
import { useSelectMasqueradeForm } from '@/components/auth/SelectMasquerade/hooks'

export const SelectMasquerade = (props: ButtonProps) => {
  const methods = useSelectMasqueradeForm()
  const { register } = methods
  const { ref, ...registered } = register('query')
  const inputRef = useRef<HTMLInputElement>(null)
  const mergedInputRef = useMergeRefs(inputRef, ref)

  return (
    <FormProvider {...methods}>
      <Popover initialFocusRef={inputRef} placement="bottom-start">
        <PopoverTrigger>
          <Button
            variant="outline"
            colorScheme="gray"
            bg="white"
            rightIcon={<ChevronDownIcon />}
            {...props}
          >
            <Suspense fallback={<Spinner />}>
              <SelectMasqueradeContent />
            </Suspense>
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="auto">
            <FocusLock restoreFocus>
              <InputGroup>
                <Input
                  placeholder="検索"
                  variant="flushed"
                  px="3"
                  {...registered}
                  ref={mergedInputRef}
                />
                <InputRightElement pointerEvents="none" color="gray.400">
                  <BsSearch />
                </InputRightElement>
              </InputGroup>
              <Suspense
                fallback={
                  <VStack divider={<StackDivider />} align="stretch" p="2">
                    <Skeleton h="4" />
                    <Skeleton h="4" />
                    <Skeleton h="4" />
                  </VStack>
                }
              >
                <AgentList />
              </Suspense>
            </FocusLock>
          </PopoverContent>
        </Portal>
      </Popover>
    </FormProvider>
  )
}
