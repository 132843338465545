export const normalizePrice = (
  miraiePrice: number | null | undefined,
  isChintai: boolean,
) =>
  miraiePrice == null
    ? undefined
    : isChintai
      ? miraiePrice
      : miraiePrice * 10000

export const convertToMiraiePrice = (
  normalizedPrice: number | null | undefined,
  isChintai: boolean,
) =>
  normalizedPrice == null
    ? undefined
    : isChintai
      ? normalizedPrice
      : Math.trunc(normalizedPrice / 10000)
