/**
 * 地役権区分
 */
export const Enum = {
  無: '1',
  地役権: '2',
  通行地役権: '3',
  引水地役権: '4',
  眺望借地権: '5',
  通路借地権: '6',
} as const
