export const readAsDataURL = (reader: FileReader, file: File) =>
  new Promise<Extract<FileReader['result'], string>>((resolve, reject) => {
    reader.addEventListener('load', loadListener)
    reader.addEventListener('error', reject)
    reader.addEventListener('abort', reject)
    reader.addEventListener('loadend', removeEventListener)
    reader.readAsDataURL(file)

    function removeEventListener() {
      reader.removeEventListener('load', loadListener)
      reader.removeEventListener('error', reject)
      reader.removeEventListener('abort', reject)
      reader.removeEventListener('loadend', removeEventListener)
    }

    function loadListener() {
      if (typeof reader.result !== 'string') throw new Error()
      resolve(reader.result)
    }
  })
