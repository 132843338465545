import {
  StackDivider,
  VStack,
  chakra,
  usePopoverContext,
} from '@chakra-ui/react'

import { useWatchSelectMasqueradeForm } from '@/components/auth/SelectMasquerade/hooks'
import { useAgents } from '@/hooks/useAgents'
import { useUpdateOwnUser } from '@/hooks/useOwnUser'

export const AgentList = () => {
  const agents = useAgents()
  const updateUser = useUpdateOwnUser()
  const query = useWatchSelectMasqueradeForm('query')
  const filteredAgents = agents.filter(
    ({ name, englishName, businessName, englishBusinessName }) =>
      [name, englishBusinessName, businessName, englishName].some((name) =>
        name?.toLowerCase().includes(query.toLowerCase()),
      ),
  )
  const { onClose } = usePopoverContext()
  const onSelect = async (masquerade: string) => {
    await updateUser({ masquerade })
    onClose()
  }

  return (
    <VStack
      divider={<StackDivider />}
      maxH="xl"
      overflow="auto"
      spacing={0}
      align="stretch"
    >
      {filteredAgents.map(({ name, email }) => (
        <chakra.button
          key={email}
          onClick={() => onSelect(email)}
          whiteSpace="nowrap"
          transitionDuration="normal"
          transitionProperty="common"
          cursor="pointer"
          _hover={{
            bg: 'gray.100',
          }}
          p="2"
          textAlign="start"
        >
          {name} {email}
        </chakra.button>
      ))}
    </VStack>
  )
}
