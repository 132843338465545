import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ReikinCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['reikin_flg']}
      display={(reikin_flg) => reikin_flg === miraieFields.reikin_flg.Enum.有}
    >
      {children}
    </MiraieFieldCollapse>
  )
}
