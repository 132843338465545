import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const InternetInitPriceCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['internet_initprice_umu_kbn']}
      display={(internet_initprice_umu_kbn) =>
        internet_initprice_umu_kbn ===
        miraieFields.internet_initprice_umu_kbn.Enum.有
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
