import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { HaniEnumCheckbox } from '@/components/form/PriceAndCost/HaniEnumCheckbox'
import {
  EnumOptions,
  EnumRadios,
  FieldLength,
  FormControl,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const ShidoSetbac = () => {
  return (
    <MiraieFieldContents
      name={['bk_sbt_kbn']}
      display={(bk_sbt_kbn) =>
        bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
        bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
        bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
      }
    >
      <FormGroup>
        <FormGroupHeading>私道・セットバック</FormGroupHeading>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldset>
            <FormLabel>道路</FormLabel>
            <FormGroup>
              <FormControl fieldName="doro_haba_hani_kbn">
                <HaniEnumCheckbox>道路幅を範囲で指定する</HaniEnumCheckbox>
                <PortalErrorMessages />
              </FormControl>

              <Flex gap={2} alignItems="center">
                <FormControl fieldName="doro_haba_from">
                  <InputGroup>
                    <NumberInput step={miraieFields.doro_haba_from.step} />
                    <InputRightAddon>m</InputRightAddon>
                  </InputGroup>
                </FormControl>

                <MiraieFieldContents
                  name={['doro_haba_hani_kbn']}
                  display={(doro_haba_hani_kbn) =>
                    doro_haba_hani_kbn ===
                    miraieFields.doro_haba_hani_kbn.Enum['〜']
                  }
                >
                  <Text>~</Text>

                  <FormControl fieldName="doro_haba_to">
                    <InputGroup>
                      <NumberInput step={miraieFields.doro_haba_to.step} />
                      <InputRightAddon>m</InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </MiraieFieldContents>
              </Flex>

              <PortalErrorMessages fieldName="doro_haba_from" />
              <PortalErrorMessages fieldName="doro_haba_to" />

              <FormGroup nested>
                <FormControl fieldName="doro_hoso_kbn">
                  <FormLabel>道路舗装区分</FormLabel>
                  <Select>
                    <PlaceholderOption />
                    <EnumOptions enum={miraieFields.doro_hoso_kbn.Enum} />
                  </Select>
                  <PortalErrorMessages />
                </FormControl>
              </FormGroup>
            </FormGroup>
          </FormControl>

          <FormControl fieldName="suumo_doro_comment">
            <FormLabel>道路説明</FormLabel>
            <Textarea />
            <FieldLength
              maxLength={miraieFields.suumo_doro_comment.maxLength}
            />
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldName="shido_menseki_flg" fieldset>
            <FormLabel>私道面積</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.shido_menseki_flg.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldCollapse
            name={['shido_menseki_flg']}
            display={(shido_menseki_flg) =>
              shido_menseki_flg === miraieFields.shido_menseki_flg.Enum.有
            }
          >
            <FormGroup nested>
              <FormControl fieldset>
                <Flex gap={2} alignItems="center">
                  <FormControl fieldName="shido_menseki">
                    <InputGroup>
                      <InputLeftAddon>面積</InputLeftAddon>
                      <NumberInput step={miraieFields.shido_menseki.step} />
                      <InputRightAddon>㎡</InputRightAddon>
                    </InputGroup>
                  </FormControl>
                  <FormControl fieldName="shido_menseki_kijun_kbn">
                    <Select>
                      <PlaceholderOption />
                      <EnumOptions
                        enum={miraieFields.shido_menseki_kijun_kbn.Enum}
                      />
                    </Select>
                  </FormControl>
                </Flex>
                <PortalErrorMessages fieldName="shido_menseki" />
                <PortalErrorMessages fieldName="shido_menseki_kijun_kbn" />
              </FormControl>

              <FormControl fieldset>
                <FormLabel>面積持分(私道負担割合)</FormLabel>
                <Flex gap={2} alignItems="center">
                  <FormControl fieldName="shido_bunshi">
                    <NumberInput step={miraieFields.shido_bunshi.step} />
                  </FormControl>
                  <Text>/</Text>
                  <FormControl fieldName="shido_bunbo">
                    <NumberInput step={miraieFields.shido_bunbo.step} />
                  </FormControl>
                </Flex>
                <PortalErrorMessages fieldName="shido_bunshi" />
                <PortalErrorMessages fieldName="shido_bunbo" />
              </FormControl>
            </FormGroup>
          </MiraieFieldCollapse>

          <FormControl fieldName="setbac_kbn" fieldset>
            <FormLabel>セットバック</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.setbac_kbn.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldCollapse
            name={['setbac_kbn']}
            display={(setbac_kbn) =>
              setbac_kbn === miraieFields.setbac_kbn.Enum.セットバック有 ||
              setbac_kbn === miraieFields.setbac_kbn.Enum.セットバック済
            }
          >
            <FormGroup nested>
              <FormControl fieldName="setbac_menseki_h">
                <InputGroup>
                  <InputLeftAddon>面積</InputLeftAddon>
                  <NumberInput step={miraieFields.setbac_menseki_h.step} />
                  <InputRightAddon>㎡</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>

              <MiraieFieldContents
                name={['bk_sbt_kbn', 'athome_c_open_kbn']}
                display={(bk_sbt_kbn, athome_c_open_kbn) =>
                  miraieFields.c_open_kbn.isOpen(athome_c_open_kbn) &&
                  (bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
                    bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅)
                }
              >
                <FormControl fieldName="setbac_haba">
                  <InputGroup>
                    <InputLeftAddon>幅</InputLeftAddon>
                    <NumberInput step={miraieFields.setbac_haba.step} />
                    <InputRightAddon>㎡</InputRightAddon>
                  </InputGroup>
                  <PortalErrorMessages />
                </FormControl>
              </MiraieFieldContents>
            </FormGroup>
          </MiraieFieldCollapse>
        </MiraieFieldContents>
      </FormGroup>
    </MiraieFieldContents>
  )
}
