import { Alert, AlertDescription, AlertIcon, Flex } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { AddressDisplayText } from './AddressDisplayText'
import { useOoaza, useAza } from './hooks'
import { useWatchAddressCodes } from './hooks/useWatchAddressCodes'

import { useAddressName } from '@/hooks/useAddressName'

export const AddressDisplay = () => {
  const name = useAddressName()

  return (
    <Flex direction="column" gap={4}>
      <AddressDisplayText>
        {name || '住所が入力されていません'}
      </AddressDisplayText>
      <ObsoleteMessage />
    </Flex>
  )
}

const ObsoleteMessage = () => {
  const { ooazaCode, azaCode } = useWatchAddressCodes()
  return (
    <>
      {ooazaCode !== miraieFields.aza_cd.ooaza.Code.未選択 && <OoazaObsolete />}
      {azaCode !== miraieFields.aza_cd.aza.Code.未選択 && <AzaObsolete />}
    </>
  )
}

const OoazaObsolete = () => {
  const { isObsolete } = useOoaza()
  if (!isObsolete) return null
  return <ObsoleteMessageContent />
}

const AzaObsolete = () => {
  const { isObsolete } = useAza()
  if (!isObsolete) return null
  return <ObsoleteMessageContent />
}

const ObsoleteMessageContent = () => (
  <Alert status="warning" borderRadius={8} justifyContent="center">
    <AlertIcon />
    <AlertDescription>
      この住所は廃止されています。ご確認の上、新しい住所の入力をお願いします。
    </AlertDescription>
  </Alert>
)
