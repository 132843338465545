import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormControl, FormLabel, FormHelperText } from '../controls'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'
import { usePrice } from '@/hooks/usePrice'
import { truncateDecimal, calculateTsubo } from '@/utils/utils'

export const Hosoku: React.FC = () => {
  const price = usePrice()
  const [bk_sbt_kbn, tochi_menseki] = useWatchMiraieFields({
    name: ['bk_sbt_kbn', 'tochi_menseki'],
  })

  if (bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.売地) return null

  let tsuboTanka: number | undefined
  if (price && tochi_menseki) {
    tsuboTanka = truncateDecimal(price / calculateTsubo(tochi_menseki), 2)
  }
  return (
    <FormControl>
      <FormLabel>価格補足</FormLabel>
      <InputGroup>
        <InputLeftAddon>坪単価</InputLeftAddon>
        <Input value={tsuboTanka} isDisabled />
        <InputRightAddon>万円</InputRightAddon>
      </InputGroup>
      <FormHelperText>価格と面積から自動計算されます。</FormHelperText>
    </FormControl>
  )
}
