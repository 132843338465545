import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const BikeOkibaPriceCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['bikeokiba_yuryo_flg']}
      display={(bikeokiba_yuryo_flg) =>
        bikeokiba_yuryo_flg === miraieFields.bikeokiba_yuryo_flg.Enum.有料
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
