import { Button, ButtonProps } from '@chakra-ui/react'
import { getAuth, signOut } from 'firebase/auth'

import { toast } from '@/utils/standaloneToast'

export const LogoutButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      onClick={async () => {
        await signOut(getAuth())
        toast({
          description: 'ログアウトしました',
          status: 'success',
          position: 'top',
          isClosable: true,
        })
      }}
    >
      ログアウト
    </Button>
  )
}
