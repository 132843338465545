import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const CarParkingRyokinCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['parking_yuryo_flg']}
      display={(parking_yuryo_flg) =>
        parking_yuryo_flg === miraieFields.parking_yuryo_flg.Enum.有料
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
