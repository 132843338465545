import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { ChangeEventHandler } from 'react'

import { useFormControlContext } from '@/components/form/controls'
import { MiraieFieldPath, useMiraieFormController } from '@/hooks/useMiraieForm'

export type HaniEnumCheckboxProps = {
  fieldName?: Extract<MiraieFieldPath, `${string}_hani_kbn`>
} & CheckboxProps

/**
 * hani_kbn系のフィールドは「値を2つ指定する場合」を表す「・」と
 * 「値を範囲で指定する場合」を表す「～」の2つの値を取りうる
 * しかし、現実的には範囲で指定する場合のみ対応すれば良さそうなので、Selectではなくチェックボックスで実装する
 * https://github.com/terass-inc/tera-seven/issues/652
 */
export const HaniEnumCheckbox = ({
  fieldName: fieldNameProp,
  ...props
}: HaniEnumCheckboxProps) => {
  const { fieldName: _fieldName } = useFormControlContext()
  const fieldName = fieldNameProp ?? _fieldName
  if (!fieldName) throw new Error()

  const {
    field: { value, ...field },
  } = useMiraieFormController({
    name: fieldName,
  })

  const isChecked = value === miraieFields._hani_kbn.Enum['〜']
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    // チェックされていない場合は「・」ではなくundefined
    field.onChange(
      e.target.checked ? miraieFields._hani_kbn.Enum['〜'] : undefined,
    )

  return (
    <Checkbox
      {...field}
      {...{ isChecked, onChange }}
      id={fieldName}
      {...props}
    />
  )
}
