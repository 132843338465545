import * as bk_sbt_kbn from '../bk_sbt_kbn'

import * as bk_item_kbn from './enum'

type Values = {
  bk_sbt_kbn: bk_sbt_kbn.Enum
  bk_item_kbn: bk_item_kbn.Enum
}

export const isAvailable = ({ bk_sbt_kbn, bk_item_kbn }: Values) =>
  availableMap[bk_item_kbn] === bk_sbt_kbn

const availableMap: {
  [A in bk_item_kbn.Enum]: bk_sbt_kbn.Enum
} = {
  [bk_item_kbn.Enum.所有権譲渡]: bk_sbt_kbn.Enum.売地,
  [bk_item_kbn.Enum.借地権譲渡]: bk_sbt_kbn.Enum.売地,
  [bk_item_kbn.Enum.売戸建]: bk_sbt_kbn.Enum.売戸建住宅,
  [bk_item_kbn.Enum.売マンション]: bk_sbt_kbn.Enum.売マンション,
  [bk_item_kbn.Enum['売戸建て(2戸以上)']]: bk_sbt_kbn.Enum['戸建て(2戸以上)'],
  [bk_item_kbn.Enum.貸マンション]: bk_sbt_kbn.Enum.居住用,
  [bk_item_kbn.Enum.貸アパート]: bk_sbt_kbn.Enum.居住用,
  [bk_item_kbn.Enum.貸戸建住宅]: bk_sbt_kbn.Enum.居住用,
  [bk_item_kbn.Enum.貸テラスハウス]: bk_sbt_kbn.Enum.居住用,
}
