import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { ShinchikuFlagCollapse } from './ShinchikuFlagCollapse'
import { TatemonoNamePerPortalContents } from './TatemonoNamePerPortalContents'

import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { WatchMiraieFields } from '@/components/form/WatchMiraieFields'
import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  TextInput,
  EnumRadios,
} from '@/components/form/controls'

export const BukkenControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>物件</FormGroupHeading>
      <WatchMiraieFields name={['bk_sbt_kbn']}>
        {(bk_sbt_kbn) => (
          <FormControl
            fieldName="tatemono_name"
            isRequired={bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.売地}
          >
            <FormLabel>物件名</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        )}
      </WatchMiraieFields>
      <FormControl fieldName="tatemono_name_kana">
        <FormLabel>物件名フリガナ</FormLabel>
        <TextInput />
        <PortalErrorMessages />
      </FormControl>
      <FormGroup nested>
        <TatemonoNamePerPortalContents portal="suumo">
          <FormControl fieldName="tatemono_name_suumo">
            <FormLabel>物件名(SUUMO)</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
          <FormControl fieldName="tatemono_name_kana_suumo">
            <FormLabel>物件名フリガナ(SUUMO)</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </TatemonoNamePerPortalContents>
        <TatemonoNamePerPortalContents portal="homes">
          <FormControl fieldName="tatemono_name_homes">
            <FormLabel>物件名(HOME'S)</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
          <FormControl fieldName="tatemono_name_kana_homes">
            <FormLabel>物件名フリガナ(HOME'S)</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </TatemonoNamePerPortalContents>
        <MiraieFieldContents
          name={['athome_c_open_kbn', 'bk_sbt_kbn']}
          display={(athome_c_open_kbn, bk_sbt_kbn) =>
            bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.売地 &&
            miraieFields.c_open_kbn.isOpen(athome_c_open_kbn)
          }
        >
          <FormControl fieldName="tatemono_name_athome">
            <FormLabel>物件名(athome)</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
          <FormControl fieldName="tatemono_name_kana_athome">
            <FormLabel>物件名フリガナ(athome)</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>
      </FormGroup>
      <ShinchikuFlagCollapse>
        <FormControl fieldName="shinchiku_flg" fieldset>
          <FormLabel>新築中古</FormLabel>
          <RadioGroup>
            <EnumRadios enum={miraieFields.shinchiku_flg.Enum} />
          </RadioGroup>
          <PortalErrorMessages />
        </FormControl>
      </ShinchikuFlagCollapse>
    </FormGroup>
  )
}
