import { Box, Card, CardBody } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { ChokiYuryo } from './ChokiYuryo'
import { Flat35 } from './Flat35'
import { JutakuSeinou } from './JutakuSeinou'
import { PaymentExample } from './PaymentExample'

import { ColumnSuumoSectionContents } from '@/components/form/ColumnSuumo/ColumnSuumoSectionContents'
import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { sectionIds } from '@/components/form/SectionBar'

export const ColumnSuumoSection: React.FC = () => {
  return (
    <ColumnSuumoSectionContents>
      <Box as="section" id={sectionIds.columnSuumoSection}>
        <FormSectionHeading>コラム（SUUMO専用）</FormSectionHeading>
        <Card>
          <CardBody as={FormGroup}>
            <MiraieFieldContents
              name={['suumonet_keisai_kbn']}
              display={(suumonet_keisai_kbn) =>
                suumonet_keisai_kbn ===
                miraieFields.suumonet_keisai_kbn.Enum.SUUMOネットレポート
              }
            >
              <JutakuSeinou />
              <ChokiYuryo />
            </MiraieFieldContents>
            <PaymentExample />
            <Flat35 />
          </CardBody>
        </Card>
      </Box>
    </ColumnSuumoSectionContents>
  )
}
