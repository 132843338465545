import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

export const uploadImageFile = async (
  data: Parameters<typeof uploadBytes>[1],
  uid: string,
) => {
  const path = `images/${uid}/${Date.now()}-${Math.random()
    .toString(36)
    .substring(2, 8)}`
  const uploadResult = await uploadBytes(ref(getStorage(), path), data)
  const url = await getDownloadURL(uploadResult.ref)
  if (!uploadResult.metadata.md5Hash) throw new Error('Not found `md5hash`')

  const md5 = [...window.atob(uploadResult.metadata.md5Hash)]
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
    .join('')
  return { url, md5 }
}
