import { useOperator } from '@/hooks/useOperator'

export const SelectedAgent = () => {
  const { name, email } = useOperator()
  return (
    <>
      {name} {email}
    </>
  )
}
