/**
 * 居住用途部分以外の用途区分
 */
export const Enum = {
  店舗: '1',
  事務所: '2',
  倉庫: '3',
  工場: '4',
  診療所: '5',
  賃貸: '6',
  その他: '9',
} as const
