import { swapKeyValue } from './utils'

export const portalNames = {
  suumo: 'SUUMO',
  homes: "HOME'S",
  athome: 'athome',
  rakumachi: '楽待',
} as const

export type Portal = keyof typeof portalNames
export type ErrorPortal = Portal | '公取' | 'logParam' | 'validate'

export const ePortal = swapKeyValue(portalNames)

export const portals = [
  ePortal.SUUMO,
  ePortal["HOME'S"],
  ePortal.athome,
  ePortal.楽待,
] as const
