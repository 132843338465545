/**
 * 都市計画区分
 */
export const Enum = {
  市街化区域: '1',
  調整区域: '2',
  非線引区域: '3',
  区域外: '4',
  準都市区域: '5',
} as const
