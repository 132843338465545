/**
 * 客付手数料区分
 */
export const Enum = {
  分かれ: '1',
  当方不払: '2',
  当方片手負担: '3',
  代理負担折半: '4',
  相談: '5',
} as const
