import { ValueOf } from '../../../../types'
import * as bk_sbt_kbn from '../../bk_sbt_kbn'

import { GroupName } from './groupName'

import * as img_sbt_kbn from '.'

export const groupedValues: {
  [G in GroupName]: {
    [B in ValueOf<typeof bk_sbt_kbn.Enum>]: ValueOf<typeof img_sbt_kbn.Enum>[]
  }
} = {
  内観: {
    [bk_sbt_kbn.Enum.売地]: [],
    [bk_sbt_kbn.Enum.売戸建住宅]: [
      img_sbt_kbn.Enum['間取図(平面図)'],
      img_sbt_kbn.Enum['室内'],
      img_sbt_kbn.Enum['バス'],
      img_sbt_kbn.Enum['トイレ'],
      img_sbt_kbn.Enum['洗面'],
      img_sbt_kbn.Enum['キッチン'],
      img_sbt_kbn.Enum['収納'],
      img_sbt_kbn.Enum['玄関'],
      img_sbt_kbn.Enum['眺望'],
      img_sbt_kbn.Enum['バルコニー'],
      img_sbt_kbn.Enum['リビング'],
      img_sbt_kbn.Enum['寝室'],
      img_sbt_kbn.Enum['子供部屋'],
      img_sbt_kbn.Enum['同仕様画像(リビング)'],
      img_sbt_kbn.Enum['同仕様画像(バス)'],
      img_sbt_kbn.Enum['同仕様画像(キッチン)'],
      img_sbt_kbn.Enum['同仕様画像(その他内観)'],
      img_sbt_kbn.Enum['建物プラン例(内観写真)'],
      img_sbt_kbn.Enum['建物プラン例(パース・内観)'],
      img_sbt_kbn.Enum['建物プラン例(間取り図)'],
      img_sbt_kbn.Enum['完成予想図(内観)'],
    ],
    get [bk_sbt_kbn.Enum.売マンション]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: [
      img_sbt_kbn.Enum['間取図(平面図)'],
      img_sbt_kbn.Enum['室内'],
      img_sbt_kbn.Enum['バス'],
      img_sbt_kbn.Enum['トイレ'],
      img_sbt_kbn.Enum['洗面'],
      img_sbt_kbn.Enum['キッチン'],
      img_sbt_kbn.Enum['収納'],
      img_sbt_kbn.Enum['玄関'],
      img_sbt_kbn.Enum['眺望'],
      img_sbt_kbn.Enum['バルコニー'],
      img_sbt_kbn.Enum['リビング'],
      img_sbt_kbn.Enum['寝室'],
      img_sbt_kbn.Enum['子供部屋'],
      img_sbt_kbn.Enum['建物プラン例(外観写真)'],
    ],
  },
  外観: {
    [bk_sbt_kbn.Enum.売地]: [],
    [bk_sbt_kbn.Enum.売戸建住宅]: [
      img_sbt_kbn.Enum['外観'],
      img_sbt_kbn.Enum['エントランス'],
      img_sbt_kbn.Enum['ロビー'],
      img_sbt_kbn.Enum['庭'],
      img_sbt_kbn.Enum['駐車場'],
      img_sbt_kbn.Enum['外観パース図'],
      img_sbt_kbn.Enum['前面道路含む外観'],
      img_sbt_kbn.Enum['同仕様画像(外観)'],
      img_sbt_kbn.Enum['建物プラン例(外観写真)'],
      img_sbt_kbn.Enum['建物プラン例(パース・外観)'],
      img_sbt_kbn.Enum['その他現地'],
    ],
    get [bk_sbt_kbn.Enum.売マンション]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: [
      img_sbt_kbn.Enum['外観'],
      img_sbt_kbn.Enum['エントランス'],
      img_sbt_kbn.Enum['庭'],
      img_sbt_kbn.Enum['駐車場'],
      img_sbt_kbn.Enum['外観パース図'],
    ],
  },
  設備: {
    [bk_sbt_kbn.Enum.売地]: [],
    [bk_sbt_kbn.Enum.売戸建住宅]: [
      img_sbt_kbn.Enum['発電・温水設備'],
      img_sbt_kbn.Enum['冷暖房・空調設備'],
      img_sbt_kbn.Enum['防犯設備'],
      img_sbt_kbn.Enum['その他設備'],
    ],
    get [bk_sbt_kbn.Enum.売マンション]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: [],
  },
  ['地図・図面']: {
    [bk_sbt_kbn.Enum.売地]: [],
    [bk_sbt_kbn.Enum.売戸建住宅]: [
      img_sbt_kbn.Enum['地形図'],
      img_sbt_kbn.Enum['公図実測図'],
      img_sbt_kbn.Enum['地図'],
      img_sbt_kbn.Enum['交通アクセス図'],
      img_sbt_kbn.Enum['路線図'],
    ],
    get [bk_sbt_kbn.Enum.売マンション]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    get [bk_sbt_kbn.Enum.居住用]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
  },
  その他: {
    [bk_sbt_kbn.Enum.売地]: [],
    [bk_sbt_kbn.Enum.売戸建住宅]: [
      img_sbt_kbn.Enum['共有部分'],
      img_sbt_kbn.Enum['構造'],
      img_sbt_kbn.Enum['その他'],
      img_sbt_kbn.Enum['その他建物プラン例'],
      img_sbt_kbn.Enum['その他内観'],
      img_sbt_kbn.Enum['モデルルーム・モデルハウス写真'],
      img_sbt_kbn.Enum['分譲済街並み写真'],
      img_sbt_kbn.Enum['航空写真'],
      img_sbt_kbn.Enum['高台写真'],
      img_sbt_kbn.Enum['街並完成予想図'],
      img_sbt_kbn.Enum['全体区画図'],
      img_sbt_kbn.Enum['区画図'],
      img_sbt_kbn.Enum['区画図+建物プラン例'],
    ],
    get [bk_sbt_kbn.Enum.売マンション]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    get [17 /** 戸建て(2戸以上) */]() {
      return this[bk_sbt_kbn.Enum.売戸建住宅]
    },
    [bk_sbt_kbn.Enum.居住用]: [
      img_sbt_kbn.Enum['共有部分'],
      img_sbt_kbn.Enum['その他設備'],
      img_sbt_kbn.Enum['構造'],
      img_sbt_kbn.Enum['周辺写真'],
      img_sbt_kbn.Enum['前面道路含む外観'],
      img_sbt_kbn.Enum['その他'],
    ],
  },
  土地: {
    [bk_sbt_kbn.Enum.売地]: [
      img_sbt_kbn.Enum['間取図(平面図)'],
      img_sbt_kbn.Enum['前面道路含む外観'],
      img_sbt_kbn.Enum['建物プラン例(外観写真)'],
      img_sbt_kbn.Enum['建物プラン例(内観写真)'],
      img_sbt_kbn.Enum['建物プラン例(パース・外観)'],
      img_sbt_kbn.Enum['建物プラン例(パース・内観)'],
      img_sbt_kbn.Enum['建物プラン例(間取り図)'],
      img_sbt_kbn.Enum['その他建物プラン例'],
      img_sbt_kbn.Enum['眺望'],
      img_sbt_kbn.Enum['外観'],
      img_sbt_kbn.Enum['地図'],
      img_sbt_kbn.Enum['交通アクセス図'],
      img_sbt_kbn.Enum['路線図'],
      img_sbt_kbn.Enum['その他'],
      img_sbt_kbn.Enum['その他現地'],
      img_sbt_kbn.Enum['モデルルーム・モデルハウス写真'],
      img_sbt_kbn.Enum['分譲済街並み写真'],
      img_sbt_kbn.Enum['航空写真'],
      img_sbt_kbn.Enum['高台写真'],
      img_sbt_kbn.Enum['全体区画図'],
      img_sbt_kbn.Enum['区画図+建物プラン例'],
    ],
    [bk_sbt_kbn.Enum.売戸建住宅]: [],
    [bk_sbt_kbn.Enum.売マンション]: [],
    [bk_sbt_kbn.Enum['戸建て(2戸以上)']]: [],
    [bk_sbt_kbn.Enum.居住用]: [],
  },
}
