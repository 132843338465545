import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  FieldLength,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  Textarea,
  TextInput,
} from '@/components/form/controls'
import { useIsChintai } from '@/hooks/useIsChintai'

export const Athome = () => {
  const isChintai = useIsChintai()

  return (
    <MiraieFieldCollapse
      name={['bk_sbt_kbn']}
      display={(bk_sbt_kbn) =>
        bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
      }
    >
      <FormGroup>
        <FormGroupHeading>athome専用項目</FormGroupHeading>

        <FormControl fieldName="athome_com">
          <FormLabel>おすすめコメント</FormLabel>
          <Textarea />
          <FieldLength maxLength={miraieFields.athome_com.maxLength} />
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.居住用
          }
        >
          <FormControl fieldName="athome_miryoku">
            <FormLabel>物件の魅力</FormLabel>
            <Textarea />
            <FieldLength maxLength={miraieFields.athome_miryoku.maxLength} />
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.居住用
          }
        >
          <FormControl fieldName="athome_end_appeal">
            <FormLabel>エンド向けアピール</FormLabel>
            <Textarea />
            <FieldLength maxLength={miraieFields.athome_end_appeal.maxLength} />
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <FormControl fieldName="athome_biko_1">
          <FormLabel>備考</FormLabel>
          <Textarea />
          <FieldLength maxLength={miraieFields.athome_biko.maxLength} />
          <PortalErrorMessages />
        </FormControl>

        <FormControl fieldName="athome_panorama_id">
          <FormLabel>パノラマコンテンツID</FormLabel>
          <TextInput />
          <PortalErrorMessages />
        </FormControl>

        <MiraieFieldCollapse
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション ||
            isChintai
          }
        >
          <FormControl fieldName="pet_biko">
            <FormLabel>ペット備考</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldCollapse>
      </FormGroup>
    </MiraieFieldCollapse>
  )
}
