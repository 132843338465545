import { Button, ButtonProps, Tooltip } from '@chakra-ui/react'

export type AppendImageButtonProps = ButtonProps & {
  tooltip?: React.ReactNode
}

export const AppendImageButton = ({
  tooltip,
  isDisabled,
  ...props
}: AppendImageButtonProps) => {
  return (
    <Tooltip label={tooltip} isDisabled={!isDisabled} hasArrow>
      <Button variant="outline" isDisabled={isDisabled} {...props} />
    </Tooltip>
  )
}
