import {
  RegisteredRouter,
  RoutePaths as _RoutePaths,
  createRouter,
} from '@tanstack/react-router'

import { NotFound } from './components/NotFound'
import { routeTree } from './routeTree.gen'

export const router = createRouter({
  routeTree,
  notFoundMode: 'root',
  defaultNotFoundComponent: NotFound,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export type RoutePaths = _RoutePaths<RegisteredRouter['routeTree']>
