import { Flex, Grid } from '@chakra-ui/react'

import { FormGroup } from '../FormGroup'

import { NitteiKubunCollapse } from './NitteiKubunCollapse'
import { OpenHouseDateRangeContents } from './OpenHouseDateRangeContents'
import { OpenHouseDatesContents } from './OpenHouseDatesContents'
import { OpenHouseFromDateInput } from './OpenHouseFromDateInput'
import { OpenHouseToDateInput } from './OpenHouseToDateInput'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  TextInput,
} from '@/components/form/controls'
import { useIsChintai } from '@/hooks/useIsChintai'

export const KaisaibiControls = () => {
  const isChintai = useIsChintai()
  return isChintai ? (
    <FormControl fieldset>
      <FormLabel>開催日</FormLabel>
      <Flex gap={2} alignItems="center">
        <FormControl fieldName="openhouse_from_date">
          <OpenHouseFromDateInput />
        </FormControl>
        ~
        <FormControl fieldName="openhouse_to_date">
          <OpenHouseToDateInput />
        </FormControl>
      </Flex>
      <PortalErrorMessages fieldName="openhouse_from_date" />
      <PortalErrorMessages fieldName="openhouse_to_date" />
    </FormControl>
  ) : (
    <NitteiKubunCollapse>
      <FormGroup nested>
        <FormControl fieldset>
          <FormLabel>開催日</FormLabel>
          <OpenHouseDateRangeContents>
            <Flex gap={2} alignItems="center">
              <FormControl fieldName="openhouse_from_date">
                <OpenHouseFromDateInput />
              </FormControl>
              ~
              <FormControl fieldName="openhouse_to_date">
                <OpenHouseToDateInput />
              </FormControl>
            </Flex>
            <PortalErrorMessages fieldName="openhouse_from_date" />
            <PortalErrorMessages fieldName="openhouse_to_date" />
          </OpenHouseDateRangeContents>
          <OpenHouseDatesContents>
            <Grid templateColumns="auto 1fr" alignItems="center" gap={2}>
              {([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const).map(
                (value) => (
                  <FormControl
                    key={value}
                    display="contents"
                    fieldName={
                      value === 1
                        ? 'openhouse_from_date'
                        : `openhouse_date_${value}`
                    }
                  >
                    <FormLabel>{value}</FormLabel>
                    <TextInput type="date" />
                    <PortalErrorMessages gridColumn="2 / -1" />
                  </FormControl>
                ),
              )}
            </Grid>
          </OpenHouseDatesContents>
        </FormControl>
      </FormGroup>
    </NitteiKubunCollapse>
  )
}
