import * as bk_sbt_kbn from '../../bk_sbt_kbn'

import { GroupName } from './groupName'
import { groupedValues } from './groupedValues'

type Values = {
  groupName: GroupName
  bk_sbt_kbn: bk_sbt_kbn.Enum
}

export const getGroupedValues = ({ groupName, bk_sbt_kbn }: Values) =>
  groupedValues[groupName][bk_sbt_kbn]
