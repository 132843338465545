import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const KahiContents = ({ children }: PropsWithChildren) => {
  const [bk_sbt_kbn, athome_c_open_kbn] = useWatchMiraieFields({
    name: ['bk_sbt_kbn', 'athome_c_open_kbn'],
  })
  const isMansion = bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
  const athomeOpen = athome_c_open_kbn === miraieFields.c_open_kbn.Enum.掲載
  const display = isMansion && athomeOpen

  return display && children
}
