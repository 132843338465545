import { Input, InputProps } from '@chakra-ui/react'
import { omit } from '@terass/common/src/utils'
import { ChangeEventHandler } from 'react'

import { EnsenEkiIndex } from '@/components/form/EnsenEki/useEnsenEkiContext'
import { walkTimeToDistance } from '@/components/form/EnsenEki/utils'
import {
  useMiraieFormContext,
  useMiraieFormController,
} from '@/hooks/useMiraieForm'

export type WalkTimeInputProps = InputProps & { index: EnsenEkiIndex }

export function WalkTimeInput({ index, ...props }: WalkTimeInputProps) {
  const { setValue } = useMiraieFormContext()
  const { field } = useMiraieFormController({ name: `walk_time_${index}` })
  const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.stopPropagation()
    e.currentTarget.blur()
  }
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value ? Number(e.target.value) : null
    field.onChange(value)
    if (value !== null)
      setValue(`walk_kyori_${index}`, walkTimeToDistance(value))
  }

  return (
    <Input
      {...omit(field, ['value'])}
      type="number"
      onWheel={onWheel}
      onChange={onChange}
      {...props}
    />
  )
}

/**
 * InputGroup が内部的に id で判定しているため必要
 * ないと border-radius などのスタイルが上書きされなくなる
 */
WalkTimeInput.id = Input.id
