import { LatLng } from './types'

/**
 * 緯度経度から距離を計算する - 球面三角法
 * @return 2点間の直線距離(m)
 */
export function getDirectDistance(
  { lat: lat1, lng: lng1 }: LatLng,
  { lat: lat2, lng: lng2 }: LatLng,
) {
  const R = Math.PI / 180
  lat1 *= R
  lng1 *= R
  lat2 *= R
  lng2 *= R
  const distance =
    6371 *
    Math.acos(
      Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) +
        Math.sin(lat1) * Math.sin(lat2),
    )

  return Math.floor(distance * 1000)
}
