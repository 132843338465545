import { InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import {
  EnumRadios,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  TextInput,
  UnselectedRadio,
} from '@/components/form/controls'

export const JutakuSeinou = () => {
  return (
    <FormGroup>
      <FormGroupHeading>
        住宅性能評価書（ネットレポートのみ掲載）
      </FormGroupHeading>

      <FormControl fieldName="s_seinohyouka_kbn" fieldset>
        <FormLabel>設計性能評価</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.s_seinohyouka_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <MiraieFieldCollapse
        name={['s_seinohyouka_kbn']}
        display={(s_seinohyouka_kbn) => !!s_seinohyouka_kbn}
      >
        <FormGroup nested>
          <FormControl fieldName="s_seinohyouka_biko">
            <InputGroup>
              <InputLeftAddon>その他特記事項</InputLeftAddon>
              <TextInput />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </MiraieFieldCollapse>

      <FormControl fieldName="k_seinohyouka_kbn" fieldset>
        <FormLabel>建設性能評価</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.k_seinohyouka_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <MiraieFieldCollapse
        name={['k_seinohyouka_kbn']}
        display={(k_seinohyouka_kbn) => !!k_seinohyouka_kbn}
      >
        <FormGroup nested>
          <FormControl fieldName="k_seinohyouka_biko">
            <InputGroup>
              <InputLeftAddon>その他特記事項</InputLeftAddon>
              <TextInput />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </MiraieFieldCollapse>
    </FormGroup>
  )
}
