import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const TorokuChusenCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['hanbai_hoshiki_kbn']}
      display={(hanbai_hoshiki_kbn) =>
        hanbai_hoshiki_kbn === miraieFields.hanbai_hoshiki_kbn.Enum.登録抽選
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
