export const ROLE_IS_REQUIRED_ERROR_MESSAGE = '__ROLE_IS_REQUIRED_ERROR__'

export const isRoleRequiredErrorMessage = (message: string) =>
  message.includes(ROLE_IS_REQUIRED_ERROR_MESSAGE)

export const INVALID_EMAIL_DOMAIN_ERROR_MESSAGE =
  '__INVALID_EMAIL_DOMAIN_ERROR_MESSAGE__'

export const isInvalidEmailDomainErrorMessage = (message: string) =>
  message.includes(INVALID_EMAIL_DOMAIN_ERROR_MESSAGE)
