import { DndContext } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { miraieFields } from '@terass/common/src'
import { ReactNode } from 'react'

import { AppendImageButton } from '@/components/form/BukkenImages/AppendImageButton'
import { HiddenImageInput } from '@/components/form/BukkenImages/Controls/HiddenImageInput'
import { ImageControls } from '@/components/form/BukkenImages/Controls/ImageControls'
import {
  useAppendableImages,
  useBukkenImagesFieldArray,
} from '@/components/form/BukkenImages/hooks'
import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'

export type ImagesCategoryProps = {
  category: miraieFields.images.category.Enum
  heading: ReactNode
}

export function ImagesCategory({ category, heading }: ImagesCategoryProps) {
  const { fields, isAppendable, maxSize, appendImageFiles } =
    useAppendableImages({
      category,
    })
  const { move } = useBukkenImagesFieldArray()

  return (
    <FormGroup>
      <FormGroupHeading>
        {heading} ({fields.length}/{maxSize})
      </FormGroupHeading>
      <DndContext
        onDragEnd={({ active, over }) => {
          if (!over || active.id === over.id) return
          const oldIndex = fields.findIndex(({ id }) => id === active.id)
          const newIndex = fields.findIndex(({ id }) => id === over.id)
          move(oldIndex, newIndex)
        }}
      >
        <SortableContext items={fields}>
          {fields.map(({ id }, index) => (
            <ImageControls key={id} {...{ category, index, id }} />
          ))}
        </SortableContext>
      </DndContext>
      <AppendImageButton
        as="label"
        isDisabled={!isAppendable}
        tooltip={`最大${maxSize}枚まで登録可能です。`}
      >
        画像を追加
        <HiddenImageInput
          disabled={!isAppendable}
          handleImageFiles={appendImageFiles}
        />
      </AppendImageButton>
    </FormGroup>
  )
}
