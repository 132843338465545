import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const OpenHouseDateRangeContents = ({ children }: PropsWithChildren) => {
  const [tenanto_status_com] = useWatchMiraieFields({
    name: ['tenanto_status_com'],
  })
  const display =
    !tenanto_status_com ||
    tenanto_status_com !== miraieFields.tenanto_status_com.Enum.日時指定

  return <>{display && children}</>
}
