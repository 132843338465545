export type RequestCondition = Omit<
  google.maps.places.PlaceSearchRequest,
  'location' | 'radius'
>

/** react-google-mapsのnearbySearchをpromisifyしたラッパー */
export const nearbySearch = async (
  map: google.maps.Map,
  requests: RequestCondition[],
  radius: number,
  location?: google.maps.LatLngLiteral,
) => {
  const service = new google.maps.places.PlacesService(map)
  const places = (
    await Promise.all(
      requests.map(
        (request) =>
          new Promise<google.maps.places.PlaceResult[]>((resolve) => {
            service.nearbySearch(
              {
                ...request,
                radius,
                location: location ?? map.getCenter(),
              },
              (result, status) => {
                if (
                  status !== google.maps.places.PlacesServiceStatus.OK ||
                  !result
                ) {
                  resolve([])
                  return
                }
                resolve(result)
              },
            )
          }),
      ),
    )
  ).flat()
  // 重複を除く
  return Array.from(
    new Map(places.map((place) => [place.place_id, place])).values(),
  )
}
