import { Input, InputProps } from '@chakra-ui/react'

import { useFormControlContext } from '@/components/form/controls/FormControl'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'

export const NumberInput = (props: Omit<InputProps, 'onWheel'>) => {
  const { fieldName } = useFormControlContext()
  if (!fieldName) throw new Error()

  const { register } = useMiraieFormContext()

  // スクロール時に数値が上限しないように、フォーカスを外す
  const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.stopPropagation()
    e.currentTarget.blur()
  }
  return (
    <Input
      type="number"
      {...register(fieldName, {
        setValueAs: (value: string | null) =>
          value !== '' && value !== null ? Number(value) : null,
      })}
      onWheel={onWheel}
      {...props}
    />
  )
}

/**
 * InputGroup が内部的に id で判定しているため必要
 * ないと border-radius などのスタイルが上書きされなくなる
 */
NumberInput.id = Input.id
