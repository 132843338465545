import { miraieFields } from '@terass/common/src'

import { Textarea } from '@/components/form/controls'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const StaffCommentTextarea = () => {
  const [staff_keisai_flg] = useWatchMiraieFields({
    name: ['staff_keisai_flg'],
  })
  return (
    <Textarea
      isDisabled={staff_keisai_flg !== miraieFields.staff_keisai_flg.Enum.指示}
    />
  )
}
