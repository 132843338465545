import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from '@chakra-ui/react'
import { useRef } from 'react'

export type ConfirmAlertDialogProps = Omit<
  AlertDialogProps,
  'leastDestructiveRef' | 'children'
> & {
  onAbort: () => void
}

export const ConfirmAlertDialog = ({
  onAbort,
  ...props
}: ConfirmAlertDialogProps) => {
  const leastDestructiveRef = useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog leastDestructiveRef={leastDestructiveRef} {...props}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>確認</AlertDialogHeader>
          <AlertDialogBody>
            内容に変更があります。保存せずに閉じますか？
          </AlertDialogBody>
          <AlertDialogFooter gap={2}>
            <Button
              ref={leastDestructiveRef}
              onClick={props.onClose}
              colorScheme="gray"
            >
              編集画面に戻る
            </Button>
            <Button onClick={onAbort} colorScheme="red">
              保存せずに閉じる
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
