/**
 * パンフレット情報キャプション区分
 */
export const Enum = {
  'こちらのパンフレットが届きます！今すぐ資料請求しよう！': '2',
  '物件のコンセプトや詳細な間取りプランが届きます。今すぐ資料請求！': '3',
  '物件の周辺情報も充実！今すぐ資料請求しよう！': '4',
  '設備や構造の詳細を資料請求して確認しよう！': '5',
  その他: '6',
} as const
