import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { omit } from '@terass/common/src/utils'

import { MiraieFieldContents } from '../MiraieFieldContents'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import {
  EnumOptions,
  EnumRadios,
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  UnselectedRadio,
} from '@/components/form/controls'
import { useIsChintai } from '@/hooks/useIsChintai'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const TorihikiJouhou = () => {
  const [bk_sbt_kbn] = useWatchMiraieFields({
    name: ['bk_sbt_kbn'],
  })

  const isChintai = useIsChintai()

  return (
    <FormGroup>
      <FormGroupHeading>取引情報</FormGroupHeading>
      {/* user_tesuryo_priceが複数回出てくるため、switch内で一回のみレンダリングされるように強制する
       ∵FieldNameが重複する場合、registerで2回登録すると最初に登録した要素は値が反映されなくなるため */}
      {(() => {
        switch (bk_sbt_kbn) {
          case miraieFields.bk_sbt_kbn.Enum.売地:
          case miraieFields.bk_sbt_kbn.Enum.売戸建住宅:
          case miraieFields.bk_sbt_kbn.Enum.売マンション:
            return (
              <FormControl fieldset>
                <FormLabel>客付報酬/手数料(税込)</FormLabel>
                <FormGroup nested>
                  <MiraieFieldContents
                    name={['kyakutsuke_pass_flg']}
                    display={(kyakutsuke_pass_flg) =>
                      kyakutsuke_pass_flg ===
                      miraieFields.kyakutsuke_pass_flg.Enum.可
                    }
                  >
                    <FormControl fieldName="customer_tesuryo_kbn">
                      <FormLabel>報酬形態</FormLabel>
                      <Select>
                        <PlaceholderOption />
                        <EnumOptions
                          enum={miraieFields.customer_tesuryo_kbn.Enum}
                        />
                      </Select>
                      <FormHelperText>
                        {
                          'SUUMOは全項目対応です。\nAthomeは「分かれ」と「当方不払」が対応しており、HOMES・Yahooは「分かれ」のみが対応しております。'
                        }
                      </FormHelperText>
                      <PortalErrorMessages />
                    </FormControl>

                    <FormControl fieldset>
                      <FormLabel>客付報酬配分</FormLabel>
                      <Flex gap={2} alignItems="center">
                        <FormControl fieldName="kyakutsuke_tesuryo_ritsu">
                          <InputGroup>
                            <NumberInput
                              step={miraieFields.kyakutsuke_tesuryo_ritsu.step}
                            />
                            <InputRightAddon>％</InputRightAddon>
                          </InputGroup>
                        </FormControl>

                        <Text>+</Text>

                        <FormControl fieldName="kyakutsuke_tesuryo_price">
                          <InputGroup>
                            <NumberInput />
                            <InputRightAddon>円</InputRightAddon>
                          </InputGroup>
                        </FormControl>
                      </Flex>
                      <PortalErrorMessages fieldName="kyakutsuke_tesuryo_ritsu" />
                      <PortalErrorMessages fieldName="kyakutsuke_tesuryo_price" />
                    </FormControl>
                  </MiraieFieldContents>

                  <FormControl fieldset>
                    <FormLabel>一般向け表示</FormLabel>
                    <Flex gap={2} alignItems="center">
                      <FormControl fieldName="user_tesuryo_ritsu">
                        <InputGroup>
                          <NumberInput
                            step={miraieFields.user_tesuryo_ritsu.step}
                          />
                          <InputRightAddon>％</InputRightAddon>
                        </InputGroup>
                      </FormControl>

                      <Text>+</Text>

                      <FormControl fieldName="user_tesuryo_price">
                        <InputGroup>
                          <NumberInput />
                          <InputRightAddon>円</InputRightAddon>
                        </InputGroup>
                      </FormControl>
                    </Flex>
                    <PortalErrorMessages fieldName="user_tesuryo_ritsu" />
                    <PortalErrorMessages fieldName="user_tesuryo_price" />
                  </FormControl>
                </FormGroup>
              </FormControl>
            )
          case miraieFields.bk_sbt_kbn.Enum.居住用:
            return (
              <FormControl fieldset>
                <FormLabel>仲介手数料</FormLabel>
                <FormGroup nested>
                  <FormControl fieldset>
                    <FormLabel>一般消費者向け仲介手数料</FormLabel>
                    <Flex gap={2} alignItems="center">
                      <FormControl fieldName="user_tesuryo_month">
                        <InputGroup>
                          <NumberInput />
                          <InputRightAddon>ヶ月</InputRightAddon>
                        </InputGroup>
                        <FormHelperText>
                          税額を含めた月数(例：1.1)で入力してください。
                        </FormHelperText>
                      </FormControl>

                      <FormControl fieldName="user_tesuryo_price">
                        <InputGroup>
                          <NumberInput />
                          <InputRightAddon>円</InputRightAddon>
                        </InputGroup>
                        <FormHelperText>
                          税額を含めた金額で入力してください。
                        </FormHelperText>
                      </FormControl>
                    </Flex>
                    <FormHelperText>
                      月数か金額のいずれかを指定してください。
                    </FormHelperText>
                    <PortalErrorMessages fieldName="user_tesuryo_month" />
                    <PortalErrorMessages fieldName="user_tesuryo_price" />
                  </FormControl>

                  <FormControl fieldset>
                    <FormLabel>負担割合</FormLabel>
                    <Flex gap={2} alignItems="center">
                      <FormControl fieldName="lender_ritsu">
                        <InputGroup>
                          <InputLeftAddon>貸主率</InputLeftAddon>
                          <NumberInput step={miraieFields.lender_ritsu.step} />
                          <InputRightAddon>％</InputRightAddon>
                        </InputGroup>
                      </FormControl>

                      <FormControl fieldName="kari_ritsu">
                        <InputGroup>
                          <InputLeftAddon>借主率</InputLeftAddon>
                          <NumberInput step={miraieFields.kari_ritsu.step} />
                          <InputRightAddon>％</InputRightAddon>
                        </InputGroup>
                      </FormControl>
                    </Flex>
                    <PortalErrorMessages fieldName="lender_ritsu" />
                    <PortalErrorMessages fieldName="kari_ritsu" />
                  </FormControl>

                  <FormControl fieldset>
                    <FormLabel>配分割合</FormLabel>
                    <Flex gap={2} alignItems="center">
                      <FormControl fieldName="moto_ritsu">
                        <InputGroup>
                          <InputLeftAddon>元付率</InputLeftAddon>
                          <NumberInput step={miraieFields.moto_ritsu.step} />
                          <InputRightAddon>％</InputRightAddon>
                        </InputGroup>
                      </FormControl>

                      <FormControl fieldName="kyaku_ritsu">
                        <InputGroup>
                          <InputLeftAddon>客付率</InputLeftAddon>
                          <NumberInput step={miraieFields.kyaku_ritsu.step} />
                          <InputRightAddon>％</InputRightAddon>
                        </InputGroup>
                      </FormControl>
                    </Flex>
                    <PortalErrorMessages fieldName="moto_ritsu" />
                    <PortalErrorMessages fieldName="kyaku_ritsu" />
                  </FormControl>
                </FormGroup>
              </FormControl>
            )

          default:
            return <></>
        }
      })()}

      <FormControl fieldset>
        <FormLabel>物件確認</FormLabel>
        <FormGroup nested>
          {/* bk_kakunin_dateはみらいえのコード・日付関連セクションからこちらへ移植 */}
          <FormControl fieldName="bk_kakunin_date">
            <InputGroup>
              <InputLeftAddon>物件確認日</InputLeftAddon>
              <TextInput type="date" />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="bk_kakuninsho_send_date">
            <InputGroup>
              <InputLeftAddon>物件確認書送信日</InputLeftAddon>
              <TextInput type="date" />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="bk_kokoku_kakunin_date">
            <InputGroup>
              <InputLeftAddon>広告掲載確認日</InputLeftAddon>
              <TextInput type="date" />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </FormControl>

      <FormControl fieldset>
        <FormLabel>広告費</FormLabel>
        <FormGroup nested>
          <FormControl fieldName="kokokuhi_flg" fieldset>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.kokokuhi_flg.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>

          <MiraieFieldCollapse
            name={['kokokuhi_flg']}
            display={(kokokuhi_flg) =>
              kokokuhi_flg === miraieFields.kokokuhi_flg.Enum.有
            }
          >
            <FormControl fieldset>
              <Flex gap={2} alignItems="center">
                <FormControl fieldName="kokokuhi_to">
                  <InputGroup>
                    <InputLeftAddon>広告費</InputLeftAddon>
                    <NumberInput step={miraieFields.kokokuhi_to.step} />
                  </InputGroup>
                </FormControl>

                <FormControl fieldName="kokokuhi_to_tani_kbn">
                  <Select>
                    <PlaceholderOption />
                    <EnumOptions
                      enum={omit(
                        miraieFields.kokokuhi_to_tani_kbn.Enum,
                        isChintai ? [] : ['ヶ月'],
                      )}
                    />
                  </Select>
                </FormControl>
              </Flex>
              <PortalErrorMessages fieldName="kokokuhi_to" />
              <PortalErrorMessages fieldName="kokokuhi_to_tani_kbn" />
            </FormControl>
          </MiraieFieldCollapse>
        </FormGroup>
      </FormControl>
    </FormGroup>
  )
}
