import {
  Button,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from '@chakra-ui/react'
import { useNavigate } from '@tanstack/react-router'
import { miraieFields } from '@terass/common/src'
import { FormProvider } from 'react-hook-form'

import { ShopSelectOptions } from '../ShopSelectOptions'

import { Contents } from '@/components/Contents'
import {
  BkItemKbnOptions,
  BkSbtKbnOptions,
  FormControl,
  FormErrorMessage,
  Select,
} from '@/components/CreateBaitaiKeisaiForm'
import { PlaceholderOption } from '@/components/form/controls'
import { useCreateBaitaiKeisaiForm } from '@/hooks/useCreateBaitaiKeisaiForm'
import { useOperator } from '@/hooks/useOperator'
import { createImport } from '@/utils/import'
import { convertOfficeToShop } from '@/utils/officeToShop'

export const CreateSakimonoModalContent = () => {
  const { onClose } = useModalContext()
  const { email, office } = useOperator()
  const methods = useCreateBaitaiKeisaiForm({
    defaultValues: { office },
  })
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = methods
  const navigate = useNavigate()
  const onSubmit = handleSubmit(async (values) => {
    const { id } = await createImport({
      __readers: [email],
      __writers: [email],
      __tera_three: {},
      formData: {
        ...values,
        sakimono_flg: miraieFields.sakimono_flg.Enum.先物,
      },
      teraSeven: {},
      shopId: convertOfficeToShop(values.office),
    })

    navigate({
      to: '/bukkens/$importId/edit',
      params: { importId: id },
    })
  })

  return (
    <FormProvider {...methods}>
      <Contents as="form" onSubmit={onSubmit}>
        <ModalContent>
          <ModalHeader>先物媒体掲載を作成</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column" gap={4}>
            <FormControl fieldName="bk_sbt_kbn">
              <FormLabel fontWeight="semibold">物件種別</FormLabel>
              <Select>
                <PlaceholderOption />
                <BkSbtKbnOptions />
              </Select>
              <FormErrorMessage />
            </FormControl>
            <FormControl fieldName="bk_item_kbn">
              <FormLabel fontWeight="semibold">物件種目</FormLabel>
              <Select>
                <PlaceholderOption />
                <BkItemKbnOptions />
              </Select>
              <FormErrorMessage />
            </FormControl>
            <FormControl fieldName="office">
              <FormLabel fontWeight="semibold">店舗</FormLabel>
              <Select>
                <ShopSelectOptions />
              </Select>
              <FormErrorMessage />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={4} onClick={onClose}>
              キャンセル
            </Button>
            <Button type="submit" isLoading={isSubmitting}>
              作成
            </Button>
          </ModalFooter>
        </ModalContent>
      </Contents>
    </FormProvider>
  )
}
