import { Box, Card, CardBody } from '@chakra-ui/react'

import { Mototsuke } from './Mototsuke'
import { TorihikiJouhou } from './TorihikiJouhou'

import { FormGroup } from '@/components/form/FormGroup'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { sectionIds } from '@/components/form/SectionBar'

export const TorihikiSection: React.FC = () => {
  return (
    <Box as="section" id={sectionIds.torihikiSection}>
      <FormSectionHeading>取引・付帯情報</FormSectionHeading>
      <Card>
        <CardBody as={FormGroup}>
          <TorihikiJouhou />
          <Mototsuke />
        </CardBody>
      </Card>
    </Box>
  )
}
