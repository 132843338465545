import { miraieFields } from '@terass/common/src'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

export const ChinhoshokinCollapse = ({ children }: PropsWithChildren) => {
  return (
    <MiraieFieldCollapse
      name={['chinhoshokin_kbn']}
      display={(chinhoshokin_kbn) =>
        chinhoshokin_kbn === miraieFields.chinhoshokin_kbn.Enum.利用可 ||
        chinhoshokin_kbn === miraieFields.chinhoshokin_kbn.Enum.加入要
      }
    >
      {children}
    </MiraieFieldCollapse>
  )
}
