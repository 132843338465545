import {
  Badge,
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { QueryErrorResetBoundary, useMutation } from '@tanstack/react-query'
import { createFileRoute, useNavigate, Link } from '@tanstack/react-router'
import { miraieFields } from '@terass/common/src'
import { BaikaiKeiyaku } from '@terass/common/src/tera-three'
import { swapKeyValue } from '@terass/common/src/utils'
import { formatDate, parseDateString } from '@terass/common/src/utils/date'
import { Suspense, useState } from 'react'

import { CSpinner, PageHeading } from '@/components/Atoms'
import { ShopSelectOptions } from '@/components/ShopSelectOptions'
import { SakimonoBaitaiKeisaiIraiList } from '@/components/chintai/SakimonoBaitaiKeisaiIraiList'
import { SakimonoBaitaiKeisaiIraiListErrorBoundary } from '@/components/chintai/SakimonoBaitaiKeisaiIraiListErrorBoundary'
import { SakimonoBaitaiKeisaiIraiListFallback } from '@/components/chintai/SakimonoBaitaiKeisaiIraiListFallback'
import { useMedContracts } from '@/hooks/useMedContracts'
import { useOperator } from '@/hooks/useOperator'
import { createImport } from '@/utils/import'
import { convertOfficeToShop } from '@/utils/officeToShop'
import { priceToJpString } from '@/utils/utils'

export const Route = createFileRoute('/contracts/')({
  component: MedContracts,
})

export function MedContracts() {
  const { data, isLoading } = useMedContracts()

  return (
    <>
      <Flex justifyContent="space-between" my={2}>
        <PageHeading title="物件選択" />
        <Flex gap="8px">
          <Link to="/">
            <Button colorScheme="gray">キャンセル</Button>
          </Link>
        </Flex>
      </Flex>
      <Text>
        媒体掲載を行う媒介契約または先物を選択し掲載情報の入力に進んでください
      </Text>
      <Tabs isLazy>
        <TabList>
          <Tab>媒介契約</Tab>
          {/* 初期リリースでは賃貸未対応 */}
          {/* <Tab>賃貸</Tab> */}
        </TabList>

        <TabPanels>
          <TabPanel px={0}>
            {isLoading ? (
              <CSpinner />
            ) : !data ? (
              <Text>媒介契約が取得できませんでした。</Text>
            ) : data.length === 0 ? (
              <Text>媒介契約がありません。</Text>
            ) : (
              data.map((con) => (
                <MedContractCard
                  key={con.id}
                  baikaiKeiyaku={con}
                ></MedContractCard>
              ))
            )}
          </TabPanel>
          <TabPanel px={0}>
            <QueryErrorResetBoundary>
              <SakimonoBaitaiKeisaiIraiListErrorBoundary
                fallback={<SakimonoBaitaiKeisaiIraiListFallback />}
              >
                <Suspense fallback={<CSpinner />}>
                  <SakimonoBaitaiKeisaiIraiList />
                </Suspense>
              </SakimonoBaitaiKeisaiIraiListErrorBoundary>
            </QueryErrorResetBoundary>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

type Props = {
  baikaiKeiyaku: BaikaiKeiyaku
}

const MedContractCard: React.FC<Props> = ({ baikaiKeiyaku }) => {
  const { email, office } = useOperator()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [officeName, setOfficeName] = useState<string>(office)

  const {
    id,
    name,
    propertyId,
    bk_sbt_kbn,
    bk_item_kbn,
    mediationCategory,
    status,
    mansionFloor,
    price, // ここはT3の価格なので常に正規化されている
    startDate,
    endDate,
  } = baikaiKeiyaku
  const createImportMutation = useMutation({
    mutationFn: async () => {
      // 媒介契約の情報から媒体掲載を作成
      const shopId = convertOfficeToShop(officeName)
      const data = await createImport({
        __readers: [email],
        __writers: [email],
        __tera_three: {
          baikaiKeiyakuId: id,
          bukkenId: propertyId ?? undefined,
        },
        formData: {
          bk_sbt_kbn,
          bk_item_kbn,
          tatemono_name: name ?? undefined,
          shozai_kai: mansionFloor ?? undefined,
          torihiki_kbn: mediationCategory ?? undefined,
          price: miraieFields.price.convertToMiraiePrice(
            price,
            miraieFields.bk_sbt_kbn.isChintai(bk_sbt_kbn),
          ),
          sakimono_flg: miraieFields.sakimono_flg.Enum.自社,
        },
        teraSeven: {},
        shopId,
      })

      return data
    },
    onSuccess: ({ id }) =>
      navigate({ to: '/bukkens/$importId/edit', params: { importId: id } }),
  })

  return (
    <Box
      border="solid 1px"
      borderColor="gray.400"
      borderRadius="10px"
      p="12px"
      my="12px"
      _hover={{ bg: 'gray.200' }}
      transition="background-color 100ms linear"
      onClick={onOpen}
    >
      <Flex gap="10px">
        <Badge borderRadius="4px" px="16px" variant="solid">
          {mediationCategory &&
            `${
              swapKeyValue(miraieFields.torihiki_kbn.Enum)[mediationCategory]
            }契約`}
        </Badge>
        <Badge borderRadius="4px" px="16px" variant="subtle">
          {status}
        </Badge>
        <Badge borderRadius="4px" px="16px" variant="outline">
          {swapKeyValue(miraieFields.bk_sbt_kbn.Enum)[bk_sbt_kbn]}
        </Badge>
      </Flex>
      <Text fontSize="24px" fontWeight="700" mt="12px">
        {name ?? '---'}
      </Text>
      <Text fontWeight="700" fontSize="36px">
        {price ? priceToJpString(price) : '---'}
      </Text>
      <Text color="gray.500" fontWeight="700">
        契約期間：
        {startDate && endDate
          ? `${formatDate(
              parseDateString(startDate),
            )} 〜 ${formatDate(parseDateString(endDate))}`
          : '---'}
      </Text>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        closeOnEsc={!createImportMutation.isPending}
        closeOnOverlayClick={!createImportMutation.isPending}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>媒体掲載作成</ModalHeader>
          <ModalCloseButton isDisabled={createImportMutation.isPending} />
          <ModalBody display="flex" flexDirection="column" gap={4}>
            <Text>以下の媒介契約から媒体掲載を作成します。</Text>

            <Box>
              <FormLabel fontWeight="semibold">物件名</FormLabel>
              <Text>{name ?? '---'}</Text>
            </Box>

            <Box>
              <FormLabel fontWeight="semibold">価格</FormLabel>
              <Text>{price ? priceToJpString(price) : '---'}</Text>
            </Box>

            <Heading size="sm">店舗を選択</Heading>
            <Select
              onChange={(e) => {
                setOfficeName(e.target.value)
              }}
              value={officeName}
            >
              <ShopSelectOptions />
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              colorScheme="gray"
              isDisabled={createImportMutation.isPending}
            >
              キャンセル
            </Button>
            <Button
              ml={3}
              onClick={() => createImportMutation.mutate()}
              isLoading={createImportMutation.isPending}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
