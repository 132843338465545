/**
 * 地目区分
 */
export const Enum = {
  宅地: '1',
  田: '2',
  畑: '3',
  山林: '4',
  雑種地: '5',
  運河用地: '6',
  塩田: '7',
  境内地: '8',
  原野: '10',
  公園: '11',
  公衆用道路: '12',
  鉱泉地: '13',
  水道用地: '14',
  井溝: '15',
  ため池: '16',
  池沼: '17',
  堤: '18',
  保安林: '19',
  牧場: '20',
  墓地: '21',
  用悪水路: '22',
  その他: '23',
} as const
