import { useLoadScript } from './useLoadScript'
import { useMiraieFormContext } from './useMiraieForm'

import { FullAddressPath, getFullAddressName } from '@/hooks/useAddressName'
import { getLatLngByAddress } from '@/utils/googleMap/geoCoding'
import { toast } from '@/utils/standaloneToast'

/**
 * フォームに入力されている住所をもとに、緯度経度を更新する関数を返す
 */
export const useUpdateLatLngByAddress = () => {
  const { setValue } = useMiraieFormContext()
  const { isLoaded, loadError } = useLoadScript()
  if (!isLoaded || loadError) return () => {}

  return async (addressPath: FullAddressPath) => {
    try {
      const addressName = await getFullAddressName(addressPath)
      const position = await getLatLngByAddress(addressName)
      setValue('lat', position.lat)
      setValue('lon', position.lng)
      const successToastId = 'successToast'
      if (!toast.isActive(successToastId))
        toast({
          id: successToastId,
          description: '緯度、経度を更新しました',
          status: 'success',
          position: 'top',
          isClosable: true,
        })
    } catch {
      const failureToastId = 'failureToast'
      if (!toast.isActive(failureToastId))
        toast({
          id: failureToastId,
          description: '緯度、経度の更新に失敗しました',
          status: 'warning',
          position: 'top',
          isClosable: true,
        })
    }
  }
}
