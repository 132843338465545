import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react'

import { useBukkenImagesFormContext } from '@/components/form/BukkenImages/hooks'
import { BukkenImagesPath } from '@/components/form/BukkenImages/types'

export type SelectProps = ChakraSelectProps & {
  fieldName: BukkenImagesPath
}

export const Select = ({ fieldName, ...props }: SelectProps) => {
  const { register } = useBukkenImagesFormContext()
  return <ChakraSelect {...register(fieldName)} {...props} />
}
