import { Shikuchoson } from '@terass/common/src/firestore/Address'
import { doc, onSnapshot, query } from 'firebase/firestore'

import { useWatchAddressForm } from './useAddressForm'
import { useWatchAddressCodes } from './useWatchAddressCodes'

import { useObserver } from '@/hooks/useObserver'
import { DocumentNotFoundError } from '@/utils/errors'
import { getCollection } from '@/utils/firestore'

export const useShikuchosonList = (): { id: string; data: Shikuchoson }[] => {
  const prefectureCode = useWatchAddressForm('prefectureCode')
  return useObserver({
    observerKey: ['shikuchosonList', prefectureCode],
    observerFn: (onChange, onError, { observerKey: [, prefectureCode] }) =>
      onSnapshot(
        query(getCollection('address', prefectureCode, 'shikuchoson')),
        (snapshot) =>
          onChange(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })),
          ),
        onError,
      ),
  })
}

export const useShikuchoson = () => {
  const { prefectureCode, shikuchosonCode } = useWatchAddressCodes()

  return useObserver({
    observerKey: ['shikuchoson', prefectureCode, shikuchosonCode],
    observerFn: (
      onChange,
      onError,
      { observerKey: [, prefectureCode, shikuchosonCode] },
    ) =>
      onSnapshot(
        doc(
          getCollection('address', prefectureCode, 'shikuchoson'),
          shikuchosonCode,
        ),
        (snapshot) => {
          const data = snapshot.data()
          if (data) onChange(data)
          else onError(new DocumentNotFoundError())
        },
      ),
  })
}
