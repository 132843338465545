import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  UnselectedRadio,
  EnumRadios,
} from '../controls'

import { BikeOkibaPriceCollapse } from '@/components/form/PriceAndCost/BikeOkibaPriceCollapse'
import { BikeRyokin } from '@/components/form/PriceAndCost/BikeRyokin'

export const BikeRyokinControl: React.FC = () => {
  return (
    <>
      <FormControl fieldName="bikeokiba_yuryo_flg" fieldset>
        <FormLabel>有料・無料</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.bikeokiba_yuryo_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <BikeOkibaPriceCollapse>
        <BikeRyokin />
      </BikeOkibaPriceCollapse>
    </>
  )
}
